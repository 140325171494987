import { SvgIcon, SvgIconProps } from '@mui/material';

export const ErrorIcon20 = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      width: '20px',
      height: '20px',
    }}
  >
    <path
      d="M10.6673 18.3332C15.2697 18.3332 19.0007
      14.6022 19.0007 9.99984C19.0007 5.39746
      15.2697 1.6665 10.6673 1.6665C6.06494
      1.6665 2.33398 5.39746 2.33398 9.99984C2.33398
      14.6022 6.06494 18.3332 10.6673 18.3332Z"
      stroke="#C94937"
      strokeWidth="1.33"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M8.02637 12.6407L13.3082 7.35889"
      stroke="#C94937"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M13.3082 12.6407L8.02637 7.35889"
      stroke="#C94937"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
