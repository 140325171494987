import { lighten, styled } from '@mui/system';
import {
  Autocomplete,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { stagesetColors } from '../../../../theme/stagesetPalette';

export const CurrencyItem = styled<any>(MenuItem)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '12px',
  backgroundColor: stagesetColors.white[100],
  '&:not(:last-child) path': {
    stroke: stagesetColors.newGrey[400],
  },

  '&:hover': {
    backgroundColor: lighten(stagesetColors.newGrey[100], 0.6),
  },
  '&:active': {
    backgroundColor: stagesetColors.newGrey[100],
  },
  '&.disabled': {
    stroke: stagesetColors.newGrey[300],
  },
  '& [data-focused=y]': {
    backgroundColor: stagesetColors.newGrey[100],
  },
}));

export const CurrencyLogo = styled<any>(Typography)(() => ({
  width: '32px',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '22px',
  '&.currency-input-logo': {
    textAlign: 'center',
    color: stagesetColors.newGrey[800],
  },
}));

export const CurrencyInput = styled<any>(TextField)(() => ({
  '& fieldset': {
    border: '1px solid',
    borderColor: stagesetColors.newGrey[300],
    borderRadius: '10px !important',
    fontSize: '16px',
    lineHeight: '22px',
    '& legend': {
      fontSize: '0.55em',
    },
  },
  '& .MuiInputLabel-root': {
    color: stagesetColors.newGrey[500],
    fontSize: '12px',
    lineHeight: '14px',
    top: '-2px',
    height: '14px',
    overflow: 'visible',
    '&.Mui-focused': {
      top: '0',
    },
    '&.MuiFormLabel-filled': {
      top: '0',
    },
  },
  '&:not(.multiline).size': {
    '&-l': {
      height: '48px',
      '& input': {
        color: stagesetColors.newGrey[800],
        height: '33px',
      },
      '& .MuiInputLabel-root': {
        top: '2px',
      },
    },
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: stagesetColors.white[100],
    padding: '0px 12px',
  },
  '& input': {
    padding: '0px',
  },
  '& .MuiInputBase-root:hover fieldset': {
    border: '1px solid',
    borderColor: stagesetColors.newGrey[400],
  },
  '& .MuiInputBase-root.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
    border: '1px solid',
    borderColor: stagesetColors.newGrey[400],
  },
  '& .MuiOutlinedInput-root:active fieldset': {
    border: '1px solid',
    borderColor: stagesetColors.newGrey[500],
    borderRadius: '20px',
    color: stagesetColors.newGrey[800],
  },
  '& .MuiInputBase-root:active input': {
    color: stagesetColors.newGrey[800],
  },
  '& input[aria-expanded=true]': {
    color: `${stagesetColors.newGrey[800]} !important`,
  },
  '&.disabled': {
    '& fieldset': {
      borderColor: stagesetColors.newGrey[200],
      color: stagesetColors.newGrey[400],
    },
    '& .MuiInputBase-root.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: stagesetColors.newGrey[200],
      color: stagesetColors.newGrey[400],
    },
    '& .MuiOutlinedInput-root:active fieldset': {
      borderColor: stagesetColors.newGrey[200],
      color: stagesetColors.newGrey[400],
    },
    '& .MuiInputBase-root:hover fieldset': {
      borderColor: stagesetColors.newGrey[200],
      color: stagesetColors.newGrey[400],
    },
    '& .MuiInputLabel-root': {
      color: stagesetColors.newGrey[400],
    },
  },
}));

export const CurrencyAutocomplete = styled<any>(Autocomplete)(() => ({
  padding: '0',
}));

export const CurrencyPaper = styled<any>(Paper)(() => ({
  marginTop: '8px',
  border: '1px solid',
  borderRadius: '10px',
  borderColor: stagesetColors.newGrey[400],
  backgroundColor: stagesetColors.white[100],
}));
