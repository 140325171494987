import { Box, Typography } from '@mui/material';
import { GridRowsProp } from '@mui/x-data-grid';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Close } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { EProcessType, ProcessDTO } from '../../../shared/process/ProcessMilestoneActionDTO';
import ConfirmationDialog from '../../common/ConfirmationDialog/ConfirmationDialog';
import { EStateStatus } from '../../core/commonTypes';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import { DeleTeIcon } from '../../icons/ConfirmationDialogIcons/ConfirmationDialogIcons';
import DeleteSectionSvg from '../../icons/SectionMenuIcons/Delete';
import {
  ETemplatesTypes,
  resetSelectedTemplateData,
  selectLibraryFilter,
  selectLibraryTab,
  selectLibraryTemplateData,
  setSelectedTemplateData,
  clearTemplatesState,
  deleteTemplate,
  getTemplates,
  selectAllTemplates,
  selectTemaplatesLoadingStatus,
} from '../../pages/templates/lib/templatesSlice';
import { StyledDealListDataGrid } from '../../routes-old/deals/DealPageStyles/DealsListStyle';
import { formateValueForLastActive } from '../../routes-old/process/helpers';
import MenuWithButton from '../../shared/MenuWithButton/MenuWithButton';
import MobileTable from '../../shared/MobileTable/MobileTable';
import { stagesetPalette } from '../../theme/stagesetPalette';
import { ExtendedGridColumns } from '../../types/mobile-table';
import { createProcess } from '../../routes-old/deals/state/dealsSlice';
import { CopyTemplateIcon20px } from '../../icons/CopyTemplateIcons';
import useModalWindow from '../../hooks/useModalWindow';
import { FormattedLabel } from '../../shared/AssigneeSelection/FormattedLabel';
import { AppSkeletonTemplateStrings } from '../../shared/AppSkeleton/AppSkeletonStrings';

enum EProcessTemplatesColumns {
  name = 'name',
  menu = 'menu',
}

const ProcessTemplatesList = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const templates = useAppSelector(selectAllTemplates);
  const selectedTemplateData = useAppSelector(selectLibraryTemplateData);
  const templatesLoadingStatus = useAppSelector(selectTemaplatesLoadingStatus);
  const currentTab = useAppSelector(selectLibraryTab);
  const { setTemplateModalWindowState, onModalWindowStateReset } = useModalWindow();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const filter = useAppSelector(selectLibraryFilter);

  useEffect(() => {
    if (
      templates.length
      && selectedTemplateData?.type !== EProcessType.TEMPLATE
      && currentTab === ETemplatesTypes.TEMPLATES
      && !isMobile
    ) {
      dispatch(setSelectedTemplateData({
        id: templates[0].id,
        title: templates[0].title,
        type: EProcessType.TEMPLATE,
      }));
    }
    if (
      !templates?.length
      && selectedTemplateData?.type !== EProcessType.TEMPLATE
      && currentTab === ETemplatesTypes.TEMPLATES
    ) {
      dispatch(resetSelectedTemplateData());
    }
  }, [templates, currentTab, selectedTemplateData?.type, isMobile]);

  useEffect(() => {
    if (templatesLoadingStatus !== EStateStatus.LOADING) {
      dispatch(getTemplates(filter));
    }
    return () => dispatch(clearTemplatesState());
  }, [filter]);

  const [templateToDelete, setTemplateToDelete] = useState<{ id: string, title: string } | null>(null);

  const onDeleteDialogAccept = (choice: boolean) => {
    if (choice && !!templateToDelete) {
      dispatch(deleteTemplate(templateToDelete.id));
      if (
        selectedTemplateData?.id === templateToDelete.id
      ) {
        const newTemplate = templates.find((template) => template.id !== templateToDelete.id);
        if (newTemplate) {
          dispatch(setSelectedTemplateData({
            id: newTemplate.id,
            title: newTemplate.title,
            type: EProcessType.TEMPLATE,
          }));
        }
      }
      setTemplateToDelete(null);
    } else {
      setTemplateToDelete(null);
    }
  };

  const onTemplateSelect = (id: string, title: string) => {
    dispatch(setSelectedTemplateData({ id, title, type: EProcessType.TEMPLATE }));
  };

  const onTemplateCopy = async ({ title, id }: Partial<ProcessDTO>) => {
    try {
      setTemplateModalWindowState();
      const newTemplate: ProcessDTO = await dispatch(createProcess({
        title: `${title} Copy`,
        type: EProcessType.TEMPLATE,
        templateId: id!,
      }))
        .unwrap();
      await dispatch(getTemplates(filter));
      if (newTemplate) {
        const key = `create-template-snackbar-${Date.now()}`;
        enqueueSnackbar(
          'Template has been created',
          {
            key,
            variant: 'success',
            autoHideDuration: 1500,
            action: (
              <Box
                onClick={() => closeSnackbar(key)}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Close />
              </Box>
            ),
          },
        );
      }
      dispatch(setSelectedTemplateData({
        id: newTemplate.id,
        title: newTemplate.title,
        type: EProcessType.TEMPLATE,
      }));
    } catch (e) {
      console.log(e);
    } finally {
      onModalWindowStateReset();
    }
  };

  const skeletonColumns: ExtendedGridColumns = [
    {
      field: EProcessTemplatesColumns.name,
      sortable: true,
      headerName: isMobile ? 'Last updated' : 'Name',
      align: 'left',
      cellClassName: 'pro-grid-cell',
      flex: 1,
      renderCell: () => (
        <AppSkeletonTemplateStrings />
      ),
    },
  ];

  const columns: ExtendedGridColumns = [
    {
      field: EProcessTemplatesColumns.name,
      sortable: true,
      headerName: isMobile ? 'Last updated' : 'Name',
      align: 'left',
      cellClassName: 'pro-grid-cell',
      flex: 1,
      renderCell: (
        {
          value,
        },
      ) => (
        <Box
          className="RowLink"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: '20px',
          }}
        >
          <FormattedLabel
            label={value.title}
            len={24}
            tooltip
            placement="right"
            sx={{
              fontSize: '16px',
              lineHeight: '24px',
              color: (theme) => theme.palette.grey[600],
            }}
          />
          <Typography
            sx={{
              color: (theme) => theme.palette.grey[400],
              fontSize: '12px',
            }}
          >
            {
              `Updated ${formateValueForLastActive(DateTime.fromISO(value?.lastInteraction).toMillis() as number)}`
            }
          </Typography>
        </Box>
      ),
      renderMobileCell: ({ row, value }) => (
        <Typography
          sx={{
            color: (theme) => theme.palette.grey[400],
            fontSize: '12px',
          }}
        >
          {
            `${formateValueForLastActive(DateTime.fromISO(value?.lastInteraction).toMillis() as number)}`
          }
        </Typography>
      ),
    },
    {
      field: EProcessTemplatesColumns.menu,
      sortable: false,
      headerName: '',
      align: 'right',
      cellClassName: 'pro-grid-cell menu-cell',
      width: 70,
      maxWidth: 70,
      renderCell: (
        {
          id,
          value: template,
        },
      ) => (
        <MenuWithButton
          MenuItems={
            [
              {
                label: 'Duplicate',
                startIcon: (
                  <Box sx={{ width: '20px', height: '20px' }}>
                    <CopyTemplateIcon20px />
                  </Box>
                ),
                labelColor: '#455273',
                onClick: () => onTemplateCopy({ id: id as string, title: template.title }),
              },
              {
                label: '',
                divider: true,
              },
              {
                label: 'Delete',
                startIcon: (
                  <Box sx={{ width: '20px', height: '20px' }}>
                    <DeleteSectionSvg />
                  </Box>
                ),
                labelColor: stagesetPalette?.error[600],
                onClick: () => setTemplateToDelete({ id: id as string, title: template.title }),
              },
            ]
          }
        />
      ),
    },
  ];

  const skeletonRow = [{
    id: '1',
    title: '',
    [EProcessTemplatesColumns.name]: {
      title: '',
      lastInteraction: '',
    },
    [EProcessTemplatesColumns.menu]: [],
  }];

  const rows: GridRowsProp = templates.map((template) => ({
    id: template.id,
    title: template.title,
    [EProcessTemplatesColumns.name]: {
      title: template.title,
      lastInteraction: template.lastInteraction,
    },
    [EProcessTemplatesColumns.menu]: template,
  }));

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          height: 'calc( 100% )',
          maxHeight: 'calc( 100% )',
          '& .menu-cell': {
            visibility: 'hidden',
            justifyContent: 'flex-start',
          },
          '& .MuiDataGrid-row:hover .menu-cell': {
            visibility: 'visible',
          },
        }}
      >
        {
          isMobile
            ? (
              <Box
                sx={{
                  overflow: 'auto',
                  height: 'calc( 100% )',
                  maxHeight: 'calc( 100% )',
                }}
              >
                <MobileTable
                  onRowClick={(row) => onTemplateSelect(row.id, row.title)}
                  getId={(row) => row.id}
                  renderTitle={(row) => row.title}
                  renderMenu={(row) => {
                    if (templatesLoadingStatus === EStateStatus.LOADING) return null;
                    return (
                      <MenuWithButton
                        MenuItems={
                        [
                          {
                            label: 'Duplicate',
                            startIcon: (
                              <Box sx={{ width: '20px', height: '20px' }}>
                                <CopyTemplateIcon20px />
                              </Box>
                            ),
                            labelColor: '#455273',
                            onClick: () => onTemplateCopy({ id: row.id as string, title: row.title }),
                          },
                          {
                            label: '',
                            divider: true,
                          },
                          {
                            label: 'Delete',
                            startIcon: (
                              <Box sx={{ width: '20px', height: '20px' }}>
                                <DeleteSectionSvg />
                              </Box>
                            ),
                            onClick: () => setTemplateToDelete({ id: row.id as string, title: row.title }),
                          },
                        ]
                      }
                      />
                    );
                  }}
                  columns={templatesLoadingStatus === EStateStatus.LOADING ? skeletonColumns : columns}
                  rows={templatesLoadingStatus === EStateStatus.LOADING ? skeletonRow : rows}
                />
              </Box>
            )
            : (
              <StyledDealListDataGrid
                className="hiddenHeader"
                disableSelectionOnClick
                disableColumnMenu
                hideFooter
                columns={templatesLoadingStatus === EStateStatus.LOADING ? skeletonColumns : columns}
                rows={templatesLoadingStatus === EStateStatus.LOADING ? skeletonRow : rows}
                rowHeight={74}
                headerHeight={-4}
                getRowSpacing={() => ({
                  top: 4,
                  bottom: 4,
                })}
                onRowClick={(rowParams) => onTemplateSelect(rowParams.id as string, rowParams.row.title)}
                getRowClassName={(params) => (params.row.id === selectedTemplateData?.id ? 'SelectedRow' : '')}
                sx={{
                  '& .MuiDataGrid-virtualScroller': {
                    '&::-webkit-scrollbar': {
                      width: '0px',
                    },
                    '&::-webkit-scrollbar-track': {
                      width: '0px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      width: '0px',
                    },
                  },
                }}
              />
            )
        }
      </Box>
      <ConfirmationDialog
        icon={<DeleTeIcon />}
        titleVariant="danger-primary"
        isDialogOpen={!!templateToDelete}
        onConfirm={() => onDeleteDialogAccept(true)}
        onCancel={() => onDeleteDialogAccept(false)}
        dialogContentText={(
          <Box>
            Are you sure you want to delete template?
          </Box>
        )}
        dialogTitle={templateToDelete?.title ?? 'Delete process template'}
        confirmText="Delete"
      />
    </>
  );
};

export default ProcessTemplatesList;
