import { Box } from '@mui/material';
import classNames from 'classnames';
import { useBlockContext } from '../../../TiptapBlockContextProvider';
import { useCurrentStage } from '../../../../../hooks/useCurrentStage';
import { StageContentType } from '../../../../../../shared/Stage';

const MediaNodeToolbar = ({ children }: any): JSX.Element => {
  const { stageContentType } = useCurrentStage();
  const { data } = useBlockContext();
  const widescreen = stageContentType === StageContentType.WIDESCREENSTAGE;

  return (
    <Box
      className={classNames(
        'media-node-toolbar',
        { 'media-node-toolbar-widescreen': widescreen },
      )}
      sx={{
        display: 'flex',
        position: widescreen ? 'absolute' : undefined,
        height: '0px',
        justifyContent: widescreen ? 'center' : 'flex-end',
        width: widescreen ? undefined : data.width ?? '100%',
        maxWidth: data.width ? undefined : 'calc(100%)',
        zIndex: 3,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          padding: '2px',
          gap: '2px',
          borderRadius: '6px',
          background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.60) -9.58%, #E2E8F0 100%)',
          backdropFilter: 'blur(14px)',
          margin: '8px',
          height: '36px',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default MediaNodeToolbar;
