import {
  useEffect,
  useState,
} from 'react';
import { Box } from '@mui/material';
import { ThemePalette } from '../../../shared/process/ProcessMilestoneActionDTO';
import BackgroundColorLayerSelector from './BackgroundColorLayerSelector';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import {
  selectDealThemePaletteCommon,
  selectDealThemePaletteSidebar,
  selectInitialDealPaletteCommon,
  selectInitialDealPaletteSidebar,
  setDealThemePalette,
} from './lib/dealCustomizationSlice';
import { stagesetPalette } from '../../theme/stagesetPalette';
import useDebouncedValue from '../../hooks/useDebouncedValue';
import { greySidebarContrastText } from '../../core/utils/greyContrastText';

const BackgroundColorPickerPanel = (): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const [activeLayer, setActiveLayer] = useState<number>();
  const [palettePreset, setPalettePreset] = useState<ThemePalette>();
  const dealThemePaletteCommon = useAppSelector(selectDealThemePaletteCommon);
  const dealThemePaletteSidebar = useAppSelector(selectDealThemePaletteSidebar);
  const initialDealPaletteCommon = useAppSelector(selectInitialDealPaletteCommon);
  const initialDealPaletteSidebar = useAppSelector(selectInitialDealPaletteSidebar);
  const { clear, debouncedValue: debouncedPalettePreset } = useDebouncedValue(palettePreset, 300);
  const [localThemePalette, setLocalThemePalette] = useState<ThemePalette>({
    sidebar: dealThemePaletteSidebar,
    // common: dealThemePaletteCommon,
  });

  const onColorUpdate = ({ title, color }: { title: string, color: string }) => {
    const palette: ThemePalette = { ...localThemePalette };
    if (title === 'Sidebar') {
      palette.sidebar = color;
    }
    // if (title === 'Common') {
    //   palette.common = color;
    // }
    dispatch(setDealThemePalette(palette));
    setLocalThemePalette(palette);
  };

  const applyColorSchemeItem = (palette: ThemePalette) => {
    dispatch(setDealThemePalette(palette));
    setLocalThemePalette(palette);
  };

  const onTempSchemaAply = (palette: ThemePalette) => {
    dispatch(setDealThemePalette(palette));
  };

  useEffect(() => {
    if (debouncedPalettePreset) onTempSchemaAply(debouncedPalettePreset);
  }, [debouncedPalettePreset]);

  const onTempSchemaReset = () => {
    clear();
    dispatch(setDealThemePalette(localThemePalette));
  };

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '8px',
        justifyContent: 'space-between',
        width: 'calc( 100% )',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column-reverse',
            gap: '16px',
          }}
        >
          {
            [
              // {
              //   title: 'Common',
              //   color: dealThemePaletteCommon ?? stagesetPalette.primary[600],
              //   startingColor: localThemePalette?.common || '#FFFFFF',
              //   initialColor: initialDealPaletteCommon,
              // },
              {
                title: 'Sidebar',
                color: dealThemePaletteSidebar ?? stagesetPalette.background?.default,
                startingColor: localThemePalette?.sidebar || '#FFFFFF',
                initialColor: initialDealPaletteSidebar,
              },
            ].map((layer, i) => (
              <BackgroundColorLayerSelector
                key={layer.title}
                onSelect={() => setActiveLayer(i)}
                onDeselect={() => setActiveLayer(undefined)}
                isActive={activeLayer === i}
                startingColor={layer.startingColor}
                label={layer.color}
                defaultColor={layer.initialColor}
                layer={i}
                onColorUpdate={(_, newColor) => onColorUpdate({ title: layer.title, color: newColor })}
                layerLabel={layer.title}
              />
            ))
          }
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
            justifyContent: 'flex-end',
            marginTop: '12px',
          }}
        >
          <Box
            onMouseLeave={() => onTempSchemaReset()}
            sx={{
              display: 'flex',
              gap: '8px',
            }}
          >
            {
            [
              { sidebar: undefined, common: undefined, iconColor: '#fff' },
              { sidebar: '#7fbca1', common: '#008891', iconColor: '#7fbca1' },
              { sidebar: '#252a34', common: '#cf1b1b', iconColor: '#252a34' },
            ].map((schemeItem, i) => (
              <Box
                key={`background-extended-color-scheme-item-display-${i}`}
                onClick={() => applyColorSchemeItem(schemeItem)}
                onMouseEnter={() => setPalettePreset(schemeItem)}
                sx={{
                  cursor: 'pointer',
                  width: '32px',
                  height: '32px',
                  background: schemeItem.iconColor,
                  borderRadius: '8px',
                  border: '1px solid',
                  borderColor: (theme) => greySidebarContrastText(theme, 0.2),
                }}
              />
            ))
            }
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BackgroundColorPickerPanel;
