import { styled } from '@mui/styles';
import { Box } from '@mui/material';
import { stagesetColors, stagesetPalette } from '../../theme/stagesetPalette';

export const OrganizationUsersWrapper = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  background: stagesetPalette.background?.default,
  display: 'flex',
  flexDirection: 'column',
  '& .OrganizationUsersContainer-active': {
    '&::-webkit-scrollbar-thumb': {
      visibility: 'visible',
    },
  },
  '& .app-toolbar': {
    height: '52px',
    alignContent: 'center',
    '&-mobile': {
      height: '102px',
      alignContent: 'flex-bottom',
      padding: '15px 16px 0px',
    },
    '&-content-wrapper': {
      height: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  '& .org-panel-section': {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '24px',
    '&-mobile': {
      marginBottom: '0px',
      height: '88px',
      alignItems: 'center',
    },
  },
  '& .org-users-container': {
    '&-mobile': {
      padding: 0,
    },
  },
  '& .tab-panel': {
    padding: 0,
  },
  '& .content-paper': {
    mx: '1vw',
    mt: '2vh',
    flex: 1,
    display: 'flex',
    borderRadius: '16px',
    background: 'transparent',
  },
}));

export const StyledSettingsTab = styled(Box)(({ theme }) => ({
  maxWidth: '485px',
  '& .logo-section': {
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
    height: '72px',
    '& .logo': {
      width: '72px',
      height: '72px',
      borderRadius: '10px',
      border: `1px solid ${theme.palette.grey[100]}`,
    },
    '& .manage-logo-section': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '56px',
      '& .input': {
        display: 'none',
      },
      '& .upload-button': {
        height: '32px',
      },
      '& .remove-button': {
        stroke: stagesetColors.red?.[600],
        backgroundColor: stagesetColors.red?.[100],
        minWidth: '32px',
        height: '32px',
        marginLeft: '12px',
        '&:hover': {
          stroke: 'white',
          backgroundColor: stagesetColors.red?.[500],
        },
        '&:active': {
          stroke: 'white',
          backgroundColor: stagesetColors.red?.[400],
        },
        '&-hidden': {
          display: 'none',
        },
      },
      '& .description': {
        fontSize: '12px',
        color: '#7084B4',
        fontFamily: 'Inter',
      },
    },
  },
  '& .form-fields-section': {
    marginTop: '32px',
    '& .submit-section': {
      marginTop: '24px',
    },
    '& .title-field-input': {
      borderRadius: '10px',
      height: '40px',
    },
    '& .title-field-input-label': {
      lineHeight: '12px',
      top: '-2px',
      '&.Mui-focused': {
        top: '0',
      },
      '&.MuiFormLabel-filled': {
        top: '0',
      },
    },
  },
}));
