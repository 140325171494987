import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { EAppRoutes } from '../../core/router';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import { clearNotificationsState } from '../../routes-old/process/state/dealNotificationsSlice';
import { clearProcessState } from '../../routes-old/process/state/processSlice';
import { clearTasksState } from '../../routes-old/process/state/tasksSlice';
import EditableHeaderTitle from '../../shared/EditableTitleWithBackground/EditableHeaderTitle';
import { selectLibraryTemplateData, updateTemplate } from './lib/templatesSlice';
import EditButton from '../../shared/Buttons/EditButton';
import { EProcessType } from '../../../shared/process/ProcessMilestoneActionDTO';
import { EFieldType } from '../../../shared/Field/Field';
import StagesSidebarButton from '../../shared/StagesSidebarButton/StagesSidebarButton';
import { Analytics, EMixPanelEvents } from '../../core/Analytics';
import useProcessField from '../../hooks/useProcessField';

const analytics: Analytics = Analytics.getInstance();

const TemplatePreviewHeader = (): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const selectedTemplateData = useAppSelector(selectLibraryTemplateData);
  const { onFieldUpdate } = useProcessField({ id: selectLibraryTemplateData.id! });

  const cleanProcessState = () => {
    dispatch(clearProcessState());
    dispatch(clearTasksState());
    dispatch(clearNotificationsState());
  };

  const onEditClick = () => {
    if (!!selectedTemplateData && selectedTemplateData?.id && selectedTemplateData?.type) {
      switch (selectedTemplateData?.type) {
        case EProcessType.TEMPLATE:
          cleanProcessState();
          navigate(`${EAppRoutes.deal}/${selectedTemplateData.id}`);
          analytics.track(EMixPanelEvents.EDIT_ROOM_TEMPLATE_BUTTON_PRESSED);
          break;
        case EFieldType.STAGE:
          navigate(`${EAppRoutes.templates}/${EFieldType.STAGE}/${selectedTemplateData.id}`);
          analytics.track(EMixPanelEvents.EDIT_PAGE_TEMPLATE_BUTTON_PRESSED);
          break;
        case EFieldType.SECTION:
          navigate(`${EAppRoutes.templates}/${EFieldType.SECTION}/${selectedTemplateData.id}`);
          analytics.track(EMixPanelEvents.EDIT_SECTION_TEMPLATE_BUTTON_PRESSED);
          break;
        default:
          break;
      }
    }
  };

  if (!selectedTemplateData) {
    return null;
  }

  const onTemplateTitleUpdate = (newTitle: string) => {
    if (
      selectedTemplateData
      && selectedTemplateData.id
      && selectedTemplateData.title !== newTitle
    ) {
      switch (selectedTemplateData?.type) {
        case EProcessType.TEMPLATE:
          dispatch(updateTemplate({
            id: selectedTemplateData.id,
            title: newTitle,
          }));
          break;
        case EFieldType.STAGE:
          onFieldUpdate({
            title: newTitle,
          });
          break;
        case EFieldType.SECTION:
          onFieldUpdate({
            title: newTitle,
          });
          break;
        default:
          break;
      }
    }
  };

  const controls = [
    <EditButton
      key="edit-stage-template-btn"
      onClick={onEditClick}
      active={false}
    />,
  ];

  const preControls = selectedTemplateData?.type === EProcessType.TEMPLATE
    ? [
      (
        <StagesSidebarButton
          key="stages-sidebar-button"
        />
      ),
    ] : undefined;

  return (
    <Box sx={{
      '& .EditableTemplateHeaderTitleContainer': {
        paddingX: '40px',
      },
    }}
    >
      <EditableHeaderTitle
        title={selectedTemplateData?.title ?? ''}
        bgColor="white"
        preControls={preControls}
        controls={controls}
        onTitleUpdate={onTemplateTitleUpdate}
        fullTitleWidth
        noBackToHomeBtn
      />
    </Box>
  );
};

export default TemplatePreviewHeader;
