import {
  Box, Card, CardMedia, CircularProgress, Dialog,
} from '@mui/material';
import { SxProps, styled } from '@mui/material/styles';
import { useEffect, useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { ELogoType, ProcessDTO } from '../../shared/process/ProcessMilestoneActionDTO';
import { Analytics as MixAnalytics, EMixPanelEvents } from '../core/Analytics';
import { useAppDispatch, useAppSelector } from '../hooks/stateHooks';
import { updateProcess, uploadLogo } from '../routes-old/process/state/dealActions';
import { FilesDragAndDrop } from './FilesDragAndDrop/FilesDragAndDrop';
import MenuLogo from '../icons/UploadFilePlaceholder.svg';
import { stagesetColors, stagesetPalette } from '../theme/stagesetPalette';
import DeleteSectionSvg from '../icons/SectionMenuIcons/Delete';
import LightTooltip from '../shared/LightToolTip/LightToolTip';
import ConfirmationDialog from './ConfirmationDialog/ConfirmationDialog';
import { DeleTeIcon } from '../icons/ConfirmationDialogIcons/ConfirmationDialogIcons';
import { AppSkeleton } from '../shared/AppSkeleton/AppSkeleton';
import { additionalImgFormats, imgFormats } from '../features/MediaField/Helpers/MediaFieldHelpers';
import ImageCrop from '../features/ImageCrop/ImageCrop';
import { useFileHelpers } from '../hooks/useFileHelpers';

const analytics: MixAnalytics = MixAnalytics.getInstance();

const StyledBox = styled(Box)(() => ({
  display: 'flex',
  flex: 0,
  maxHeight: '100%',
  maxWidth: '100%',
  height: 'fit-content',
  borderRadius: '16px',
}));

const logoSize = '72px';
const dndSize = '72px';
const skeletonSize = '72px';

const itemStyles: SxProps = {
  maxWidth: logoSize,
  width: logoSize,
  maxHeight: logoSize,
  height: logoSize,
};

const dndStyles: SxProps = {
  maxWidth: dndSize,
  width: dndSize, // width of logo slot
  maxHeight: dndSize,
  height: dndSize,
};

export interface CustomDropzoneStyles {
  maxWidth?: string;
  width?: string;
  mobileWidth?: string;
}

export interface ICompanyLogoProps {
  src?: string;
  logoType: ELogoType;
  isEditable?: boolean;
  customDropzoneStyles?: CustomDropzoneStyles;
  logoTitle?: string;
  isLoaded?: boolean;
  className?: string;
}

const CompanyLogo = ({
  src,
  logoType,
  isEditable,
  customDropzoneStyles,
  logoTitle,
  isLoaded,
  className,
}: ICompanyLogoProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const isLayoutEditActive: boolean = useAppSelector((state) => state.process.isLayoutEditActive);
  const process: ProcessDTO = useAppSelector((state) => state.process.process);
  const userId: string = useAppSelector((state) => state.app.user?.id);

  const [isUploading, setUploading] = useState<boolean>(false);
  const [logoLoaded, setLogoLoaded] = useState<boolean>(false);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const dropzoneStyles = useMemo<CustomDropzoneStyles>(() => ({
    ...dndStyles,
    mobileWidth: '72px',
    ...customDropzoneStyles,
  }), []);
  const analyticsAdditionalData = {
    dealId: process?.id,
    userId,
  };
  const [tempFile, setTempFile] = useState<File | undefined>();
  const {
    validateFile,
  } = useFileHelpers();

  useEffect(() => {
    setUploading(false);
  }, [src]);

  const onLogoUpload = (files: File[]) => {
    setUploading(true);
    dispatch(uploadLogo({
      file: files[0],
      processId: process?.id,
      userId: userId || '',
      organizationId: process.organizationId,
      logoType,
    }));
  };

  const onLogoClick = () => {
    if (process && isEditable) {
      if (logoType === ELogoType.BuyerLogo) {
        analytics.track(EMixPanelEvents.BUYERS_LOGO_CLICKED, analyticsAdditionalData);
        dispatch(updateProcess({
          id: process.id,
          buyerLogoSrc: '',
        }));
      } else if (logoType === ELogoType.SellerLogo) {
        analytics.track(EMixPanelEvents.SELLERS_LOGO_CLICKED, analyticsAdditionalData);
        dispatch(updateProcess({
          id: process.id,
          sellerLogoSrc: '',
        }));
      }
    }
  };

  const onDeleteDialogAccept = (accept: boolean) => {
    if (accept) {
      onLogoClick();
    }
    setDeleteDialogOpen(false);
  };

  const getDeleteDialogText = () => {
    switch (logoType) {
      case ELogoType.BuyerLogo:
        return "Are you sure you want to remove the buyer's logo?";
      case ELogoType.SellerLogo:
        return "Are you sure you want to remove the sellers's logo?";
      default:
        return 'Are you sure you want to remove the logo?';
    }
  };

  const customHandleLogoChange = (files: File[]) => {
    const [file] = files;
    if (!validateFile(file)) return;
    setTempFile(file);
  };

  const onCustomeBackgroundUpload = async (file: File): Promise<void> => {
    if (!file) {
      setTempFile(undefined);
      return;
    }
    onLogoUpload([file]);
    setTempFile(undefined);
  };

  return (
    <>
      <LightTooltip
        title={logoTitle}
        placement="bottom"
        disableHoverListener={!logoTitle}
      >
        <Box
          className={className}
          component="button"
          sx={{
            padding: '0',
            border: 'unset',
            background: stagesetColors.white[100],
            borderRadius: '16px',
          }}
        >
          {src && (
            <StyledBox
              className={isLayoutEditActive ? 'LogoCardContainer-active' : 'LogoCardContainer'}
              sx={{
                border: '1px solid',
                borderColor: stagesetColors.newGrey[100],
                borderRadius: '16px',
                overflow: 'hidden',
                '&.LogoCardContainer-active': {
                  '&:hover': {
                    '& .CardImage': {
                      opacity: isEditable ? '0.15' : 'none',
                    },
                    '& .DeleteButtonContainer': {
                      '& .DeleteButton': {
                        visibility: 'visible',
                      },
                    },
                  },
                },
              }}
            >
              <Card
                sx={{
                  ...itemStyles,
                  padding: '0px',
                  width: '100%',
                  borderRadius: '16px',
                  display: 'flex',
                  flex: 1,
                  cursor: '',
                  justifyContent: 'center',
                  border: 'none',
                  boxShadow: 'none',
                }}
                variant="outlined"
              >
                <Box
                  sx={{
                    ...itemStyles,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {!logoLoaded && (
                    <Box
                      sx={{
                        zIndex: 1,
                        width: '100%',
                        height: '100%',
                        backgroundColor: stagesetColors.white[100],
                        border: '1px solid',
                        borderColor: stagesetColors.newGrey[100],
                        borderRadius: '16px',
                      }}
                    >
                      <AppSkeleton
                        animation="wave"
                        variant="rectangular"
                        sx={{
                          borderRadius: '16px',
                          width: '100%',
                          height: '100%',
                        }}
                      />
                    </Box>
                  )}
                  <CardMedia
                    className="CardImage"
                    component="img"
                    image={src.replaceAll('+', '%2B')}
                    alt="logo"
                    onLoad={() => {
                      setLogoLoaded(true);
                    }}
                    sx={{
                      zIndex: 1,
                      ...itemStyles,
                      display: logoLoaded ? 'flex' : 'none',
                      justifyContent: 'center',
                      objectFit: 'cover',
                      borderRadius: '16px',
                      width: 'auto',
                    }}
                  />
                </Box>
                {(src && isEditable) && (
                  <Box
                    className="DeleteButtonContainer"
                    sx={{
                      display: 'flex',
                      width: '0px',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Box
                      className="DeleteButton"
                      onClick={() => {
                        setDeleteDialogOpen(true);
                      }}
                      sx={{
                        ...itemStyles,
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        visibility: 'hidden',
                        position: 'relative',
                        right: '33px',
                        background: 'transparent',
                      }}
                    >
                      <DeleteSectionSvg />
                    </Box>
                  </Box>
                )}
              </Card>
            </StyledBox>
          )}

          {(!src && isEditable && isLayoutEditActive) && (
            <>
              {
                isUploading
                && (
                  <Box
                    sx={{
                      ...dropzoneStyles,
                      maxWidth: dropzoneStyles.maxWidth,
                      width: isMobile ? dropzoneStyles.mobileWidth : dropzoneStyles.width,
                      padding: '0px',
                      border: `1px solid ${stagesetPalette.primary?.main}`,
                      borderRadius: '16px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <CircularProgress size={20} />
                  </Box>
                )
              }
              {
                !isUploading
                && (
                  <>
                    {!isLoaded && (
                      <AppSkeleton
                        animation="wave"
                        variant="rectangular"
                        width={skeletonSize}
                        height={skeletonSize}
                        sx={{
                          borderRadius: '16px',
                        }}
                      />
                    )}
                    {isLoaded && (
                      <FilesDragAndDrop
                        onFileDrop={(data) => customHandleLogoChange(data)}
                        text=" "
                        dropzoneOptions={{
                          accept: `.${[...imgFormats, ...additionalImgFormats].join(', .').toUpperCase()}`,
                        }}
                        // border={(theme) => `1px solid ${theme.palette.grey[100]}`}
                        styles={{
                          ...dropzoneStyles,
                          // aspectRatio: '1',
                          padding: '0px',
                          marginLeft: '0px',
                          marginRight: '0px',
                          width: isMobile ? dropzoneStyles.mobileWidth : dropzoneStyles.width,
                          borderRadius: '16px',
                          // border: (theme) => `1px solid ${theme.palette.grey[100]}`,
                        }}
                        textStyle={{
                          fontSize: '14px',
                        }}
                        icon={<MenuLogo />}
                      />
                    )}
                  </>
                )
              }
            </>
          )}
        </Box>
      </LightTooltip>
      <ConfirmationDialog
        icon={<DeleTeIcon />}
        titleVariant="danger-primary"
        isDialogOpen={isDeleteDialogOpen}
        onConfirm={() => onDeleteDialogAccept(true)}
        onCancel={() => onDeleteDialogAccept(false)}
        dialogContentText={(
          <Box>
            {
              getDeleteDialogText()
            }
          </Box>
        )}
        dialogTitle="Delete logo"
        confirmText="Delete"
      />
      <Dialog
        open={!!tempFile}
        onClose={() => setTempFile(undefined)}
        keepMounted={false}
        PaperProps={{
          sx: {
            width: 'auto',
            maxHeight: '80vh',
            height: 'auto',
            padding: '24px',
          },
        }}
      >
        <ImageCrop
          image={tempFile}
          onCropAccept={onCustomeBackgroundUpload}
          onCancel={() => setTempFile(undefined)}
          customAspect={1}
        />
      </Dialog>
    </>
  );
};

export default CompanyLogo;
