import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Box, TextField, Typography } from '@mui/material';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { EJWTTokenType } from '../../../../shared/schema/token.schema';
import { EStateStatus } from '../../../core/commonTypes';
import { EAppRoutes } from '../../../core/router';
import { useAppDispatch, useAppSelector } from '../../../hooks/stateHooks';
import AuthButton from '../Components/AuthButton';
import { TitleAdditionalTypography, TitleTypography, TitleTypographyWrapper } from '../Components/AuthStyledComponents';
import FormError from '../Components/FormError';
import { SocialAuth } from '../Login/SocialAuth';
import {
  logout, resetAuthError, setShareSingUpOpen, signup,
} from '../state/authState';
import AuthPaper from '../../../shared/AuthPaper/AuthPapper';
import { useQuery } from '../../../hooks/useQuery';
import { AppLinkButton } from '../../../shared/AppButton/AppLinkButton';

export interface IFormState {
  email: string;
}

const Signup = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const query = useQuery();
  const navigate = useNavigate();
  const { status, error, tokenType } = useAppSelector((state) => state.auth);
  const { t } = useTranslation();

  const schema = yup.object({
    email: yup.string().required(t('Error_Email_is_required')).email(t('Error_The_email_must_be_a_valid_email_address')),
  });

  const getCelloReferrer = () => {
    if (query.has('ucc')) {
      return query.get('ucc');
    }
    if (typeof window.CelloAttribution === 'function') {
      return window.CelloAttribution('getReferral');
    }
    return undefined;
  };

  const {
    handleSubmit,
    control,
    register,
  } = useForm<IFormState>({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(schema),
  });

  register('email', {
    onChange: () => {
      dispatch(resetAuthError());
    },
  });

  useEffect(() => () => {
    dispatch(resetAuthError());
  }, []);

  const onSubmit = async (data: IFormState): Promise<void> => {
    try {
      await dispatch(signup({ email: data?.email, celloReferrer: getCelloReferrer() })).unwrap();
      if (tokenType !== EJWTTokenType.SHARE) {
        navigate(`${EAppRoutes.auth + EAppRoutes.signupSuccess}`);
      }
      if (tokenType === EJWTTokenType.SHARE) {
        dispatch(setShareSingUpOpen(false));
        dispatch(logout());
        navigate(`${EAppRoutes.auth + EAppRoutes.signupSuccess}`);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleLoginClick = (): void => {
    if (tokenType !== EJWTTokenType.SHARE) {
      navigate(`${EAppRoutes.auth}${EAppRoutes.login}`);
    }
    if (tokenType === EJWTTokenType.SHARE) {
      dispatch(logout());
      navigate(`${EAppRoutes.auth}${EAppRoutes.login}`);
    }
  };

  return (
    <AuthPaper>
      <>
        <TitleTypographyWrapper>
          <TitleTypography>
            {t('Auth_Sign_up')}
          </TitleTypography>
        </TitleTypographyWrapper>
        <Box
          sx={{
            width: '100%',
          }}
        >
          <SocialAuth isSignup />
        </Box>
        <Typography
          sx={{
            color: ' #7084B4',
            fontSize: '14px',
            lineHeight: '16px',
            fontWeight: 500,
            textAlign: 'center',
          }}
        >
          {t('Auth_or_use_email')}
        </Typography>
        <Box
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '12px',
            width: '100%',
          }}
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
        >
          {
          status === EStateStatus.ERROR
          && error?.message
          && (
            <FormError message={error?.message} isVisible={status === EStateStatus.ERROR} />
          )
        }
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                label="Email"
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                sx={{
                  borderRadius: '8px',
                }}
                InputProps={{
                  sx: {
                    borderRadius: '8px',
                  },
                }}
              />
            )}
          />
          <AuthButton status={status} title={t('Auth_Sign_up')} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
          }}
        >
          <TitleAdditionalTypography>
            {t('Auth_Already_a_member')}
          </TitleAdditionalTypography>
          <AppLinkButton
            size="xs"
            onClick={handleLoginClick}
            variant="primary"
          >
            {t('Auth_Log_in')}
          </AppLinkButton>
        </Box>
      </>
    </AuthPaper>
  );
};

export default Signup;
