import { SvgIcon, SvgIconProps } from '@mui/material';

export const DropletIcon = ({
  customeColor,
  pathClassName,
  customeStroke,
}: SvgIconProps & {
  customeColor?: string,
  pathClassName?: string,
  customeStroke?: ((t: any) => string) | string,
}): JSX.Element => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      '& path': {
        stroke: customeStroke || '#7084B4',
      },
    }}
  >
    <path
      className={pathClassName}
      d="M9.99668 1.66602L14.1238 7.60655C14.1238 7.60655 15.4959
      9.44918 15.7213 10.5733C15.9467 11.6974 15.8314 12.8626 15.39
      13.9216C14.9486 14.9806 14.2009 15.8857 13.2415 16.5226C12.2822
      17.1594 11.1542 17.4994 10.0003 17.4994C8.84644 17.4994 7.71847
      17.1594 6.7591 16.5226C5.79973 15.8857 5.05206 14.9806 4.61066
      13.9216C4.16927 12.8626 4.05397 11.6974 4.27936 10.5733C4.50475
      9.44918 5.87689 7.60655 5.87689 7.60655L9.99668 1.66602Z"
      //stroke={customeStroke ?? customeColor ?? '#7084B4'}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={customeColor ?? 'none'}
    />
  </SvgIcon>
);
