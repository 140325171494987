import { Box, styled } from '@mui/material';

export const FileUploadStatusWrapper = styled(Box)(() => ({
  '& .FileUploadStatusContainer': {
    position: 'relative',
    boxSizing: 'border-box',
    height: '100%',
    maxHeight: '100%',
    width: '100%',
    maxWidth: '100%',
    flex: 1,
    border: '1px dashed #A6A6A6',
    alignItems: 'center',
    borderRadius: '5px',
    color: 'rgba(101, 103, 109, 0.5)',
    outline: 'none',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'flex-start',
    padding: '0 !important',
    transition: 'background 1s',
    '& .FileUploadSuccessBack': {
      borderRadius: '5px',
      background: '#62B839',
      height: '100%',
      width: '100%',
    },
    '& .FileUploadSuccessText': {
      width: '50%',
      height: '50%',
      overflow: 'auto',
      margin: 'auto',
      position: 'absolute',
      top: '0',
      left: '0',
      bottom: '0',
      right: '0',
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
    },
    '& .FileUploadBack': {
      borderRadius: '5px',
      background: '#0894E4',
      height: '100%',
      transition: 'all .5s ease-in',
    },
    '& .FileUploadText': {
      width: '50%',
      height: '50%',
      overflow: 'auto',
      margin: 'auto',
      position: 'absolute',
      top: '0',
      left: '0',
      bottom: '0',
      right: '0',
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'rgb(101, 103, 109)',
    },
  },
  '& .DealFileUploadStatusContainer': {
    position: 'relative',
    boxSizing: 'border-box',
    maxHeight: '140px',
    minHeight: '140px',
    height: '100%',
    width: 'auto',
    flex: 1,
    border: '1px dashed #A6A6A6',
    alignItems: 'center',
    borderRadius: '10px',
    color: 'rgba(101, 103, 109, 0.5)',
    outline: 'none',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignContent: 'center',
    justifyContent: 'flex-start',
    padding: '0 !important',
    transition: 'background 1s',
    margin: '10px 30px 25px 30px',
    '& .DealFileUploadSuccessBack': {
      borderRadius: '10px',
      background: '#62B839',
      height: '100%',
      width: '100%',
    },
    '& .DealFileUploadSuccessText': {
      width: '80%',
      height: '50%',
      overflow: 'auto',
      margin: 'auto',
      position: 'absolute',
      top: '0',
      left: '0',
      bottom: '0',
      right: '0',
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
    },
    '& .DealFileUploadBack': {
      borderRadius: '10px',
      background: '#0894E4',
      height: '100%',
      transition: 'all .5s ease-in',
    },
    '& .DealFileUploadText': {
      width: '50%',
      height: '50%',
      overflow: 'auto',
      margin: 'auto',
      position: 'absolute',
      top: '0',
      left: '0',
      bottom: '0',
      right: '0',
      display: 'flex',
      alignContent: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'rgb(101, 103, 109)',
    },
  },
}));
