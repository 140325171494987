import { Box, Dialog } from '@mui/material';
import { EFieldType } from '../../../shared/Field/Field';
import { EProcessType } from '../../../shared/process/ProcessMilestoneActionDTO';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import { resetSelectedTemplateData, selectLibraryTemplateData } from '../../pages/templates/lib/templatesSlice';
import SectionDisplay from './SectionDisplay';
import StageDisplay from './StageDisplay';
import MobileBottomMenu from '../SideMenu/MobileBottomMenu';
import { DealLayout } from '../../routes-old/process/DealLayout';

const MobileTemplateDisplay = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const selectedTemplateData = useAppSelector(selectLibraryTemplateData);

  const onClose = () => {
    dispatch(resetSelectedTemplateData());
  };

  return (
    <Dialog
      open={!!selectedTemplateData}
      onClose={onClose}
      PaperProps={{
        sx: {
          maxHeight: '100%',
          minWidth: '100%',
          borderRadius: '0px',
          height: '100%',
          overflow: 'hidden',
          margin: '0px',
        },
      }}
    >
      {
        selectedTemplateData
        && selectedTemplateData.id
        && selectedTemplateData.type === EProcessType.TEMPLATE
        && (
          <DealLayout
            toFetchId={selectedTemplateData.id}
          />
        )
    }
      {
        selectedTemplateData
        && selectedTemplateData.id
        && selectedTemplateData.type === EFieldType.STAGE
        && (
          <StageDisplay
            id={selectedTemplateData.id}
            onClose={onClose}
            toolbar
          />
        )
    }
      {
        selectedTemplateData
        && selectedTemplateData.id
        && selectedTemplateData.type === EFieldType.SECTION
        && (
          <SectionDisplay
            id={selectedTemplateData.id}
            onClose={onClose}
            toolbar
          />
        )
      }
      {
        !selectedTemplateData
        && (
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            Select a template for previewing
          </Box>
        )
      }
      {
        selectedTemplateData
          && (
          <MobileBottomMenu />
          )
      }
    </Dialog>
  );
};

export default MobileTemplateDisplay;
