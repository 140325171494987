import styled from '@emotion/styled';
import Paper from '@mui/material/Paper';

export const OrganizationUsersListPaper = styled(Paper)(() => ({
  display: 'flex',
  flex: 1,
  width: '100%',
  maxWidth: '100vw',
  border: 'none',
  margin: '0px',
  borderRadius: '0',
  alignSelf: 'center',
  padding: '0',
  boxShadow: 'none',
  '& .MuiDataGrid-root': {
    border: 'none',
  },
}));
