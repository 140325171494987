import { SvgIcon, SvgIconProps } from '@mui/material';

export const SuccessIcon20 = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    {...props}
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      width: '20px',
      height: '20px',
    }}
  >
    <path
      d="M10.5003 18.3332C15.1027 18.3332 18.8337
      14.6022 18.8337 9.99984C18.8337 5.39746
      15.1027 1.6665 10.5003 1.6665C5.89795 1.6665
      2.16699 5.39746 2.16699 9.99984C2.16699
      14.6022 5.89795 18.3332 10.5003 18.3332Z"
      stroke="#27AE60"
      strokeWidth="1.66"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M14 7.5L9.1875 12.5L7 10.2273"
      stroke="#27AE60"
      strokeWidth="1.66"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </SvgIcon>
);
