import { Box, Typography } from '@mui/material';
import { useAppSelector } from '../../hooks/stateHooks';
import { selectCurrentStageId, selectItemBookmarks } from '../ProcessFields/lib/processItemsSlice';
import StagesSidebarSubitem from './StagesSidebarSubItem';
import { greySidebarContrastText } from '../../core/utils/greyContrastText';

const StagesSidebarBookmarks = (): JSX.Element => {
  const itemId = useAppSelector(selectCurrentStageId);
  const bookmarks = useAppSelector((state) => selectItemBookmarks(state, itemId));

  if (!itemId || !bookmarks.length) {
    return (
      <Box
        key="item-bookmark-empty"
        sx={{
          display: 'flex',
          height: '48px',
          alignItems: 'center',
          justifyContent: 'center',

        }}
      >
        <Typography
          sx={{
            color: (theme) => greySidebarContrastText(theme, 0.3),
          }}
        >
          No sections
        </Typography>
      </Box>
    );
  }

  return (
    <>
      {
        bookmarks.map(([id, title]: [string, string], i: number) => (
          <StagesSidebarSubitem
            key={`item-bookmark-${window.crypto.randomUUID()}-${id}`}
            id={id}
            title={title}
            isFirst={i === 0}
            isLast={i === bookmarks.length - 1}
          />
        ))
      }
    </>
  );
};

export default StagesSidebarBookmarks;
