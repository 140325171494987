import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const FileManagerWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  backgroundColor: '#efefef',
  width: '100%',
  height: '100%',
  overflow: 'overlay',
  overflowX: 'hidden',
  '&::-webkit-scrollbar': {
    width: '7px',
    height: '7px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '3px',
    backgroundColor: '#c7c7c7',
    opacity: '50%',
    visibility: 'hidden',
    '&:hover': {
      visibility: 'visible',
    },
  },
  '& .FileManagmentContainer': {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    flexWrap: 'wrap',
    alignContent: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    '& .FileManagmentInnerContainer': {

      height: 'calc( 100vh - 50px )',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      flexWrap: 'nowrap',
      alignContent: 'center',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },
  },
}));

export const FileManagementStatusBox = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  padding: '0 18%',
  height: 'auto',
  margin: '20px 0 0 0',
  '& .StatusLabel': {
    fontWeight: 500,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'center',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
}));
