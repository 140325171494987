import { Box } from '@mui/material';
import { InfoTableSubtitle, InfoTableTitle } from '../../Styles/CustomDomainTabStyles';
import { SettingsFormWrapper } from '../../Styles/GeneralTabStyles';

export const OrdinaryUserInitialHint = () => (
  <Box>
    <SettingsFormWrapper
      className="settings-custom-domain-info-table-wrapper"
    >
      <InfoTableTitle>
        Specify subdomain for your organization
      </InfoTableTitle>
      <InfoTableSubtitle>
        The chosen subdomain should include only latin symbols (a-z)
        and digits (0-9)
      </InfoTableSubtitle>
    </SettingsFormWrapper>
  </Box>
);

export const OrdinaryUserSuccessHint = () => (
  <Box>
    <SettingsFormWrapper
      className="settings-custom-domain-info-table-wrapper"
    >
      <InfoTableTitle>
        Your subdomain is set
      </InfoTableTitle>
      <InfoTableSubtitle>
        It is automatically applied for all links of your organization
      </InfoTableSubtitle>
    </SettingsFormWrapper>
  </Box>
);
