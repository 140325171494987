import { Grid } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { EUserOrganizationPermissions } from '../../../shared/permissions';
import { SalesforceAuthApi } from '../../api/SalesforceAuthApi';
import PagePresentation from '../../common/PagePresentation/PagePresentation';
import PagePresentationNoPermissions from '../../common/PagePresentation/PagePresentationNoPermissions';
import { salesforceCancelIntegration } from '../../core/crmintegrations/salesforce';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import useUserOrganizationPermissions from '../../hooks/useOrganizationPermissions';
import { PipedriveAuthApi } from '../../api/PipedriveAuthApi';
import { HubspotAuthApi } from '../../api/HubspotAuthApi';
import { pipedriveCancelIntegration } from '../../core/crmintegrations/pipedrive';
import { hubspotCancelIntegration } from '../../core/crmintegrations/hubspot';
import { IntegrationCard } from './ui/IntegrationCard';
import Salesforce from '../../icons/Integrations/Salesforce.svg';
import HubSpot from '../../icons/Integrations/HubSpot.svg';
import Pipedrive from '../../icons/Integrations/Pipedrive.svg';
import AppTopBar from '../../shared/AppTopBar/AppTopBar';
import { ESubscriptionType } from '../../../shared/Subscription';
import { useTranslation } from "react-i18next";

const premiumSubscriptionTypes = [
  ESubscriptionType.SELLER_PREMIUM,
  ESubscriptionType.SELLER_APPSUMO_TIER_2,
  ESubscriptionType.SELLER_APPSUMO_TIER_3,
];

const pipedriveAuthApi: PipedriveAuthApi = new PipedriveAuthApi();
const hubspotAuthApi: HubspotAuthApi = new HubspotAuthApi();
const salesforceAuthApi: SalesforceAuthApi = new SalesforceAuthApi();

const Integrations = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const subscriptionType: ESubscriptionType = useAppSelector((state) => state.billing.subscription.type);
  const {
    isUserHaveIntegration: isUserHavePipedriveIntegration,
    integration: pipedriveIntegration,
  } = useAppSelector((state) => state.pipedrive);
  const {
    isUserHaveIntegration: isUserHaveHubspotIntegration,
    integration: hubspotIntegration,
  } = useAppSelector((state) => state.hubspot);
  const {
    isUserHaveIntegration: isUserHaveSalesforceIntegration,
    integration: salesforceIntegration,
  } = useAppSelector((state) => state.salesForce);
  const somethingIntegrated = isUserHavePipedriveIntegration || isUserHaveHubspotIntegration || isUserHaveSalesforceIntegration;
  const [checkOrganizationPermissions] = useUserOrganizationPermissions();

  const isPageAvailable: boolean = premiumSubscriptionTypes.includes(subscriptionType);
  const isBillingAvailable: boolean = checkOrganizationPermissions(EUserOrganizationPermissions.ORGANIZATION_NAV_BILLING);

  const onPipedriveSet = (): void => {
    pipedriveAuthApi.startAuthSession();
  };
  const onHubspotSet = (): void => {
    hubspotAuthApi.startAuthSession();
  };
  const onSalesforceSet = (): void => {
    salesforceAuthApi.startAuthSession();
  };

  const onPipedriveCancel = (): void => {
    dispatch(pipedriveCancelIntegration());
  };
  const onHubspotCancel = (): void => {
    dispatch(hubspotCancelIntegration());
  };
  const onSalesforceCancel = (): void => {
    dispatch(salesforceCancelIntegration());
  };

  return (
    <>
      <AppTopBar title={t('Integrations_Integrations')} />
      {isPageAvailable
        && (
          <Grid
            container
            spacing={3}
            p={isMobile ? 1 : 3}
            sx={{
              overflow: 'overlay',
            }}
          >
            <IntegrationCard
              logo={<Pipedrive />}
              haveIntegration={isUserHavePipedriveIntegration}
              somethingIntegrated={somethingIntegrated}
              integration={pipedriveIntegration}
              name="Pipedrive"
              onCancel={onPipedriveCancel}
              onSet={onPipedriveSet}
              description={t('Integrations_Pipedrive_text')}
            />
            <IntegrationCard
              logo={<HubSpot />}
              haveIntegration={isUserHaveHubspotIntegration}
              somethingIntegrated={somethingIntegrated}
              integration={hubspotIntegration}
              name="Hubspot"
              onCancel={onHubspotCancel}
              onSet={onHubspotSet}
              description={t('Integrations_Hubspot_text')}
            />
            <IntegrationCard
              logo={<Salesforce />}
              haveIntegration={isUserHaveSalesforceIntegration}
              somethingIntegrated={somethingIntegrated}
              integration={salesforceIntegration}
              name="Salesforce"
              onCancel={onSalesforceCancel}
              onSet={onSalesforceSet}
              description={t('Integrations_Hubspot_text')}
            />
          </Grid>
        )}
      {
        !isPageAvailable
        && isBillingAvailable
        && (
          <PagePresentation
            title="Integrations"
            description="With Integrations, updating your CRM is done automatically for you. Unlock the company plan to connect your Salesforce account to Stageset."
          />
        )
      }
      {
        !isPageAvailable
        && !isBillingAvailable
        && (
          <PagePresentationNoPermissions
            title="No Access"
            description="You do not have access to this page"
          />
        )
      }
    </>
  );
};

export default Integrations;
