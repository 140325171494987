import { Box } from '@mui/material';
import { AppSkeleton } from '../../../shared/AppSkeleton/AppSkeleton';

export enum EDataGridColumns {
  status = 'status',
  title = 'title',
  menu = ' ',
  value = 'value',
  client = 'client',
  template = 'template',
  lastActive = 'lastActive',
  lastMileStone = 'lastMileStone',
  health = 'health',
  seller = 'seller',
  owner = 'owner',
}

export const GridItemSkeleton = () => (
  <AppSkeleton
    animation="wave"
    variant="rectangular"
    width="101px"
    height="32px"
    sx={{
      borderRadius: '16px',
    }}
  />
);

export const GridTitleItemSkeleton = ({ disableSubtext } : { disableSubtext?: boolean }) => (
  <Box
    sx={{
      display: 'flex',
      gap: '8px',
    }}
  >
    <AppSkeleton
      animation="wave"
      variant="rectangular"
      width="40px"
      height="40px"
      sx={{
        borderRadius: '12px',
      }}
    />
    <Box
      sx={{
        height: '40px',
        display: 'flex',
        gap: '2px',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <AppSkeleton
        animation="wave"
        variant="rectangular"
        width="160px"
        height="22px"
        sx={{
          borderRadius: '16px',
        }}
      />
      {
        !disableSubtext && (
          <AppSkeleton
            animation="wave"
            variant="rectangular"
            width="50px"
            height="16px"
            sx={{
              borderRadius: '16px',
            }}
          />
        )
      }
    </Box>
  </Box>
);

export const skeletonRows = [
  {
    id: 0,
    [EDataGridColumns.status]: 'skeleton',
    [EDataGridColumns.menu]: 'skeleton',
    [EDataGridColumns.title]: 'skeleton',
    [EDataGridColumns.value]: 'skeleton',
    [EDataGridColumns.health]: 'skeleton',
    [EDataGridColumns.lastActive]: 'skeleton',
    [EDataGridColumns.client]: 'skeleton',
    [EDataGridColumns.template]: 'skeleton',
    [EDataGridColumns.lastMileStone]: 'skeleton',
    [EDataGridColumns.seller]: 'skeleton',
    [EDataGridColumns.owner]: 'skeleton',
  },
  {
    id: 1,
    [EDataGridColumns.status]: 'skeleton',
    [EDataGridColumns.menu]: 'skeleton',
    [EDataGridColumns.title]: 'skeleton',
    [EDataGridColumns.value]: 'skeleton',
    [EDataGridColumns.health]: 'skeleton',
    [EDataGridColumns.lastActive]: 'skeleton',
    [EDataGridColumns.client]: 'skeleton',
    [EDataGridColumns.template]: 'skeleton',
    [EDataGridColumns.lastMileStone]: 'skeleton',
    [EDataGridColumns.seller]: 'skeleton',
    [EDataGridColumns.owner]: 'skeleton',
  },
  {
    id: 2,
    [EDataGridColumns.status]: 'skeleton',
    [EDataGridColumns.menu]: 'skeleton',
    [EDataGridColumns.title]: 'skeleton',
    [EDataGridColumns.value]: 'skeleton',
    [EDataGridColumns.health]: 'skeleton',
    [EDataGridColumns.lastActive]: 'skeleton',
    [EDataGridColumns.client]: 'skeleton',
    [EDataGridColumns.template]: 'skeleton',
    [EDataGridColumns.lastMileStone]: 'skeleton',
    [EDataGridColumns.seller]: 'skeleton',
    [EDataGridColumns.owner]: 'skeleton',
  },
  {
    id: 3,
    [EDataGridColumns.status]: 'skeleton',
    [EDataGridColumns.menu]: 'skeleton',
    [EDataGridColumns.title]: 'skeleton',
    [EDataGridColumns.value]: 'skeleton',
    [EDataGridColumns.health]: 'skeleton',
    [EDataGridColumns.lastActive]: 'skeleton',
    [EDataGridColumns.client]: 'skeleton',
    [EDataGridColumns.template]: 'skeleton',
    [EDataGridColumns.lastMileStone]: 'skeleton',
    [EDataGridColumns.seller]: 'skeleton',
    [EDataGridColumns.owner]: 'skeleton',
  },
  {
    id: 4,
    [EDataGridColumns.status]: 'skeleton',
    [EDataGridColumns.menu]: 'skeleton',
    [EDataGridColumns.title]: 'skeleton',
    [EDataGridColumns.value]: 'skeleton',
    [EDataGridColumns.health]: 'skeleton',
    [EDataGridColumns.lastActive]: 'skeleton',
    [EDataGridColumns.client]: 'skeleton',
    [EDataGridColumns.template]: 'skeleton',
    [EDataGridColumns.lastMileStone]: 'skeleton',
    [EDataGridColumns.seller]: 'skeleton',
    [EDataGridColumns.owner]: 'skeleton',
  },
];
