import { useAppDispatch, useAppSelector } from './stateHooks';
import { EProcessBackgroundStorageType, EProcessBackgroundType } from '../../shared/process/ProcessMilestoneActionDTO';
import {
  IDealCustomizationState,
  resetBackgrounds,
  selectBackground,
  selectBackgroundStorageType,
  selectBackgroundType,
  selectCurrentBackgroundColors,
  selectProcessBackgroundColor,
  selectProcessBackgroundDisplayStatus,
  selectProcessBackgroundUrl,
  selectProcessSvgColors,
  selectTempBackground,
  selectTempBackgroundStorageType,
  selectTempBackgroundType,
  setBackground,
  setBackgroundColor,
  setBackgroundDisplayStatus,
  setBackgroundStorageType,
  setBackgroundType,
  setCurrentBackgroundColor,
  setCustomizationData,
  setProcessBackgroundUrl,
  setProcessSvgColors,
  setTempBackground,
  setTempBackgroundStorageType,
  setTempBackgroundType,
} from '../features/DealCustomizationMenu/lib/dealCustomizationSlice';

const useProcessBackground = () => {
  const dispatch = useAppDispatch();
  const background = useAppSelector(selectBackground);
  const tempBackground = useAppSelector(selectTempBackground);
  const backgroundType = useAppSelector(selectBackgroundType);
  const tempBackgroundType = useAppSelector(selectTempBackgroundType);
  const backgroundStorageType = useAppSelector(selectBackgroundStorageType);
  const tempBackgroundStorageType = useAppSelector(selectTempBackgroundStorageType);
  const url = useAppSelector(selectProcessBackgroundUrl);
  const display = useAppSelector(selectProcessBackgroundDisplayStatus);
  const svgColors = useAppSelector(selectCurrentBackgroundColors);
  const processSvgColors = useAppSelector(selectProcessSvgColors);
  const backgroundColor = useAppSelector(selectProcessBackgroundColor);

  const onBackgroundUpdate = (newBackground?: number | string) => {
    dispatch(setBackground(newBackground));
  };

  const onBackgroundColorUpdate = (newBackgroundColor: string) => {
    dispatch(setBackgroundColor(newBackgroundColor));
  };

  const onTempBackgroundUpdate = (newBackground?: number | string) => {
    dispatch(setTempBackground(newBackground));
  };

  const onUrlUpdate = (newUrl?: string) => {
    dispatch(setProcessBackgroundUrl(newUrl));
  };

  const onBackgroundsReset = () => {
    dispatch(resetBackgrounds());
  };

  const onBackgroundTypeUpdate = (newType?: EProcessBackgroundType) => {
    dispatch(setBackgroundType(newType));
  };

  const onTempBackgroundTypeUpdate = (newType?: EProcessBackgroundType) => {
    dispatch(setTempBackgroundType(newType));
  };

  const onBackgroundStorageTypeUpdate = (newType?: EProcessBackgroundStorageType) => {
    dispatch(setBackgroundStorageType(newType));
  };

  const onTempBackgroundStorageTypeUpdate = (newType?: EProcessBackgroundStorageType) => {
    dispatch(setTempBackgroundStorageType(newType));
  };

  const onBackgroundDisplayUpdate = (status?:boolean) => {
    dispatch(setBackgroundDisplayStatus(status));
  };

  const onBackgroundDataUpdate = (
    newData?: Pick<
    IDealCustomizationState,
    'background'
    | 'backgroundType'
    | 'backgroundStorageType'
    | 'backgroundDisplayStatus'
    | 'currentBackgroundColors'
    | 'url'
    >,
  ) => {
    if (newData) {
      dispatch(setCustomizationData(newData));
    } else {
      dispatch(setCustomizationData({
        background: undefined,
        backgroundType: undefined,
        backgroundDisplayStatus: undefined,
        currentBackgroundColors: undefined,
        url: undefined,
      }));
    }
  };

  const onTempBackgroundDataUpdate = (
    newData?: Pick<
    IDealCustomizationState,
    'tempBackground'
    | 'tempBackgroundType'
    | 'tempBackgroundStorageType'
    | 'tempBackgroundColors'
    | 'url'
    >,
  ) => {
    if (newData) {
      dispatch(setCustomizationData(newData));
    } else {
      dispatch(setCustomizationData({
        tempBackground: undefined,
        tempBackgroundStorageType: undefined,
        tempBackgroundType: undefined,
        tempBackgroundColors: undefined,
      }));
    }
  };

  const onProcessSvgColorsUpdate = (id: number, colors: string[]) => {
    if (processSvgColors) {
      const newColors = {
        ...processSvgColors,
      };
      if (colors.length) {
        newColors[id] = colors;
      } else {
        delete newColors[id];
      }
      dispatch(setProcessSvgColors(newColors));
    } else if (colors.length) {
      dispatch(setProcessSvgColors({ [id]: colors }));
    }
  };

  const onProcessSvgColorsReset = (oldColors?: { [key: number]: string[] | undefined }) => {
    dispatch(setProcessSvgColors(oldColors));
    dispatch(setCurrentBackgroundColor([]));
  };

  return {
    onUrlUpdate,
    processSvgColors,
    svgColors,
    display,
    url,
    background,
    tempBackground,
    onBackgroundUpdate,
    backgroundColor,
    onTempBackgroundUpdate,
    onBackgroundsReset,
    backgroundType,
    tempBackgroundType,
    onBackgroundTypeUpdate,
    onTempBackgroundTypeUpdate,
    backgroundStorageType,
    onBackgroundStorageTypeUpdate,
    onBackgroundDisplayUpdate,
    onProcessSvgColorsUpdate,
    onProcessSvgColorsReset,
    tempBackgroundStorageType,
    onTempBackgroundStorageTypeUpdate,
    onTempBackgroundDataUpdate,
    onBackgroundDataUpdate,
    onBackgroundColorUpdate,
  };
};

export default useProcessBackground;
