import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccessLinkDTO, AccessLinkGuestData } from '../../../../shared/AccessLinkDTO';
import { FileDTO } from '../../../../shared/FileDTO';
import { EUserDealPermissions, TAllRoleNames, TPermissions } from '../../../../shared/permissions';
import { EProcessType, ProcessDTO, ProcessField } from '../../../../shared/process/ProcessMilestoneActionDTO';
import { EIdTagsForAPIs } from '../../../../shared/ProcessIdsTags';
import { OrganizationUserDTO, ProcessUserDTO } from '../../../../shared/UserDTO';
import { EStateStatus } from '../../../core/commonTypes';
import { RootState } from '../../../core/store/store';
import {
  addUserByIdToProcess,
  copyTeamFileToProcess,
  addStageToProcess,
  deactivateUser,
  deleteProcessFile,
  getProcess,
  getProcessAccessLink,
  getProcessFields,
  getProcessFiles,
  getProcessUsers,
  inviteManyUsersToProcess,
  inviteUserToProcess,
  updateAccessLinkById,
  updateAccessProtection,
  updateProcess,
  updateProcessFields,
  uploadFieldFile,
  uploadFiles,
  uploadLogo,
  uploadBackground,
  deleteProcessBackground,
  updateSvgBackgroundColors,
  uploadWideLogo, getProcessAccessLinkByHash, getProcessLastActiveDate, getProcessGuests,
} from './dealActions';
import { ProcessStageDTO } from '../../../../shared/Field/Field';
import { EJWTTokenType } from '../../../../shared/schema/token.schema';

export interface IProcessState {
  status: EStateStatus;
  process?: ProcessDTO;
  accessLink?: AccessLinkDTO;
  files: FileDTO[];
  processUsers: ProcessUserDTO[];
  onlineUsers: string[];
  error?: string;
  currentUserPermissions: TPermissions<EUserDealPermissions>;
  isConnectedToSalesforce?: boolean;
  isSummaryEditingActive: boolean;
  isLayoutEditActive: boolean,
  isActivityLogOpen: boolean,
  fileUploadProgress?: {
    progress?: number;
    name?: string;
  }
  owner: string;
  processOrgWideLogo: string;
  processOrgSquaredLogo: string;
  processDomain?: string;
  userProcessRoleName?: TAllRoleNames;
  newField?: string;
  stage?: ProcessField;
  //
  //
  currency?: string;
}

export const initialState: IProcessState = {
  process: undefined,
  files: [],
  onlineUsers: [],
  processUsers: [],
  accessLink: undefined,
  status: EStateStatus.IDLE,
  currentUserPermissions: {},
  isConnectedToSalesforce: false,
  isSummaryEditingActive: false,
  isLayoutEditActive: false,
  isActivityLogOpen: false,
  owner: '',
  processOrgWideLogo: '',
  processOrgSquaredLogo: '',
  userProcessRoleName: undefined,
  stage: undefined,

};

export const processSlice = createSlice({
  name: 'process',
  initialState,
  reducers: {
    updateFileUploadProgress: (state, { payload }: PayloadAction<{ progress: number | undefined, name: string | undefined, }>) => {
      state.fileUploadProgress = payload;
    },
    clearProcessState: () => initialState,
    setOnlineUsers: (state, { payload }: PayloadAction<string[]>) => {
      state.onlineUsers = payload;
    },
    setIsSummaryEditingActive: (state, { payload }: PayloadAction<boolean>) => {
      state.isSummaryEditingActive = payload;
    },
    setIsLayoutEditActive: (state, { payload }: PayloadAction<boolean>) => {
      state.isLayoutEditActive = payload;
    },
    setIsActivityLogOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isActivityLogOpen = payload;
    },
    setNewField: (state, { payload }: PayloadAction<string | undefined>) => {
      state.newField = payload;
    },
    setStage: (state, { payload }: PayloadAction<ProcessField | undefined>) => {
      state.stage = payload;
    },
    setProcessOrgLogo: (state, { payload }: PayloadAction<string>) => {
      state.processOrgWideLogo = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProcess.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(getProcess.fulfilled, (state, action) => {
        const {
          process,
          processUsers,
          // files,
          accessLink,
          currentUserPermissions,
          isConnectedToSalesforce,
          owner,
          userProcessRoleName,
          processOrgWideLogo,
          processOrgSquaredLogo,
          processDomain,
          currency,
        } = action.payload;
        state.status = EStateStatus.IDLE;
        state.process = process;
        state.accessLink = accessLink;
        state.processUsers = processUsers;
        // state.files = files;
        state.currentUserPermissions = currentUserPermissions;
        state.isConnectedToSalesforce = isConnectedToSalesforce;
        state.owner = owner;
        state.processDomain = processDomain,
        state.processOrgWideLogo = processOrgWideLogo;
        state.processOrgSquaredLogo = processOrgSquaredLogo;
        state.userProcessRoleName = userProcessRoleName;
        state.currency = currency;
      })
      .addCase(getProcess.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(getProcessLastActiveDate.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        if (state.process && action.payload) {
          state.process.lastActive = action.payload;
        }
      })
      .addCase(updateProcess.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(updateProcess.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        if (state.process) {
          state.process = {
            ...state.process,
            ...action.payload,
          };
        }
      })
      .addCase(updateProcess.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(uploadFiles.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(uploadFiles.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        if (state.files) {
          state.files = [...action.payload];
        }
      })
      .addCase(uploadFiles.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(uploadFieldFile.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(uploadFieldFile.fulfilled, (state) => {
        state.status = EStateStatus.IDLE;
      })
      .addCase(uploadFieldFile.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(getProcessFiles.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(getProcessFiles.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        if (state.files && action.payload.length) {
          state.files = [...action.payload];
        }
      })
      .addCase(getProcessFiles.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(getProcessUsers.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        if (state.processUsers) {
          state.processUsers = [...action.payload];
        }
      })
      .addCase(getProcessUsers.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(getProcessGuests.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(getProcessGuests.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        if (state.accessLink) {
          state.accessLink.guests = action.payload;
        }
      })
      .addCase(getProcessGuests.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(copyTeamFileToProcess.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(copyTeamFileToProcess.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        if (state.files) {
          state.files = [...action.payload];
        }
      })
      .addCase(copyTeamFileToProcess.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(inviteUserToProcess.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(inviteUserToProcess.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        if (state.processUsers) {
          const filteredUsers: ProcessUserDTO[] = state.processUsers.filter(
            (processUser: ProcessUserDTO) => processUser.id !== action.payload.id,
          );
          state.processUsers = [...filteredUsers, action.payload];
        }
      })
      .addCase(inviteUserToProcess.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(inviteManyUsersToProcess.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(inviteManyUsersToProcess.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        const newUsers: ProcessUserDTO[] = action.payload;
        if (state.processUsers) {
          const filteredUsers: ProcessUserDTO[] = state.processUsers.filter(
            (processUser: ProcessUserDTO) => !newUsers.find((newUser: ProcessUserDTO) => newUser.id === processUser.id),
          );
          state.processUsers = [...filteredUsers, ...newUsers];
        }
      })
      .addCase(inviteManyUsersToProcess.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(addUserByIdToProcess.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(addUserByIdToProcess.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        if (state.processUsers) {
          state.processUsers = [...state.processUsers, action.payload];
        }
      })
      .addCase(addUserByIdToProcess.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(deactivateUser.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(deactivateUser.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        if (state.processUsers) {
          state.processUsers = state.processUsers.map((user: ProcessUserDTO) => {
            if (user.id === action.payload.id) {
              return {
                ...action.payload,
              };
            }
            return user;
          });
        }
      })
      .addCase(deactivateUser.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(uploadLogo.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(uploadLogo.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        if (state.process) {
          state.process = {
            ...state.process,
            ...action.payload,
          };
        }
      })
      .addCase(uploadLogo.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(uploadWideLogo.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(uploadWideLogo.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        if (state.process) {
          state.process.wideLogo = action.payload.wideLogo;
        }
      })
      .addCase(uploadWideLogo.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(uploadBackground.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(uploadBackground.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        if (state.process) {
          state.process = {
            ...state.process,
            ...action.payload,
          };
        }
      })
      .addCase(uploadBackground.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(updateSvgBackgroundColors.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(updateSvgBackgroundColors.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        if (action.payload.newColors.length) {
          if (state?.process?.styles?.svgColors) {
            state.process.styles.svgColors = {
              ...state.process.styles.svgColors,
              [action.payload.backgroundId]: action.payload.newColors,
            };
          } else if (state?.process?.styles) {
            state.process.styles.svgColors = {
              [action.payload.backgroundId]: action.payload.newColors,
            };
          }
        } else if (state?.process?.styles?.svgColors) {
          state.process.styles.svgColors = {
            ...state.process.styles.svgColors,
            [action.payload.backgroundId]: undefined,
          };
        }
      })
      .addCase(updateSvgBackgroundColors.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(deleteProcessBackground.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(deleteProcessBackground.fulfilled, (state) => {
        state.status = EStateStatus.IDLE;
        if (state.process?.styles) {
          state.process.styles.displayBackground = false;
        }
      })
      .addCase(deleteProcessBackground.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(updateAccessLinkById.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(updateAccessLinkById.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        state.accessLink = {
          ...state.accessLink,
          ...action.payload,
        };
      })
      .addCase(updateAccessLinkById.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(updateAccessProtection.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(updateAccessProtection.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        state.accessLink = {
          ...state.accessLink,
          ...action.payload,
        };
      })
      .addCase(updateAccessProtection.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(getProcessAccessLink.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(getProcessAccessLink.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        state.accessLink = {
          ...action.payload,
        };
      })
      .addCase(getProcessAccessLink.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(getProcessAccessLinkByHash.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(getProcessAccessLinkByHash.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        state.accessLink = {
          ...action.payload,
        };
      })
      .addCase(getProcessAccessLinkByHash.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(deleteProcessFile.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(deleteProcessFile.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        state.files = [...state.files.filter((file: FileDTO) => file.fullPath !== action.payload)];
      })
      .addCase(deleteProcessFile.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(updateProcessFields.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(updateProcessFields.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        state.process = {
          ...state.process,
          fields: action.payload,
        };
      })
      .addCase(updateProcessFields.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(addStageToProcess.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(addStageToProcess.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        state.newField = action.payload.newField;
        state.process = {
          ...state.process,
          fields: action.payload.fields,
        };
      })
      .addCase(addStageToProcess.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(getProcessFields.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(getProcessFields.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        state.process = {
          ...state.process,
          fields: action.payload,
        };
      })
      .addCase(getProcessFields.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      });
  },
});

export const {
  clearProcessState,
  setOnlineUsers,
  setIsSummaryEditingActive,
  setIsLayoutEditActive,
  updateFileUploadProgress,
  setIsActivityLogOpen,
  setNewField,
  setStage,
  setProcessOrgLogo,
} = processSlice.actions;

export const selectProcess = (state: RootState): ProcessDTO => state.process.process;
export const selectProcessUsers = (state: RootState) => state.process.processUsers;
export const selectProcessUserById = (state: RootState, id: string): ProcessUserDTO | undefined => {
  let user;

  user = state?.process?.processUsers?.find((item: ProcessUserDTO) => item.id === id);

  if (user) {
    return user;
  }

  if (!user) {
    const teammate = state?.app?.users.find((item: OrganizationUserDTO) => item.id === id);
    if (teammate) {
      return teammate;
    }
    const guest = state?.process?.accessLink?.guests?.find((item: AccessLinkGuestData) => item.id === id);
    if (guest) {
      return guest;
    }
    user = {
      email: state?.process?.accessLink?.usedByEmails?.find((item: string) => item === id) ?? id,
      colorId: Math.floor(Math.random() * 19) + 1,
    };
  }

  return user;
};

export const selectIsProcessUser = (state: RootState, id: string): boolean => {
  if (
    state?.process?.processUsers?.find((item: ProcessUserDTO) => item.id === id)
  ) {
    return true;
  }

  if (state?.app?.users.find((item: OrganizationUserDTO) => item.id === id)) {
    return true;
  }

  if (
    state?.process?.accessLink?.usedByEmails?.find((item: string) => item === id)
    ?? state?.process?.accessLink?.guests?.find((item: AccessLinkGuestData) => item.id === id)?.name
  ) {
    return true;
  }

  return false;
};

export const selectProcessUsersMap = (state: RootState): Map<string, string> => {
  const usersMap = new Map<string, string>();
  state?.process?.processUsers?.forEach((item: ProcessUserDTO) => usersMap.set(item.id, item.id));
  state?.process?.accessLink?.guests?.forEach((item: AccessLinkGuestData) => usersMap.set(item.id, item.id));
  state?.process?.accessLink?.usedByEmails?.forEach((item: string) => usersMap.set(item, item));
  state?.app?.users.forEach((item: OrganizationUserDTO) => usersMap.set(item.id, item.id));
  return usersMap;
};

export const selectUsersDataForTimelineFilter = (state: RootState): { id: string, name: string }[] => {
  const defaultUsers = state.process.processUsers.map((user: ProcessUserDTO) => ({
    id: user.id,
    name: user.name ?? user.email,
  }));
  const sharedUsers = state.process.accessLink.usedByEmails.map((email: string) => ({ id: email, name: email }));
  const guests = state.process.accessLink.guests ?? [];
  return [...defaultUsers, ...sharedUsers, ...guests];
};

export const selectUsersDataForOwnershipEvent = (state: RootState, ids: string[]): {
  id: string,
  name: string,
  avatarSrc?: string,
}[] => {
  const parsedUsers = ids.map((id) => {
    let user;

    user = state.process.processUsers.find((processUser: ProcessUserDTO) => id === processUser.id);

    if (user) {
      return ({
        id,
        name: user.name ?? user.email,
        avatarSrc: user.avatarSrc,
      });
    }
    user = state.process.accessLink.usedByEmails.find((email: string) => email === id);

    if (user) {
      return ({
        id,
        name: user,
      });
    }

    return ({
      id,
      name: id,
    });
  });
  return parsedUsers;
};

export const selectProcessSliceStatus = (state: RootState) => state.process.status;

export const selectOnlineProcessUsers = (state: RootState) => state.process.onlineUsers;
export const selectProcessId = (state: RootState): string => state?.process?.process?.id ?? EIdTagsForAPIs.TEMPLATE;
export const selectIsTemplate = (state: RootState) => state.process?.process?.type === EProcessType.TEMPLATE;
export const selectLayouEditingStatus = (state: RootState): boolean => !!state.process.isLayoutEditActive;
export const selectProcessFields = (state: RootState): ProcessField[] => state?.process?.process?.fields ?? [];
export const selectProcessFieldsCount = (state: RootState): number => state?.process?.process?.fields.length ?? 0;
export const selectStage = (state: RootState): ProcessStageDTO => state?.process.stage;
export const selectStageId = (state: RootState): string => state?.process?.stage?.id;
export const selectAccessLink = (state: RootState): AccessLinkDTO => state?.process?.accessLink;
export const selectIsActiveStage = (state: RootState, id?: string): boolean => !!id && (state?.processItems?.currentStage?.id === id);
export const selectProcessWideLogo = (state: RootState):string | undefined => state?.process?.process?.wideLogo;
export const selectProcessOrgWideLogo = (state: RootState):string | undefined => state?.process?.processOrgWideLogo;
export const selectProcessOrgSquaredLogo = (state: RootState): string | undefined => state?.process?.processOrgSquaredLogo;

export const selectIsProcessOwner = (state: RootState): boolean => state?.app?.user?.id && (
  state?.process?.owner === state?.app?.user?.id
);

export const selectProcessBackgroundUrl = (state: RootState): string => state?.process?.process?.styles?.url;
export const selectSellerLogo = (state: RootState): string => state?.process?.process?.sellerLogoSrc;
export const selectSellerName = (state: RootState): string => state?.process?.process?.sellerName;
export const selectBuyerLogo = (state: RootState): string => state?.process?.process?.buyerLogoSrc;
export const selectBuyerName = (state: RootState): string => state?.process?.process?.client;
export const selectProcessCurrency = (state: RootState): string => state?.process?.currency;

export const selectSvgBackroundColors = (
  state: RootState,
): { [key:number] : string[] } => state?.process?.process?.styles?.svgColors ?? {};

export const selectIsNewField = (state: RootState, fieldId: string): boolean => state?.process?.newField === fieldId;

export const selectShouldRenderSideBar = (state: RootState): boolean => (
  (!state.process.process || state.process.process.type === EProcessType.TEMPLATE)
  && !state.library.templateEditMode
  && state.auth.tokenType !== EJWTTokenType.GUEST_AUTH
);

export const selectProcessLastActive = (state: RootState): number => state.process.process.lastActive;

export const selectIsOwnersTeammate = (state: RootState): boolean => !!state?.app?.users?.find(
  (user: OrganizationUserDTO) => user.id === state.process.owner,
);

export const selectProcessDomain = (state: RootState): string | undefined => (
  state.process.processDomain ? state.process.processDomain : undefined
);

export const selectNextPosition = (state: RootState, currentPositionId?: string | undefined): number => {
  const currentFields = state?.process?.process?.fields.map((item: ProcessField) => item.id) ?? [];
  return (
    currentPositionId ? currentFields.indexOf(currentPositionId) + 1 : currentFields.length + 1
  );
};

export const selectProcessOwner = (state: RootState): string => state?.process?.owner;
