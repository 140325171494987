import { useEffect, useState } from 'react';
import { WebsocketProvider } from 'y-websocket';
import * as Y from 'yjs';
import { useCurrentStage } from '../../hooks/useCurrentStage';
import { config } from '../../core';
import { TiptapCustomEvents } from './TiptapEventListnerPlugin';
import useEditorEvent from './Hooks/useTiptapEditorEvents';

let ydoc: Y.Doc | undefined;
let wsProvider: WebsocketProvider | undefined;

const useTiptapSockets = (offline?: boolean) => {
  if (offline) return { ydoc: null };
  const { id } = useCurrentStage();
  const { dispatchEditorEvent } = useEditorEvent();
  const [doc, setDoc] = useState<Y.Doc | null>(null);
  const [docStatus, setDocStatus] = useState(false);

  useEffect(() => {
    if (!ydoc && !!id) {
      ydoc = new Y.Doc();
      ydoc.on('update', (...args) => {
        if (args[3].origin.key) {
          dispatchEditorEvent({ eventType: TiptapCustomEvents.SAVE_UPDATE });
        }
      });
      setDocStatus(true);
    }
    return () => {
      ydoc = undefined;
      setDocStatus(false);
      setDoc(null);
    };
  }, [id]);

  useEffect(() => {
    if (ydoc && docStatus && !wsProvider) {
      wsProvider = new WebsocketProvider(config.tiptapSockets.wsHref, `?roomId=${id}`, ydoc, { disableBc: true });
      wsProvider.on('synced', (x: boolean) => {
        if (x) {
          setDoc(ydoc ?? null);
          if (ydoc) {
            ydoc.isSynced = true;
          }
        }
      });
    }
    return () => {
      if (wsProvider) {
        wsProvider?.disconnect();
        wsProvider = undefined;
      }
    };
  }, [docStatus]);

  return { ydoc: doc, wsProvider } as { ydoc:Y.Doc | null, wsProvider: WebsocketProvider | undefined
  };
};

export default useTiptapSockets;
