import { SyntheticEvent, useState } from 'react';
import ConfirmationDialog from '../../common/ConfirmationDialog/ConfirmationDialog';
import DeleteTypography from '../../common/DeleteTypography';
import { organizationDeactivateUser } from '../../core/store/appState/appState';
import { useAppDispatch } from '../../hooks/stateHooks';
import NormalizedSvgIcon from '../../shared/NormalizedSvgIcon/NormalizedSvgIcon';
import AlertTriangle from '../../icons/AlertTriangle.svg';
import { AppIconButton } from '../../shared/AppButton/AppIconButton';
import TrashIcon from '../../icons/TrashIcon';
import { stagesetColors } from '../../theme/stagesetPalette';

interface Props {
  id: string;
  name?: string;
  email: string;
}

const OrganizationUsersMenu = ({
  name,
  email,
  id,
}: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const [isDeleteUserConfirmationDialogOpen, setDeleteUserConfirmationDialogOpen] = useState<boolean>(false);

  const deleteTypography = (
    <DeleteTypography>
      Are you sure you want to exclude
      <b>
        {' '}
        {name || email}
        {' '}
      </b>
      from the team?
    </DeleteTypography>
  );

  const onDeleteConfirm = (): void => {
    setDeleteUserConfirmationDialogOpen(false);
    dispatch(organizationDeactivateUser({ userId: id }));
  };

  const handleRemoveClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    setDeleteUserConfirmationDialogOpen(true);
  };

  return (
    <>
      <AppIconButton
        onClick={handleRemoveClick}
        icon={<TrashIcon />}
        variant="danger-transparent"
      />
      <ConfirmationDialog
        isDialogOpen={isDeleteUserConfirmationDialogOpen}
        onCancel={() => setDeleteUserConfirmationDialogOpen(false)}
        onConfirm={onDeleteConfirm}
        dialogContentText={deleteTypography}
        dialogTitle="Are your sure?"
        titleVariant="danger-primary"
        confirmText="Exclude"
        icon={<NormalizedSvgIcon component={AlertTriangle} />}
      />
    </>
  );
};

export default OrganizationUsersMenu;
