import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import classNames from 'classnames';
import { AppButton } from '../../shared/AppButton/AppButton';
import { AppIconButton } from '../../shared/AppButton/AppIconButton';
import DealCustomizationMenu from '../DealCustomizationMenu/DealCustomizationMenu';
import {
  ESidebarTabs, selectSidebarTab, setSidebarTab, setStagesDrawerOpen,
} from '../Layout/lib/fieldsLayoutSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import useProcessBackground from '../../hooks/useProcessBackground';
import { selectProcess } from '../../routes-old/process/state/processSlice';
import { updateProcess } from '../../routes-old/process/state/dealActions';
import { EProcessBackgroundStorageType, ThemePalette } from '../../../shared/process/ProcessMilestoneActionDTO';
import {
  resetDealThemePalette, saveDealThemePalette,
  selectDealThemePaletteSidebar,
  selectLogoDisplayStatus,
  setLogoDisplayStatus,
} from '../DealCustomizationMenu/lib/dealCustomizationSlice';
import { useDealTheme } from '../../hooks/useDealTheme';
import { CloseIcon20 } from '../../icons/CloseIcon';
import {
  HeaderTitle,
  SidebarContent,
  SidebarHeader,
  SidebarTabButtonWrapper,
  SidebarTabContentWrapper,
  SidebarTabPanel,
} from '../Stages/Styles/SidebarStyles';

const DealCustomizationTab = () => {
  const dispatch = useAppDispatch();
  const process = useAppSelector(selectProcess);
  const currentDealPaletteSidebar = useSelector(selectDealThemePaletteSidebar);
  const displayLogo = useAppSelector(selectLogoDisplayStatus);
  const currentTab = useAppSelector(selectSidebarTab) === ESidebarTabs.CUSTOMIZATION;
  const { dealTheme: { dealOverrides: { sidebar: { contrastText } } } } = useDealTheme();
  const changeColor = contrastText === '#000000';
  const {
    background: localId,
    backgroundType: type,
    backgroundStorageType: backgroundStorage,
    url,
    display,
    processSvgColors: svgColors,
    onBackgroundStorageTypeUpdate,
    onBackgroundTypeUpdate,
    onBackgroundUpdate,
    onBackgroundDisplayUpdate,
    onProcessSvgColorsReset,
    backgroundColor,
  } = useProcessBackground();

  const goBack = () => {
    dispatch(setSidebarTab(ESidebarTabs.STAGES));
    if (isMobile) {
      dispatch(setStagesDrawerOpen(false));
    }
  };

  const onNewBackgroundSelect = async () => {
    const themePalette: ThemePalette = {
      // common: currentDealPaletteCommon,
      sidebar: currentDealPaletteSidebar,
    };
    await dispatch(updateProcess({
      id: process.id,
      styles: {
        backgroundStorage,
        type: type ?? process.styles?.type,
        localId,
        url,
        displayBackground: display ?? true,
        backgroundColor: backgroundColor ?? '',
        svgColors,
        displayLogo,
        theme: themePalette,
      },
    }));
    dispatch(saveDealThemePalette(themePalette));
  };

  const onSave = () => {
    onNewBackgroundSelect();
    goBack();
  };

  const onReset = () => {
    onBackgroundStorageTypeUpdate(process?.styles?.backgroundStorage);
    onBackgroundTypeUpdate(process?.styles?.type);
    onBackgroundUpdate(
      process?.styles?.backgroundStorage === EProcessBackgroundStorageType.AWS
        ? process?.styles?.url : process?.styles?.localId,
    );
    onBackgroundDisplayUpdate(process?.styles?.displayBackground);
    onProcessSvgColorsReset(process?.styles?.svgColors);
    dispatch(resetDealThemePalette());
    dispatch(setLogoDisplayStatus(process?.styles?.displayBackground ?? true));
    goBack();
    if (isMobile) {
      dispatch(setStagesDrawerOpen(false));
    }
  };

  return (
    <SidebarTabPanel
      value={ESidebarTabs.CUSTOMIZATION}
      className={classNames('sidebar-themed', { 'active-sidebar-tab': currentTab })}
    >
      <SidebarTabContentWrapper
        className="sidebar-wrapper-default"
      >
        <SidebarHeader>
          <HeaderTitle>
            Customization
          </HeaderTitle>
          <AppIconButton
            variant={changeColor ? 'transparent' : 'transparent-white'}
            transparentStroke
            icon={<CloseIcon20 />}
            onClick={onReset}
          />
        </SidebarHeader>
        <SidebarContent>
          <DealCustomizationMenu />
        </SidebarContent>
      </SidebarTabContentWrapper>
      <SidebarTabButtonWrapper>
        <AppButton
          size="l"
          fullWidth
          variant="primary"
          onClick={onSave}
        >
          Done
        </AppButton>
      </SidebarTabButtonWrapper>
    </SidebarTabPanel>
  );
};

export default DealCustomizationTab;
