import { Box, Typography } from '@mui/material';
import OrganizationInfoLowerSection
  from '../../../../widgets/OrganizationInfoPanel/OrganizationInfoPanelLowerSection/OrganizationInfoPanelLowerSection';
import {
  OrganizationUsersListPaper,
} from '../../../../routes-old/organizationUsers/OrganizationUsersStyles/OrganizationUsersListStyle';
import { CrossDimensionDataGrid } from '../../../../shared/CrossDimensionDataGrid/CrossDimensionDataGrid';
import OrganizationUsersMenu from '../../../../routes-old/organizationUsers/OrganizationUsersMenu';
import { AppDataGrid } from '../../../../shared/AppDataGrid';
import { useOrganization } from '../../../../hooks/useOrganization';
import { EDataGridColumns, useOrganizationDataGrid } from '../../../../hooks/useOrganizationDataGrid';
import { EUserOrganizationPermissions } from '../../../../../shared/permissions';
import useUserOrganizationPermissions from '../../../../hooks/useOrganizationPermissions';
import { useAppSelector } from '../../../../hooks/stateHooks';
import { selectOrgSubscriptionStatus } from '../../../../routes-old/Billing/state/billingSlice';
import { ESubscriptionStatus } from '../../../../../shared/Subscription';

export const MainTab = () => {
  const {
    organizationOwner,
  } = useOrganization();
  const subscriptionStatus = useAppSelector(selectOrgSubscriptionStatus);
  const { columns, rows } = useOrganizationDataGrid();
  const [checkOrganizationPermissions] = useUserOrganizationPermissions();
  const inviteAvailable: boolean = checkOrganizationPermissions(EUserOrganizationPermissions.ORGANIZATION_TEAM_INVITE)
    && subscriptionStatus === ESubscriptionStatus.ACTIVE;
  const removeAvailable: boolean = checkOrganizationPermissions(EUserOrganizationPermissions.ORGANIZATION_TEAM_REMOVE);
  const canRemove = (id: string) => removeAvailable && id !== organizationOwner?.id;

  return (
    <>
      <OrganizationInfoLowerSection
        inviteAvailable={inviteAvailable}
      />
      <OrganizationUsersListPaper className="content-paper">
        <CrossDimensionDataGrid
          columns={columns}
          rows={rows}
          getId={(row) => row.id}
          renderMobileTitle={(row) => (
            <Box>
              <Box>
                {row[EDataGridColumns.title].name ?? row[EDataGridColumns.title].email}
              </Box>
              <Box>
                <Typography color="#7084B4" fontSize={12}>
                  {row[EDataGridColumns.title].title}
                </Typography>
              </Box>
            </Box>
          )}
          renderMobileMenu={(row) => (
            <Box hidden={!canRemove(row.id)}>
              <OrganizationUsersMenu id={row.id} email={row[EDataGridColumns.email]} name={row[EDataGridColumns.name].name} />
            </Box>
          )}
          renderDesktopGridComponent={(props) => (
            <AppDataGrid
              {...props}
              disableSelectionOnClick
              disableColumnMenu
              hideFooter
              columns={columns}
              rows={rows}
              headerHeight={24}
              getRowSpacing={() => ({
                top: 4,
                bottom: 4,
              })}
            />
          )}
        />
      </OrganizationUsersListPaper>
    </>
  );
};
