import { yupResolver } from '@hookform/resolvers/yup';
import { Box, TextField, Typography } from '@mui/material';
import { message } from 'antd';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { EStateStatus } from '../../../core/commonTypes';
import { EAppRoutes } from '../../../core/router';
import { useAppDispatch } from '../../../hooks/stateHooks';
import AuthButton from '../Components/AuthButton';
import {
  TitleAdditionalTypography, TitleTypography, TitleTypographyWrapper,
} from '../Components/AuthStyledComponents';
import FormError from '../Components/FormError';
import PasswordVisibilityIcon from '../Components/PasswordVisibilityIcon';
import { logout, resetAuthError } from '../state/authState';
import { SocialAuth } from './SocialAuth';
import Add from '../../../icons/Add.svg';
import { AppLinkButton } from '../../../shared/AppButton/AppLinkButton';

export interface IFormState {
  email: string;
  password: string;
}

interface Props {
  onSubmit: (data: IFormState) => void;
  status: EStateStatus;
  errorMessage?: string;
}

export const LoginForm = ({ onSubmit, status, errorMessage }: Props): JSX.Element => {
  const [isPasswordVisible, setPasswordVisible] = useState<boolean>(false);
  const userEmail = window.sessionStorage.getItem('userEmail');
  const { t } = useTranslation();

  const schema = yup.object({
    email: yup.string().required('Email is required'),
    password: yup.string()
      .required('No password provided.'),
  }).required();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    register,
  } = useForm<IFormState>({
    defaultValues: { email: userEmail ?? '', password: '' },
    resolver: yupResolver(schema),
  });
  register('email', {
    onChange: () => {
      dispatch(resetAuthError());
    },
  });

  register('password', {
    onChange: () => {
      dispatch(resetAuthError());
    },
  });

  useEffect(() => () => {
    dispatch(resetAuthError());
  }, []);

  return (
    <>
      <TitleTypographyWrapper>
        <TitleTypography>
          {t('Auth_Log_in')}
        </TitleTypography>
      </TitleTypographyWrapper>
      <Box
        sx={{
          width: '100%',
        }}
      >
        <SocialAuth />
      </Box>
      <Typography
        sx={{
          color: ' #7084B4',
          fontSize: '14px',
          lineHeight: '16px',
          fontWeight: 500,
          textAlign: 'center',
        }}
      >
        {t('Auth_or_use_email')}
      </Typography>
      <Box
        component="form"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '12px',
          width: '100%',
        }}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        {
          status === EStateStatus.ERROR
          && message
          && (
            <FormError message={errorMessage} isVisible={status === EStateStatus.ERROR} />
          )
        }
        <Controller
          name="email"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              label="Email"
              variant="outlined"
              size="small"
              fullWidth
              id="email"
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
              sx={{
                borderRadius: '8px',
              }}
              InputProps={{
                sx: {
                  borderRadius: '8px',
                },
              }}
            />
          )}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          <Controller
            name="password"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="Password"
                variant="outlined"
                size="small"
                id="password"
                fullWidth
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
                type={isPasswordVisible ? 'text' : 'password'}
                sx={{
                  borderRadius: '8px',
                }}
                InputProps={{
                  sx: {
                    borderRadius: '8px',
                  },
                  endAdornment: <PasswordVisibilityIcon
                    setPasswordVisible={setPasswordVisible}
                    isPasswordVisible={isPasswordVisible}
                  />,
                }}
              />
            )}
          />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'start',
            }}
          >
            <AppLinkButton
              size="xs"
              onClick={() => {
                dispatch(logout());
                navigate(`${EAppRoutes.auth}${EAppRoutes.resetPassword}`);
              }}
              variant="primary"
            >
              {t('Auth_Forgot_your_password')}
            </AppLinkButton>
          </Box>
        </Box>
        <AuthButton status={status} title={t('Auth_Log_in')} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
        }}
      >
        <TitleAdditionalTypography>
          {t('Auth_Not_a_member_yet')}
        </TitleAdditionalTypography>
        <AppLinkButton
          size="xs"
          onClick={() => {
            dispatch(logout());
            navigate(`${EAppRoutes.auth}${EAppRoutes.signup}`);
          }}
          variant="primary"
        >
          &nbsp;
          {t('Auth_Sign_up')}
        </AppLinkButton>
      </Box>
    </>
  );
};
