import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { GridColumns, GridRowParams, GridRowsProp } from '@mui/x-data-grid';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { FileDTO } from '../../../shared/FileDTO';
import { FileStorageApi } from '../../api/FileStorageApi';
import FileUploadStatus, { EFileUploadSource } from '../../common/FilesDragAndDrop/FileUploadStatus';
import PagePresentation from '../../common/PagePresentation/PagePresentation';
import { EStateStatus } from '../../core/commonTypes';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import { FileManagementDragAndDrop } from './FileManagementDragAndDrop';
import FileManagementMenu from './FileManagementMenu';
import { FileManagementDataGrid, FileManagementPaper } from './FileManagementStyles/FileManagementListStyle';
import { FileManagementStatusBox, FileManagerWrapper } from './FileManagementStyles/UploadSectionStyle';
import { getOrganizationFiles, selectAll, uploadOrganizationFiles } from './state/filesSlice';
import { handleScroll } from '../process/helpers';
import { EUserOrganizationPermissions } from '../../../shared/permissions';
import PagePresentationNoPermissions from '../../common/PagePresentation/PagePresentationNoPermissions';
import useUserOrganizationPermissions from '../../hooks/useOrganizationPermissions';
import useUserOrganizationStatus from '../../hooks/useSubscriptionStatus';

const fileStorageApi = new FileStorageApi();

interface Props {
  isUploadActive?: boolean;
  isDeleteActive?: boolean;
  isCopyToProcessActive?: boolean;
  isDownloadActive?: boolean;
  isToolbarActive?: boolean;
}

enum EDataGridColumns {
  menu = ' ',
  fileName = 'File Name',
  lastChange = 'Last Change',
}

const FileManagement = ({
  isDeleteActive, isUploadActive, isCopyToProcessActive, isDownloadActive, isToolbarActive,
}: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const { status, fileUploadProgress } = useAppSelector((state) => state.files);
  const { user, organization } = useAppSelector((state) => state.app);
  const files = useAppSelector((state) => selectAll(state.files));
  const [isUploading, setIsUploading] = useState(false);
  const [uploadSuccess, setUploadSucces] = useState(false);
  const [checkOrganizationPermissions] = useUserOrganizationPermissions();
  const { checkLimits } = useUserOrganizationStatus();

  const isPageAvailable: boolean = checkOrganizationPermissions(EUserOrganizationPermissions.ORGANIZATION_NAV_FILES) && checkLimits();
  const isBillingAvailable: boolean = checkOrganizationPermissions(EUserOrganizationPermissions.ORGANIZATION_NAV_BILLING);

  useEffect(() => {
    if (fileUploadProgress?.progress) {
      setIsUploading(true);
    }
    if (fileUploadProgress?.progress === 100) {
      setUploadSucces(true);
      setTimeout(() => {
        setIsUploading(false);
        setUploadSucces(false);
      }, 2000);
    }
  }, [fileUploadProgress]);

  useEffect(() => {
    if (status !== EStateStatus.LOADING) {
      dispatch(getOrganizationFiles());
    }
  }, [user.id]);

  const onFileUpload = (files: File[]) => {
    dispatch(uploadOrganizationFiles({
      file: files[0],
      userId: user?.id,
      organizationId: organization?.id,
    }));
  };

  const onItemClick = (fileData: GridRowParams) => {
    if (isDownloadActive && !isMobile) {
      fileStorageApi.downloadFileByPath(fileData.row.fileId, fileData.row.fileName);
    }
  };

  const columns: GridColumns = [
    {
      field: EDataGridColumns.menu,
      width: 50,
      cellClassName: 'pro-grid-cell',
      hideSortIcons: true,
      flex: 1,
      align: 'right',
      renderCell: (
        {
          value,
        },
      ) => (
        <FileManagementMenu
          // @ts-ignore
          file={value?.file}
          // @ts-ignore
          isDeleteActive={value?.isDeleteActive}
          // @ts-ignore
          isCopyToProcessActive={value?.isCopyToProcessActive}
          // @ts-ignore
          isDownloadActive={value?.isDownloadActive}
        />
      ),
    },
    {
      field: EDataGridColumns.fileName,
      width: 1000,
      cellClassName: 'pro-grid-cell',
      flex: 3,
      align: 'left',
    },
    {
      field: EDataGridColumns.lastChange,
      width: 200,
      cellClassName: 'pro-grid-cell',
      type: 'dateTime',
      flex: 1.6,
      align: 'left',
      headerAlign: 'left',
      valueFormatter: (params: any) => DateTime.fromISO(params.value).setLocale('en').toLocaleString({
        year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric',
      }),
    },
  ];

  let rows: GridRowsProp = [];
  if (files.length) {
    rows = files
      .map((file: FileDTO, i: number) => ({
        id: i,
        fileId: file.fullPath,
        fileName: file.name,
        [EDataGridColumns.menu]: {
          id: file.fullPath,
          file,
          isDeleteActive,
          isCopyToProcessActive,
          isDownloadActive,
        },
        [EDataGridColumns.fileName]: file.name,
        [EDataGridColumns.lastChange]: file.lastModified,
      }));
  }

  return (
    <Box
      sx={{
        width: '100%',
        '& .FileManagmentWrapper-active': {
          '&::-webkit-scrollbar-thumb': {
            visibility: 'visible',
          },
        },
      }}
    >
      {
        isPageAvailable && (
          <FileManagerWrapper
            className="FileManagmentWrapper"
            onScroll={(e) => handleScroll(e.target as Element, 'FileManagmentWrapper-active')}
          >
            <Box className="FileManagmentContainer">
              <Box className="FileManagmentInnerContainer">
                <FileManagementStatusBox className="FileManagerStatus">
                  <Typography
                    variant="h2"
                    className="StatusLabel"
                  >
                    <CloudQueueIcon
                      sx={{
                        color: '#7E7E7E',
                        mr: '20px',
                        fontSize: '50px',
                      }}
                    />
                    Team cloud
                  </Typography>
                </FileManagementStatusBox>
                {isUploadActive && (
                  <Box
                    sx={{
                      width: '100%',
                      flexBasis: '180px',
                      padding: '0 18%',
                      marginTop: '5vh',
                      height: '180px',
                      minHeight: '180px',
                    }}
                  >

                    {isUploading ? (
                      <FileUploadStatus
                        uploadStatusSource={EFileUploadSource.FILE_MANAGEMENT}
                        fileProgress={fileUploadProgress?.progress}
                        uploadSuccess={uploadSuccess}
                      />
                    ) : (
                      <FileManagementDragAndDrop
                        onFileDrop={(data) => onFileUpload(data)}
                        text="Drag and drop the file here to upload"
                        icon={(
                          <CloudUploadOutlinedIcon
                            sx={{
                              fontSize: '50px',
                            }}
                          />
                        )}
                      />
                    )}
                  </Box>
                )}
                <FileManagementPaper>
                  <FileManagementDataGrid
                    disableSelectionOnClick
                    disableColumnMenu
                    hideFooter
                    columns={columns}
                    rows={rows}
                    onRowClick={onItemClick}
                  />
                </FileManagementPaper>
              </Box>
            </Box>
          </FileManagerWrapper>
        )
      }
      {
        !isPageAvailable
        && isBillingAvailable
        && (
          <PagePresentation
            title="File Management"
            description="With File Management, your team will save time accessing and using your most up to date content. Unlock the Company plan to upload and manage files for your organisation."
          />
        )
      }
      {
        !isPageAvailable
        && !isBillingAvailable
        && (
          <PagePresentationNoPermissions
            title="No Access"
            description="You do not have access to this page"
          />
        )
      }
    </Box>
  );
};

export default FileManagement;
