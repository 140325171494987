export interface IAppConfig {
  name: string;
  origin: string;
  env: string;
  isLogsEnabled: boolean;
  disableDealNotificationsFetching?: boolean;
  domain?: string;
  cookiePrefix?: string;
  approximatedClusterIP?: string;
  localesBasicPath?: string;
}

export interface IApiConfig {
  origin: string;
  prefix: string;
  path: string;
  wsHref: string;
  notificationsBaseUrl: string;
  notificationsUrlPrefix: string;
}

export interface IFireBaseConfig {
  authKey: string;
  authDomain: string;
  projectId: string;
}

export interface IConfig {
  app: IAppConfig;
  api: IApiConfig;
  firebase: IFireBaseConfig;
  mixpanel: {
    key: string,
  },
  sentry: {
    dsn: string,
  },
  googleTagManager: {
    container: string,
  },
  iframely: {
    apiKeyHash: string
  },
  cello: {
    scriptUrl: string,
    attributionUrl: string,
  },
  favicon: string,
  tiptapSockets: {
    wsHref: string,
  },
}

/**
 * Client-side application settings for the local development environment.
 */
export const dev: IConfig = {
  // Core application settings
  app: {
    name: 'Stageset',
    origin: 'http://localhost:3000',
    domain: 'http://dev-stageset.io:3000',
    env: 'local',
    isLogsEnabled: false,
    disableDealNotificationsFetching: true,
    cookiePrefix: 'stageset_',
    approximatedClusterIP: '169.155.55.83',
    localesBasicPath: '/locales',
  },
  // GraphQL API and OAuth endpoint(s)
  // https://github.com/kriasoft/node-starter-kit
  api: {
    origin: 'http://localhost:3050',
    notificationsBaseUrl: 'http://localhost:3250',
    prefix: '', // Cloud Function URL pathname
    notificationsUrlPrefix: '',
    path: '',
    wsHref: 'ws://localhost:3050/',
  },
  // Firebase / Firestore (optional)
  // https://firebase.google.com/docs/firestore
  firebase: {
    authKey: 'xxxxx',
    authDomain: 'https://example.firebaseapp.com',
    projectId: 'example',
  },
  mixpanel: {
    key: '52519c50d5576cdd9c09bb3119a3ef21',
  },
  sentry: {
    dsn: 'https://d8af475b854728a00f913455a4562b58@o4505937568989184.ingest.sentry.io/4505937572134912',
  },
  googleTagManager: {
    container: 'GTM-NSXFGX6',
  },
  iframely: {
    apiKeyHash: '2374da57d15ffc011f83d09ccff349b9',
  },
  cello: {
    scriptUrl: 'https://assets.sandbox.cello.so/app/latest/cello.js',
    attributionUrl: 'https://assets.sandbox.cello.so/attribution/latest/cello-attribution.js',
  },
  favicon: 'http://localhost:3000/favicon.ico',
  tiptapSockets: {
    wsHref: 'ws://localhost:3400',
  },
};

/**
 * Client-side application settings for the test / QA environment.
 */
export const stage: IConfig = {
  app: {
    ...dev.app,
    origin: 'https://app.staging.stageset.io/app',
    domain: 'https://staging.stageset.io',
    env: 'test',
    isLogsEnabled: false,
    disableDealNotificationsFetching: false,
    cookiePrefix: 'stageset_',
    approximatedClusterIP: '169.155.55.83',
    localesBasicPath: '/app/locales',
  },
  api: {
    ...dev.api,
    origin: 'https://app.staging.stageset.io/app/api',
    prefix: 'api/',
    notificationsUrlPrefix: 'api/',
    notificationsBaseUrl: 'https://notifications.staging.stageset.io',
    wsHref: 'wss://app.staging.stageset.io/',
  },
  tiptapSockets: {
    wsHref: 'wss://tiptap-sockets.staging.stageset.io',
  },
  firebase: {
    authKey: 'xxxxx',
    authDomain: 'https://example.firebaseapp.com',
    projectId: 'example',
  },
  mixpanel: {
    key: '52519c50d5576cdd9c09bb3119a3ef21',
  },
  sentry: {
    dsn: 'https://d8af475b854728a00f913455a4562b58@o4505937568989184.ingest.sentry.io/4505937572134912',
  },
  googleTagManager: {
    container: 'GTM-NSXFGX6',
  },
  iframely: {
    apiKeyHash: '2374da57d15ffc011f83d09ccff349b9',
  },
  cello: {
    scriptUrl: 'https://assets.sandbox.cello.so/app/latest/cello.js',
    attributionUrl: 'https://assets.sandbox.cello.so/attribution/latest/cello-attribution.js',
  },
  favicon: 'https://app.staging.stageset.io/app/favicon.ico',
};

/**
 * Client-side application settings for the production environment.
 */
export const prod: IConfig = {
  app: {
    ...dev.app,
    origin: 'https://stageset.io/app',
    domain: 'https://stageset.io',
    env: 'prod',
    isLogsEnabled: false,
    disableDealNotificationsFetching: false,
    cookiePrefix: 'stageset_',
    approximatedClusterIP: '137.66.19.21',
    localesBasicPath: '/app/locales',
  },
  api: {
    ...dev.api,
    origin: 'https://app.stageset.io/app/api',
    prefix: 'api/',
    wsHref: 'wss://app.stageset.io/',
    notificationsUrlPrefix: 'api/',
    notificationsBaseUrl: 'https://notifications.app.stageset.io',
  },
  tiptapSockets: {
    wsHref: 'wss://tiptap-sockets.stageset.io',
  },
  firebase: {
    authKey: 'xxxxx',
    authDomain: 'https://example.firebaseapp.com',
    projectId: 'example',
  },
  mixpanel: {
    key: '71f7db8fdcc40210cc242aa74f5e6540',
  },
  sentry: {
    dsn: 'https://d8af475b854728a00f913455a4562b58@o4505937568989184.ingest.sentry.io/4505937572134912',
  },
  googleTagManager: {
    container: 'GTM-NSXFGX6',
  },
  iframely: {
    apiKeyHash: '2374da57d15ffc011f83d09ccff349b9',
  },
  cello: {
    scriptUrl: 'https://assets.cello.so/app/latest/cello.js',
    attributionUrl: 'https://assets.cello.so/attribution/latest/cello-attribution.js',
  },
  favicon: 'https://stageset.io/app/favicon.ico',
};

export default { dev, stage, prod };
