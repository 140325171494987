import { EUserDealPermissions, EUserOrganizationPermissions } from '../../shared/permissions';
import { EProcessType } from '../../shared/process/ProcessMilestoneActionDTO';
import { useAppSelector } from './stateHooks';

const useDealPermissions = (): [
  (processPermission?: EUserDealPermissions, organizationPermission?: EUserOrganizationPermissions) => boolean,
] => {
  const organization = useAppSelector((state) => state.app.organization);
  const permissions = useAppSelector((state) => state.app.permissions);
  const process = useAppSelector((state) => state.process.process);
  const isSharedUser = useAppSelector((state) => state.auth.isSharedUser);
  const currentUserPermissions = useAppSelector((state) => state.process.currentUserPermissions);

  const checkPermissions = (
    processPermission?: EUserDealPermissions,
    organizationPermission?: EUserOrganizationPermissions,
  ): boolean => {
    if (
      !process
      && permissions
      && permissions[EUserOrganizationPermissions.ORGANIZATION_TEMPLATE_EDIT]
    ) {
      return true;
    }
    if (
      process?.type === EProcessType.TEMPLATE
      && permissions
      && permissions[EUserOrganizationPermissions.ORGANIZATION_TEMPLATE_EDIT]) {
      return true;
    }
    if (
      !process
      || !currentUserPermissions
    ) {
      return false;
    }
    if (!processPermission && !organizationPermission) {
      return true;
    }
    return currentUserPermissions[processPermission]
      || (permissions && permissions[organizationPermission] && process.organizationId === organization?.id);
  };

  return [checkPermissions];
};

export default useDealPermissions;
