import { Box } from '@mui/material';
import { useEffect } from 'react';
import AgendaNodeName from './AgendaNodeName';
import { AgendaNodeAssignee } from './AgendaNodeAssignee';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/stateHooks';
import { selectIsOpenedInFeed, setSelectedFeedAgenda } from '../../../../DealFeed/lib/feedSlice';
import { useBlockContext } from '../../../TiptapBlockContextProvider';
import { useTaskPermissions } from '../../../../../hooks/useTaskPermissions';
import AgendaNodeInfo from './AgendaNodeInfo';
import useFrontEventsApi from '../../../../../hooks/useFrontEventsApi';
import { AgendaNodeAttrs } from '../../../../../../shared/Tiptap/TiptapTypes';
import { stagesetColors } from '../../../../../theme/stagesetPalette';
import { ETaskStatus } from '../../../../../../shared/TaskDTO';

const AgendaNodeComponent = () => {
  const dispatch = useAppDispatch();
  const { data, onUpdate } = useBlockContext<AgendaNodeAttrs>();
  const isOpenedInFeed = useAppSelector((state) => selectIsOpenedInFeed(state, data.blockId));

  const { createAssigneesUpdateEvent } = useFrontEventsApi();

  const { isUserAssignEnabled } = useTaskPermissions();

  const onAssigneesUpdate = (u: string[], t: string[]) => {
    onUpdate({ assigneesIds: u, assigneesTeams: t });
    createAssigneesUpdateEvent({
      from: data.assigneesIds,
      to: u,
      fromTeams: data.assigneesTeams,
      toTeams: t,
      taskId: data.blockId,
    });
  };

  useEffect(() => {
    if (isOpenedInFeed) {
      dispatch(setSelectedFeedAgenda(data));
    }
  }, [data, isOpenedInFeed]);

  return (
    <Box
      onClick={(e) => {
        e.stopPropagation();
        setTimeout(() => {
          dispatch(setSelectedFeedAgenda(data));
        });
      }}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        minHeight: '56px',
        borderRadius: '12px',
        border: '1px solid',
        borderColor: stagesetColors.newGrey[200],
        background: data.status === ETaskStatus.COMPLETE ? stagesetColors.white[100] : stagesetColors.newGrey[50],
        padding: '12px',
        cursor: 'pointer',
        '&:hover': {
          borderColor: stagesetColors.newGrey[400],
          boxShadow: '0px 3px 2px 0px rgba(30, 41, 59, 0.04),'
            + '0px 1px 1px 0px rgba(30, 41, 59, 0.07),'
            + '0px 0px 1px 0px rgba(30, 41, 59, 0.08),'
            + '0px 0px 0px 0px rgba(30, 41, 59, 0.08)',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <AgendaNodeName />
          <AgendaNodeInfo />
        </Box>
        <AgendaNodeAssignee
          showTeams
          size={30}
          marginTop={2}
          isEditable={isUserAssignEnabled}
          assigneesIds={data.assigneesIds}
          assigneesTeams={data.assigneesTeams}
          onAssigneesUpdate={onAssigneesUpdate}
        />
      </Box>
    </Box>
  );
};

export default AgendaNodeComponent;
