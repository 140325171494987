import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { ESubscriptionLimitStatus, ESubscriptionType } from '../../../shared/Subscription';
import PagePresentation from '../../common/PagePresentation/PagePresentation';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import BuyerDataGrid from './BuyerDataGrid';
import CloneTemplateDialog from './CloneTemplateDialog/CloneTemplateDialog';
import { StyledDealListPaper } from './DealPageStyles/DealsListStyle';
import DealLayoutWrapper from './DealsLayoutWrapper';
import DealsTabsHeader from './DealsTabsHeader';
import EditDealDialog from './EditDealDialog/EditDealDialog';
import ManagerDataGrid from './ManagerDataGrid';
import SellerDataGrid from './SellerDataGrid';
import { TemplatePicker } from '../../features/TemplatePicker/TemplatePicker';
import useDealsSlice from '../../hooks/useDealsSlice';
import { DealBoard } from '../../features/DealBoard/DealBoard';
import {
  closeEditDealDialog,
  selectEditDealDialogStatus,
  selectSelectedDealId,
  selectGridToRender,
} from './state/dealsSlice';
import { DealsType } from '../../../shared/process/Process';
import { ETemplatesTypes, resetSelectedTemplateData, setLibraryTab } from '../../pages/templates/lib/templatesSlice';
import { EAppRoutes } from '../../core/router';

export enum SellerView {
  GRID = 'GRID',
  KANBAN = 'KANBAN',
}

const Deals = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { subscription, isSeller } = useAppSelector((state) => state.billing);
  const { isDialogOpen, onDialogStatusChange } = useDealsSlice();
  const navigate = useNavigate();
  const [isPageAvailable, setIsPageAvailable] = useState<boolean>(false);
  const gridToRender = useAppSelector(selectGridToRender);
  const [sellerView, setSellerView] = useState<SellerView>(() => localStorage.getItem('sellerView') as SellerView || SellerView.GRID);
  const editDealDialogStatus = useAppSelector(selectEditDealDialogStatus);
  const selectedDealId = useAppSelector(selectSelectedDealId);

  const allowTeamManagement: boolean = subscription?.limits?.TEAM_CAN_MANAGE === ESubscriptionLimitStatus.ACTIVE;

  useEffect(() => {
    dispatch(setLibraryTab(ETemplatesTypes.TEMPLATES));
    dispatch(resetSelectedTemplateData());
    const joinedDealId = sessionStorage.getItem('signUpJoinedId');
    if (joinedDealId) {
      sessionStorage.removeItem('signUpJoinedId');
      navigate(`${EAppRoutes.deal}/${joinedDealId}`);
    }
    return () => {
      sessionStorage.removeItem('dealGridToRender');
    };
  }, []);

  useEffect(() => {
    setIsPageAvailable(subscription && (isSeller || subscription.type === ESubscriptionType.BUYER));
  }, [subscription]);

  const onCreateDealDialogOpen = () => {
    onDialogStatusChange(true);
  };

  const renderGrid = () => {
    switch (gridToRender) {
      case DealsType.SELLER_DEALS:
        if (sellerView === SellerView.GRID) {
          return (
            <SellerDataGrid
              onCreateDealDialogOpen={onCreateDealDialogOpen}
            />
          );
        }
        return (
          <DealBoard />
        );

      case DealsType.BUYER_DEALS:
        return (
          <BuyerDataGrid />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <DealLayoutWrapper
        className={isMobile ? 'mobile' : ''}
      >
        {isPageAvailable
          && (
            <Box
              className={isMobile ? 'mobile' : ''}
              sx={{
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
              }}
            >
              <DealsTabsHeader
                onCreateDealDialogOpen={onCreateDealDialogOpen}
                gridToRender={gridToRender}
                sellerView={sellerView}
                setSellerView={setSellerView}
                allowTeamManagement={allowTeamManagement}
              />
              <Box
                sx={{
                  display: 'flex',
                  height: '100%',
                  maxHeight: isMobile ? 'calc( 100% - 136px )' : 'calc( 100% - 160px )',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <StyledDealListPaper
                  id="deals-data-grids-container"
                  className={isMobile ? 'mobile' : ''}
                  sx={{
                    backgroundColor: 'transparent',
                    width: '100%',
                    height: 'calc( 100% )',
                    // overflow: 'overlay',
                    padding: !isMobile ? '0 24px 0 24px' : '',
                  }}
                >
                  {renderGrid()}
                </StyledDealListPaper>
              </Box>
              {/* <DealsMobileFooter
                onCreateDealDialogOpen={onCreateDealDialogOpen}
              /> */}
            </Box>
          )}
        {!isPageAvailable && (
          <PagePresentation
            title="Deals management"
            // eslint-disable-next-line
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum"
          />
        )}
      </DealLayoutWrapper>
      <TemplatePicker open={isDialogOpen} setOpen={onDialogStatusChange} />
      <EditDealDialog
        isOpen={editDealDialogStatus}
        processId={selectedDealId}
        onClose={() => dispatch(closeEditDealDialog())}
      />
      <CloneTemplateDialog />
    </>
  );
};

export default Deals;
