import { isMobile } from 'react-device-detect';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import {
  SettingsCardsDescription,
  SettingsCardsHeader,
  SettingsCardsHeadingWrapper,
  SettingsTabContentWrapper,
} from './Styles/GeneralTabStyles';
import PagePresentationNoPermissions from '../../../common/PagePresentation/PagePresentationNoPermissions';
import useUserOrganizationPermissions from '../../../hooks/useOrganizationPermissions';
import { useSubscriptionsNew } from '../../../hooks/useSubscriptionsNew';
import { SubscriptionBoldTitle, SubscriptionTabHeaderTextWrapper } from './Styles/SubscriptionTabStyles';
import SubscriptionTabIntervalSelect from './tabItems/SubscriptionTabIntervalSelect';
import { SubscriptionTabGrid } from './tabItems/SubscriptionTabGrid';
import CelloBannerNew from '../../../shared/CelloBanner/CelloBannerNew';
import { EUserOrganizationPermissions } from '../../../../shared/permissions';
import { AppSumoIcon33 } from '../../../icons/AppSumoIcon';
import {
  organizationGetSubscription, selectTourStatus, selectUser, updateUserData,
} from '../../../core/store/appState/appState';
import { useAppDispatch, useAppSelector } from '../../../hooks/stateHooks';
import {
  billingGetNextInvoice, getStagesetPlans, getStagesetPrices, setSubscriptionInterval,
} from '../../Billing/state/billingSlice';

const SubscriptionTab = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const {
    currentPlanObj,
    formatDate,
    formNextPaymentData,
    nextInvoice,
    isAppSumoSubscription,
    title,
  } = useSubscriptionsNew();
  const [checkOrganizationPermissions] = useUserOrganizationPermissions();
  const isPageAvailable: boolean = checkOrganizationPermissions(EUserOrganizationPermissions.ORGANIZATION_NAV_BILLING);
  const [sessionId, setSessionId] = useState<string | null>(null);
  const user = useAppSelector(selectUser);
  const shouldSeeBilling = useAppSelector((state) => selectTourStatus(state, 'shouldSeeBilling'));

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query.get('success')) {
      dispatch(organizationGetSubscription());
      setSessionId(query.get('session_id'));
    }
  }, [sessionId]);

  useEffect(() => {
    if (currentPlanObj) {
      dispatch(setSubscriptionInterval(currentPlanObj.interval));
    }
  }, [currentPlanObj]);

  useEffect(() => {
    dispatch(getStagesetPlans());
    dispatch(getStagesetPrices());
    dispatch(billingGetNextInvoice());
  }, []);

  useEffect(() => {
    if (shouldSeeBilling) {
      dispatch(updateUserData({
        ...user,
        tourStatus: {
          ...user.tourStatus,
          shouldSeeBilling: false,
        },
      }));
    }
  }, [shouldSeeBilling]);

  const formNextPayment = useMemo((): string => {
    if (nextInvoice) {
      if (!nextInvoice.endAt) {
        return `$${nextInvoice.total / 100} at ${formatDate(nextInvoice.nextPaymentMS)}`;
      }
      if (nextInvoice.endAt) {
        return `${formatDate(nextInvoice.endAt)}`;
      }
    }
    return '';
  }, [nextInvoice]);

  return (
    <SettingsTabContentWrapper
      className={classNames(
        { 'settings-content-wrapper-mobile': isMobile },
        { 'settings-content-wrapper-appsumo': (!isMobile && isAppSumoSubscription) },
      )}
    >
      <SettingsCardsHeadingWrapper
        className={classNames({ 'heading-with-logo': isAppSumoSubscription })}
      >
        <SubscriptionTabHeaderTextWrapper>
          <SettingsCardsHeader>
            {t('Subscriptions_Subscription')}
          </SettingsCardsHeader>
          {isPageAvailable && (
          <Box>
            <SettingsCardsDescription>
              {`${t('Subscription_current_plan')} `}
              <SubscriptionBoldTitle component="span">{title}</SubscriptionBoldTitle>
              {isAppSumoSubscription ? (
                <>
                  &nbsp;&nbsp;for&nbsp;
                  <SubscriptionBoldTitle component="span">lifetime</SubscriptionBoldTitle>
                </>
              ) : (
                <>
                  {`${formNextPaymentData()}`}
                  <SubscriptionBoldTitle component="span">{formNextPayment}</SubscriptionBoldTitle>
                </>
              )}
            </SettingsCardsDescription>
            {!!nextInvoice?.credit
                && !!nextInvoice?.total
                && !(nextInvoice.total > 0)
                && Math.abs(nextInvoice.credit) > nextInvoice?.total
                  && (
                  <SettingsCardsDescription>
                    Current balance:
                    <SubscriptionBoldTitle component="span">{` $${Math.abs(nextInvoice.credit) / 100}`}</SubscriptionBoldTitle>
                  </SettingsCardsDescription>
                  )}
          </Box>
          )}
        </SubscriptionTabHeaderTextWrapper>
        {isAppSumoSubscription && (
          <AppSumoIcon33 />
        )}
      </SettingsCardsHeadingWrapper>
      {!isPageAvailable && (
        <PagePresentationNoPermissions
          title="No Access"
          description="You do not have access to this page"
        />
      )}
      {isPageAvailable && (
      <>
        <CelloBannerNew />
        <SubscriptionTabIntervalSelect />
        <SubscriptionTabGrid />
      </>
      )}
    </SettingsTabContentWrapper>
  );
};

export default SubscriptionTab;
