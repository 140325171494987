import { isMobile } from 'react-device-detect';
import classNames from 'classnames';
import { Controller } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box, InputAdornment, Tooltip, Typography,
} from '@mui/material';
import {
  WarningAmber,
} from '@mui/icons-material';
import {
  SettingsCardsDescription,
  SettingsCardsHeader,
  SettingsCardsHeadingWrapper,
  SettingsFormWrapper,
  SettingsTabContentWrapper,
} from './Styles/GeneralTabStyles';
import { AppButton } from '../../../shared/AppButton/AppButton';
import { AppIconButton } from '../../../shared/AppButton/AppIconButton';
import TrashIcon from '../../../icons/TrashIcon';
import useUserOrganizationStatus from '../../../hooks/useSubscriptionStatus';
import { useOrganization } from '../../../hooks/useOrganization';
import { ECustomDomainEnableStatus } from '../../../../shared/CustomDomains';
import { AppInput } from '../../../shared/AppInput/AppInput';
import { AppSwitch } from '../../../shared/AppSwitch/AppSwitch';
import {
  ButtonWrapper,
  CustomDomainFieldWrapper,
  CustomDomainGeneralWrapper,
  DomainSwitchWrapper,
  StatusTabletWrapper,
  SubdomainInputWrapper,
  SubdomainLabel,
  TabletContentWrapper,
  TabletItem,
  TabletTitle,
  TabletTitleWrapper,
  SwitchTitle,
  TitleSwitchGroup,
} from './Styles/CustomDomainTabStyles';
import { UpdateIcon20 } from '../../../icons/UpdateIcon';
import { ErrorIcon20 } from '../../../icons/ErrorIcon';
import { SuccessIcon20 } from '../../../icons/SuccessIcon';
import { QuestionIcon20 } from '../../../icons/QuestionIcon';
import { useCustomDomainSettingsNew } from '../../../hooks/useCustomDomainSettingsNew';
import { stagesetColors } from '../../../theme/stagesetPalette';
import PremiumUserGuide from './tabItems/CustomDomainTab/PremiumUserGuide';
import { OrdinaryUserInitialHint, OrdinaryUserSuccessHint } from './tabItems/CustomDomainTab/OrdinaryUserHints';

const CustomDomainTab = (): JSX.Element => {
  const { organization } = useOrganization();
  const { hasCustomDomains, isPremium } = useUserOrganizationStatus();

  const {
    disabled,
    saving,
    deleteCustomDomain,
    getUrlFromOrigin,
    customDomainStatus,
    getData,
    submitCustomDomainForm,
    activateEnabled,
    activateLoading,
    handleActivateCustomDomain,
    handleCustomDomainDeactivationBySwitch,
    isActivated,
    isBasic,
    customDomainFormMethods,
    showDeleteButton,
    teamManager,
  } = useCustomDomainSettingsNew();

  const [domainFieldEnabledBySwitch, setDomainFieldEnabledBySwitch] = useState<boolean>(
    organization.customDomainEnabledStatus === ECustomDomainEnableStatus.ACTIVE,
  );
  const { t } = useTranslation();

  const handleSwitchChange = (check: boolean) => {
    if (!teamManager || !hasCustomDomains() || !organization?.domain) return;
    setDomainFieldEnabledBySwitch(check);
    handleCustomDomainDeactivationBySwitch();
  };

  useEffect(() => {
    if (!hasCustomDomains() || !organization.domain || !organization.customDomain) {
      setDomainFieldEnabledBySwitch(false);
    } else {
      setDomainFieldEnabledBySwitch(true);
    }
  }, [organization]);

  const {
    handleSubmit,
    control,
    setValue,
  } = customDomainFormMethods;

  const guide = () => {
    if (hasCustomDomains()) return <PremiumUserGuide />;
    if (!hasCustomDomains() && organization.domain) return <OrdinaryUserSuccessHint />;
    return <OrdinaryUserInitialHint />;
  };

  return (
    <SettingsTabContentWrapper
      className={classNames({ 'settings-content-wrapper-mobile': isMobile })}
    >
      <SettingsCardsHeadingWrapper>
        <SettingsCardsHeader>
          {t('Settings_Custom_Domain')}
        </SettingsCardsHeader>
        <SettingsCardsDescription>
          Setup which domain should be displayed for your company. This feature may require a special plan.
        </SettingsCardsDescription>
      </SettingsCardsHeadingWrapper>
      {(isPremium() || isBasic) && (
      <CustomDomainGeneralWrapper
        className={classNames({ 'custom-domain-general-wrapper-mobile': isMobile })}
      >
        <SettingsFormWrapper
          className={classNames('settings-custom-domain-wrapper', { 'settings-custom-domain-wrapper-mobile': isMobile })}
          component="form"
          onSubmit={handleSubmit(submitCustomDomainForm)}
        >
          {/* domain */}
          <SubdomainInputWrapper>
            <Controller
              name="domain"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <AppInput
                  className="settings-menu-input"
                  fullWidth
                  label="Subdomain"
                  value={value}
                  onChange={onChange}
                  disabled={domainFieldEnabledBySwitch || !teamManager}
                  helperText={error ? error.message : null}
                />
              )}
            />
            <SubdomainLabel>
              {getUrlFromOrigin()}
            </SubdomainLabel>
          </SubdomainInputWrapper>
          <DomainSwitchWrapper>
            <TitleSwitchGroup
              onClick={() => handleSwitchChange(!domainFieldEnabledBySwitch)}
            >
              <AppSwitch
                checked={domainFieldEnabledBySwitch}
                disabled={!hasCustomDomains() || !organization?.domain}
                onChange={(event) => handleSwitchChange(event.target.checked)}
              />
              <SwitchTitle>
                Use сustom domain
              </SwitchTitle>
            </TitleSwitchGroup>
            <Tooltip
              title="To activate"
              placement="bottom-start"
              componentsProps={{
                tooltip: {
                  sx: {
                    background: stagesetColors.newGrey[400],
                  },
                },
                arrow: {
                  sx: {
                    color: stagesetColors.newGrey[400],
                  },
                },
              }}
            >
              <QuestionIcon20 className="question-icon" />
            </Tooltip>
          </DomainSwitchWrapper>
          {/* Custom domain */}
          {
          (hasCustomDomains()
          && domainFieldEnabledBySwitch)
            && (
            <CustomDomainFieldWrapper
              className="form-fields-section"
            >
              <Controller
                name="customDomain"
                control={control}
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <AppInput
                    className="settings-menu-input"
                    fullWidth
                    label="Custom Domain"
                    value={value}
                    disabled={!organization.domain || !teamManager}
                    onChange={onChange}
                    helperText={error ? error.message : null}
                    InputProps={{
                      sx: {
                        paddingRight: '4px',
                      },
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{
                            padding: '0 !important',
                          }}
                        >
                          {showDeleteButton
                            && (
                            <AppIconButton
                              variant="danger-transparent"
                              icon={<TrashIcon />}
                              onClick={() => {
                                setValue('customDomain', '');
                                deleteCustomDomain();
                              }}
                              loading={saving}
                            />
                            )}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              {teamManager && (
              <AppButton
                className="upload-button"
                variant="primary"
                disabled={!activateEnabled || activateLoading}
                onClick={handleActivateCustomDomain}
              >
                {isActivated !== ECustomDomainEnableStatus.ACTIVE ? 'Activate' : 'Deactivate'}
              </AppButton>
              )}
            </CustomDomainFieldWrapper>
            )
          }
          {hasCustomDomains()
            && domainFieldEnabledBySwitch && (
            <StatusTabletWrapper>
              <TabletTitleWrapper>
                <TabletTitle>
                  Status
                </TabletTitle>
                <AppButton
                  size="xs"
                  variant="transparent"
                  startIcon={<UpdateIcon20 />}
                  onClick={getData}
                  disabled={!teamManager}
                >
                  Update
                </AppButton>
              </TabletTitleWrapper>
              <TabletContentWrapper>
                <TabletItem
                  className={classNames({ 'tablet-item-success': customDomainStatus.DNSEnabled })}
                >
                  {customDomainStatus.DNSEnabled
                    ? <SuccessIcon20 sx={{ marginLeft: '5px', color: 'green' }} />
                    : <ErrorIcon20 sx={{ marginLeft: '5px', color: 'orange' }} />}
                  {' '}
                  DNS
                </TabletItem>
                <TabletItem
                  className={classNames({ 'tablet-item-success': customDomainStatus.Resolved })}
                >
                  {customDomainStatus.Resolved
                    ? <SuccessIcon20 sx={{ marginLeft: '5px', color: 'green' }} />
                    : <ErrorIcon20 sx={{ marginLeft: '5px', color: 'orange' }} />}
                  {' '}
                  Resolved
                </TabletItem>
                <TabletItem
                  className={classNames({ 'tablet-item-success': customDomainStatus.SSLEnabled })}
                >
                  {customDomainStatus.SSLEnabled
                    ? <SuccessIcon20 sx={{ marginLeft: '5px', color: 'green' }} />
                    : <ErrorIcon20 sx={{ marginLeft: '5px', color: 'orange' }} />}
                  {' '}
                  SSL
                </TabletItem>
              </TabletContentWrapper>
            </StatusTabletWrapper>
          )}
          {customDomainStatus.multipleDNS && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              height: '76px',
              paddingTop: '24px',
              flexDirection: 'row',
              color: '#7084B4',
            }}
          >
            <WarningAmber sx={{ marginRight: '5px', color: 'orange' }} />
            <Typography className="description">
              Multiple DNS records:
              {' '}
              { customDomainStatus.multipleDNSArray }
            </Typography>
          </Box>
          )}
          {teamManager && (
            <ButtonWrapper>
              <AppButton
                type="submit"
                loading={saving}
                disabled={disabled}
                fullWidth
                variant="primary"
                size="l"
              >
                Save
              </AppButton>
            </ButtonWrapper>
          )}
        </SettingsFormWrapper>
        {guide()}
      </CustomDomainGeneralWrapper>
      )}
    </SettingsTabContentWrapper>
  );
};

export default CustomDomainTab;
