import {
  Box, Typography,
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import {
  ETemplatesTypes, selectLibraryTab, setLibraryTab,
} from './lib/templatesSlice';
import TemplatesFilter from './TemplatesFilter';
import CreateNewTemplateBtn from './CreateNewTemplateBtn';
import { AppTabs } from '../../shared/AppTabs/AppTabs';

const TemplatesTabsHeader = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const tab = useAppSelector(selectLibraryTab);

  const setTab = (newTab: ETemplatesTypes) => {
    dispatch(setLibraryTab(newTab));
  };

  const options = [
    { title: t('Templates_Rooms'), value: ETemplatesTypes.TEMPLATES },
    { title: t('Templates_Pages'), value: ETemplatesTypes.STAGES },
    { title: t('Templates_Sections'), value: ETemplatesTypes.SECTIONS },
  ];

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flex: 0,
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          height: '56px',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingX: isMobile ? '16px' : '24px',
          background: 'white',
          borderBottom: '1px solid #EEF0FA',
        }}
      >
        <Typography
          sx={{
            fontSize: '20px',
            lineHeight: '26px',
            fontWeight: 600,
            color: (theme) => theme.palette.primary.main,
          }}
        >
          {t('Templates_Templates')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            height: '100%',
            alignItems: 'center',
            '& button': {
              minWidth: '70px',
            },
          }}
        >
          <AppTabs
            variant="boxedNew-contrast"
            size="m-new"
            value={tab}
            onChange={(option) => {
              setTab(option.value);
            }}
            options={options}
          />
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          marginTop: isMobile ? '20px' : '24px',
          marginBottom: isMobile ? '20px' : '40px',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingX: isMobile ? '5px' : '24px',
          gap: '24px',
        }}
      >
        <TemplatesFilter />
        {
          !isMobile && (
            <CreateNewTemplateBtn />
          )
        }
      </Box>
    </Box>
  );
};

export default TemplatesTabsHeader;
