import {
  NodeViewContent, NodeViewProps, NodeViewWrapper, ReactNodeViewRendererOptions,
} from '@tiptap/react';
import { Box } from '@mui/material';
import classNames from 'classnames';
import { ElementType } from 'react';
import { isMobile } from 'react-device-detect';
import { ExtensionNames } from './Utils/TiptapHelpers';
import { TiptapBlockContextProvider } from './TiptapBlockContextProvider';
import TiptapNodeControls from './TiptapNodeControls';
import { useAppSelector } from '../../hooks/stateHooks';
import { selectLayouEditingStatus } from '../../routes-old/process/state/processSlice';
import { useCurrentStage } from '../../hooks/useCurrentStage';
import { StageContentType } from '../../../shared/Stage';
import TiptapNodeBookmarkListnerPlugin from './TiptapNodeBookmarkListner';

const TiptapNodeWrapper = (
  props: NodeViewProps & { _as:string, component?: JSX.Element, className: ExtensionNames | string, disableMenus?: boolean },
  options: Partial<ReactNodeViewRendererOptions>,
) => {
  const isLayoutEditable = useAppSelector(selectLayouEditingStatus);
  const { stageContentType } = useCurrentStage();
  const isCanvas = stageContentType !== StageContentType.WIDESCREENSTAGE;
  const {
    component,
    className,
    node,
    disableMenus,
    _as,
  } = props;

  if (
    !isLayoutEditable
    && node.type.name === 'media'
    && !node.attrs.src
    && !node.attrs.doc
  ) {
    return null;
  }

  return (
    <NodeViewWrapper
      className={className}
      id={node.attrs.blockId}
    >
      {
        node.type.name === 'heading'
        && node.attrs.level === 1
        && (
          <TiptapNodeBookmarkListnerPlugin
            id={node.attrs.blockId}
          />
        )
      }
      <TiptapBlockContextProvider
        nodeProps={props}
      >
        <Box
          className={classNames({
            'tiptap-node-wrapper-canvas': isCanvas,
            [_as]: _as,
          })}
          sx={{
            display: 'flex',
            '&:hover': {
              '& > .nodeControls': {
                '& .NodeDndHandle': {
                  visibility: 'visible',
                },
                '& .NodeMenuBtn': {
                  visibility: 'visible',
                },
              },
            },
          }}
        >
          {
            !disableMenus
            && (
              <TiptapNodeControls />
            )
          }
          {
            isCanvas
              ? component
            ?? (
              <NodeViewContent
                className={classNames(
                  'WrappedNode',
                  { 'WrappedNode-mobile': isMobile },
                  {
                    [`level-${node.attrs.level}`]: !!node.attrs.level,
                    'is-empty': !node.content.size,
                    [`text-align-${node.attrs.textAlign}`]: !!node.attrs.textAlign,
                  },
                )}
                as={
                  _as
                  || options?.contentDOMElementTag as ElementType<any>
                  || node?.type?.spec?.parseDOM?.[0].tag as ElementType<any>
                  || '' as ElementType<any>
                }
                data-placeholder={isLayoutEditable ? 'Enter `/` to add element' : undefined}
              />
            ) : component ?? null
          }
        </Box>
      </TiptapBlockContextProvider>
    </NodeViewWrapper>
  );
};

export default TiptapNodeWrapper;
