import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useSnackbar } from 'notistack';
import { Box } from '@mui/material';
import { Close } from '@mui/icons-material';
import { arrayMove } from '@dnd-kit/sortable';
import { isMobile } from 'react-device-detect';
import { JSONContent } from '@tiptap/core';
import { AppDropdownItemProps } from '../shared/AppDropdown/AppDropdownItem';
import ChevronRight from '../icons/StageMenu/ChevronRight.svg';
import ChevronLeft from '../icons/StageMenu/ChevronLeft.svg';
import Trash from '../icons/Trash.svg';
import { useAppDispatch, useAppSelector } from './stateHooks';
import {
  selectIsActiveStage,
  selectProcessFields,
  selectProcessId,
  setStage,
} from '../routes-old/process/state/processSlice';
import {
  useCreateTemplateFromStageMutation,
  useDeleteStageMutation,
  useGetStagesTemplatesByOrgIdQuery,
} from '../features/ProcessFields/lib/processFieldsApi';
import {
  EFieldType,
  EFieldsubType,
  ProcessStageDTO,
} from '../../shared/Field/Field';
import { addStageToProcess, updateProcessFields } from '../routes-old/process/state/dealActions';
import { ProcessField } from '../../shared/process/ProcessMilestoneActionDTO';
import Squar from '../icons/StageMenu/Squar.svg';
import { selectLibraryFilter } from '../pages/templates/lib/templatesSlice';
import { setStagesDrawerOpen } from '../features/Layout/lib/fieldsLayoutSlice';
import useProcessField from './useProcessField';
import { ContentType, StageContentType, WideScreenSectionType } from '../../shared/Stage';
import CompassIcon from '../icons/AddSectionIcons/Compass20.svg';
import MediaIcon from '../icons/AddSectionIcons/Media20.svg';
import CanvasIcon from '../icons/AddSectionIcons/Canvas20.svg';
import { selectOrganizationId } from '../core/store/appState/appState';
import { Analytics, EMixPanelEvents } from '../core/Analytics';
import { ChevronUp20 } from '../icons/ChevronUp';
import { ChevronDown20 } from '../icons/ChevronDown';
import { setCurrentStage } from '../features/ProcessFields/lib/processItemsSlice';
import { EMediaTypeTags, EMediaProvider } from '../features/MediaField/Helpers/MediaFieldHelpers';
import { FileItem } from '../../shared/FileItems/FileItems';
import { BookOpenIcon20 } from '../icons/BookOpenIcon';
import { PenIcon20 } from '../icons/PenIcon';
import { LockIcon20 } from '../icons/LockIcon';
import { PlusIcon20 } from '../icons/PlusIcon';
import useDealPermissions from './useDealPermissions';
import { EUserDealPermissions } from '../../shared/permissions';
import { AppSwitch } from '../shared/AppSwitch/AppSwitch';
import { StrikedEyeIcon20 } from '../icons/StrikedEyeIcon';
import { EyeIcon20 } from '../icons/EyeIcon';

const analytics: Analytics = Analytics.getInstance();

type CreateStagePayload = {
  title?: string;
  lock?: boolean;
  hide?: boolean;
  templateId?: string;
  position?: number;
  stageContentType?: StageContentType;
  wideScreenSectionType?: WideScreenSectionType;
  file?: File[];
  url?: string;
  subType?: EFieldsubType;
  contentType?: ContentType;
  source?: string;
  typeTag?: EMediaTypeTags;
  provider?: EMediaProvider;
  organisationId?: string;
  producerId?: string;
  items?: JSONContent,
  files?: Record<string, FileItem>;
  client?: string;
};

type WideStageOpts = {
  wideScreenSectionType: WideScreenSectionType
};

export type StageMenuLabels = 'default' | 'createNewStage' | 'selectTemplate' | 'slides';

// DO NOT add active stage selection here it will trigger unnecessary rerenders in all components with this hook
export const useStages = (id?: string) => {
  const dispatch = useAppDispatch();

  const processId = useAppSelector(selectProcessId);
  const stages = useAppSelector(selectProcessFields);
  const filter = useAppSelector(selectLibraryFilter);
  const organizationId = useAppSelector(selectOrganizationId);
  const isActiveStage = useAppSelector((state) => selectIsActiveStage(state, id));
  const [checkPermissions] = useDealPermissions();

  const { data: stage, onFieldUpdate } = useProcessField<ProcessStageDTO>({ id: id! });
  const { data: stageTemplates } = useGetStagesTemplatesByOrgIdQuery({ organizationId: organizationId!, filter });

  const [deleteStage] = useDeleteStageMutation();
  const [createStageTemplate] = useCreateTemplateFromStageMutation();

  const [activeTab, setActiveTab] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [lock, setLock] = useState(stage?.lock || false);
  const [hide, setHide] = useState(stage?.hide || false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [stageCreation, setStageCreation] = useState(false);
  const [collectDialog, setCollectDialog] = useState(false);
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);
  const [menuVariant, setMenuVariant] = useState<StageMenuLabels>(isMobile ? 'selectTemplate' : 'default');

  const textFieldRef = useRef<HTMLInputElement>(null);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { isLast, isFirst } = useMemo(() => {
    if (!id) return { isLast: false, isFirst: false };
    const isLastStage = stages[stages.length - 1]?.id === id;
    const isFirstStage = stages[0]?.id === id;
    return {
      isLast: isLastStage,
      isFirst: isFirstStage,
    };
  }, [stages]);

  const currentPosition = useMemo(() => {
    if (!stages || !stage) return undefined;
    const stageIndex = stages.findIndex((item) => item?.id === id);
    return stageIndex < 0 ? stages.length : stageIndex;
  }, [stages, stage]);

  const handleSave = (
    params: {
      title?: string,
      lock?: boolean,
      hide?: boolean
    },
    saveEnv?: 'lock' | 'hide',
  ) => {
    if (
      (lock && saveEnv === 'hide')
      || (hide && saveEnv === 'lock')
      || (!saveEnv && !params.title)
    ) {
      return;
    }
    if (saveEnv) {
      setHide(!!params.hide);
      setLock(!!params.lock);
    }
    onFieldUpdate(params);
  };

  useEffect(() => {
    if (!stage?.lock) setHide(stage?.hide || false);
    if (!stage?.hide) setLock(stage?.lock || false);
  }, [stage?.lock, stage?.hide]);

  const moveRight = () => {
    const index = stages.findIndex((item) => item?.id === stage?.id);
    if ([stages.length - 1, -1].includes(index)) return;
    const stagesForUpdate = [...stages];
    const next = stagesForUpdate[index + 1];
    const current = stagesForUpdate[index];
    stagesForUpdate[index] = next;
    stagesForUpdate[index + 1] = current;
    dispatch(
      updateProcessFields(
        stagesForUpdate.map((field, i) => ({ ...field, order: i })),
      ),
    );
  };

  const moveLeft = () => {
    const index = stages.findIndex((item) => item?.id === stage?.id);
    if ([0, -1].includes(index)) return;
    const stagesForUpdate = [...stages];
    const previous = stagesForUpdate[index - 1];
    const current = stagesForUpdate[index];
    stagesForUpdate[index] = previous;
    stagesForUpdate[index - 1] = current;
    dispatch(
      updateProcessFields(
        stagesForUpdate.map((field, i) => ({ ...field, order: i })),
      ),
    );
  };

  const makeSpaceBetweenStages = async (pos: number) => {
    const updatedStagesChunk = stages.slice(pos).map((field) => ({ ...field, order: field.order + 1 }));
    const previousStagesChunk = stages.slice(0, pos);
    await dispatch(
      updateProcessFields(
        previousStagesChunk.concat(updatedStagesChunk),
      ),
    ).unwrap();
    return pos;
  };

  const onCreateNewStage = async (payload: CreateStagePayload, activate?: boolean): Promise<{
    fields: ProcessField[];
    newField: string;
    newFieldData: ProcessStageDTO;
  }> => {
    setStageCreation(true);
    let position = stages.length;
    if (payload.position !== undefined && payload.position !== position) {
      position = await makeSpaceBetweenStages(payload.position);
    }
    const newStage = await dispatch(addStageToProcess({
      processId,
      position,
      title: `Page ${position + 1}`,
      ...payload,
    })).unwrap();
    setAnchor(null);
    setStageCreation(false);
    if (activate) {
      dispatch(setStage(newStage.newFieldData));
    }
    return newStage;
  };

  const onStageSelect = () => {
    if (lock && !checkPermissions(EUserDealPermissions.DEAL_LAYOUT_EDIT)) return;
    dispatch(setCurrentStage(stage.id));
  };

  const getNextPosition = (order?: number) => {
    if (typeof order === 'undefined') return stages?.length;
    return order;
  };

  const onCreateTemplate = async (name: string) => {
    try {
      const template = await createStageTemplate({
        id: id!,
        processId,
        title: name,
      }).unwrap();
      if (template) {
        const key = `create-template-snackbar-${Date.now()}`;
        enqueueSnackbar(
          'Template has been created',
          {
            key,
            variant: 'success',
            autoHideDuration: 1500,
            action: (
              <Box
                onClick={() => closeSnackbar(key)}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Close />
              </Box>
            ),
          },
        );
      }
    } catch (e) {
      console.log(e);
    }
    setAnchor(null);
    analytics.track(EMixPanelEvents.TEMPLATE_FROM_PAGE_CREATED);
  };

  const onStageDelete = async () => {
    if (isActiveStage) {
      const cStage = stages.find((item) => item.id === id);
      let pos;
      if (cStage) {
        pos = stages.indexOf(cStage);
      }
      if (typeof pos === 'number' && pos >= 0) {
        if (stages[pos + 1]) {
          dispatch(setCurrentStage(stages[pos + 1].id));
        } else {
          dispatch(setCurrentStage(stages[pos - 1].id));
        }
      }
    }
    const updatedStages: ProcessField[] = [...stages].filter((updatedStage) => updatedStage.id !== id);
    try {
      await dispatch(
        updateProcessFields(
          updatedStages.map((field, i) => ({ ...field, order: i })),
        ),
      );
      await deleteStage({ stageId: id!, processId });
    } catch (e) {
      console.log(e);
    }
  };

  const swapPositions = async (swapIndex1: string | number, swapIndex2: string | number) => {
    const stagesCopy = [...stages];
    const movingStageIndex1 = stagesCopy.findIndex((item) => item.id === swapIndex1);
    const movingStageIndex2 = stagesCopy.findIndex((item) => item.id === swapIndex2);
    const newStages = arrayMove(stagesCopy, movingStageIndex1, movingStageIndex2);
    dispatch(
      updateProcessFields(
        newStages.map((field, i) => ({ ...field, order: i })),
      ),
    ).catch(console.log);
    return newStages;
  };

  const onMediaSlideAdd = async (
    contentDisplayType: EFieldType,
    opts: WideStageOpts,
  ) => {
    try {
      await onCreateNewStage({
        stageContentType: StageContentType.WIDESCREENSTAGE,
        wideScreenSectionType: opts.wideScreenSectionType,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const defaultMenuItems: (AppDropdownItemProps & { showNext?: boolean })[] = useMemo(() => [
    {
      label: 'Rename',
      onClick: () => {
        setDisabled(false);
        setAnchor(null);
        setTimeout(() => {
          textFieldRef.current?.focus();
        });
      },
      startIcon: <PenIcon20 />,
    },
    {
      label: lock ? 'Unlock' : 'Lock',
      startIcon: <LockIcon20 />,
      disabled: hide,
      onClick: () => handleSave({ lock: !lock }, 'lock'),
      endIcon: <AppSwitch
        disabled={hide}
        checked={lock}
      />,
    },
    {
      label: hide ? 'Show' : 'Hide',
      startIcon: hide ? <EyeIcon20 /> : <StrikedEyeIcon20 />,
      disabled: lock,
      onClick: () => handleSave({ hide: !hide }, 'hide'),
      endIcon: <AppSwitch
        disabled={lock}
        checked={hide}
      />,
    },
    {
      label: 'Add page',
      onClick: () => setMenuVariant('createNewStage'),
      startIcon: <PlusIcon20 />,
      endIcon: <Box sx={{ position: 'absolute', right: '0px', display: 'flex' }}><ChevronRight /></Box>,
    },
    {
      label: 'Create template',
      onClick: () => {
        setCollectDialog(true);
        setAnchor(null);
      },
      startIcon: <BookOpenIcon20 />,
    },
    {
      label: '',
      divider: true,
    },
    {
      label: 'Delete',
      labelColor: 'error.main',
      startIcon: <Trash />,
      disabled: stages.length === 1,
      onClick: () => {
        setDeleteDialog(true);
        setAnchor(null);
      },
    },
  ], [stages, menuVariant, lock, hide]);

  const mobileOptionsMenuItems: (AppDropdownItemProps & { showNext?: boolean })[] = useMemo(() => [
    {
      label: 'Rename',
      onClick: () => {
        setTimeout(() => {
          setDisabled(false);
        });
        setAnchor(null);
      },
      startIcon: <PenIcon20 />,
    },
    {
      label: lock ? 'Unlock' : 'Lock',
      startIcon: <LockIcon20 />,
      disabled: hide,
      onClick: () => handleSave({ lock: !lock }, 'lock'),
      endIcon: <AppSwitch
        disabled={hide}
        checked={lock}
      />,
    },
    {
      label: hide ? 'Show' : 'Hide',
      startIcon: hide ? <EyeIcon20 /> : <StrikedEyeIcon20 />,
      disabled: lock,
      onClick: () => handleSave({ hide: !hide }, 'hide'),
      endIcon: <AppSwitch
        disabled={lock}
        checked={hide}
      />,
    },
    {
      label: 'Add page',
      onClick: () => setActiveTab(2),
      startIcon: <PlusIcon20 />,
      showNext: true,
      disabled: stages.length >= 7,
    },
    {
      label: 'Create template',
      onClick: () => {
        setCollectDialog(true);
        setAnchor(null);
        dispatch(setStagesDrawerOpen(false));
      },
      startIcon: <BookOpenIcon20 />,
    },
    {
      label: 'Move up',
      onClick: moveLeft,
      disabled: isFirst,
      startIcon: <ChevronUp20 />,
    },
    {
      label: 'Move down',
      onClick: moveRight,
      disabled: isLast,
      startIcon: <ChevronDown20 />,
    },
    {
      label: 'Delete',
      labelColor: 'error.main',
      startIcon: <Trash />,
      disabled: stages.length === 1,
      onClick: () => {
        setDeleteDialog(true);
        setAnchor(null);
      },
    },
  ], [stages, menuVariant, lock, hide]);

  const createNewStageMenuItems: AppDropdownItemProps[] = useMemo(() => [
    {
      key: 'Add page',
      label: 'Add page',
      onClick: () => setMenuVariant('default'),
      startIcon: <ChevronLeft />,
    },
    {
      label: 'From template',
      onClick: () => {
        setMenuVariant('selectTemplate');
      },
      startIcon: <Squar />,
    },
    {
      key: 'Blank',
      label: 'Canvas',
      onClick: () => {
        onCreateNewStage({
          position: getNextPosition(currentPosition),
        }, true);
      },
      startIcon: <CanvasIcon />,
    },
    {
      key: 'Media',
      label: 'Media',
      onClick: () => {
        onMediaSlideAdd(EFieldType.MEDIA, {
          wideScreenSectionType: WideScreenSectionType.MEDIA,
        });
      },
      startIcon: <MediaIcon />,
    },
    {
      key: 'Embed',
      label: 'Embed',
      onClick: () => {
        setMenuVariant('slides');
      },
      startIcon: <CompassIcon />,
    },
  ], [stages, menuVariant]);

  const templatesMenuItems: AppDropdownItemProps[] = useMemo(() => [
    {
      label: 'From Template',
      onClick: () => setMenuVariant('createNewStage'),
      startIcon: <ChevronLeft />,
    },
    ...(stageTemplates || []).map((template) => ({
      key: template.id,
      label: template.title!,
      onClick: () => {
        onCreateNewStage({ templateId: template.id, position: getNextPosition(currentPosition) }, true);
        analytics.track(EMixPanelEvents.ADD_PAGE_FROM_TEMPLATE_TEMPLATE_CHOSEN);
      },
    })),
  ], [stages, menuVariant]);

  return {
    onCreateNewStage,
    onMediaSlideAdd,
    anchor,
    setAnchor,
    menuVariant,
    createNewStageMenuItems,
    templatesMenuItems,
    setMenuVariant,
    stage,
    stageCreation,
    mobileOptionsMenuItems,
    collectDialog,
    setCollectDialog,
    onCreateTemplate,
    setActiveTab,
    activeTab,
    currentPosition,
    defaultMenuItems,
    onStageSelect,
    onStageDelete,
    deleteDialog,
    setDeleteDialog,
    textFieldRef,
    setDisabled,
    disabled,
    handleSave,
    isActiveStage,
    swapPositions,
  };
};
