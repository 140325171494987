import { Box, Typography } from '@mui/material';
import getSymbolFromCurrency from 'currency-symbol-map';
import currencyToSymbolMap from 'currency-symbol-map/map';
import { useState, useMemo } from 'react';
import { AppDropdown } from '../../../shared/AppDropdown/AppDropdown';
import { AppDropdownItemProps } from '../../../shared/AppDropdown/AppDropdownItem';

interface Props{
  currency: string;
  setCurrency: (_: string) => void;
  size: number;
}

const CurrencySelector = ({
  currency,
  setCurrency,
  size,
}: Props) => {
  const [anchor, setAnchor] = useState<HTMLElement | null>(null);

  const currencySelection: AppDropdownItemProps[] = useMemo(() => Object.entries(currencyToSymbolMap).map((item) => ({
    label: item[0],
    startIcon: item[1],
    onClick: () => { setCurrency(item[0]); setAnchor(null); },
  })), []);

  const openSelection = (e: React.MouseEvent<HTMLElement>) => {
    setAnchor(e.target as HTMLElement);
  };
  return (
    <>
      <Box
        onClick={openSelection}
        sx={{
          cursor: 'pointer',
          border: '1px solid #c8d0e0',
          width: `${size}px`,
          height: `${size}px`,
          display: 'flex',
          background: '#FFF',
          alignItems: 'center',
          borderRadius: '10px',
          justifyContent: 'center',
          userSelect: 'none',
          aspectRatio: '1',
        }}
      >
        <Typography
          fontSize={`${size / 2}px`}
          sx={{
            pointerEvents: 'none',
            userSelect: 'none',
          }}
        >
          {getSymbolFromCurrency(currency)}
        </Typography>
      </Box>
      <AppDropdown
        open={!!anchor}
        anchorEl={anchor}
        onClose={() => setAnchor(null)}
        items={currencySelection}
        searchType="default"
      />
    </>
  );
};

export default CurrencySelector;
