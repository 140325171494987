/* eslint-disable react/no-unused-prop-types */
import { isMobile } from 'react-device-detect';
import classNames from 'classnames';
import { useState } from 'react';
import { CircularProgress, Box } from '@mui/material';
import { ESubscriptionStatus, ESubscriptionType, appSumoSubscriptionTypes } from '../../../../../../shared/Subscription';
import DeleteTypography from '../../../../../common/DeleteTypography';
import NormalizedSvgIcon from '../../../../../shared/NormalizedSvgIcon/NormalizedSvgIcon';
import { AppButton } from '../../../../../shared/AppButton/AppButton';
import ConfirmationDialog from '../../../../../common/ConfirmationDialog/ConfirmationDialog';
import AlertTriangle from '../../../../../icons/AlertTriangle.svg';
import { useSubscriptionsNew } from '../../../../../hooks/useSubscriptionsNew';
import CrownGrey from '../../../../../icons/CrownGrey.svg';
import { StyledSubscriptionButton } from '../../../../../features/Subscriptions/SubscriptionsStyles';

type Props = {
  current?: boolean,
  currentIcon?: JSX.Element,
  currentTitle?: string | JSX.Element,
  icon?: JSX.Element,
  title?: string | JSX.Element,
  type?: ESubscriptionType,
  isDisabledPremium?: boolean,
  priceId?: string,
};

export const SubscriptionTabButton = ({
  title,
  current,
  currentIcon,
  currentTitle,
  icon,
  type,
  isDisabledPremium,
  priceId,
}: Props) => {
  const {
    onPremiumSubscriptionStart,
    isLoading,
    checkIfCurrentSubscriptionIsAppSumo,
    currentPlanObj,
    onSubscriptionPlanChange,
    onSubscriptionStatusUpdate,
    subscription,
  } = useSubscriptionsNew();

  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState<boolean>(false);

  const isCurrent = currentPlanObj && priceId && currentPlanObj.priceId === priceId;

  const isFree = type === ESubscriptionType.SELLER;

  const isAppsumo = type && appSumoSubscriptionTypes.includes(type);

  const isCanceled = subscription?.status === ESubscriptionStatus.CANCELED;

  if (
    (isAppsumo && !current)
    || (isFree && isCanceled)
    || ((type === ESubscriptionType.SELLER_PREMIUM) && checkIfCurrentSubscriptionIsAppSumo)
  ) {
    return (null);
  }

  const changeTypography: JSX.Element = (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '26px',
      }}
    >
      <DeleteTypography>
        Are you sure you want to update your subscription?
      </DeleteTypography>
      <DeleteTypography>
        Your payments will automatically adjust
      </DeleteTypography>
    </Box>
  );

  const cancelTypography: JSX.Element = (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '26px',
      }}
    >
      <DeleteTypography>
        Are you sure you want to cancel your subscription?
      </DeleteTypography>
      <DeleteTypography>
        Subscription will be automatically changed to Free plan at the end of the period
      </DeleteTypography>
    </Box>
  );

  const resumeTypography: JSX.Element = (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '26px',
      }}
    >
      <DeleteTypography>
        Are you sure you want to resume your subscription?
      </DeleteTypography>
    </Box>
  );

  const handleClick = () => {
    if (!currentPlanObj) {
      onPremiumSubscriptionStart(priceId);
    }
    if (
      (isCurrent === false)
      || (isCurrent === undefined)
      || (isCurrent && isCanceled)
      || isFree
    ) {
      setConfirmationDialogOpen(true);
    }
  };

  const onPlanChangeConfirm = () => {
    if (!isCurrent && priceId) {
      setConfirmationDialogOpen(false);
      onSubscriptionPlanChange(priceId);
    }
  };

  const onSubscriptionResume = () => {
    onSubscriptionStatusUpdate();
    setConfirmationDialogOpen(false);
  };

  const onCancelConfirm = () => {
    onSubscriptionStatusUpdate();
    setConfirmationDialogOpen(false);
  };

  if (isDisabledPremium) {
    return (
      <StyledSubscriptionButton
        startIcon={<NormalizedSvgIcon component={CrownGrey} />}
        disabled
        className={classNames('subscription-active', { mobile: isMobile })}
      >
        Coming soon
      </StyledSubscriptionButton>
    );
  }

  if (current && !isCanceled) {
    return (
      <AppButton
        startIcon={currentIcon}
        size="xl"
        disabled
        variant={type === ESubscriptionType.SELLER_PREMIUM ? 'transparent-black-icon' : 'secondary-black-icon'}
      >
        {currentTitle}
      </AppButton>
    );
  }

  return (
    <>
      <AppButton
        startIcon={icon}
        disableRipple
        fullWidth
        onClick={handleClick}
        size="xl"
        variant={isFree ? 'danger' : 'secondary-newGrey'}
      >
        {isLoading && (
          <CircularProgress sx={{
            maxHeight: '20px',
            maxWidth: '20px',
            height: '20px',
            width: '20px',
            color: 'white',
          }}
          />
        )}
        {
          !isLoading && (
            isCurrent ? 'Resume' : `${title}`
          )
        }
      </AppButton>
      {
        isFree
        && (
          <ConfirmationDialog
            isDialogOpen={isConfirmationDialogOpen}
            onCancel={() => setConfirmationDialogOpen(false)}
            onConfirm={onCancelConfirm}
            dialogContentText={cancelTypography}
            dialogTitle="Cancel subscription"
            titleVariant="danger-primary"
            confirmText="Confirm"
            icon={<NormalizedSvgIcon component={AlertTriangle} />}
          />
        )
      }
      {
        !isCurrent
        && !isFree
        && (
          <ConfirmationDialog
            titleVariant="primary"
            isDialogOpen={isConfirmationDialogOpen}
            onCancel={() => setConfirmationDialogOpen(false)}
            onConfirm={onPlanChangeConfirm}
            dialogContentText={changeTypography}
            dialogTitle="Update Stageset subscription"
          />
        )
      }
      {
        isCurrent
        && !isFree
        && isCanceled
        && (
          <ConfirmationDialog
            titleVariant="primary"
            isDialogOpen={isConfirmationDialogOpen}
            onCancel={() => setConfirmationDialogOpen(false)}
            onConfirm={onSubscriptionResume}
            dialogContentText={resumeTypography}
            dialogTitle="Resume Stageset subscription"
          />
        )
      }
    </>
  );
};
