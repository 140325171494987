import styled from '@emotion/styled';
import { Box, Grid } from '@mui/material';

export const OrganizationUsersContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '100vw',
  height: '100%',
  overflow: 'overlay',
  overflowX: 'hidden',
  padding: '24px 32px 0px',
  '&::-webkit-scrollbar': {
    width: '7px',
    height: '7px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '3px',
    backgroundColor: '#c7c7c7',
    opacity: '50%',
    visibility: 'hidden',
    '&:hover': {
      visibility: 'visible',
    },
  },
  '& .UpperSectionContainer': {
    display: 'flex',
    margin: '0',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    flexBasis: '250px',
    alignContent: 'center',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    padding: 'calc( 1% + 4px ) 18% 1% 18%',
    maxWidth: '100%',
  },
}));

export const InfoPaperContainer = styled(Grid)(() => ({
  height: '200px',
  maxWidth: '100%',
  padding: '0 !important',
  marginBottom: '10px',
  '& .InfoPaper': {
    boxShadow: '0px 8px 10px rgba(0, 0, 0, 0.06)',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    height: '100%',
    padding: '0',
    borderRadius: '5px',
    '& .InfoWrapper': {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignContent: 'space-between',
      justifyContent: 'space-around',
      '& .InfoContainer': {
        height: '100%',
        width: '100%',
        '& .InfoTitle': {
          marginBottom: '20px',
        },
        '& .InfoContent': {
          width: '80%',
          marginTop: '14px',
          marginLeft: '34px',
        },
      },
      '& .LogoContainer': {
        border: '0px solid red',
        aspectRatio: '1',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        marginRight: '34px',
      },
    },
  },
}));

interface Props {
  colorId?: number;
}

export const InvitePaperContainer = styled(Grid)(() => ({
  marginBottom: '10px',
  height: '50px',
  width: '100%',
  maxWidth: '100%',
  padding: '0 !important',
  '& .InvitePaper': {
    width: '100%',
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'flex-start',
    alignContent: 'center',
    alignItems: 'center',
    minHeight: '60px',
    padding: '10px 25px',
    borderRadius: '5px',
    boxShadow: '0px 8px 10px rgba(0, 0, 0, 0.06)',
    '& .OwnerInfoItem': {
      display: 'flex',
      margin: '5px 50px 5px 0',
      '& .OwnerAvatarIcon': {
        display: 'flex',
        borderRadius: '50%',
        height: '40px',
        width: '40px',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        marginRight: '10px',
      },
      '& .Icon': {
        display: 'flex',
        borderRadius: '50%',
        height: '40px',
        width: '40px',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        marginRight: '10px',
        color: 'white',
        background: '#7E7E7E',
      },
      '& .Info': {
        padding: '0',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        '& .InfoDescriptor': {
          color: '#7E7E7E',
        },
      },
    },
    '& .InviteButtonContainer': {
      justifyContent: 'flex-end',
      '& .InviteUser': {
        color: 'black',
        height: '100%',
        fontSize: '14px',
        '& .InviteIcon': {
          marginLeft: '20px',
          width: '40px',
          height: '40px',
          padding: '7px',
          color: 'white',
          background: '#62B839',
          borderRadius: '50%',
        },
      },
    },
  },
}));
