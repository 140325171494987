import styled from '@emotion/styled';
import Paper from '@mui/material/Paper';
import { DataGrid } from '@mui/x-data-grid';

// lListPaper
export const FileManagementPaper = styled(Paper)(() => ({
  display: 'flex',
  flex: 1,
  width: '100%',
  height: '100%',
  margin: '5vh 0 0 0',
  border: 'none',
  borderRadius: '0',
  alignSelf: 'center',
  padding: '0',
  background: '#F9F9F9',
  boxShadow: 'none',
  '& .MuiDataGrid-root, .MuiDataGrid-root, .css-1yp7hw7-FileManagementDataGrid, .eec3bos0': {
    border: 'none',
  },
}));

// DataGrid/Header+rows
export const FileManagementDataGrid = styled(DataGrid)(() => ({
  width: '100%',
  border: 'none',
  borderRadius: '0 !important',
  '& .MuiDataGrid-main': {
    width: '100%',
    height: '55.9vh',
    alignSelf: 'center',
  },
  // header
  '& .MuiDataGrid-root, .MuiDataGrid-columnHeaderWrapper': {
    background: '#F9F9F9',
    '& .MuiDataGrid-root, .MuiDataGrid-columnSeparator': {
      visibility: 'hidden',
    },
    '&.MuiDataGrid-root, .MuiDataGrid-columnHeader--sortable, .MuiDataGrid-columnHeader': {
      padding: '0 5px',
      outline: 'none !important',
    },
    '& MuiButtonBase-root, .MuiIconButton-root, .MuiIconButton-sizeSmall, .css-15h2yz5-MuiButtonBase-root-MuiIconButton-root': {
      visibility: 'visible',
      '&:active': {
        background: 'transparent !important',
      },
      '&:hover': {
        background: 'transparent !important',
      },
      '&:focus': {
        background: 'transparent !important',
      },
    },
  },
  // header column aligment
  '& .MuiDataGrid-root, .MuiDataGrid-columnHeader--alignCenter, .MuiDataGrid-columnHeaderTitleContainer': {
    justifyContent: 'flex-start',
  },
  // scrollbar
  '& .MuiDataGrid-root, .MuiDataGrid-window': {
    '&::-webkit-scrollbar': {
      overflow: 'overlay',
      width: '7px',
      height: '7px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '3px',
      backgroundColor: 'rgba(128, 128, 128, .5)',
      opacity: '50%',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#7E7E7E',
    },
  },
  // rows
  '& .MuiDataGrid-renderingZone': {
    width: '100%',
    // row
    '& .MuiDataGrid-row': {
      background: '#F9F9F9',
      borderBottom: '1px solid #E5E5E5',
      width: '100%',
      height: '100%',
      color: '#7E7E7E',
      borderRight: '1px solid #E7E7E7',
      alignItems: 'center',
      '&:hover': {
        color: 'black',
        background: 'white',
        transition: '0.3s',
        '& .StyledMoreMenuButtonContainer': {
          '&:active': {
            background: 'transparent !important',
          },
          '&:hover': {
            background: 'transparent !important',
          },
          '&:focus': {
            background: 'transparent !important',
          },
          '& .StyledMoreMenuButton': {
            color: 'black',
            visibility: 'visible',
            backgroundColor: '#F9F9F9',
            background: 'white',
          },
        },
      },
      // cells
      '& .MuiDataGrid-cell, .pro-grid-cell, .MuiDataGrid-cell--textLeft': {
        padding: '0 11px',
        border: 'none',
        height: '100%',
        outline: 'none !important',
      },
    },
  },
}));
