import { Box, Typography } from '@mui/material';
import { useAppSelector } from '../../hooks/stateHooks';
import { DealLayout } from '../../routes-old/process/DealLayout';
import { selectLibraryTemplateData } from '../../pages/templates/lib/templatesSlice';
import StagesSidebarButton from '../../shared/StagesSidebarButton/StagesSidebarButton';

const Template = (): JSX.Element => {
  const selectedTemplateData = useAppSelector(selectLibraryTemplateData);

  return (
    <>
      <Box
        sx={{
          height: '56px',
          minHeight: '56px',
          background: '#fff',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: '16px',
          paddingX: '24px',
          borderBottom: '1px solid #EEF0FA',
        }}
        className="template-header"
      >
        <StagesSidebarButton />
        <Box sx={{
          width: '100%',
        }}
        >
          <Typography sx={{
            fontSize: '20px',
            fontWeight: 500,
            fontFamily: 'Inter',
            color: (theme) => theme.palette.grey[500],
            maxWidth: 'calc(90%)',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
          >
            {selectedTemplateData?.title}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          height: 'calc( 100% - 56px )',
        }}
      >
        <DealLayout
          toFetchId={selectedTemplateData?.id ?? ''}
        />
      </Box>
    </>
  );
};

export default Template;
