import { Box } from '@mui/material';
import { useEffect, useRef } from 'react';
import { isMobile } from 'react-device-detect';
import useProcessBackground from '../../hooks/useProcessBackground';
import { useAppSelector } from '../../hooks/stateHooks';
import { ESidebarTabs, selectSidebarTab } from '../Layout/lib/fieldsLayoutSlice';
import { EProcessBackgroundType } from '../../../shared/process/ProcessMilestoneActionDTO';

const SolidColorBackground = () => {
  const sidebarTab = useAppSelector(selectSidebarTab);
  const {
    backgroundColor,
    backgroundType,
  } = useProcessBackground();

  const displayRef = useRef<HTMLDivElement>(null);

  function colorChangeHandler(e: Event & { detail: any }) {
    if (displayRef.current) {
      displayRef.current.style.background = e.detail.color;
    }
  }

  useEffect(() => {
    if (sidebarTab === ESidebarTabs.CUSTOMIZATION && backgroundType === EProcessBackgroundType.COLOR) {
      window.addEventListener('background-single-color-change', colorChangeHandler as EventListener);
    }
    return () => {
      window.removeEventListener('background-single-color-change', colorChangeHandler as EventListener);
    };
  }, [sidebarTab, backgroundType, displayRef]);

  useEffect(() => {
    if (displayRef?.current?.style?.background && backgroundColor) {
      displayRef.current.style.background = backgroundColor;
    }
  }, [backgroundColor, displayRef]);

  return (
    <Box
      ref={displayRef}
      sx={{
        minHeight: isMobile ? '120px' : '250px',
        width: '100%',
        objectFit: 'fill',
        maxHeight: isMobile ? '120px' : '250px',
        background: backgroundColor ?? '#FFF',
      }}
    />
  );
};

export default SolidColorBackground;
