import jwtDecode from 'jwt-decode';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Params, useNavigate, useParams } from 'react-router-dom';
import { ThemeProvider } from '@mui/system';
import classNames from 'classnames';
import { EUserOrganizationPermissions } from '../../../shared/permissions';
import { EProcessType, ProcessDTO } from '../../../shared/process/ProcessMilestoneActionDTO';
import { EActivationLinkType } from '../../../shared/schema/auth.shcema';
import { EJWTTokenType, IShareJWTTokenPayload } from '../../../shared/schema/token.schema';
import { EStateStatus } from '../../core/commonTypes';
import { EAppRoutes } from '../../core/router';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import useDealWebsocket from '../../hooks/useDealWebsocket';
import useUserOrganizationPermissions from '../../hooks/useOrganizationPermissions';
import {
  logout,
  selectIsSignupDialogOpen,
  selectIsToolbarNotShown,
  setIsShareProtectedAuthDialogOpen,
  setIsSignupDialogOpen,
} from '../auth/state/authState';
import DealError from './DealError';
import DealFeed from './DealFeed/DealFeed';
import { getTabForOverview } from './helpers';
import ProcessLayoutWrapper from './ProcessLayoutWrapper';
import ProtectedSignupDialog from './SignupDialog/ProtectedSignupDialog';
import ShareSignUpDialog from './SignupDialog/ShareSignUpDialog';
import SignupDialog from './SignupDialog/SignupDialog';
import { getProcess } from './state/dealActions';
import { clearNotificationsState } from './state/dealNotificationsSlice';
import {
  clearProcessState, selectProcessSliceStatus, setIsLayoutEditActive, setStage,
} from './state/processSlice';
import { clearTasksState } from './state/tasksSlice';
import { useActionOnFieldsUpdate } from '../../hooks/APIlistners/useListenForFieldsUpdates';
import { setTemplateEditMode, updateTemplate } from '../../pages/templates/lib/templatesSlice';
import { ELocalStorageItems } from '../../../shared/LocalStorageItems/LocalStorageItems';
import ProcessTabTitlePlugin from './ProcessTabTitleOlugin';
import StagesSidebar from '../../features/Stages/StagesSidebar';
import StagesDrawer from '../../features/Stages/StagesDrawer';
import { EComponentsIds } from '../../../shared/ComponentsIdsAndClassNames/ComponentsIdsAndClassNames';
import {
  EMenuTabs, resetFieldsLayoutSlice, setCurrentMenuTab, setStagesDrawerOpen,
} from '../../features/Layout/lib/fieldsLayoutSlice';
import ProcessStageSetterPlugin from './ProcessStageSetterPlugin';
import { resetBackgrounds } from '../../features/DealCustomizationMenu/lib/dealCustomizationSlice';
import { useGetProcessFieldsQuery } from '../../features/ProcessFields/lib/processFieldsApi';
import ProcessFieldsControllerPlugin from '../../features/ProcessFieldsControllerPlugin/ProcessFieldsControllerPlugin';
import { useDealTheme } from '../../hooks/useDealTheme';
import EmbedSectionDialog from '../../features/BlockContentSection/BlockEmbedSectionDialog/BlockEmbedSectionDialog';
import { resetLogsState } from '../../features/ActivityLog/lib/processActivityLogSlice';
import PolicyDisclaimerDialog from '../../features/PolicyDisclaimer/PolicyDisclaimerDialog';
import DealContentTabs from './DealContentTabs';
import DealIntercomPlugin from './DealIntercomPlugin';
import DealBlurPlugin from './DealBlurPlugin';
import RoomToolbar from '../../features/Stages/RoomToolbar';
import { MobileBottomDrawer } from '../../features/MobileSwipeableDrawer/MobileBottomDrawer';
import { DealLayoutContainer, DealLayoutMainWrapper } from './Styles/DealLayoutStyles';
import { getProcessItems, resetItemsSlice } from '../../features/ProcessFields/lib/processItemsSlice';

export const DealLayout = ({ toFetchId }: { toFetchId?: string | null }): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id: paramId }: Readonly<Params> = useParams<string>();
  const [orgPermissions] = useUserOrganizationPermissions();

  const id = toFetchId ?? paramId;

  const status: EStateStatus = useAppSelector(selectProcessSliceStatus);
  const process: ProcessDTO = useAppSelector((state) => state.process.process);
  const userProcessRoleName = useAppSelector((state) => state.process.userProcessRoleName);
  const tokenType: EJWTTokenType = useAppSelector((state) => state.auth.tokenType);
  const authToken: string = useAppSelector((state) => state.auth.authToken);
  const isShareSingUpOpen: boolean = useAppSelector((state) => state.auth.isShareSingUpOpen);
  const isShareProtectedAuthDialogOpen: boolean = useAppSelector((state) => state.auth.isShareProtectedAuthDialogOpen);
  const processType: EProcessType = useAppSelector((state) => state.process?.process?.type);
  const [shareAuthError, setShareAuthError] = useState<string>();
  const [isError, setError] = useState<boolean>(false);
  const isSignupDialogOpen = useAppSelector(selectIsSignupDialogOpen);
  const [isWrapperReady, setWrapperReady] = useState(false);
  const isToolbarNotShown = useAppSelector(selectIsToolbarNotShown);
  const dealLayoutToolbarLayout = !isToolbarNotShown && !toFetchId && isWrapperReady;

  const {
    refetch,
    isLoading,
    isSuccess,
  } = useGetProcessFieldsQuery({
    pointerId: id,
    pointerType: 'process',
  }, { skip: !id || id === 'share' });

  useEffect(() => {
    if (id && !isLoading && isSuccess) {
      refetch();
    }
    return () => {
      dispatch(clearProcessState());
      dispatch(clearTasksState());
      dispatch(clearNotificationsState());
      dispatch(resetItemsSlice());
      dispatch(setStagesDrawerOpen(false));
      dispatch(resetFieldsLayoutSlice());
      dispatch(resetBackgrounds());
      dispatch(resetLogsState());
    };
  }, [id]);

  useEffect(() => {
    dispatch(resetItemsSlice());
    return () => {
      dispatch(setTemplateEditMode(false));
    };
  }, []);

  useEffect(() => {
    if (
      processType === EProcessType.TEMPLATE
      && !toFetchId
    ) {
      dispatch(setCurrentMenuTab(EMenuTabs.TEMPLATES));
      dispatch(setTemplateEditMode(true));
      dispatch(setIsLayoutEditActive(true));
    }
    if (processType === EProcessType.DEAL) {
      dispatch(setCurrentMenuTab(EMenuTabs.ROOMS));
    }
    if (process) {
      getTabForOverview(userProcessRoleName, orgPermissions(EUserOrganizationPermissions.ORGANIZATION_DEALS_MANAGE));
    }
  }, [process]);

  const {
    processId,
    email,
    accountLinkHash,
    isEmailProtected,
    isPasswordProtected,
  }: IShareJWTTokenPayload = jwtDecode<IShareJWTTokenPayload>(authToken);

  const getData = async () => {
    if (
      id === 'share'
      && authToken
      && (tokenType === EJWTTokenType.SHARE || tokenType === EJWTTokenType.PROCESS_AUTH)
      && !process
      && status !== EStateStatus.LOADING
    ) {
      if (processId) {
        navigate(`${EAppRoutes.deal}/${processId}`);
      }
    }
    if (
      id !== 'share'
      && (process?.id !== id || toFetchId)
      && status !== EStateStatus.LOADING
      && id
    ) {
      await dispatch(getProcess(id))
        .unwrap()
        .catch((e: Error) => {
          if (tokenType === EJWTTokenType.SHARE) {
            setShareAuthError(e.message);
          }
          setError(true);
        });
      await dispatch(getProcessItems(id));
    }

    if (id && tokenType === EJWTTokenType.SHARE && id !== 'share') {
      if (
        (isEmailProtected)
        && !accountLinkHash
      ) {
        dispatch(setIsShareProtectedAuthDialogOpen(true));
      }
      if (email && accountLinkHash && processId) {
        dispatch(setIsSignupDialogOpen(true));
        navigate(`${EAppRoutes.deal}/${processId}?email=${email}&hash=${accountLinkHash}&invite-type=${EActivationLinkType.PROCESS}`);
      }
    }
    if (localStorage.getItem(ELocalStorageItems.ASK_FOR_ADDITIONAL_USER_DATA)) {
      dispatch(setIsSignupDialogOpen(true));
    }
  };

  useEffect(() => {
    // IMPORTANT!: this won't work without timeout, persistor is async and we are waiting for it to hydrate an auth state to local storage
    if (shareAuthError === 'Unauthorized' || shareAuthError === 'Forbidden resource') {
      setTimeout(() => dispatch(logout()), 2000);
    }
  }, [shareAuthError]);

  useEffect(() => {
    getData();
  }, [id, toFetchId]);

  if (!toFetchId && id) {
    useDealWebsocket(id);
  }

  useActionOnFieldsUpdate({
    action: () => dispatch(updateTemplate({
      id: id!,
      updatedAt: Date.now(),
    })),
    prevent: !(process && processType === EProcessType.TEMPLATE),
    checkAgainst: process,
  });

  const { dealTheme } = useDealTheme();

  if (isError) {
    return (
      <DealError />
    );
  }

  if (!process) {
    return null;
  }

  return (
    <>
      <DealIntercomPlugin />
      <DealBlurPlugin />
      <ProcessFieldsControllerPlugin />
      <ProcessTabTitlePlugin />
      <ProcessStageSetterPlugin />
      <DealLayoutMainWrapper>
        {dealLayoutToolbarLayout && <RoomToolbar />}
        <DealLayoutContainer
          id={EComponentsIds.PROCESS_LAYOUT_CONTANER}
          className={classNames({ 'deal-layout-with-toolbar': dealLayoutToolbarLayout })}
        >
          <ThemeProvider theme={dealTheme}>
            {
            !isMobile
              && !toFetchId
              && (
              <StagesSidebar />
              )
          }
            {
              (toFetchId || isMobile)
            && (
              <>
                <MobileBottomDrawer />
                <StagesDrawer />
              </>
            )
          }
          </ThemeProvider>
          <ProcessLayoutWrapper
            toFetchId={toFetchId}
            onWrapperReady={setWrapperReady}
          >
            <DealContentTabs />
          </ProcessLayoutWrapper>
        </DealLayoutContainer>
        <DealFeed />
      </DealLayoutMainWrapper>
      <DealFeed />
      <SignupDialog open={isSignupDialogOpen} />
      <ShareSignUpDialog open={isShareSingUpOpen} />
      <ProtectedSignupDialog open={isShareProtectedAuthDialogOpen} />
      <PolicyDisclaimerDialog />
      {/* <ProcessActivityLogDialog /> */}
      <EmbedSectionDialog />
    </>
  );
};
