import EditableHeaderTitle from '../../shared/EditableTitleWithBackground/EditableHeaderTitle';
import { useAppSelector } from '../../hooks/stateHooks';
import { DealLayout } from '../../routes-old/process/DealLayout';
import { selectLibraryTemplateData } from '../../pages/templates/lib/templatesSlice';
import { AppButton } from '../../shared/AppButton/AppButton';

export const MobileTemplate = (): JSX.Element => {
  const selectedTemplateData = useAppSelector(selectLibraryTemplateData);

  return (
    <DealLayout
      toFetchId={selectedTemplateData?.id ?? ''}
    />
  );
};
