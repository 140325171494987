import { DropzoneOptions } from 'react-dropzone';
import { FilesDragAndDrop } from '../../common/FilesDragAndDrop/FilesDragAndDrop';

export interface IFilesDragAndDropProps {
  onFileDrop: (files: File[]) => void;
  dropzoneOptions?: DropzoneOptions;
  text?: string;
  icon?: JSX.Element;
}

const styles = {
  fontSize: '8px',
  textAlign: 'center',
  backgroundColor: '#efefef',
};

export const FileManagementDragAndDrop = ({
  onFileDrop, text, icon, dropzoneOptions,
}: IFilesDragAndDropProps): JSX.Element => (
  <FilesDragAndDrop
    icon={icon}
    text={text}
    onFileDrop={onFileDrop}
    dropzoneOptions={dropzoneOptions}
    // @ts-ignore
    styles={styles}
  />
);
