import {
  EFieldType, ProcessStageDTO,
} from '../../shared/Field/Field';
import useProcessField from './useProcessField';
import { useAppSelector } from './stateHooks';
import { selectLibraryTemplateData } from '../pages/templates/lib/templatesSlice';
import { selectCurrentStage } from '../features/ProcessFields/lib/processItemsSlice';

export const useCurrentStage = () => {
  const templateData = useAppSelector(selectLibraryTemplateData);
  const currentStage = useAppSelector(selectCurrentStage);
  const stageId = () => {
    if (currentStage) return currentStage.id;
    if (templateData?.type === EFieldType.STAGE && templateData.id) {
      return templateData.id;
    }
    if (templateData?.type === EFieldType.SECTION && templateData.id) {
      return templateData.id;
    }
    return '';
  };
  const { data } = useProcessField({ id: stageId() });

  return data as ProcessStageDTO;
};
