import { Box, BoxProps } from '@mui/material';
import Loader from '../../icons/Loader.png';

type Props = {
  size: number;
} & BoxProps;

export const AppLoader = ({ size = 20, ...boxProps }: Props) => (
  <Box
    height={size}
    width={size}
    sx={{
      '@keyframes round': {
        '0%': { rotate: '0deg' },
        '100%': { rotate: '360deg' },
      },
      animation: 'round 2s linear infinite',
    }}
    {...boxProps}
  >
    <img width={size} height={size} src={Loader} alt="loader" />
  </Box>
);
