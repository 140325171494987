import {
  createSlice, PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from '../../../core/store/store';
import { AgendaNodeAttrs } from '../../../../shared/Tiptap/TiptapTypes';

export enum EFeedTab {
  USERS = 'USERS',
  PREVIEW = 'PREVIEW',
  PROCESS = 'PROCESS',
  STAGES = 'STAGES',
  AGENDA = 'AGENDA',
  PRICING_TABLE = 'PRICING_TABLE',
  NONE = 'NONE',
  INTERNAL = 'INTERNAL',
  ROOM_INFO = 'ROOM_INFO',
}

const feedSliceReducerName: string = 'feed';
const storageName: string = 'isFeedPinned';

const initialState = {
  isOpen: false,
  selectedTab: EFeedTab.NONE,
  isPinned: false,
  preventClosure: false,
  selectedAgenda: {},
  feedTitle: '',
  isInternalFilters: false,
};

export const feedSlice = createSlice({
  name: feedSliceReducerName,
  initialState,
  reducers: {
    setFeedTab: (state, { payload }: PayloadAction<EFeedTab>) => ({
      ...state,
      selectedTab: payload,
      selectedAgenda: EFeedTab.NONE ? '' : state.selectedAgenda,
    }),
    setFeedPinned: (state, { payload }: PayloadAction<boolean>) => {
      if (payload) {
        localStorage.setItem(storageName, JSON.stringify(payload));
      }
      if (!payload) {
        localStorage.removeItem(storageName);
      }
      return ({
        ...state,
        isPinned: payload,
      });
    },
    preventFeedClosure: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      preventClosure: payload,
    }),
    setFeedTitle: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      feedTitle: payload,
    }),
    setSelectedFeedAgenda: (state, { payload }: PayloadAction<{ [key: string]: any }>) => ({
      ...state,
      isOpen: !!payload,
      feedTitle: payload.name,
      selectedTab: payload ? EFeedTab.AGENDA : state.selectedTab,
      selectedAgenda: { ...payload },
    }),
    setIsInternalFilters: (state, { payload }: PayloadAction<boolean | undefined>) => ({
      ...state,
      isInternalFilters: payload ?? !state.isInternalFilters,
    }),
  },
});

export const {
  setFeedTab,
  setFeedPinned,
  preventFeedClosure,
  setSelectedFeedAgenda,
  setFeedTitle,
  setIsInternalFilters,
} = feedSlice.actions;

export const selectFeedTab = (state: RootState): EFeedTab => state.feed.selectedTab;
export const selectIsFeedPinned = (state: RootState) => state.feed.isPinned;
export const selectFeedAgenda = (state: RootState): AgendaNodeAttrs => state?.feed?.selectedAgenda;
export const selectIsOpenedInFeed = (state: RootState, id?: string): boolean => !!id && state?.feed?.selectedAgenda?.blockId === id;
export const selectFeedTitle = (state: RootState): string => state?.feed?.feedTitle;
export const selectIsInternalFilters = (state: RootState): boolean => state?.feed?.isInternalFilters;
