import { Analytics } from '../../../core/Analytics';

const analytics: Analytics = Analytics.getInstance();

// @ts-ignore
export const Milestone = (): JSX.Element => {
  // const log = (...args) => {
  //   if (config.app.isLogsEnabled) {
  //     console.log(...args);
  //   }
  // };
  // const dispatch = useAppDispatch();
  //
  // log('pre');
  // const { milestoneId }: Readonly<Params> = useParams<string>();
  // log('milestoneId', milestoneId);
  // const milestone = useAppSelector((state) => selectById(state.tasks, milestoneId));
  // log('milestone', milestone);
  // const actions: TaskDTO[] = useAppSelector((state) => selectAll(state.tasks));
  // log('actions', actions);
  // const { process, processUsers, currentUserPermissions } = useAppSelector((state) => state.process);
  // const { permissions, organization } = useAppSelector((state) => state.app);
  // log('processUsers', processUsers);
  // log('currentUserPermissions', currentUserPermissions);
  // const theme = createTheme();
  // const navigate = useNavigate();
  //
  // const [selectedAction, selectAction] = useState<string>('');
  // log('selectedAction', selectedAction);
  // const [isBackdropOpen, toggleBackdrop] = useState<boolean>(false);
  // log('isBackdropOpen', isBackdropOpen);
  // const [isStartDialogOpen, setStartDialogOpen] = useState<boolean>(false);
  // log('isStartDialogOpen', isStartDialogOpen);
  // const [isStatusChangeDialogOpen, setStatusChangeDialogOpen] = useState<boolean>(false);
  // log('isStatusChangeDialogOpen', isStatusChangeDialogOpen);
  // const [isCompletedDialogOpen, setCompletedDialogOpen] = useState<boolean>(false);
  // log('isCompletedDialogOpen', isCompletedDialogOpen);
  // const [selectedTitleEditingTaskId, setTitleEditingTaskId] = useState<string>('');
  // log('selectedTitleEditingTaskId', selectedTitleEditingTaskId);
  // const [tasks, setTasks] = useState<TaskDTO[]>([]);
  //
  // const { user } = useAppSelector((state) => state.app);
  //
  // log('tasks', tasks);
  // const [, drop] = useDrop(() => ({ accept: ItemTypes.MILESTONE }));
  // const findAction = (id: string | number): TaskDTO | undefined => tasks.find((action: TaskDTO) => action.id === id);
  //
  // const organizationPermissions = (permissions && permissions[EUserOrganizationPermissions.ORGANIZATION_DEALS_EDIT_ALL])
  //   && process?.organizationId === organization?.id;
  //
  // const isSummaryEditable: boolean = currentUserPermissions[EUserDealPermissions.DEAL_SUMMARY_EDIT]
  //   || organizationPermissions;
  //
  // const isTaskEditable: boolean = currentUserPermissions[EUserDealPermissions.DEAL_ACTIONS_EDIT]
  //   || organizationPermissions;
  //
  // const isActionCreationEnabled: boolean = currentUserPermissions[EUserDealPermissions.DEAL_ACTIONS_CREATE]
  //   || organizationPermissions;
  //
  // const isUserAssignEnabled: boolean = currentUserPermissions[EUserDealPermissions.DEAL_USERS_ASSIGN]
  //   || organizationPermissions;
  //
  // const onSummaryEdit = () => {
  //   analytics.track(EMixPanelEvents.MILESTONE_SUMMARY_TEXT_CLICKED, {
  //     userId: user?.id,
  //     milestoneId,
  //   });
  // };
  //
  // useEffect(() => {
  //   setTasks(actions
  //     .filter((task: TaskDTO) => task.parentId === milestoneId)
  //     .sort((a, b) => a.order - b.order));
  //   log('useEffect - setTasks() - tasks', tasks);
  // }, [actions, milestoneId]);
  //
  // const onTaskDrop = (): void => {
  //   dispatch(reorderTasks(tasks.map((task: TaskDTO) => task.id)));
  // };
  //
  // const findTask = useCallback(
  //   (taskId: string) => {
  //     log('called', 'dnd - findTask');
  //     const task = tasks.find((c) => `${c.id}` === taskId);
  //     return {
  //       task,
  //       index: tasks.indexOf(task),
  //     };
  //   },
  //   [tasks],
  // );
  //
  // const moveTask = useCallback(
  //   (taskId: string, atIndex: number) => {
  //     log('called', 'dnd - moveTask');
  //     const { task, index } = findTask(taskId);
  //     setTasks(
  //       update(tasks, {
  //         $splice: [
  //           [index, 1],
  //           [atIndex, 0, task],
  //         ],
  //       }),
  //     );
  //   },
  //   [findTask, tasks, setTasks],
  // );
  //
  // const getActionAnalyticsData = (action: TaskDTO) => ({
  //   id: action.id,
  //   position: action.order,
  //   title: action.title,
  //   assignees: action.assigneesIds,
  //   status: action.status,
  //   due: action.timeLineEndDate,
  // });
  //
  // const onAssigneesClick = (rowId: string) => {
  //   const clickedAction: TaskDTO | undefined = findAction(rowId);
  //   if (clickedAction) {
  //     analytics.track(
  //       EMixPanelEvents.MILESTONE_HEADER_ROW_ASSIGNEES_CLICKED,
  //       getActionAnalyticsData(clickedAction),
  //     );
  //   }
  //   log('called', 'onAssigneesClick');
  //   selectAction(rowId);
  //   toggleBackdrop(true);
  // };
  //
  // const trackActionAnalyticsEvent = (action: TaskDTO, event: EMixPanelEvents): void => {
  //   analytics.track(
  //     event,
  //     getActionAnalyticsData(action),
  //   );
  // };
  //
  // const isThisLastActionCompleted = (currentTasks: TaskDTO[], taskId: string): boolean => !currentTasks
  //   .filter((action: TaskDTO) => action.status !== ETaskStatus.COMPLETE && action.id !== taskId)
  //   .length;
  //
  // const onTaskCheck = ({ rowId, checked }: { rowId: string, checked: boolean }): void => {
  //   log('called', 'onTaskCheck');
  //   if (checked && isThisLastActionCompleted(tasks, rowId) && milestone?.status !== ETaskStatus.COMPLETE) {
  //     setCompletedDialogOpen(true);
  //   } else if (milestone?.status === ETaskStatus.PENDING && checked) {
  //     setStartDialogOpen(true);
  //   }
  //   const action = findAction(rowId);
  //
  //   if (action) {
  //     trackActionAnalyticsEvent(action, EMixPanelEvents.COMPLETE_ACTION_CHECKBOX_CLICKED);
  //     dispatch(updateTask({
  //       ...action,
  //       status: checked ? ETaskStatus.COMPLETE : ETaskStatus.PENDING,
  //     }));
  //   }
  // };
  //
  // let listItems: JSX.Element[] = [];
  //
  // if (tasks) {
  //   log('generateTasks');
  //   listItems = tasks.map((action: TaskDTO) => (
  //     <TaskListItemWrapper
  //       isElevated={selectedAction === action.id}
  //       findTask={findTask}
  //       moveTask={moveTask}
  //       id={action.id}
  //       key={action.id}
  //       isDnDEnabled={!selectedTitleEditingTaskId}
  //       onTaskDrop={onTaskDrop}
  //     >
  //       <>
  //         <TaskListItemCell
  //           xs={8}
  //           sx={{
  //             cursor: 'pointer',
  //           }}
  //         >
  //           <>
  //             <TaskCheckbox
  //               rowId={action.id}
  //               isChecked={action.status === ETaskStatus.COMPLETE}
  //               onChange={(data) => {
  //                 if (isTaskEditable) {
  //                   onTaskCheck(data);
  //                 }
  //               }}
  //             />
  //             <TaskTitleWithEditor
  //               action={action}
  //               id={action.id}
  //               title={action.title}
  //               onSave={(title) => {
  //                 dispatch(updateTask({
  //                   ...action,
  //                   title,
  //                 }));
  //               }}
  //               isEditable={isTaskEditable}
  //               setTitleEditingTaskId={setTitleEditingTaskId}
  //               selectedTitleEditingTaskId={selectedTitleEditingTaskId}
  //             />
  //           </>
  //         </TaskListItemCell>
  //
  //         <TaskListItemCell xs={2}>
  //           <UserAvatars
  //             onAssigneesClick={(rowId: string) => onAssigneesClick(rowId)}
  //             links={getMilestoneAssigneesAvatarsData(action.assigneesIds, processUsers)}
  //             isEditable={isUserAssignEnabled}
  //             isSelected={action.id === selectedAction}
  //             rowId={action.id}
  //             sx={{
  //               color: (appTheme) => appTheme.palette.primary.main,
  //             }}
  //           />
  //         </TaskListItemCell>
  //         <TaskListItemCell
  //           xs={2}
  //           sx={{
  //           }}
  //         >
  //           <TaskDatePickerCell
  //             date={action.timeLineEndDate}
  //             onSubmit={(date) => {
  //               dispatch(updateTask({
  //                 ...action,
  //                 timeLineEndDate: date,
  //               }));
  //             }}
  //           />
  //         </TaskListItemCell>
  //       </>
  //     </TaskListItemWrapper>
  //   ));
  //   log('listItems', listItems);
  // }
  //
  // const onSave = (data: ISummaryOnSaveData) => {
  //   log('called', 'onSave');
  //   if (milestone) {
  //     dispatch(updateTask({
  //       ...milestone,
  //       ...data,
  //     }));
  //   }
  // };
  //
  // const onCreate = (title: string): void => {
  //   log('called', 'onCreate');
  //   if (milestone && milestone.id) {
  //     dispatch(createTask({
  //       task: {
  //         title,
  //         parentId: milestone?.id,
  //         description: '',
  //         type: ETaskTypes.ACTION,
  //         order: tasks.length ? [...tasks].reverse()[0].order + 1 : 0,
  //       },
  //       oldOrder: [...tasks].map((item: TaskDTO) => item.id),
  //       processId: milestone.parentId,
  //     }));
  //   }
  // };
  //
  // const onBackdropClose = (): void => {
  //   log('called', 'onBackdropClose');
  //   toggleBackdrop(false);
  //   selectAction('');
  // };
  //
  // let selectedTask: TaskDTO | undefined;
  // if (!selectedAction && milestone) {
  //   log('!selectedAction && milestone', !selectedAction && milestone);
  //   selectedTask = milestone;
  // } else {
  //   log('else');
  //   const foundAction = findAction(selectedAction);
  //   log('foundAction', foundAction);
  //   if (foundAction) {
  //     selectedTask = foundAction;
  //     log('selectedTask', selectedTask);
  //   } else {
  //     log('else');
  //     selectedTask = milestone;
  //     log('selectedTask', selectedTask);
  //   }
  // }
  //
  // const onStartMilestoneDialogClose = (isAccepted: boolean): void => {
  //   setStartDialogOpen(false);
  //   if (isAccepted) {
  //     setStatusChangeDialogOpen(true);
  //   }
  // };
  //
  // const onCompleteMilestoneDialogClose = (isAccepted: boolean): void => {
  //   setCompletedDialogOpen(false);
  //   if (isAccepted) {
  //     dispatch(updateTask({
  //       ...milestone,
  //       status: ETaskStatus.COMPLETE,
  //     }));
  //     navigate(`${EAppRoutes.deal}/${milestone?.parentId}`);
  //   }
  // };
  //
  // const milestoneRow = milestone && processUsers ? (
  //   <MilestoneActionListHeader
  //     milestone={milestone}
  //     isEditableViaOrgPermissions={organizationPermissions}
  //     onAssigneesClick={(id: string) => onAssigneesClick(id)}
  //     currentUserPermissions={currentUserPermissions}
  //     selectedAction={selectedAction}
  //     processUsers={processUsers}
  //   />
  // ) : '';
  //
  // return (
  //   <Grid container minHeight="93vh" spacing={0}>
  //     <Grid
  //       item
  //       xs={8}
  //       flexDirection="column"
  //       sx={{
  //         display: 'flex',
  //       }}
  //     >
  //       <SummaryContainer>
  //         {
  //           milestone
  //           && (
  //             <MilestoneSummary
  //               description={milestone.description || ''}
  //               title={milestone.title}
  //               onSave={onSave}
  //               isEditable={isSummaryEditable}
  //               trackSummaryClickedEvent={onSummaryEdit}
  //               assignees={processUsers.filter((item: ProcessUserDTO) => milestone?.assigneesIds?.includes(item.id))}
  //               status={milestone.status}
  //             />
  //           )
  //         }
  //       </SummaryContainer>
  //       <Box sx={{
  //         marginRight: '1vw',
  //         flex: 1,
  //         display: 'flex',
  //       }}
  //       >
  //         <Backdrop
  //           sx={{
  //             color: '#fff',
  //             zIndex: (appTheme) => appTheme.zIndex.drawer + 5,
  //           }}
  //           open={isBackdropOpen}
  //           onClick={() => onBackdropClose()}
  //         />
  //         <MilestoneDialog title={`Start the ${milestone?.title}`} isOpen={isStartDialogOpen} onClose={onStartMilestoneDialogClose}>
  //           <Typography>
  //             This milestone has not yet been started. Would you like to change the status to keep everyone in the loop?
  //           </Typography>
  //         </MilestoneDialog>
  //         <MilestoneDialog
  //         title={`Finish the ${milestone?.title}`} isOpen={isCompletedDialogOpen} onClose={onCompleteMilestoneDialogClose}>
  //           <Typography>
  //             Hooray! All actions of this milestone have been completed.
  //             Would you like to set the status to Completed for everyone to join the party?
  //           </Typography>
  //         </MilestoneDialog>
  //         {
  //           milestone && (
  //             <Box
  //               sx={{
  //                 display: 'flex',
  //                 flexDirection: 'column',
  //                 flex: 1,
  //               }}
  //             >
  //               <TaskListHeader itemsType={ETaskTypes.ACTION} />
  //               {milestoneRow}
  //               <ScrollableBox>
  //                 <Box
  //                   ref={drop}
  //                   sx={{
  //                     display: 'flex',
  //                     flex: 1,
  //                     flexDirection: 'column',
  //                   }}
  //                 >
  //                   {listItems}
  //                 </Box>
  //               </ScrollableBox>
  //               <Box
  //                 sx={{
  //                   display: 'flex',
  //                   flex: 1,
  //                   flexDirection: 'row',
  //                   mt: '2px',
  //                   mx: 0,
  //                   overflow: 'clip !important',
  //                 }}
  //               >
  //                 <TaskListItemCell
  //                   xs={12}
  //                   sx={{
  //                     '@media (min-width: 1366px)': {
  //                       borderRadius: '0 0 16px 16px',
  //                     },
  //                     '@media (max-width: 1366px)': {
  //                       borderRadius: '0 0 10px 10px',
  //                     },
  //                   }}
  //                 >
  //                   {/* <AddTask onCreate={onCreate} /> */}
  //                 </TaskListItemCell>
  //               </Box>
  //             </Box>
  //           )
  //         }
  //       </Box>
  //     </Grid>
  //     <Grid
  //       item
  //       xs={4}
  //       sx={{
  //         display: 'flex',
  //         flex: 1,
  //       }}
  //     >
  //       <ThemeProvider theme={theme}>
  //         <DealFeed
  //           selectedTask={selectedTask}
  //           taskStatus={milestone?.status}
  //         />
  //       </ThemeProvider>
  //     </Grid>
  //   </Grid>
  // );
};
