import { Box, Typography } from '@mui/material';
import { GridRowsProp, gridStringOrNumberComparator } from '@mui/x-data-grid';
import { ExtendedGridColumns } from '../types/mobile-table';
import OrganizationUsersRoleSelector
  from '../features/Organization/OrganizationUsersDataGrid/OrganizationUsersRoleSelector/OrganizationUsersRoleSelector';
import { OrganizationUserDTO } from '../../shared/UserDTO';
import { EUserOrganizationStatus } from '../../shared/user-organization';
import OrganizationUsersMenu from '../routes-old/organizationUsers/OrganizationUsersMenu';
import { EUserOrganizationPermissions, RoleDTO } from '../../shared/permissions';
import { useOrganization } from './useOrganization';
import useUserOrganizationPermissions from './useOrganizationPermissions';
import { useAppSelector } from './stateHooks';
import findOrganizationOwner from '../core/store/appState/selectors/ownerSelector';
import { NameCell } from '../features/Organization/OrganizationUsersDataGrid/NameCell';

export enum EDataGridColumns {
  email = 'email',
  name = 'name',
  title = 'title',
  menu = 'menu',
  status = 'status',
  actions = 'actions',
  lastActive = 'lastActive',
  role = 'role',
  value = 'value',
  client = 'client',
  template = 'template',
  lastMileStone = 'lastMileStone',
  health = 'health',
}

export const useOrganizationDataGrid = () => {
  const { roles, roleNames, users } = useOrganization();
  const organizationOwner = useAppSelector(findOrganizationOwner);
  const [checkOrganizationPermissions] = useUserOrganizationPermissions();
  const teamManager: boolean = checkOrganizationPermissions(EUserOrganizationPermissions.ORGANIZATION_TEAM_MANAGE);
  const removeAvailable: boolean = checkOrganizationPermissions(EUserOrganizationPermissions.ORGANIZATION_TEAM_REMOVE);
  const canRemove = (id: string) => removeAvailable && id !== organizationOwner?.id;

  const columns: ExtendedGridColumns = [
    {
      sortable: true,
      headerName: 'Name',
      field: EDataGridColumns.name,
      sortComparator: (
        val1,
        val2,
        conf1,
        conf2,
      ) => gridStringOrNumberComparator(val1?.name, val2?.name, conf1, conf2),
      minWidth: 100,
      flex: 5,
      renderCell: (
        {
          value: {
            name, avatarSrc, title, colorId, email,
          },
        },
      ) => (
        <NameCell name={name ?? email} title={title} colorId={colorId} avatarSrc={avatarSrc} />
      ),
    },
    {
      sortable: true,
      headerName: 'Email',
      field: EDataGridColumns.email,
      minWidth: 200,
      flex: 1,
      renderMobileCell: true,
    },
    {
      sortable: false,
      headerName: 'Role',
      field: EDataGridColumns.role,
      minWidth: 150,
      flex: 1,
      cellClassName: 'RoleSellecror',
      renderCell: (
        {
          value,
        },
      ) => (
        <OrganizationUsersRoleSelector
          user={value.user as OrganizationUserDTO}
          value={value.roleId as string}
          roles={roles}
          roleNames={roleNames}
          organizationOwner={organizationOwner}
          teamManager={teamManager}
        />
      ),
      renderMobileCell: ({ value }) => (
        <OrganizationUsersRoleSelector
          user={value.user as OrganizationUserDTO}
          value={value.roleId as string}
          roles={roles}
          roleNames={roleNames}
          organizationOwner={organizationOwner}
          teamManager={teamManager}
        />
      ),
    },
    {
      sortable: true,
      headerName: 'Status',
      field: EDataGridColumns.status,
      minWidth: 50,
      flex: 1,
      renderCell: (
        {
          value,
        },
      ) => (

        <Typography
          variant="h4"
          textTransform="capitalize"
        >
          {
      // @ts-ignore
      value === EUserOrganizationStatus.ACTIVE ? 'Active' : 'Pending'
  }
        </Typography>
      ),
      renderMobileCell: ({ value }) => (
        <span>
          {value === EUserOrganizationStatus.ACTIVE ? 'Active' : 'Pending'}
        </span>
      ),
    },
    {
      headerName: '',
      minWidth: 50,
      field: EDataGridColumns.menu,
      flex: 1,
      renderCell: ({
        value: { email, name, id },
      }) => (
        <Box hidden={!canRemove(id)}>
          <OrganizationUsersMenu id={id} email={email} name={name} />
        </Box>
      ),
    },
  ];

  let rows: GridRowsProp = [];

  if (users?.length) {
    rows = users
      .map((user: OrganizationUserDTO) => ({
        [EDataGridColumns.title]: user,
        [EDataGridColumns.role]: {
          roleId: user.rolesIds ? roles.filter((role: RoleDTO) => user.rolesIds.includes(role.id))[0].id : ' ',
          user,
        },
        [EDataGridColumns.email]: user.email,
        [EDataGridColumns.name]: user,
        [EDataGridColumns.status]: user.organizationStatus,
        [EDataGridColumns.menu]: user,
        id: user.id,
      }));
  }

  return {
    rows,
    columns,
  };
};
