import {
  ChangeEvent,
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { Check } from '@mui/icons-material';
import { Box } from '@mui/material';
import {
  LibraryFilterSetDTO,
  LibraryProductFilterCategory,
  LibraryProductFilterParams,
  LibraryProductFilterSet,
  LibraryProductFilterSetDTO,
  LibraryProductFilterTitle,
  LibraryProductMultipleFilter,
  LibraryProductOwnerFilterItem,
  SubItem,
} from '../../../../shared/library/LibraryFilter';
import { useAppDispatch, useAppSelector } from '../../../hooks/stateHooks';
import useDebounce from '../../../hooks/useDebounce';
import {
  selectLibraryProductCollectFilterSetInfoDialogStatus,
  selectLibraryProductExpanded,
  selectLibraryProductFilterParams,
  selectLibraryProductFilterSearch,
  selectLibraryProductGridFilter,
  selectLibraryProductManageFilterSetsDialogStatus,
  selectLibraryProductPopupCategory,
  setLibraryProductFilterExpanded,
  setLibraryProductFilterPopupCategory,
  setLibraryProductGridFilter,
  setLibraryProductManageFilterSetsDialogStatus,
  setLibraryProductMobileFilterMenu,
  setLibraryProductSearch,
  setLibraryProductNewFilterSetDialogStatus,
  selectLibraryProductFilterInfo,
  allProductFiltersSelector,
  createNewFilter,
  getUserProductFilters,
  updateFilter,
  deleteFilter,
  getLibraryProductFilterInfo,
  selectLibraryProductMobileFilterMenu,
} from '../lib/libraryProductFilterSlice';
import { AppDropdownItemProps } from '../../../shared/AppDropdown/AppDropdownItem';
import { AppAvatar } from '../../../shared/AppAvatar';
import { AppButton } from '../../../shared/AppButton/AppButton';
import Owner from '../../../icons/DealsFiltersInput/Owner.svg';
import { selectProductsLoadingJobs, selectLibraryGridToRender } from '../lib/librarySlice';

type UpdateMultipleFilter<T = {}> = { category: LibraryProductFilterCategory, item: SubItem<T>, title: LibraryProductFilterTitle };
type RemoveMultipleFilterValue = { category: LibraryProductFilterCategory, value: string };

export const useLibraryProductGridFilter = () => {
  const dispatch = useAppDispatch();
  const search: string = useAppSelector(selectLibraryProductFilterSearch);
  const gridFilters = useAppSelector(selectLibraryProductGridFilter);
  const libraryProductFilterInfos = useAppSelector(selectLibraryProductFilterInfo);
  const expanded = useAppSelector(selectLibraryProductExpanded);
  const popupCategory = useAppSelector(selectLibraryProductPopupCategory);
  const newFilterSetDialogStatus = useAppSelector(selectLibraryProductCollectFilterSetInfoDialogStatus);
  const manageFilterSetsDialogStatus = useAppSelector(selectLibraryProductManageFilterSetsDialogStatus);
  const filterParams: LibraryProductFilterParams = useAppSelector(selectLibraryProductFilterParams);
  const [anchorEl, setAnchorEl] = useState<DOMRect>();
  const debouncedGridFilters = useDebounce(gridFilters, 250);
  const debouncedFilterSearch = useDebounce(search, 250);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const savedFilters = useAppSelector(allProductFiltersSelector);
  const gridToRender = useAppSelector(selectLibraryGridToRender);
  const loadingJobs = useAppSelector(selectProductsLoadingJobs);

  useEffect(() => {
    dispatch(getUserProductFilters());
  }, []);

  useEffect(() => {
    dispatch(getLibraryProductFilterInfo());
  }, [debouncedGridFilters, debouncedFilterSearch]);

  const closeMenu = () => {
    if (isMobile) {
      dispatch(setLibraryProductMobileFilterMenu(false));
    } else {
      setAnchorEl(undefined);
    }
  };

  const openMenu = (node: Element, category?: LibraryProductFilterCategory) => {
    dispatch(setLibraryProductFilterPopupCategory(category || null));
    if (isMobile) {
      dispatch(setLibraryProductMobileFilterMenu(true));
    } else {
      setAnchorEl(node.getBoundingClientRect());
    }
  };

  const getCategoryItem = (category: LibraryProductFilterCategory, value: string | number) => category in filterParams
    && (filterParams[category]! as LibraryProductMultipleFilter)
      .subItems.find((subItem) => subItem.value === value);

  const updateFilterParams = async (params: LibraryProductFilterParams) => {
    dispatch(setLibraryProductGridFilter(params));
  };

  const updateSearch = (searchQuery: string) => {
    dispatch(setLibraryProductSearch(searchQuery));
  };

  const saveNewFilter = async (filterSet: LibraryProductFilterSet) => {
    await dispatch(createNewFilter(filterSet)).unwrap();
    enqueueSnackbar('The filter set has been saved', {
      variant: 'success',
    });
  };

  const updateFilterSet = async (id: string, name: string) => {
    await dispatch(updateFilter({ id, filterSet: { name } as Partial<LibraryProductFilterSet> })).unwrap();
    enqueueSnackbar('The filter set has been successfully changed', {
      variant: 'success',
    });
  };

  const deleteFilterSet = async (deleteId: string) => {
    if (deleteId) {
      await dispatch(deleteFilter(deleteId)).unwrap();
    }
  };

  const getFilterInfo = () => {
    dispatch(getLibraryProductFilterInfo());
  };

  const updateMultipleFilter = (toUpdate: UpdateMultipleFilter) => {
    const mutableFilters: LibraryProductFilterParams = JSON.parse(JSON.stringify(filterParams));
    const currentFilter = mutableFilters[toUpdate.category];
    if (!currentFilter) {
      mutableFilters[toUpdate.category] = {
        category: toUpdate.category,
        title: toUpdate.title,
        subItems: [toUpdate.item],
      } as LibraryProductMultipleFilter;
      return updateFilterParams(mutableFilters);
    }
    const currentMultipleFilter = currentFilter as LibraryProductMultipleFilter;
    const itemIndex = currentMultipleFilter.subItems.findIndex((subItem) => toUpdate.item.value === subItem.value);
    if (itemIndex === -1) {
      currentMultipleFilter.subItems.push(toUpdate.item);
    } else {
      if (currentMultipleFilter.subItems.length === 1) {
        delete mutableFilters[currentMultipleFilter.category];
        return updateFilterParams(mutableFilters);
      }
      currentMultipleFilter.subItems.splice(itemIndex, 1);
    }
    mutableFilters[currentMultipleFilter.category] = currentMultipleFilter;
    return updateFilterParams(mutableFilters);
  };

  const removeMultipleFilter = (toRemove: RemoveMultipleFilterValue) => {
    const mutableFilters: LibraryProductFilterParams = JSON.parse(JSON.stringify(filterParams));
    const currentFilter = mutableFilters[toRemove.category];
    if (!currentFilter) {
      return null;
    }
    const currentMultipleFilter = currentFilter as LibraryProductMultipleFilter;
    const itemIndex = currentMultipleFilter.subItems.findIndex((subItem) => toRemove.value === subItem.value);
    if (itemIndex === -1) return null;
    if (currentMultipleFilter.subItems.length === 1) {
      delete mutableFilters[currentMultipleFilter.category];
      return updateFilterParams(mutableFilters);
    }
    currentMultipleFilter.subItems.splice(itemIndex, 1);
    mutableFilters[currentMultipleFilter.category] = currentMultipleFilter;
    return updateFilterParams(mutableFilters);
  };

  const removeFilter = (key: LibraryProductFilterCategory) => {
    const mutableFilters: LibraryProductFilterParams = JSON.parse(JSON.stringify(filterParams));
    delete mutableFilters[key];
    updateFilterParams(mutableFilters);
  };

  const multipleMenuItemParams = (
    filter: UpdateMultipleFilter,
    icon?: ReactElement,
  ): Partial<AppDropdownItemProps> & Pick<AppDropdownItemProps, 'label'> => ({
    label: filter.item.title,
    onClick: () => {
      dispatch(setLibraryProductFilterPopupCategory(filter.category));
      updateMultipleFilter(filter);
    },
    startIcon: icon,
    endIcon: getCategoryItem(filter.category, filter.item.value) ? (
      <Box sx={{
        position: 'absolute',
        right: '15px',
        top: '30%',
      }}
      >
        <Check />
      </Box>
    ) : undefined,
    sx: {
      minWidth: '200px',
    },
    selected: !!getCategoryItem(filter.category, filter.item.value),
    selectable: ['owner', 'type'].includes(filter.category),
    value: filter.item.value,
  });

  const resetCategoryFilterParams = (category: LibraryProductFilterCategory): Partial<AppDropdownItemProps> => ({
    onClick: (e) => {
      e.preventDefault();
      e.stopPropagation();
      removeFilter(category);
    },
    endIcon: category in filterParams ? undefined : (
      <Box sx={{
        position: 'absolute',
        right: '15px',
        top: '30%',
      }}
      >
        <Check />
      </Box>
    ),
  });

  const ownerOpts = useMemo(() => [
    {
      label: t('Filters_All'),
      ...resetCategoryFilterParams('owner'),
    },
  ].concat((libraryProductFilterInfos && libraryProductFilterInfos.owner ? libraryProductFilterInfos.owner : []).map(
    (owner: LibraryProductOwnerFilterItem) => multipleMenuItemParams({
      category: 'owner',
      title: 'Owner',
      item: {
        title: owner.name, value: owner.id, avatar: owner.avatar, colorId: owner.colorId,
      },
    }, <AppAvatar src={owner.avatar} name={owner.name} colorId={owner.colorId} />),
  )), [filterParams, libraryProductFilterInfos]);

  const allOpts: AppDropdownItemProps[] = useMemo(() => {
    const mainOpts: AppDropdownItemProps[] = [
      {
        label: 'Owner',
        startIcon: <Owner />,
        childrenItems: ownerOpts,
      },
    ];

    const savedFiltersOpts: AppDropdownItemProps[] = [];

    if (savedFilters?.length) {
      const savedFiltersHeaderOpts = [
        { label: '', divider: true },
        {
          label: 'Saved',
          category: true,
          endIcon:
            <AppButton
              onClick={() => {
                dispatch(setLibraryProductManageFilterSetsDialogStatus(true));
                dispatch(setLibraryProductMobileFilterMenu(false));
                setAnchorEl(undefined);
              }}
              size="xs"
              sx={{
                padding: 0,
              }}
            >
              Settings
            </AppButton>,
        },
      ];

      const savedFiltersMenuItems = savedFilters?.map((filter: LibraryFilterSetDTO) => ({
        label: filter.name,
        onClick: () => {
          updateFilterParams((filter as LibraryProductFilterSetDTO).filterParams);
          enqueueSnackbar(`The filter set "${filter.name}" has been chosen`, {
            variant: 'success',
          });
          closeMenu();
        },
      }));

      savedFiltersOpts
        .push(...savedFiltersHeaderOpts, ...savedFiltersMenuItems);
    }

    return mainOpts.concat(savedFiltersOpts);
  }, [ownerOpts]);

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>): void => {
    updateSearch(e.target.value);
  };

  const getPopupItems = () => {
    switch (popupCategory) {
      case 'owner':
        return ownerOpts;
      default:
        return allOpts;
    }
  };

  const mobileIndex = useMemo(() => {
    switch (popupCategory) {
      case 'owner':
        return 1;
      default:
        return 0;
    }
  }, [popupCategory]);

  const openFilter = () => {
    dispatch(setLibraryProductFilterExpanded(true));
  };

  const closeFilter = () => {
    if (isMobile) return;
    dispatch(setLibraryProductFilterExpanded(false));
  };

  return {
    libraryProductFilterInfos,
    removeFilter,
    allOpts,
    anchorEl,
    expanded,
    ownerOpts,
    typeOpts: [],
    handleSearchChange,
    getPopupItems,
    setAnchorEl,
    setLibraryProductFilterPopupCategory,
    popupCategory,
    mobileIndex,
    getCategoryItem,
    removeMultipleFilter,
    openFilter,
    closeFilter,
    newFilterSetDialogStatus,
    manageFilterSetsDialogStatus,
    openMenu,
    closeMenu,
    debouncedGridFilters,
    debouncedFilterSearch,
    updateFilterParams,
    updateSearch,
    filterParams,
    search,
    setLibraryFilterExpanded: setLibraryProductFilterExpanded,
    setLibraryNewFilterSetDialogStatus: setLibraryProductNewFilterSetDialogStatus,
    setLibraryManageFilterSetsDialogStatus: setLibraryProductManageFilterSetsDialogStatus,
    setMobileFilterMenu: setLibraryProductMobileFilterMenu,
    selectMobileFilterMenu: selectLibraryProductMobileFilterMenu,
    savedFilters,
    saveNewFilter,
    updateFilterSet,
    deleteFilterSet,
    gridToRender,
    loadingJobs,
    getFilterInfo,
  };
};
