import { styled } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import { stagesetColors } from '../../../../theme/stagesetPalette';

export const CardBox = styled(Box)(() => ({
  display: 'flex',
  width: '344px',
  flexDirection: 'column',
  padding: '24px 0px 24px 0px',
  gap: '8px',
  border: '1px solid',
  borderRadius: '20px',
  borderColor: stagesetColors.newGrey[200],
  backgroundColor: stagesetColors.white[100],
  boxShadow: '0px 3px 2px 0px rgba(30, 41, 59, 0.04),'
    + '0px 1px 1px 0px rgba(30, 41, 59, 0.07),'
    + '0px 0px 1px 0px rgba(30, 41, 59, 0.08),'
    + '0px 0px 0px 0px rgba(30, 41, 59, 0.08)',
  '&.card-box-premium': {
    backgroundColor: stagesetColors.newGrey[800],
  },
  '&.card-box-mobile': {
    width: '100%',
  },
}));

export const TitleContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  padding: '0px 24px 16px 24px',
  justifyContent: 'start',
  borderBottom: `1px solid ${stagesetColors.newGrey[200]}`,
  '&.title-container-premium': {
    borderBottom: `1px solid ${stagesetColors.newGrey[700]}`,
  },
}));

export const CardTitle = styled(Typography)(() => ({
  color: stagesetColors.newGrey[800],
  fontWeight: 500,
  fontSize: '20px',
  lineHeight: '26px',
  '&.card-title-premium': {
    color: stagesetColors.newGrey[50],
  },
}));

export const CardSubtitle = styled(Typography)(() => ({
  color: stagesetColors.newGrey[500],
  fontWeight: 600,
  fontSize: '32px',
  lineHeight: 'normal',
  '&.card-subtitle-premium': {
    color: stagesetColors.newGrey[50],
  },
  '&.card-subtitle-black': {
    color: stagesetColors.newGrey[800],
  },
}));

export const CardOrdinaryText = styled(Typography)(() => ({
  color: stagesetColors.newGrey[500],
  fontSize: '16px',
  lineHeight: '22px',
  '&.card-ordinary-text-premium': {
    color: stagesetColors.newGrey[50],
  },
}));

export const FeatureContainer = styled(Box)(() => ({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  padding: '16px 24px 32px 24px',
  gap: '12px',
  color: stagesetColors.newGrey[800],
  '& svg': {
    '& path': {
      stroke: stagesetColors.newGrey[800],
    },
  },
  '&.feature-container-premium': {
    color: stagesetColors.newGrey[50],
    '& svg': {
      '& path': {
        stroke: stagesetColors.newGrey[50],
      },
    },
  },
}));

export const FeatureBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '8px',
  color: 'inherit',
}));

export const FeatureTitle = styled(Typography)(() => ({
  color: stagesetColors.newGrey[800],
  fontSize: '18px',
  lineHeight: '26px',
  '&.feature-title-premium': {
    color: stagesetColors.newGrey[50],
  },
}));

export const ButtonContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '0px 32px',
}));

export const ButtonPlaceholder = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
  width: '100%',
  height: '48px',
  padding: '12px 16px',
  '& svg': {
    '& path': {
      stroke: stagesetColors.newGrey[500],
    },
  },
}));

export const ButtonPlaceholderTitle = styled(Typography)(() => ({
  color: stagesetColors.newGrey[500],
  fontSize: '14px',
  lineHeight: '16px',
  fontWeight: 600,
}));
