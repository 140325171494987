import { TabContext } from '@mui/lab';
import Drawer from '@mui/material/Drawer';
import { Box, Stack } from '@mui/material';
import { isMobile } from 'react-device-detect';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import {
  EFeedTab,
  selectFeedTab,
  selectIsInternalFilters,
  setFeedTab,
} from '../../../features/DealFeed/lib/feedSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/stateHooks';
import DealFeedHeader from './DealFeedHeader';
import UsersTab from './Users/UsersTab';
import { stagesetColors } from '../../../theme/stagesetPalette';
import AgendaTab from './Agenda/AgendaTab';
import SharePreviewTab from './SharePreview/SharePreviewTab';
import SidebarPricingTableItemDetailsTab from '../../../features/Stages/PricingTableTab/SidebarPricingTableItemDetailsTab';
import { selectCurrentStage } from '../../../features/ProcessFields/lib/processItemsSlice';
import { FileUploadPrivider } from '../../../features/FilesUpload/FilesUploadProvider/FilesUploadProvider';
import InternalModeTab from '../../../features/DealFeed/InternalModeTab/InternalModeTab';
import InternalTabFilters from '../../../features/DealFeed/InternalModeTab/InternalTabFilters';
import { AppTabs } from '../../../shared/AppTabs/AppTabs';
import RoomInfoTab from '../../../features/DealFeed/RoomInfoTab/RoomInfoTab';
import { selectIsOwnersTeammate, selectIsProcessOwner } from '../state/processSlice';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

const DealFeed = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const process = useAppSelector((state) => state.process.process);
  const isShared = useAppSelector((state) => state.auth.isSharedUser);
  const isOwner = useAppSelector(selectIsProcessOwner);
  const isTeammate = useAppSelector(selectIsOwnersTeammate);
  const currentFeedTab: EFeedTab = useAppSelector(selectFeedTab);
  const [open, setOpen] = useState(false);
  const [drawerWidth, setDrawerWidth] = useState('600px');
  const [drawerAnchor, setDrawerAnchor] = useState<Anchor>('right');
  const currentStage = useAppSelector(selectCurrentStage);
  const { t } = useTranslation();

  const isInternalModeFilters = useAppSelector(selectIsInternalFilters);

  const toggleDrawer = (anchor: Anchor) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === 'keydown'
      && ((event as React.KeyboardEvent).key === 'Tab'
        || (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    dispatch(setFeedTab(EFeedTab.NONE));
  };

  useEffect(() => {
    switch (currentFeedTab) {
      case EFeedTab.NONE:
        setOpen(false);
        break;
      case EFeedTab.PRICING_TABLE:
        setDrawerAnchor('left');
        setDrawerWidth('300px');
        setOpen(true);
        break;
      default:
        setDrawerAnchor('right');
        setDrawerWidth('600px');
        setOpen(true);
        break;
    }
  }, [currentFeedTab, isInternalModeFilters]);

  const handleChange = (newValue: EFeedTab) => {
    dispatch(setFeedTab(newValue));
  };

  const options = [
    {
      title: t('Room_Access_rights'), value: EFeedTab.USERS,
    },
    {
      title: t('Room_Customize_link_preview'), value: EFeedTab.PREVIEW,
    },
  ];

  return (
    <Drawer
      anchor={drawerAnchor}
      open={open}
      keepMounted
      disablePortal={isMobile}
      variant={isMobile ? 'persistent' : 'temporary'}
      onClose={toggleDrawer(drawerAnchor)}
      slotProps={{
        backdrop: {
          sx: {
            background: 'rgba(26, 25, 41, 0.1)',
          },
        },
      }}
      hideBackdrop={isMobile}
      PaperProps={{
        className: classNames({ internalFilters: isInternalModeFilters && !isMobile }),
        sx: {
          width: isMobile ? '100%' : drawerWidth,
          overflow: 'hidden',
          zIndex: 1300,
          position: isMobile ? 'absolute' : 'fixed',
          flexDirection: 'row',
          '&.internalFilters': {
            width: '975px',
          },
        },
      }}
      sx={{
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      {
        isInternalModeFilters
        && !isMobile
        && currentFeedTab === EFeedTab.INTERNAL
        && (
        <InternalTabFilters />
        )
      }
      <Box
        sx={{
          marginX: '0px',
          display: 'flex',
          flex: 1,
          position: 'sticky',
          maxHeight: '100%',
          maxWidth: '100%',
          backgroundColor: stagesetColors.newGrey[50],
          border: '2px solid',
          borderColor: stagesetColors.newGrey[100],
        }}
      >
        <Stack
          id="deal-feed"
          sx={{
            flex: 1,
            width: '100%',
          }}
        >
          <TabContext
            value={currentFeedTab}
          >
            <DealFeedHeader />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                overflow: 'auto',
                height: '100%',
              }}
            >
              {
                (
                  currentFeedTab === EFeedTab.USERS || currentFeedTab === EFeedTab.PREVIEW
                ) && (
                  <AppTabs
                    color="white"
                    variant="boxedNew"
                    size="m-new"
                    value={currentFeedTab}
                    onChange={(option) => {
                      handleChange(option.value);
                    }}
                    options={options as any[]}
                    sx={{
                      marginTop: isMobile ? '16px' : '24px',
                      marginX: isMobile ? '16px' : '20px',
                      '& > div': {
                        width: '100%',
                        '& button': {
                          width: '100% !important',
                        },
                      },
                    }}
                  />
                )
              }
              {
                (
                  process && currentFeedTab === EFeedTab.USERS
                ) && (
                  <UsersTab
                    sx={{
                      display: 'flex',
                      padding: 0,
                    }}
                    id="deal-feed-users-tab-container"
                    value={EFeedTab.USERS}
                    isShared={isShared}
                    processId={process?.id}
                  />
                )
              }
              {
                (
                  process && currentFeedTab === EFeedTab.PREVIEW
                ) && (
                  <SharePreviewTab
                    sx={{
                      display: 'flex',
                      padding: 0,
                    }}
                    id="deal-feed-link-preview-tab-container"
                    value={EFeedTab.PREVIEW}
                  />
                )
              }
              {
                process && (
                  <InternalModeTab />
                )
              }
              {
                process && (isOwner || isTeammate) && (
                  <RoomInfoTab />
                )
              }
              {
              currentFeedTab === EFeedTab.AGENDA
              && (
                <FileUploadPrivider
                  entityId={currentStage?.id}
                >
                  <AgendaTab
                    value={EFeedTab.AGENDA}
                    id="deal-feed-agenda-container"
                  />
                </FileUploadPrivider>
              )
              }
              <SidebarPricingTableItemDetailsTab
                value={EFeedTab.PRICING_TABLE}
                id="deal-feed-pricing-table-container"
              />
            </Box>
          </TabContext>
        </Stack>
      </Box>
    </Drawer>
  );
};

export default DealFeed;
