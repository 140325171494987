import { TabContext, TabPanel } from '@mui/lab';
import {
  Box, Collapse, Dialog, Typography,
} from '@mui/material';
import { useState } from 'react';
import { EProcessBackgroundStorageType, EProcessBackgroundType } from '../../../shared/process/ProcessMilestoneActionDTO';
import { AppSwitch } from '../../shared/AppSwitch/AppSwitch';
import { AppTabs } from '../../shared/AppTabs/AppTabs';
import CustomeBackgroundPreview from './CustomeBackgroundPreview';
import CustomeBackgroundUpload from './CustomeBackgroundUpload';
import useProcessBackground from '../../hooks/useProcessBackground';
import { uploadBackground } from '../../routes-old/process/state/dealActions';
import { selectOrganizationId, selectUserId } from '../../core/store/appState/appState';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import { selectProcess } from '../../routes-old/process/state/processSlice';
import ImageCrop from '../ImageCrop/ImageCrop';
import ProcessBackgroudSolidColorSettings from './ProcessBackgroudSolidColorSettings';
import { selectLogoDisplayStatus, setLogoDisplayStatus } from './lib/dealCustomizationSlice';
import { greySidebarContrastText } from '../../core/utils/greyContrastText';

const DealCustomizationBannerSection = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const process = useAppSelector(selectProcess);
  const organizationId = useAppSelector(selectOrganizationId);
  const userId = useAppSelector(selectUserId);
  const [loading, setLoading] = useState(false);
  const [tempFile, setTempFile] = useState<File | undefined>();
  const displayLogo = useAppSelector(selectLogoDisplayStatus);

  const {
    onBackgroundDisplayUpdate,
    display: displayBackground,
    backgroundStorageType,
    url,
    background,
    onTempBackgroundDataUpdate,
    onBackgroundDataUpdate,
    backgroundType,
    onBackgroundTypeUpdate,
  } = useProcessBackground();
  const [backgroundtab, setBackgroundTab] = useState<EProcessBackgroundType>(process?.styles?.type ?? EProcessBackgroundType.IMG);

  const onNewBackgroundSelect = (
    backgroundStorage: EProcessBackgroundStorageType,
    type: EProcessBackgroundType,
    localId?: number,
    newUrl?: string,
    display? : boolean,
  ) => {
    onBackgroundDataUpdate({
      background: localId ?? background,
      backgroundType: type,
      backgroundStorageType: backgroundStorage,
      backgroundDisplayStatus: display ?? displayBackground,
      url: newUrl,
    });
  };

  const onUrlClear = () => {
    onBackgroundDataUpdate({
      background,
      backgroundType,
      backgroundStorageType,
      backgroundDisplayStatus: displayBackground,
      url: '',
    });
  };

  const onCustomeBackgroundUpdate = async (file: File): Promise<void> => {
    setTempFile(file);
  };

  const handleTabChange = (val: EProcessBackgroundType) => {
    onBackgroundTypeUpdate(val);
    setBackgroundTab(val);
  };

  const handleLogoDisplayUpdate = (val: boolean) => {
    dispatch(setLogoDisplayStatus(val));
  };

  const onCustomeBackgroundUpload = async (file: File): Promise<void> => {
    try {
      setLoading(true);
      const updatedProcess = await dispatch(uploadBackground({
        file,
        processId: process?.id,
        userId: userId || '',
        organizationId,
      })).unwrap();
      setTempFile(undefined);
      setLoading(false);
      onTempBackgroundDataUpdate();
      onBackgroundDataUpdate({
        url: updatedProcess.styles.url,
        backgroundDisplayStatus: true,
      });
      if (file?.size) {
        onBackgroundTypeUpdate(EProcessBackgroundType.IMG);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const onAWSBackgroundSelect = () => {
    onNewBackgroundSelect(
      EProcessBackgroundStorageType.AWS,
      EProcessBackgroundType.IMG,
      background,
      url,
      true,
    );
  };

  return (
    <>
      <Box
        sx={{
          borderRadius: '16px',
          border: '1px solid',
          borderColor: (theme) => greySidebarContrastText(theme, 0.2),
          backgroundColor: (theme) => greySidebarContrastText(theme, 0.1),
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '12px',
            padding: '16px',
            alignItems: 'center',
          }}
        >
          <AppSwitch
            checked={displayBackground}
            onChange={() => onBackgroundDisplayUpdate(!displayBackground)}
          />
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: '26px',
              color: (theme) => greySidebarContrastText(theme, 1),
            }}
          >
            Show banner
          </Typography>
        </Box>
        <Collapse
          in={displayBackground}
          sx={{
            '& .MuiCollapse-wrapperInner': {
              padding: '16px',
              background: 'transparent',
              borderTop: '1px solid ',
              borderBottom: '1px solid ',
              borderColor: (theme) => greySidebarContrastText(theme, 0.2),
            },
          }}
        >
          <Box>
            <AppTabs
              options={[
                { title: 'Image', value: EProcessBackgroundType.IMG },
                { title: 'Color', value: EProcessBackgroundType.COLOR },
              ]}
              value={backgroundtab}
              onChange={(option) => {
                handleTabChange(option.value);
              }}
              variant="boxed"
              color="white"
              // currentColor={currentColor}
              sx={{
                border: '4px solid',
                borderColor: (theme) => greySidebarContrastText(theme, 0),
                marginBottom: '16px',
                background: 'rgba(255, 255, 255, 0.4) !important',
                '& button': {
                  '&.active': {
                    background: '#fff !important',
                  },
                },
                '.inactive:not(.disabled)': {
                  color: 'black !important',
                  '& path': {
                    stroke: 'black !important',
                  },
                  '&:hover': {
                    color: '#0073f5 !important',
                    '& path': {
                      stroke: '#0073f5 !important',
                    },
                  },
                  '&:focus': {
                    color: '#0073f5 !important',
                    '& path': {
                      stroke: '#0073f5 !important',
                    },
                  },
                },
                '.active:not(.disabled)': {
                  '&.variant-boxed': {
                    '&.color': {
                      '&-white': {
                        color: 'black !important',
                        '& path': {
                          stroke: 'black !important',
                        },
                      },
                    },
                  },
                },
              }}
            />
          </Box>
          <TabContext
            value={backgroundtab}
          >
            <TabPanel
              value={EProcessBackgroundType.IMG}
              sx={{
                padding: '0px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                  gap: '8px',
                }}
              >
                {
        !!url
            && (
              <CustomeBackgroundPreview
                background={url}
                onClick={() => onAWSBackgroundSelect()}
                onDelete={onUrlClear}
                onNewUpload={onCustomeBackgroundUpdate}
                loading={loading}
              />
            )
        }
                {
        !url
        && (
          <CustomeBackgroundUpload
            isVisible={!url}
            onUpload={(files) => onCustomeBackgroundUpdate(files[0])}
            loading={loading}
          />
        )
        }
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 400,
                    lineHeight: '16px',
                    color: (theme) => greySidebarContrastText(theme, 1),
                  }}
                >
                  Ideal format is SVG file type with aspect ratio 1600х366px
                </Typography>
              </Box>
            </TabPanel>
            <TabPanel
              value={EProcessBackgroundType.COLOR}
              sx={{
                padding: '0px',
              }}
            >
              <ProcessBackgroudSolidColorSettings />
            </TabPanel>
          </TabContext>
        </Collapse>
        <Box
          sx={{
            display: 'flex',
            gap: '12px',
            padding: '16px',
            alignItems: 'center',
          }}
        >
          <AppSwitch
            checked={displayLogo}
            onChange={(_, checked) => handleLogoDisplayUpdate(checked)}
          />
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: '26px',
              color: (theme) => greySidebarContrastText(theme, 1),
            }}
          >
            Show logos
          </Typography>
        </Box>
      </Box>
      <Dialog
        open={!!tempFile && !loading}
        onClose={() => setTempFile(undefined)}
        keepMounted={false}
        PaperProps={{
          sx: {
            width: 'auto',
            maxHeight: '80vh',
            height: 'auto',
            padding: '24px',
          },
        }}
      >
        <ImageCrop
          image={tempFile}
          onCropAccept={onCustomeBackgroundUpload}
          onCancel={() => setTempFile(undefined)}
        />
      </Dialog>
    </>
  );
};

export default DealCustomizationBannerSection;
