import currencyToSymbolMap from 'currency-symbol-map/map';
import {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import classNames from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';
import {
  InputAdornment,
  MenuItemProps,
  TextFieldProps,
} from '@mui/material';
import { InputText } from '../../../features/LanguageSwitcher/LanguageSwitcherInputStyles';
import { ChevronDown20 } from '../../../icons/ChevronDown';
import { stagesetColors } from '../../../theme/stagesetPalette';
import {
  CurrencyAutocomplete,
  CurrencyInput,
  CurrencyItem,
  CurrencyLogo,
  CurrencyPaper,
} from './Styles/CurrencySelectorFullNameStyles';
import { useOrganizationSettingsNew } from '../../../hooks/useOrganizationSettingsNew';

type CurrencyProps = {
  label: string,
  startIcon: string,
  currencyCode: string,
};

// eslint-disable-next-line react/display-name
export const CurrencySelectorFullName = memo(() => {
  const currencyName = new Intl.DisplayNames(['en'], { type: 'currency' });
  const [inputCurrency, setInputCurrency] = useState('');
  const [currencyObject, setCurrencyObject] = useState<CurrencyProps | null>(null);

  const { teamManager } = useOrganizationSettingsNew();

  const { control, getValues } = useFormContext();

  const currencySelection = useMemo(() => Object.entries(currencyToSymbolMap).map((item) => ({
    label: currencyName.of(item[0]),
    startIcon: item[1],
    currencyCode: item[0],
  })), []);

  useEffect(() => {
    const initialCurrency = getValues('teamCurrency');
    const initialCurrencyObj = currencySelection.find((currencyObj) => currencyObj.currencyCode === initialCurrency);
    setCurrencyObject(initialCurrencyObj as CurrencyProps);
  }, [currencySelection]);

  const CustomPaper = useCallback(
    (props) => (
      <CurrencyPaper
        {...props}
      />
    ),
    [],
  );

  return (
    <Controller
      name="teamCurrency"
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        const valueToObject = currencySelection.find((currencyObj) => currencyObj.currencyCode === value);
        return (
          <CurrencyAutocomplete
            disabled={!teamManager}
            options={currencySelection}
            value={valueToObject}
            onChange={(event: any, newValue: CurrencyProps | null) => {
              if (newValue?.currencyCode) {
                setCurrencyObject(newValue);
                onChange(newValue?.currencyCode);
              }
            }}
            disableClearable
            popupIcon={<ChevronDown20 />}
            inputValue={inputCurrency}
            onInputChange={(event: any, newInputValue: string) => {
              setInputCurrency(newInputValue);
            }}
            PaperComponent={CustomPaper}
            renderOption={(props: MenuItemProps, option: CurrencyProps) => (
              <CurrencyItem
                className={classNames('organization-tab-currency-item')}
                key={option.label}
                value={option.currencyCode}
                {...props}
              >
                <CurrencyLogo>
                  {option.startIcon}
                </CurrencyLogo>
                <InputText>
                  {option.label}
                </InputText>
              </CurrencyItem>
            )}
            renderInput={(params: TextFieldProps) => (
              <CurrencyInput
                {...params}
                helperText={error ? error.message : null}
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <CurrencyLogo
                        className="currency-input-logo"
                      >
                        {currencyObject?.startIcon}
                      </CurrencyLogo>
                    </InputAdornment>
                  ),
                }}
                label="Currency"
                className={classNames(
                  'size-l',
                )}
                fullWidth
                disabled={false}
              />
            )}
            sx={{
              '& svg': {
                background: 'transparent',
                '& path': {
                  stroke: stagesetColors.newGrey[800],
                },
              },
            }}
          />
        );
      }}
    />
  );
});
