import {
  ColorResult, ChromePicker, Color, RGBColor,
} from 'react-color';
import { Box } from '@mui/material';
import { useState } from 'react';

interface Props {
  startingColor: Color;
  onColorConfirm: (nweColor: string | RGBColor) => void;
  onColorChange?: (newColor: string | RGBColor) => void;
}

const BackgroundColorPicker = ({
  startingColor,
  onColorConfirm,
  onColorChange,
}: Props): JSX.Element => {
  const [pickerColor, setPickerColor] = useState<Color>(startingColor);

  const handleColorChange = (newColor: ColorResult) => {
    setPickerColor(newColor.rgb.a === 1 ? newColor.hex : newColor.rgb);
    if (onColorChange) {
      onColorChange(newColor.rgb.a === 1 ? newColor.hex : newColor.rgb);
    }
  };

  const onColorChangeConfirm = (newColor: ColorResult) => {
    onColorConfirm(newColor.rgb.a === 1 ? newColor.hex : newColor.rgb);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        gap: '8px',
        '& .chrome-picker': {
          background: 'transparent !important',
          '& :first-child': {
            borderRadius: '12px',
          },
        },
      }}
    >
      <ChromePicker
        color={pickerColor}
        onChange={handleColorChange}
        onChangeComplete={onColorChangeConfirm}
        disableAlpha
      />
    </Box>
  );
};

export default BackgroundColorPicker;
