import {
  MenuItem, TextField, TextFieldProps, Typography,
} from '@mui/material';
import { ChangeEvent } from 'react';
import { isMobile } from 'react-device-detect';
import deepmerge from 'deepmerge';
import { EOrganizationRoles, RoleDTO } from '../../../../../shared/permissions';
import { OrganizationUserDTO } from '../../../../../shared/UserDTO';
import { updateOrganizationUser } from '../../../../core/store/appState/appState';
import { useAppDispatch } from '../../../../hooks/stateHooks';

interface Props {
  value: string;
  roleNames: {
    [key in EOrganizationRoles | string]: string
  };
  roles: RoleDTO[];
  user: OrganizationUserDTO;
  organizationOwner?: OrganizationUserDTO;
  teamManager: boolean;
  textFieldProps?: TextFieldProps,
  markAsLink?: boolean
}

const OrganizationUsersRoleSelector = ({
  value,
  roleNames,
  roles,
  user,
  organizationOwner,
  teamManager,
  textFieldProps,
  markAsLink = true,
}: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const textProps = deepmerge<TextFieldProps>({
    sx: {
      '& .MuiSelect-iconStandard': {
        display: 'none !important',
      },
      '&:hover': {
        '& .MuiSelect-iconStandard': {
          display: 'flex',
        },
      },
      '& .MuiSelect-select': {
        padding: '0px !important',
      },
    },
    variant: 'standard',
  }, textFieldProps || {});

  const onRoleSelect = (event: ChangeEvent) => {
    if (teamManager) {
      dispatch(updateOrganizationUser({ ...user, rolesIds: [event.target.value] }));
    }
  };

  const isOwner: boolean = organizationOwner?.id === user.id;

  const currentRole: RoleDTO | undefined = roles.find((role) => role.id === value);

  return (
    <>
      {!isOwner
        && teamManager
        && (
          <TextField
            id="Option-Select"
            fullWidth
            select
            color="secondary"
            value={value}
            onChange={(e: ChangeEvent) => onRoleSelect(e)}
            InputProps={{
              disableUnderline: true,
              sx: {
                color: markAsLink ? (theme) => theme.palette.primary.main : 'black',
                fontSize: '16px',
                background: 'transparent !important',
              },
            }}
            SelectProps={{
              MenuProps: {
                autoFocus: false,
              },
            }}
            {...textProps}
          >
            {
              roles.map((role: RoleDTO) => {
                if (role.name !== EOrganizationRoles.OWNER) {
                  return (
                    <MenuItem key={role.id} value={role.id} sx={{ fontSize: '14px', background: 'transparent !important' }}>
                      {roleNames[role.name]}
                    </MenuItem>
                  );
                }
              })
            }
          </TextField>
        )}
      {
        !isOwner
        && !teamManager
        && (
          <Typography sx={{
            color: markAsLink ? (theme) => theme.palette.primary.main : 'black',
            fontSize: '16px',
          }}
          >
            {
              currentRole ? roleNames[currentRole.name] : ''
            }
          </Typography>
        )
      }
      {
        isOwner
        && (
          <Typography sx={{
            color: markAsLink ? (theme) => theme.palette.primary.main : 'black',
          }}
          >
            {roleNames[EOrganizationRoles.OWNER]}
          </Typography>
        )
      }
    </>
  );
};

export default OrganizationUsersRoleSelector;
