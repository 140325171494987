import { Box, Typography } from '@mui/material';
import { GridRowsProp } from '@mui/x-data-grid';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useSnackbar } from 'notistack';
import { Close } from '@mui/icons-material';
import { EFieldType, ProcessStageDTO } from '../../../shared/Field/Field';
import ConfirmationDialog from '../../common/ConfirmationDialog/ConfirmationDialog';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import { DeleTeIcon } from '../../icons/ConfirmationDialogIcons/ConfirmationDialogIcons';
import DeleteSectionSvg from '../../icons/SectionMenuIcons/Delete';
import {
  ETemplatesTypes, resetSelectedTemplateData, selectLibraryFilter, selectLibraryTab, selectLibraryTemplateData, setSelectedTemplateData,
} from '../../pages/templates/lib/templatesSlice';
import { StyledDealListDataGrid } from '../../routes-old/deals/DealPageStyles/DealsListStyle';
import { formateValueForLastActive } from '../../routes-old/process/helpers';
import MenuWithButton from '../../shared/MenuWithButton/MenuWithButton';
import MobileTable from '../../shared/MobileTable/MobileTable';
import { stagesetPalette } from '../../theme/stagesetPalette';
import { ExtendedGridColumns } from '../../types/mobile-table';
import {
  useCreateTemplateFromStageMutation,
  useDeleteStageMutation,
  useGetStagesTemplatesByOrgIdQuery,
} from '../ProcessFields/lib/processFieldsApi';
import { CopyTemplateIcon20px } from '../../icons/CopyTemplateIcons';
import { selectProcessId } from '../../routes-old/process/state/processSlice';
import useModalWindow from '../../hooks/useModalWindow';
import { FormattedLabel } from '../../shared/AssigneeSelection/FormattedLabel';

enum EStageTemplatesColumns {
  name = 'name',
  menu = 'menu',
}

const StagesTemplatesList = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const processId = useAppSelector(selectProcessId);
  const organizationId: string = useAppSelector((state) => state.app.organization.id);
  const filter: string = useAppSelector(selectLibraryFilter);
  const selectedTemplateData = useAppSelector(selectLibraryTemplateData);
  const currentTab = useAppSelector(selectLibraryTab);
  const [copyTemplate] = useCreateTemplateFromStageMutation();
  const { setTemplateModalWindowState, onModalWindowStateReset } = useModalWindow();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { data: stageTemplates, isSuccess } = useGetStagesTemplatesByOrgIdQuery({ organizationId, filter });

  const [deleteStage] = useDeleteStageMutation();

  const [templateToDelete, setTemplateToDelete] = useState<ProcessStageDTO | null>(null);

  useEffect(() => {
    if (
      stageTemplates?.length
      && selectedTemplateData?.type !== EFieldType.STAGE
      && currentTab === ETemplatesTypes.STAGES
      && !isMobile
    ) {
      dispatch(setSelectedTemplateData({
        id: stageTemplates[0].id,
        title: stageTemplates[0].title ?? '',
        type: EFieldType.STAGE,
      }));
    }
    if (
      !stageTemplates?.length
      && currentTab === ETemplatesTypes.STAGES
      && isSuccess
    ) {
      dispatch(resetSelectedTemplateData());
    }
  }, [stageTemplates, currentTab, selectedTemplateData?.type, isMobile, isSuccess]);

  const onStageDelete = (stageId: string) => {
    deleteStage({
      stageId,
      processId: organizationId,
    });
  };

  const onDeleteDialogAccept = (choice: boolean) => {
    if (choice && !!templateToDelete) {
      onStageDelete(templateToDelete.id);
      if (
        selectedTemplateData?.id === templateToDelete.id
      ) {
        const newTemplate = stageTemplates?.find((template) => template.id !== templateToDelete.id);
        if (newTemplate) {
          dispatch(setSelectedTemplateData({
            id: newTemplate.id,
            title: newTemplate.title ?? '',
            type: EFieldType.STAGE,
          }));
        }
      }
      setTemplateToDelete(null);
    } else {
      setTemplateToDelete(null);
    }
  };

  const onTemplateSelect = (id: string, title: string) => {
    dispatch(setSelectedTemplateData({ id, title, type: EFieldType.STAGE }));
  };

  const onTemplateCopy = async (data: ProcessStageDTO) => {
    try {
      setTemplateModalWindowState();
      const newTemplate = await copyTemplate({
        id: data.id,
        processId,
      }).unwrap();
      if (newTemplate) {
        const key = `create-template-snackbar-${Date.now()}`;
        enqueueSnackbar(
          'Template has been created',
          {
            key,
            variant: 'success',
            autoHideDuration: 1500,
            action: (
              <Box
                onClick={() => closeSnackbar(key)}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Close />
              </Box>
            ),
          },
        );
        dispatch(setSelectedTemplateData({
          id: newTemplate.id,
          title: newTemplate.title ?? '',
          type: EFieldType.STAGE,
        }));
      }
    } catch (e) {
      console.log(e);
    } finally {
      onModalWindowStateReset();
    }
  };

  const columns: ExtendedGridColumns = [
    {
      field: EStageTemplatesColumns.name,
      sortable: true,
      headerName: isMobile ? 'Last updated' : 'Name',
      align: 'left',
      cellClassName: 'pro-grid-cell',
      flex: 1,
      renderCell: (
        {
          value,
        },
      ) => (
        <Box
          className="RowLink"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            paddingLeft: '20px',
          }}
        >
          <FormattedLabel
            label={value.title}
            len={24}
            tooltip
            placement="right"
            sx={{
              fontSize: '16px',
              lineHeight: '24px',
              color: (theme) => theme.palette.grey[600],
            }}
          />
          <Typography
            sx={{
              color: (theme) => theme.palette.grey[400],
              fontSize: '12px',
            }}
          >
            {
              `Updated ${formateValueForLastActive(DateTime.fromISO(value?.lastUpdated).toMillis() as number)}`
            }
          </Typography>
        </Box>
      ),
      renderMobileCell: ({ row, value }) => (
        <Typography
          sx={{
            color: (theme) => theme.palette.grey[400],
            fontSize: '12px',
          }}
        >
          {
            `${formateValueForLastActive(DateTime.fromISO(value?.lastUpdated).toMillis() as number)}`
          }
        </Typography>
      ),
    },
    {
      field: EStageTemplatesColumns.menu,
      sortable: false,
      headerName: '',
      align: 'right',
      cellClassName: 'pro-grid-cell menu-cell',
      width: 70,
      maxWidth: 70,
      renderCell: (
        {
          value: template,
        },
      ) => (
        <MenuWithButton
          MenuItems={
            [
              {
                label: 'Duplicate',
                startIcon: (
                  <Box sx={{ width: '20px', height: '20px' }}>
                    <CopyTemplateIcon20px />
                  </Box>
                ),
                onClick: () => onTemplateCopy(template),
              },
              {
                label: '',
                divider: true,
              },
              {
                label: 'Delete',
                startIcon: (
                  <Box sx={{ width: '20px', height: '20px' }}>
                    <DeleteSectionSvg />
                  </Box>
                ),
                onClick: () => setTemplateToDelete(template),
              },
            ]
          }
        />
      ),
    },
  ];

  const rows: GridRowsProp = stageTemplates?.map((template) => ({
    id: template.id,
    title: template.title,
    [EStageTemplatesColumns.name]: {
      title: template.title,
      lastUpdated: template.updatedAt,
    },
    [EStageTemplatesColumns.menu]: template,
  })) ?? [];

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          height: 'calc( 100% )',
          maxHeight: 'calc( 100% )',
          '& .menu-cell': {
            visibility: 'hidden',
            justifyContent: 'flex-start',
          },
          '& .MuiDataGrid-row:hover .menu-cell': {
            visibility: 'visible',
          },
        }}
      >
        {
          isMobile
            ? (
              <Box
                sx={{
                  overflow: 'auto',
                  height: 'calc( 100% )',
                  maxHeight: 'calc( 100% )',
                }}
              >
                <MobileTable
                  onRowClick={(row) => onTemplateSelect(row.id, row.title)}
                  getId={(row) => row.id}
                  renderTitle={(row) => row.title}
                  renderMenu={(row) => {
                    const value = row[EStageTemplatesColumns.menu];
                    return (
                      <MenuWithButton
                        MenuItems={
                          [
                            {
                              label: 'Duplicate',
                              startIcon: (
                                <Box sx={{ width: '20px', height: '20px' }}>
                                  <CopyTemplateIcon20px />
                                </Box>
                              ),
                              labelColor: '#455273',
                              onClick: () => onTemplateCopy(value),
                            },
                            {
                              label: '',
                              divider: true,
                            },
                            {
                              label: 'Delete',
                              startIcon: (
                                <Box sx={{ width: '20px', height: '20px' }}>
                                  <DeleteSectionSvg />
                                </Box>
                              ),
                              labelColor: stagesetPalette?.error[600],
                              onClick: () => setTemplateToDelete(value),
                            },
                          ]
                        }
                      />
                    );
                  }}
                  rows={rows}
                  columns={columns}
                />
              </Box>
            )
            : (
              <StyledDealListDataGrid
                className="hiddenHeader"
                disableSelectionOnClick
                disableColumnMenu
                hideFooter
                columns={columns}
                rows={rows}
                rowHeight={74}
                headerHeight={-4}
                getRowSpacing={() => ({
                  top: 4,
                  bottom: 4,
                })}
                onRowClick={(rowParams) => onTemplateSelect(rowParams.id as string, rowParams.row.title)}
                getRowClassName={(params) => (params.row.id === selectedTemplateData?.id ? 'SelectedRow' : '')}
                sx={{
                  '& .MuiDataGrid-virtualScroller': {
                    '&::-webkit-scrollbar': {
                      width: '0px',
                    },
                    '&::-webkit-scrollbar-track': {
                      width: '0px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                      width: '0px',
                    },
                  },
                }}
              />
            )
        }
      </Box>
      <ConfirmationDialog
        icon={<DeleTeIcon />}
        titleVariant="danger-primary"
        isDialogOpen={!!templateToDelete}
        onConfirm={() => onDeleteDialogAccept(true)}
        onCancel={() => onDeleteDialogAccept(false)}
        dialogContentText={(
          <Box>
            Are you sure you want to delete template?
          </Box>
        )}
        dialogTitle={templateToDelete?.title ?? 'Delete section template'}
        confirmText="Delete"
      />
    </>
  );
};

export default StagesTemplatesList;
