import { BubbleMenu } from '@tiptap/react';
import TiptapButtonNodeToolbarItems from './TiptapButtonNodeToolbarItems';

const TiptapButtonToolbar = (): JSX.Element | null => (
  <BubbleMenu
    shouldShow={
        ({ editor: e }) => !document.querySelector('.TiptapBlockMenu-open')
          && e.isEditable
          && e?.isActive('buttonNode')
      }
  >
    <TiptapButtonNodeToolbarItems />
  </BubbleMenu>
);

export default TiptapButtonToolbar;
