import { Box, SvgIcon } from '@mui/material';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { memo } from 'react';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import DNDActiveIcon from '../../icons/DndIcons/DNDActive.svg';
import StagesSidebarItem from './StagesSidebarItem';
import { useAppSelector } from '../../hooks/stateHooks';
import { selectLayouEditingStatus } from '../../routes-old/process/state/processSlice';
import { greySidebarContrastText } from '../../core/utils/greyContrastText';
import { DnDIcon16 } from '../Tiptap/icons/DnDIcon';
import { useStages } from '../../hooks/useStages';
import useDealPermissions from '../../hooks/useDealPermissions';
import { EUserDealPermissions, EUserOrganizationPermissions } from '../../../shared/permissions';
import { AppSkeleton } from '../../shared/AppSkeleton/AppSkeleton';

interface Props {
  id: string;
}

// eslint-disable-next-line react/display-name
const StagesSidebarItemWrapper = memo(({
  id,
}: Props): JSX.Element | null => {
  const isLayoutEditMode = useAppSelector(selectLayouEditingStatus);
  const [checkPermissions] = useDealPermissions();
  const isLayoutEditable = checkPermissions(
    EUserDealPermissions.DEAL_LAYOUT_EDIT,
    EUserOrganizationPermissions.ORGANIZATION_DEALS_EDIT_ALL,
  );
  const { stage } = useStages(id);

  const {
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable(
    {
      id,
      disabled: !isLayoutEditMode || isMobile,
    },
  );

  if (!stage) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '12px',
          padding: '13px 24px',
          minHeight: '52px',
          alignItems: 'center',
        }}
      >
        <AppSkeleton
          animation="wave"
          variant="rectangular"
          width="20px"
          height="20px"
          sx={{
            border: '1px solid',
            borderColor: (theme) => greySidebarContrastText(theme, 0.15),
            backgroundColor: (theme) => greySidebarContrastText(theme, 0.1),
            borderRadius: '6px',
            opacity: 1,
          }}
        />
        <AppSkeleton
          animation="wave"
          variant="rectangular"
          width="100%"
          height="100%"
          sx={{
            border: '1px solid',
            borderColor: (theme) => greySidebarContrastText(theme, 0.15),
            backgroundColor: (theme) => greySidebarContrastText(theme, 0.1),
            borderRadius: '22px',
            opacity: 1,
          }}
        />
      </Box>
    );
  }

  if (!isLayoutEditable && stage?.hide) {
    return null;
  }

  return (
    <Box
      sx={{
        cursor: isDragging && isLayoutEditMode ? 'grab' : 'initial',
        opacity: isDragging ? 0.5 : 1,
        transform: CSS.Transform.toString(transform),
        transition,
        position: 'relative',
        '&:hover .task-drag-indicator--active': {
          opacity: 1,
          cursor: 'grab',
        },
      }}
      ref={setNodeRef}
      {...listeners}
    >
      <Box
        className={classNames('task-drag-indicator', { 'task-drag-indicator--active': isLayoutEditMode })}
        sx={{
          position: 'absolute',
          left: 3,
          top: '17px',
          cursor: 'initial',
          opacity: 0,
          transition: 'opacity 0.2s ease-in-out',
        }}
        {...listeners}
      >
        {
          isDragging
            ? (
              <SvgIcon
                component={DNDActiveIcon}
                sx={{
                  color: (theme) => greySidebarContrastText(theme, 0.8),
                  stroke: (theme) => greySidebarContrastText(theme, 0.8),
                  '& path': {
                    stroke: 'inherit',
                  },
                }}
              />
            )
            : (
              <SvgIcon
                component={DnDIcon16}
                sx={{
                  color: (theme) => greySidebarContrastText(theme, 0.8),
                  stroke: (theme) => greySidebarContrastText(theme, 0.8),
                  '& path': {
                    stroke: 'inherit',
                  },
                }}
              />
            )
        }
      </Box>
      <StagesSidebarItem id={stage?.id} />
    </Box>
  );
});

export default StagesSidebarItemWrapper;
