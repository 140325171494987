import { useMemo } from 'react';
import { ThemeProvider } from '@mui/system';
import { useLocation } from 'react-router-dom';
import { EAppRoutes } from '../../core/router';
import { EProcessType } from '../../../shared/process/ProcessMilestoneActionDTO';
import { useAppSelector } from '../../hooks/stateHooks';
import useDealsSlice from '../../hooks/useDealsSlice';
import OwnerCardPopover from '../OwnerCard/OwnerCardPopover';
import EditLayoutToggleButtons from '../Layout/EditLayoutToggleButtons';
import CreateNewTemplateBtn from '../../pages/templates/CreateNewTemplateBtn';
import useDealPermissions from '../../hooks/useDealPermissions';
import { EUserDealPermissions, EUserOrganizationPermissions } from '../../../shared/permissions';
import { selectIsTemplateEditMode, selectLibraryTemplateData } from '../../pages/templates/lib/templatesSlice';
import { useDealTheme } from '../../hooks/useDealTheme';
import MobileBottomMenuAddButton from './MobileBottomMenuAddButton';
import MobileBottomMenuBurgerButton from './MobileBottomMenuBurgerButton';
import {
  EBottomDrawerTabs,
  ESettingsTabs,
  selectCurrentMenuTab,
  selectSettingsTab,
  selectStagesDrawerStatus,
} from '../Layout/lib/fieldsLayoutSlice';
import OrganizationUserInvite
  from '../../widgets/OrganizationInfoPanel/OrganizationInfoPanelLowerSection/OrganizationInfoUserInvite/OrganizationUserInvite';
import { ButtonPlaceholderLarge, MobileBottomMenuAppbar } from './Styles/MobileBottomMenuStyles';
import { AppIconButton } from '../../shared/AppButton/AppIconButton';
import { MoreVertical20 } from '../../icons/MoreVertical';
import { useMobileBottomDrawer } from '../../hooks/useMobileBottomDrawer';
import TemplateEditButton from './TemplatePreviewButtons/TemplateEditButton';
import { EFieldType } from '../../../shared/Field/Field';
import TemplateCloseButton from './TemplatePreviewButtons/TemplateCloseButton';
import { EJWTTokenType } from '../../../shared/schema/token.schema';
import BackToHomeButton from '../../shared/Buttons/BackToHomeButton';
import MobileBottomMenuLibraryAddButton from './MobileBottomMenuLibraryAddButton';

const MobileBottomMenu = (): JSX.Element | null => {
  const { dealTheme } = useDealTheme();
  const { pathname } = useLocation();
  const checkPathName = (route: EAppRoutes) => pathname.includes(route);
  const isDrawerOpen = useAppSelector(selectStagesDrawerStatus);
  const { isDialogOpen } = useDealsSlice();
  const { openDrawer } = useMobileBottomDrawer();
  const [checkPermissions] = useDealPermissions();
  const isTemplateEditMode = useAppSelector(selectIsTemplateEditMode);
  const isLayoutEditable: boolean = checkPermissions(
    EUserDealPermissions.DEAL_LAYOUT_EDIT,
    EUserOrganizationPermissions.ORGANIZATION_DEALS_EDIT_ALL,
  );
  const currentTab = useAppSelector(selectCurrentMenuTab);
  const processType: EProcessType = useAppSelector((state) => state.process?.process?.type);
  const settingsTab = useAppSelector(selectSettingsTab);
  const selectedTemplateData = useAppSelector(selectLibraryTemplateData);
  const tokenType = useAppSelector((state) => state.auth.tokenType);
  const registeredUser = tokenType !== EJWTTokenType.SHARE && tokenType !== EJWTTokenType.GUEST_AUTH;
  const process = useAppSelector((state) => state.process.process);

  const mobileButtonLayouts = useMemo((): React.JSX.Element | undefined => {
    if (checkPathName(EAppRoutes.deal) && isLayoutEditable && process) {
      return (
        <>
          <AppIconButton
            icon={<MoreVertical20 />}
            disableRipple
            size="l"
            onClick={() => openDrawer(EBottomDrawerTabs.ROOM_GENERAL_MENU)}
          />
          <EditLayoutToggleButtons
            disableText
            variant={processType === EProcessType.TEMPLATE ? 'reverse' : 'regular'}
          />
          <MobileBottomMenuBurgerButton
            themed
          />
        </>
      );
    }
    if (checkPathName(EAppRoutes.deal) && !isLayoutEditable && registeredUser) {
      return (
        <>
          <BackToHomeButton
            templateMenu
          />
          <OwnerCardPopover />
          <MobileBottomMenuBurgerButton
            themed
          />
        </>
      );
    }
    if (checkPathName(EAppRoutes.deal) && !isLayoutEditable) {
      return (
        <>
          <OwnerCardPopover />
          <MobileBottomMenuBurgerButton
            themed
          />
        </>
      );
    }
    if (checkPathName(EAppRoutes.home) && !isDialogOpen) {
      return (
        <>
          <ButtonPlaceholderLarge />
          <MobileBottomMenuAddButton />
          <MobileBottomMenuBurgerButton />
        </>
      );
    }
    if (checkPathName(EAppRoutes.templates)) {
      switch (selectedTemplateData?.type) {
        case EFieldType.SECTION:
          return (
            <>
              <TemplateCloseButton />
              <TemplateEditButton />
            </>
          );
        case EFieldType.STAGE:
          return (
            <>
              <TemplateCloseButton />
              <TemplateEditButton />
            </>
          );
        case EProcessType.TEMPLATE:
          return (
            <>
              <TemplateCloseButton />
              <MobileBottomMenuBurgerButton
                themed
              />
              <TemplateEditButton />
            </>
          );
        default:
          return (
            <>
              <ButtonPlaceholderLarge />
              <CreateNewTemplateBtn />
              <MobileBottomMenuBurgerButton />
            </>
          );
      }
    }
    if (checkPathName(EAppRoutes.templates) && !isTemplateEditMode) {
      return (
        <>
          <ButtonPlaceholderLarge />
          <CreateNewTemplateBtn />
          <MobileBottomMenuBurgerButton />
        </>
      );
    }
    if (checkPathName(EAppRoutes.settings) && settingsTab === ESettingsTabs.SETTINGS_TEAM) {
      return (
        <>
          <ButtonPlaceholderLarge />
          <OrganizationUserInvite />
          <MobileBottomMenuBurgerButton />
        </>
      );
    }
    if (checkPathName(EAppRoutes.library)) {
      return (
        <>
          <ButtonPlaceholderLarge />
          <MobileBottomMenuLibraryAddButton />
          <MobileBottomMenuBurgerButton />
        </>
      );
    }
    if (!checkPathName(EAppRoutes.deal)) {
      return (
        <>
          <ButtonPlaceholderLarge />
          <ButtonPlaceholderLarge />
          <MobileBottomMenuBurgerButton />
        </>
      );
    } return undefined;
  }, [
    isLayoutEditable,
    isTemplateEditMode,
    currentTab,
    isDialogOpen,
    isDrawerOpen,
    settingsTab,
    selectedTemplateData,
  ]);

  return (
    <ThemeProvider
      theme={dealTheme}
    >
      <MobileBottomMenuAppbar>
        {mobileButtonLayouts}
      </MobileBottomMenuAppbar>
    </ThemeProvider>
  );
};

export default MobileBottomMenu;
