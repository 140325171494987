import classNames from 'classnames';
import { AppBaseButton, AppBaseButtonProps } from './AppBaseButton';

type AppLinkButtonSize = 'xs' | 's' | 'm' | 'l';
type AppLinkButtonVariant = 'lite-primary' | 'lite-secondary' | 'lite-danger';

export type AppLinkButtonProps = {
  size?: AppLinkButtonSize,
  variant?: AppLinkButtonVariant,
} & Omit<AppBaseButtonProps, 'size' | 'variant'>;

export const AppLinkButton = ({
  className,
  variant = 'lite-primary',
  ...buttonProps
}: AppLinkButtonProps) => (
  <AppBaseButton {...buttonProps} className={classNames('link-button', className, variant)} />
);
