import { Box } from '@mui/system';
import { useMemo } from 'react';
import { FilesDragAndDrop } from '../../common/FilesDragAndDrop/FilesDragAndDrop';
import { MediaFieldUploadIcon } from '../../icons/DndIcons/DnDIcons';
import { AppLoader } from '../../shared/AppLoader/AppLoader';

interface Props {
  isVisible: boolean;
  onUpload: (files: File[]) => void;
  loading?: boolean;
}

const CustomeBackgroundUpload = ({
  isVisible,
  onUpload,
  loading,
}: Props): JSX.Element => {
  const dndText = useMemo(() => (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '4px',
        color: '#0073F5',
        '& path': {
          stroke: '#0073F5',
        },
      }}
    >
      {
        loading ? (
          <AppLoader size={30} />
        ) : (
          <>
            <MediaFieldUploadIcon />
            Upload
          </>
        )
    }
    </Box>
  ), [loading]);

  const handleFileUpload = (files: File[]) => {
    if (files?.length) {
      onUpload(files);
    }
  };

  return (
    <Box
      sx={{
        width: 'calc( 100% )',
        borderRadius: '12px',
        visibility: !isVisible ? 'hidden' : 'visible',
      }}
    >
      <FilesDragAndDrop
        onFileDrop={handleFileUpload}
        border="1px dashed #C8D0E0"
        dropzoneOptions={{
          accept: 'image/*',
        }}
        text={dndText}
        styles={{
          borderRadius: '12px',
          padding: '0px',
          background: 'white',
        }}
        textStyle={{
          alignSelf: 'center',
          fontSize: '14px',
          paddingLeft: '3px',
        }}
        textContainerStyles={{
          background: 'white',
        }}
        icon="none"
      />
    </Box>
  );
};

export default CustomeBackgroundUpload;
