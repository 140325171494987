import { Box } from '@mui/material';
import { useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { isMobile } from 'react-device-detect';
import MediaResizePlugin from './MediaResizePlugin';
import IFrameCover from '../../shared/IFrameCover/IFrameCover';
import useFrontEventsApi from '../../hooks/useFrontEventsApi';
import { StageContentType } from '../../../shared/Stage';
import { useCurrentStage } from '../../hooks/useCurrentStage';
import { selectIsToolbarNotShown } from '../../routes-old/auth/state/authState';
import { useAppSelector } from '../../hooks/stateHooks';
import { TiptapNodeUpdate, MediaNodeAttrs } from '../../../shared/Tiptap/TiptapTypes';

interface Props {
  url: string;
  width?: number;
  height?: number;
  onUpdate: (data: TiptapNodeUpdate<MediaNodeAttrs>) => void;
}

const MediaFieldVideoComponent = ({
  url,
  width: initialWidth,
  height: initialHeight,
  onUpdate,
}: Props): JSX.Element => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [width, setWidth] = useState(initialWidth);
  const [height, setHeight] = useState(initialHeight);
  const { createRichMediaInteraction } = useFrontEventsApi();
  const isToolbarNotShown = useAppSelector(selectIsToolbarNotShown);
  const { stageContentType } = useCurrentStage();
  const wideScreenStage = stageContentType === StageContentType.WIDESCREENSTAGE;

  const renderHeight = () => {
    if (height) return height;
    if (wideScreenStage) {
      if (isMobile) return 'calc(100vh - 52px)';
      if (isToolbarNotShown) return '100vh';
      return 'calc(100vh - 48px)';
    }
    return '430px';
  };

  const [isWatched, setWatched] = useState(false);

  const handlePlay = () => {
    if (!isWatched) {
      setWatched(true);
      createRichMediaInteraction({
        linkUrl: url,
      });
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        userSelect: 'none',
        width: width ? 'auto' : '100%',
        maxWidth: 'calc( 100% )',
      }}
    >
      <IFrameCover
        width={width}
        height={height}
      />
      <Box
        ref={containerRef}
        sx={{
          display: 'flex',
          height: renderHeight(),
          width: width ?? '100%',
          maxWidth: 'calc( 100% )',
          '& iframe': {
            borderRadius: wideScreenStage ? '0px' : '10px',
          },
        }}
      >
        <ReactPlayer
          controls
          url={url}
          width={width ?? '100%'}
          height={height ?? '100%'}
          onPlay={handlePlay}
        />
      </Box>
      {
        containerRef?.current
        && !wideScreenStage
        && !isMobile
          && (
            <MediaResizePlugin
              containerRef={containerRef.current}
              width={width}
              height={height}
              onUpdate={onUpdate}
              onWidthUpdate={(newWidth) => setWidth(newWidth)}
              onHeightUpdate={(newHeight) => setHeight(newHeight)}
            />
          )
        }
    </Box>
  );
};

export default MediaFieldVideoComponent;
