export enum PricingPeriod {
  ONE_TIME = 'ONE_TIME',
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export const PRICING_PERIODS: { [key in PricingPeriod]: { displayText: string; valueText: string } } = {
  [PricingPeriod.ONE_TIME]: {
    displayText: 'One time',
    valueText: '',
  },
  [PricingPeriod.DAILY]: {
    displayText: 'Daily',
    valueText: '/day',
  },
  [PricingPeriod.MONTHLY]: {
    displayText: 'Monthly',
    valueText: '/month',
  },
  [PricingPeriod.YEARLY]: {
    displayText: 'Yearly',
    valueText: '/year',
  },
};

export interface PricingItemUnits {
  value: number;
  min: number;
  max: number;
}

export interface PricingItemDiscount {
  enabled: boolean;
  name: string;
  isPercentage: boolean;
  value: number | null;
}

export interface Tax {
  enabled: boolean;
  isPercentage: boolean;
  value: number | null;
}

export interface PricingItemUnit {
  enabled: boolean;
  value: number | null;
  min: number | null;
  max: number | null;
}

export interface PricingItem {
  isOptional: boolean,
  name: string;
  shortDescription: string;
  discount: PricingItemDiscount;
  price: number | null;
  units: PricingItemUnit;
  tax: Tax;
}

export interface PricingTableAttributes {
  period: PricingPeriod;
}

export const DEFAULT_PRICING_ITEM_DISCOUNT: PricingItemDiscount = {
  enabled: false,
  name: '',
  isPercentage: true,
  value: 0,
};

export const DEFAULT_PRICING_ITEM_TAX: Tax = {
  enabled: false,
  isPercentage: true,
  value: 0,
};

export const DEFAULT_PRICING_ITEM_UNITS: PricingItemUnit = {
  enabled: true,
  value: 0,
  min: 0,
  max: null,
};

export const DEFAULT_PRICING_ITEM: PricingItem = {
  isOptional: false,
  name: '',
  shortDescription: '',
  discount: DEFAULT_PRICING_ITEM_DISCOUNT,
  price: 0,
  units: DEFAULT_PRICING_ITEM_UNITS,
  tax: DEFAULT_PRICING_ITEM_TAX,
};

export const DEFAULT_PRICING_ATTRIBUTES: PricingTableAttributes = {
  period: PricingPeriod.ONE_TIME,
};
