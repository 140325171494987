import { isMobile } from 'react-device-detect';
import ReactPlayer from 'react-player';
import { SvgIcon } from '@mui/material';
import { EFieldsubType } from '../../../../shared/Field/Field';
import {
  AirtableSVGIcon,
  BehanceSvgIcon,
  CalendlySvgIcon,
  DropboxSVGIcon,
  FigmaSvgIcon,
  GoogleDocsSVGIcon,
  GoogleDriveSVGIcon,
  GoogleFormsSVGIcon,
  GoogleSlidesSvgIcon,
  GoogleXLSvgIcon,
  LoomSVGIcon,
  MSPPSvgIcon,
  MSWordSvgIcon,
  MSXLSvgIcon,
  PitchSvgIcon,
  PreziSVGIcon,
  TypeformSvgIcon,
  VidyardSVGIcon,
  VimeoSVGIcon,
  YouTubeSVGIcon,
} from '../../../icons/EmbedIcons/EmbedIcons';
import MediaBox24px from '../../../icons/MediaBox24px.svg';
import Globe24px from '../../../icons/Globe24px.svg';
import ChainLink24px from '../../../icons/ChainLink24px.svg';
import { extensionsToMIMEs } from './MediaFieldMimes';
import { ContentType } from '../../../../shared/Stage';

export interface SelectedSourceOption {
  description: string,
  source: string,
  name: string,
  icon?: JSX.Element,
  parentId: string,
  parentDepth: number,
  position: number,
  mediaId?: string,
  provider?: EMediaProvider,
  contentTag?: ContentType;
  newStage?: boolean;
  subType?: EFieldsubType;
}

export interface MediaSource {
  name: string;
  description: string;
  subType?: EFieldsubType;
  link: string;
  contentTag: ContentType;
  typeTag?: EMediaTypeTags;
  icon?: JSX.Element;
  provider?: EMediaProvider
}

export enum GoogleDocTypes {
  SPREADSHEET = 'spreadsheets',
  PRESENTATION = 'presentation',
  DOCUMENT = 'document',
}

// used for embed menu only
export enum EMediaTypeTags {
  VIDEO = 'Video',
  PRESENTATION = 'Presentations',
  SHEET = 'Spreadsheets',
  DOC = 'Documents',
  GOOGLE = 'Google docs',
  ONE_DRIVE = 'One Drive',
  SKETCH = 'Sketches',
  FORM = 'Forms',
  GALLERY = 'Gallery',
  FILE = 'Files',
  SCHEDULING = 'Scheduling',
}

export enum EMediaProvider {
  IFRAMELY,
}

export const iframeSourceOption = {
  name: 'Embed rich media',
  link: '',
  description: 'Show a link content in the iframe',
  subType: EFieldsubType.MIXED,
  contentTag: ContentType.IFRAME,
  provider: EMediaProvider.IFRAMELY,
  icon: <SvgIcon
    component={MediaBox24px}
    sx={{
      width: '20px',
      color: 'transparent',
    }}
  />,
};

export const iframeSourceNoContentOption = {
  name: 'Link preview',
  link: '',
  description: 'Show a link content in the iframe',
  subType: EFieldsubType.MIXED_URL,
  contentTag: ContentType.URL,
  provider: EMediaProvider.IFRAMELY,
  icon: <SvgIcon
    component={ChainLink24px}
    sx={{
      width: '20px',
      color: 'transparent',
    }}
  />,
};

export const iframeSiteOption = {
  name: 'Embed website',
  link: '',
  description: '',
  subType: EFieldsubType.IFRAME,
  contentTag: ContentType.IFRAME,
  icon: <SvgIcon
    component={Globe24px}
    sx={{
      width: '20px',
      color: 'transparent',
    }}
  />,
};

export const fileFormats: string[] = ['doc', 'docx', 'xlsx', 'xls', 'pptx', 'ppt', 'rtf'];
export const additionalFileFormats: string[] = ['pdf'];

export const imgFormats: string[] = ['jpg', 'jpeg', 'png', 'webp'];
export const additionalImgFormats: string[] = ['gif', 'tiff', 'svg'];

export const additionalTextFormats: string[] = ['txt'];

export const videoFormats: string[] = ['mp4'];

export const archiveFormats: string[] = ['zip'];

export const audioFormats: string[] = ['mp3', 'acc', 'ogg'];

export const allFormats: string[] = [
  ...imgFormats,
  ...additionalImgFormats,
  ...additionalTextFormats,
  ...fileFormats,
  ...additionalFileFormats,
  ...videoFormats,
  ...archiveFormats,
  ...audioFormats,
];

export const mediaFieldFormats: string[] = [
  ...imgFormats.slice(0, 4),
  ...additionalImgFormats.slice(0, 3),
  ...additionalTextFormats.slice(0, 1),
  ...fileFormats.slice(0, 7),
  ...additionalFileFormats.slice(0, 1),
  ...videoFormats.slice(0, 1),
];

export const mediaFieldMimes = (formatsArray: string[]) => {
  const array: string[] = [];
  formatsArray.forEach((ext) => Object.keys(extensionsToMIMEs).find((key) => {
    if (key === ext) array.push(extensionsToMIMEs[key]);
  }));
  return array.join(', ');
};

export const sourceHostMap: { [key:string]: ContentType } = {
  'm.youtube.com': ContentType.YOUTUBE,
  'youtube.com': ContentType.YOUTUBE,
  'www.youtube.com': ContentType.YOUTUBE,
  'youtu.be': ContentType.YOUTUBE,
  'vimeo.com': ContentType.VIMEO,
  // 'docs.google.com': ContentType.GOOGLE_DOCS,
  // 'drive.google.com': ContentType.GOOGLE_DOCS,
  // 'onedrive.live.com': ContentType.ONE_DRIVE_WORD,
  // '1drv.ms': ContentType.ONE_DRIVE_WORD,
  'behance.net': ContentType.BEHANCE,
  'figma.com': ContentType.FIGMA,
  // 'www.figma.com': ContentType.FIGMA,
  'pitch.com': ContentType.PITCH,
  'prezi.com': ContentType.PREZI,
  'loom.com': ContentType.LOOM,
  'dropbox.com': ContentType.DROPBOX,
  'typeform.com': ContentType.TYPEFORM,
  'airtable.com': ContentType.AIRTABLE,
  'share.vidyard.com': ContentType.VIDYARD,
  'calendly.com': ContentType.CALENDLY,
};

const youTubeSources: string[] = [
  'm.youtube.com',
  'youtube.com',
  'youtu.be',
];

const vimeoSources: string[] = [
  'vimeo.com',
];

const googleDriveSources: string[] = [
  'docs.google.com',
  'drive.google.com',
];

const microsoftSources: string[] = [
  'onedrive.live.com',
  '1drv.ms',
];

const behanceSources: string[] = [
  'behance.net',
];

const figmaSources: string[] = [
  'figma.com',
];

const pitchSources: string[] = [
  'pitch.com',
];

const preziSources: string[] = [
  'prezi.com',
];

const loomSources: string[] = [
  'loom.com',
];

const dropboxSources: string[] = [
  'dropbox.com',
];

const typeformSources: string[] = [
  'typeform.com',
];

const airtableSources: string[] = [
  'airtable.com',
];

const vidyardSources: string[] = [
  'share.vidyard.com',
  'video.vidyard.com',
];

const calendlySources: string[] = [
  'calendly.com',
];

const acceptedSources: string[] = [
  ...youTubeSources,
  ...vimeoSources,
  ...googleDriveSources,
  ...microsoftSources,
  ...behanceSources,
  ...figmaSources,
  ...pitchSources,
  ...preziSources,
  ...loomSources,
  ...dropboxSources,
  ...typeformSources,
  ...airtableSources,
  ...vidyardSources,
  ...calendlySources,
];

const chooseSource = (source: ContentType) => {
  switch (source) {
    case ContentType.YOUTUBE:
      return youTubeSources;
    case ContentType.VIMEO:
      return vimeoSources;
    case ContentType.GOOGLE_DOCS:
      return googleDriveSources;
    case ContentType.GOOGLE_DRIVE:
      return googleDriveSources;
    case ContentType.GOOGLE_FORMS:
      return googleDriveSources;
    case ContentType.GOOGLE_SHEETS:
      return googleDriveSources;
    case ContentType.GOOGLE_SLIDES:
      return googleDriveSources;
    case ContentType.ONE_DRIVE_WORD:
      return microsoftSources;
    case ContentType.ONE_DRIVE_EXCEL:
      return microsoftSources;
    case ContentType.ONE_DRIVE_POWER_POINT:
      return microsoftSources;
    case ContentType.BEHANCE:
      return behanceSources;
    case ContentType.FIGMA:
      return figmaSources;
    case ContentType.PITCH:
      return pitchSources;
    case ContentType.PREZI:
      return preziSources;
    case ContentType.LOOM:
      return loomSources;
    case ContentType.DROPBOX:
      return dropboxSources;
    case ContentType.TYPEFORM:
      return typeformSources;
    case ContentType.AIRTABLE:
      return airtableSources;
    case ContentType.VIDYARD:
      return vidyardSources;
    case ContentType.CALENDLY:
      return calendlySources;
    default:
      return undefined;
  }
};

export const oneDriveSources: MediaSource[] = [
  {
    name: 'Word',
    link: 'https://onedrive.live.com/',
    contentTag: ContentType.ONE_DRIVE_WORD,
    typeTag: EMediaTypeTags.ONE_DRIVE,
    description: 'Tip: In word, go to File > Share > Embed. Copy the code and paste it into the input field',
    icon: <MSWordSvgIcon />,
  },
  {
    name: 'Excel',
    link: 'https://onedrive.live.com/',
    contentTag: ContentType.ONE_DRIVE_EXCEL,
    typeTag: EMediaTypeTags.ONE_DRIVE,
    description: 'Tip: In excel, go to File > Share > Embed. Copy the code and paste it into the input field',
    icon: <MSXLSvgIcon />,
  },
  {
    name: 'Power Point',
    link: 'https://onedrive.live.com/',
    contentTag: ContentType.ONE_DRIVE_POWER_POINT,
    typeTag: EMediaTypeTags.ONE_DRIVE,
    description: 'Tip: In powerpoint, go to File > Share > Embed. Copy the code and paste it into the input field',
    icon: <MSPPSvgIcon />,
  },
];

export const videoSources: MediaSource[] = [
  {
    name: 'YouTube',
    link: 'https://www.youtube.com/',
    contentTag: ContentType.YOUTUBE,
    typeTag: EMediaTypeTags.VIDEO,
    description: 'Insert youtube link',
    icon: <YouTubeSVGIcon />,
  },
  {
    name: 'Vimeo',
    link: 'https://vimeo.com/',
    contentTag: ContentType.VIMEO,
    typeTag: EMediaTypeTags.VIDEO,
    description: 'Insert vimeo link',
    icon: <VimeoSVGIcon />,
  },
  {
    name: 'Loom',
    link: 'https://www.loom.com/',
    contentTag: ContentType.LOOM,
    typeTag: EMediaTypeTags.VIDEO,
    description: 'Insert loom link',
    icon: <LoomSVGIcon />,
    provider: EMediaProvider.IFRAMELY,
  },
  {
    name: 'Vidyard',
    link: 'https://share.vidyard.com/',
    contentTag: ContentType.VIDYARD,
    typeTag: EMediaTypeTags.VIDEO,
    description: 'Insert vidyard link',
    icon: <VidyardSVGIcon />,
    provider: EMediaProvider.IFRAMELY,
  },
];

export const sketchSources: MediaSource[] = [
  {
    name: 'Pitch',
    link: 'https://pitch.com/',
    contentTag: ContentType.PITCH,
    typeTag: EMediaTypeTags.SKETCH,
    description: 'Insert pitch link',
    icon: <PitchSvgIcon />,
    provider: EMediaProvider.IFRAMELY,
  },
  {
    name: 'Figma',
    link: 'https://www.figma.com/',
    contentTag: ContentType.FIGMA,
    typeTag: EMediaTypeTags.SKETCH,
    description: 'Insert figma link',
    icon: <FigmaSvgIcon />,
    provider: EMediaProvider.IFRAMELY,
  },
];

export const schedulingSources: MediaSource[] = [
  {
    name: 'Calendly',
    link: 'https://calendly.com/',
    contentTag: ContentType.CALENDLY,
    typeTag: EMediaTypeTags.SCHEDULING,
    description: 'Insert calendly link',
    icon: <CalendlySvgIcon />,
    provider: EMediaProvider.IFRAMELY,
  },
];

export const gallerySources: MediaSource[] = [
  {
    name: 'Behance',
    link: 'https://www.behance.net/',
    contentTag: ContentType.BEHANCE,
    typeTag: EMediaTypeTags.GALLERY,
    description: 'Insert behance link',
    icon: <BehanceSvgIcon />,
    provider: EMediaProvider.IFRAMELY,
  },
];

export const fileSources: MediaSource[] = [
  {
    name: 'Dropbox',
    link: 'https://www.dropbox.com/',
    contentTag: ContentType.DROPBOX,
    typeTag: EMediaTypeTags.FILE,
    description: 'Insert dropbox file link',
    icon: <DropboxSVGIcon />,
    provider: EMediaProvider.IFRAMELY,
  },
];

export const presentationSources: MediaSource[] = [
  {
    name: 'Prezi',
    link: 'https://prezi.com/',
    contentTag: ContentType.PREZI,
    typeTag: EMediaTypeTags.PRESENTATION,
    description: 'Insert prezi link',
    icon: <PreziSVGIcon />,
    provider: EMediaProvider.IFRAMELY,
  },
];

export const spreadsheetSources: MediaSource[] = [
  {
    name: 'Airtable',
    link: 'https://airtable.com/',
    contentTag: ContentType.AIRTABLE,
    typeTag: EMediaTypeTags.SHEET,
    description: 'Insert airtable link',
    icon: <AirtableSVGIcon />,
    provider: EMediaProvider.IFRAMELY,
  },
];

export const formSources: MediaSource[] = [
  {
    name: 'Google Forms',
    link: 'https://docs.google.com/forms/',
    contentTag: ContentType.GOOGLE_FORMS,
    typeTag: EMediaTypeTags.FORM,
    description: 'Insert google forms link',
    icon: <GoogleFormsSVGIcon />,
    provider: EMediaProvider.IFRAMELY,
  },
  {
    name: 'Typeform',
    link: 'typeform.com',
    contentTag: ContentType.TYPEFORM,
    typeTag: EMediaTypeTags.FORM,
    description: 'Insert typeform link',
    icon: <TypeformSvgIcon />,
    provider: EMediaProvider.IFRAMELY,
  },
];

export const googleSources: MediaSource[] = [
  {
    name: 'Google Sheets',
    link: 'https://docs.google.com/spreadsheets',
    contentTag: ContentType.GOOGLE_SHEETS,
    typeTag: EMediaTypeTags.GOOGLE,
    description: 'Tip: In google sheets, go to File > Share > Publish to web. Publish. Copy the link and paste it into the input field',
    icon: <GoogleXLSvgIcon />,
  },
  {
    name: 'Google Docs',
    link: 'https://docs.google.com/document',
    contentTag: ContentType.GOOGLE_DOCS,
    typeTag: EMediaTypeTags.GOOGLE,
    description: 'Tip: In google docs, go to File > Share > Publish to web. Publish. Copy the link and paste it into the input field',
    icon: <GoogleDocsSVGIcon />,
  },
  {
    name: 'Google Slides',
    link: 'https://docs.google.com/presentation',
    contentTag: ContentType.GOOGLE_SLIDES,
    typeTag: EMediaTypeTags.GOOGLE,
    description: 'Tip: In google slides, go to File > Share > Publish to web. Publish. Copy the link and paste it into the input field',
    icon: <GoogleSlidesSvgIcon />,
  },
  {
    name: 'Google Drive',
    link: 'https://drive.google.com',
    contentTag: ContentType.GOOGLE_DRIVE,
    typeTag: EMediaTypeTags.GOOGLE,
    description: 'Insert google drive file link',
    icon: <GoogleDriveSVGIcon />,
    provider: EMediaProvider.IFRAMELY,
  },
];

export const mediaSources: MediaSource[] = [
  ...googleSources,
  ...oneDriveSources,
  ...videoSources,
  ...spreadsheetSources,
  ...presentationSources,
  ...fileSources,
  ...formSources,
  ...gallerySources,
  ...sketchSources,
  ...schedulingSources,
];

export const getSubtypeByMIME = (MIMEtype: string): EFieldsubType => {
  const type = MIMEtype.split('/')[0];
  switch (type) {
    case 'image':
      return EFieldsubType.IMG;
    case 'video':
      return EFieldsubType.VIDEO;
    default:
      return EFieldsubType.FILE;
  }
};

export const getMediaSourceByName = (sourceName: string) => mediaSources.find(({ name }) => sourceName.localeCompare(name));

// eslint-disable-next-line max-len
export const checkFileAcceptance = (name: string, formats?: string[]):boolean => (formats || allFormats).includes(name.split('.').pop()?.toLowerCase() || '');

// eslint-disable-next-line max-len
export const parseIFrame = (url: string): string | undefined => url.split(' ').find((element) => element.includes('src='))?.slice(5, -1);

export const isImage = (url: string): boolean => {
  const format: string = url.split('.')?.pop() ?? 'false';

  if ([...imgFormats, ...additionalImgFormats].includes(format)) {
    return true;
  }
  return false;
};

export const isGif = (url: string): boolean => {
  const format: string = url.split('.')?.pop() ?? 'false';

  if (additionalImgFormats.includes(format)) {
    return true;
  }
  return false;
};

export const isPDF = (url: string): boolean => url.split('.')?.pop() === 'pdf' ?? false;

export const isGoogleViewer = (url: string): boolean => {
  const format: string = url?.split('.').pop() ?? '';

  return fileFormats.includes(format);
};

export const compareLinkAndSource = (url: string, contentType: ContentType): boolean => {
  if (contentType === ContentType.IFRAME || contentType === ContentType.URL) return true;
  if (chooseSource(contentType)) {
    const answer = chooseSource(contentType)?.filter((item) => !!url.includes(item))[0];

    if (answer) {
      return true;
    }
  }
  return false;
};

export const checkLinkAcceptance = (url: string): boolean => (!!acceptedSources.find((source) => url.includes(source)) || isImage(url));

const parseGoogleUrl = (url: string, type?: ContentType): string => {
  const linkSegments: string[] = url.split('/');
  const lastSegment: string | undefined = linkSegments.pop();

  const isParseNeeded: boolean = !!(
    lastSegment?.includes('pub')
    || lastSegment?.includes('edit')
  );

  if (isParseNeeded) {
    const docType: string | undefined = type
        ?? linkSegments.find((segment: string) => segment === GoogleDocTypes.PRESENTATION)
        ?? linkSegments.find((segment: string) => segment === GoogleDocTypes.SPREADSHEET)
        ?? linkSegments.find((segment: string) => segment === GoogleDocTypes.DOCUMENT)
      ?? undefined;

    switch (docType) {
      case GoogleDocTypes.PRESENTATION:
      case ContentType.GOOGLE_SLIDES:
        return `${linkSegments.join('/')}/embed`;
      case GoogleDocTypes.DOCUMENT:
      case ContentType.GOOGLE_DOCS:
        return `${linkSegments.join('/')}/pub?embedded=true`;
      case GoogleDocTypes.SPREADSHEET:
      case ContentType.GOOGLE_SHEETS:
        return `${linkSegments.join('/')}/pubhtml?widget=true&amp;headers=false`;
      default:
        return url;
    }
  }
  return url;
};

const parseOneDriveUrl = (url: string): string => url.split('&amp;').join('&');

export const formatUrl = (url: string, contentTag?: ContentType): string => {
  let formatedUrl: string = url.trim();
  if (url.includes('<iframe')) {
    formatedUrl = parseIFrame(url) ?? url;
  }

  if (!contentTag) {
    return formatedUrl;
  }

  switch (contentTag) {
    case ContentType.GOOGLE_DOCS:
      return formatedUrl;
    case ContentType.GOOGLE_SHEETS:
      return formatedUrl;
    case ContentType.GOOGLE_SLIDES:
      return formatedUrl;
    case ContentType.ONE_DRIVE_WORD:
      return parseOneDriveUrl(formatedUrl);
    case ContentType.ONE_DRIVE_EXCEL:
      return parseOneDriveUrl(formatedUrl);
    case ContentType.ONE_DRIVE_POWER_POINT:
      return parseOneDriveUrl(formatedUrl);
    case ContentType.YOUTUBE:
      return formatedUrl;
    case ContentType.VIMEO:
      return formatedUrl;
    default:
  }

  if (formatedUrl.includes('docs.google.com') && !isImage(formatedUrl)) {
    return formatedUrl;
  }
  if (formatedUrl.includes('onedrive.live.com') && !isImage(formatedUrl)) {
    return parseOneDriveUrl(formatedUrl);
  }
  return formatedUrl;
};

export const urlCheckAndParse = (
  url: string,
  checkSource?: string,
  provider?: EMediaProvider,
  tag?: ContentType,
): { newUrl: string, newSubType: EFieldsubType } => {
  const contentTag = mediaSources.find((mSource) => mSource.link === checkSource)?.contentTag;

  if (
    (checkSource && !url.includes(checkSource))
    || (
      (contentTag === ContentType.ONE_DRIVE_WORD
        || contentTag === ContentType.ONE_DRIVE_EXCEL
        || contentTag === ContentType.ONE_DRIVE_POWER_POINT)
    && !url.includes('<iframe')
    )
  ) {
    return ({
      newUrl: '',
      newSubType: EFieldsubType.EMPTY,
    });
  }

  const newUrl: string = formatUrl(url, contentTag);

  if (tag !== ContentType.IFRAME && tag !== ContentType.URL && !checkLinkAcceptance(newUrl)) {
    return ({
      newUrl: '',
      newSubType: EFieldsubType.EMPTY,
    });
  }
  if (tag === ContentType.IFRAME && provider !== EMediaProvider.IFRAMELY) {
    return ({
      newUrl,
      newSubType: EFieldsubType.IFRAME,
    });
  }
  if (tag === ContentType.URL && provider === EMediaProvider.IFRAMELY) {
    return {
      newUrl,
      newSubType: EFieldsubType.MIXED_URL,
    };
  }
  if (provider === EMediaProvider.IFRAMELY) {
    return {
      newUrl,
      newSubType: EFieldsubType.MIXED,
    };
  }
  if (ReactPlayer.canPlay(url)) {
    return ({
      newUrl,
      newSubType: EFieldsubType.VIDEO,
    });
  }
  if (isImage(url)) {
    return ({
      newUrl,
      newSubType: EFieldsubType.IMG,
    });
  }
  return ({
    newUrl,
    newSubType: EFieldsubType.FILE,
  });
};

export const getMaxImageHeightFromSiblingsCount = (siblingsCount?: number): string => {
  if (isMobile) {
    return 'unset';
  }
  switch (siblingsCount) {
    case 2:
      return '400px';
    case 3:
      return '200px';
    default:
      return 'unset';
  }
};
