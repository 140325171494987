import { useEffect } from 'react';
import { useAppSelector } from '../../hooks/stateHooks';
import { selectProcess } from './state/processSlice';
import { EProcessType } from '../../../shared/process/ProcessMilestoneActionDTO';

const ProcessTabTitlePlugin = (): null => {
  const process = useAppSelector(selectProcess);

  useEffect(() => {
    if (process?.type === EProcessType.DEAL) {
      document.title = `${process.sellerName} x ${process.client}` ?? 'Stageset';
    }
    return () => { document.title = 'Stageset'; };
  }, [process]);

  return null;
};

export default ProcessTabTitlePlugin;
