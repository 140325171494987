import { Grid } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import Salesforce from '../../../icons/Integrations/Salesforce.svg';
import HubSpot from '../../../icons/Integrations/HubSpot.svg';
import Pipedrive from '../../../icons/Integrations/Pipedrive.svg';
import { ESubscriptionType } from '../../../../shared/Subscription';
import { PipedriveAuthApi } from '../../../api/PipedriveAuthApi';
import { HubspotAuthApi } from '../../../api/HubspotAuthApi';
import { SalesforceAuthApi } from '../../../api/SalesforceAuthApi';
import { useAppDispatch, useAppSelector } from '../../../hooks/stateHooks';
import useUserOrganizationPermissions from '../../../hooks/useOrganizationPermissions';
import { EUserOrganizationPermissions } from '../../../../shared/permissions';
import { IntegrationCard } from '../../Integrations/ui/IntegrationCard';
import PagePresentation from '../../../common/PagePresentation/PagePresentation';
import PagePresentationNoPermissions from './tabItems/SubscriptionTab/NoPermissionPlaceholder';
import { pipedriveCancelIntegration } from '../../../core/crmintegrations/pipedrive';
import { hubspotCancelIntegration } from '../../../core/crmintegrations/hubspot';
import { salesforceCancelIntegration } from '../../../core/crmintegrations/salesforce';
import {
  SettingsCardsDescription, SettingsCardsHeader, SettingsCardsHeadingWrapper, SettingsTabContentWrapper,
} from './Styles/GeneralTabStyles';

const premiumSubscriptionTypes = [
  ESubscriptionType.SELLER_PREMIUM,
  ESubscriptionType.SELLER_APPSUMO_TIER_2,
  ESubscriptionType.SELLER_APPSUMO_TIER_3,
];

const pipedriveAuthApi: PipedriveAuthApi = new PipedriveAuthApi();
const hubspotAuthApi: HubspotAuthApi = new HubspotAuthApi();
const salesforceAuthApi: SalesforceAuthApi = new SalesforceAuthApi();

const IntegrationsTab = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const subscriptionType: ESubscriptionType = useAppSelector((state) => state.billing.subscription.type);
  const {
    isUserHaveIntegration: isUserHavePipedriveIntegration,
    integration: pipedriveIntegration,
  } = useAppSelector((state) => state.pipedrive);
  const {
    isUserHaveIntegration: isUserHaveHubspotIntegration,
    integration: hubspotIntegration,
  } = useAppSelector((state) => state.hubspot);
  const {
    isUserHaveIntegration: isUserHaveSalesforceIntegration,
    integration: salesforceIntegration,
  } = useAppSelector((state) => state.salesForce);
  const somethingIntegrated = isUserHavePipedriveIntegration || isUserHaveHubspotIntegration || isUserHaveSalesforceIntegration;
  const [checkOrganizationPermissions] = useUserOrganizationPermissions();

  const isPageAvailable: boolean = premiumSubscriptionTypes.includes(subscriptionType);
  const isBillingAvailable: boolean = checkOrganizationPermissions(EUserOrganizationPermissions.ORGANIZATION_NAV_BILLING);

  const onPipedriveSet = (): void => {
    pipedriveAuthApi.startAuthSession();
  };
  const onHubspotSet = (): void => {
    hubspotAuthApi.startAuthSession();
  };
  const onSalesforceSet = (): void => {
    salesforceAuthApi.startAuthSession();
  };

  const onPipedriveCancel = (): void => {
    dispatch(pipedriveCancelIntegration());
  };
  const onHubspotCancel = (): void => {
    dispatch(hubspotCancelIntegration());
  };
  const onSalesforceCancel = (): void => {
    dispatch(salesforceCancelIntegration());
  };

  return (
    <SettingsTabContentWrapper
      className={classNames({ 'settings-content-wrapper-mobile': isMobile })}
    >
      <SettingsCardsHeadingWrapper>
        <SettingsCardsHeader>
          {t('Integrations_Integrations')}
        </SettingsCardsHeader>
        <SettingsCardsDescription>
          Setup your CRM integration
        </SettingsCardsDescription>
      </SettingsCardsHeadingWrapper>
      {isPageAvailable
        && (
          <Grid
            container
            spacing={3}
            p={isMobile ? 1 : 3}
            sx={{
              overflow: 'overlay',
            }}
          >
            <IntegrationCard
              logo={<Pipedrive />}
              haveIntegration={isUserHavePipedriveIntegration}
              somethingIntegrated={somethingIntegrated}
              integration={pipedriveIntegration}
              name="Pipedrive"
              onCancel={onPipedriveCancel}
              onSet={onPipedriveSet}
              description={t('Integrations_Pipedrive_text')}
            />
            <IntegrationCard
              logo={<HubSpot />}
              haveIntegration={isUserHaveHubspotIntegration}
              somethingIntegrated={somethingIntegrated}
              integration={hubspotIntegration}
              name="Hubspot"
              onCancel={onHubspotCancel}
              onSet={onHubspotSet}
              description={t('Integrations_Hubspot_text')}
            />
            <IntegrationCard
              logo={<Salesforce />}
              haveIntegration={isUserHaveSalesforceIntegration}
              somethingIntegrated={somethingIntegrated}
              integration={salesforceIntegration}
              name="Salesforce"
              onCancel={onSalesforceCancel}
              onSet={onSalesforceSet}
              description={t('Integrations_Salesforce_text')}
            />
          </Grid>
        )}
      {
        !isPageAvailable
        && isBillingAvailable
        && (
          <PagePresentation
            title="Integrations"
            // eslint-disable-next-line max-len
            description="With Integrations, updating your CRM is done automatically for you. Unlock the company plan to connect your Salesforce account to Stageset."
          />
        )
      }
      {
        !isPageAvailable
        && !isBillingAvailable
        && (
          <PagePresentationNoPermissions
            title="No Access"
            description="You do not have access to this page"
          />
        )
      }
    </SettingsTabContentWrapper>
  );
};

export default IntegrationsTab;
