import {
  Box, Button, Menu, MenuItem,
} from '@mui/material';
import React, { SyntheticEvent, useState } from 'react';
import { MoreHoriz } from '@mui/icons-material';
import { FileDTO } from '../../../shared/FileDTO';
import ConfirmationDialog from '../../common/ConfirmationDialog/ConfirmationDialog';
import DeleteTypography from '../../common/DeleteTypography';
import { useAppDispatch } from '../../hooks/stateHooks';
import { deleteOrganizationFile } from './state/filesSlice';

interface Props {
  file: FileDTO;
  isDeleteActive?: boolean;
}

const FileManagementMenu = ({
  file, isDeleteActive,
}: Props): JSX.Element => {
  const { name } = file;
  const dispatch = useAppDispatch();
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const displayName: string = name.length > 15 ? `${name.substring(0, 15)}...` : name;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    handleClose();
    if (isDeleteActive) {
      setDeleteDialogOpen(true);
    }
  };

  const onDeleteConfirm = (): void => {
    setDeleteDialogOpen(false);
    dispatch(deleteOrganizationFile(file.fullPath));
  };

  const deleteTypography = (
    <DeleteTypography>
      Are you sure you want to delete
      <b>
        {' '}
        {displayName}
      </b>
      ?
    </DeleteTypography>
  );

  return (
    <Box>
      <Button
        className="StyledMoreMenuButtonContainer"
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        disableRipple
      >
        <MoreHoriz
          className="StyledMoreMenuButton"
          sx={{
            visibility: 'hidden',
          }}
        />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={handleDeleteClick}
        >
          Delete
        </MenuItem>
      </Menu>
      <ConfirmationDialog
        isDialogOpen={isDeleteDialogOpen}
        onCancel={() => setDeleteDialogOpen(false)}
        onConfirm={onDeleteConfirm}
        dialogContentText={deleteTypography}
        dialogTitle="Delete File"
      />
    </Box>
  );
};

export default FileManagementMenu;
