import {
  Table,
  TableCell, TableCellProps, TableProps, TableRow, TableRowProps, styled,
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import { stagesetColors } from '../../theme/stagesetPalette';

export const StyledPTRow = styled(TableRow)(() => ({
  height: '32px',
  '&:last-child': {
    backgroundColor: stagesetColors.newGrey[50],
    '& *': {
      borderBottom: 'none',
    },
  },
}));

export const StyledPTRowMobile = styled(TableRow)(() => ({
  backgroundColor: stagesetColors.background[200],
  padding: '4px 0px',
  height: '30px',
}));

export const StyledPTValueCell = styled(TableCell)(() => ({
  textAlign: 'right',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '16px',
  padding: '9px 12px',
  color: stagesetColors.newGrey[800],
  borderBottom: `1px solid ${stagesetColors.newGrey[100]}`,
  '&:last-child': {
    // borderBottom: 'none',
  },
}));

export const StyledPTValueCellMobile = styled(TableCell)(() => ({
  textAlign: 'right',
  color: stagesetColors.newGrey[800],
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '22px',
  padding: '0px 4px',
}));

export const StyledPTNameCell = styled(TableCell)(() => ({
  textAlign: 'left',
  fontSize: '12px',
  lineHeight: '14px',
  fontWeight: 500,
  padding: '9px 12px',
  color: stagesetColors.newGrey[400],
  borderRight: '1px solid',
  borderColor: stagesetColors.newGrey[100],
  borderBottom: `1px solid ${stagesetColors.newGrey[100]}`,
  '&:last-child': {
    borderBottom: 'none',
  },
}));

export const StyledPTNameCellMobile = styled(TableCell)(() => ({
  textAlign: 'left',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '16px',
  borderBottom: 'none',
  padding: '0px',
  color: stagesetColors.newGrey[400],
}));

export const StyledPTTable = styled(Table)(() => ({
  borderRadius: '12px',
  border: '1px solid',
  borderColor: stagesetColors.newGrey[100],
  overflow: 'hidden',
  minWidth: 258,
}));

export const StyledPTTableMobile = styled(Table)(() => ({
  backgroundColor: stagesetColors.newGrey[50],
  borderRadius: '12px',
  padding: '16px',
  maxHeight: 440,
  width: '100%',
  border: '1px solid',
  borderColor: stagesetColors.newGrey[100],
}));

export const PTRow = (props: TableRowProps): JSX.Element => (
  isMobile
    ? <StyledPTRowMobile tabIndex={-1} {...props} />
    : <StyledPTRow tabIndex={-1} {...props} />
);

export const PTValueCell = (props: TableCellProps): JSX.Element => (
  isMobile
    ? <StyledPTValueCellMobile {...props} />
    : <StyledPTValueCell {...props} />
);

export const PTNameCell = (props: TableCellProps): JSX.Element => (
  isMobile
    ? <StyledPTNameCellMobile {...props} />
    : <StyledPTNameCell {...props} />
);

export const PTTable = (props: TableProps): JSX.Element => (
  isMobile
    ? <StyledPTTableMobile {...props} />
    : <StyledPTTable {...props} />
);
