import {
  Box,
} from '@mui/material';
import React, { useState } from 'react';
import { ThreeDotsVert20px } from '../../icons/ThreeDots';
import { AppIconButton } from '../AppButton/AppIconButton';
import { AppDropdown } from '../AppDropdown/AppDropdown';
import { AppDropdownItemProps } from '../AppDropdown/AppDropdownItem';

interface Props {
  MenuItems: AppDropdownItemProps[];
}

const MenuWithButton = ({
  MenuItems = [],
}: Props): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const onClose = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <Box onClick={onClick}>
        <AppIconButton
          transparentStroke
          icon={<ThreeDotsVert20px />}
        />
      </Box>
      <AppDropdown
        id="section-options-menu"
        lastElementHasAnotherColor
        items={MenuItems}
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        sx={{
          '& ul': {
            padding: '0px',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          sx: {
            border: '1px solid #7084B4',
            padding: '0px',
          },
          onClick: onClose,
        }}
      />
    </>
  );
};

export default MenuWithButton;
