import { Box } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useEffect } from 'react';
import TemplateDisplay from '../../features/TemplateDisplay/TemplateDisplay';
import ProcessTemplatesList from '../../features/TemplatesLists/ProcessTemplatesList';
import SectionTemplatesList from '../../features/TemplatesLists/SectionsTemplatesList';
import StagesTemplatesList from '../../features/TemplatesLists/StagesTemplatesList';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import {
  ETemplatesTypes, selectLibraryTab,
} from './lib/templatesSlice';
import MobileTemplateDisplay from '../../features/TemplateDisplay/MobileTemplateDisplay';
import TemplatesTabsHeader from './TemplatesTabsHeader';
import TemplatePreviewHeader from './TemplatePreviewHeader';
import { stagesetPalette } from '../../theme/stagesetPalette';
import { resetItemsSlice } from '../../features/ProcessFields/lib/processItemsSlice';

const Templates = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const tab = useAppSelector(selectLibraryTab);

  useEffect(() => () => { dispatch(resetItemsSlice()); }, []);

  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexDirection: 'column',
          background: stagesetPalette.background?.default,
        }}
      >
        <TemplatesTabsHeader />
        <Box
          sx={{
            height: isMobile ? 'calc( 100% - 136px )' : 'calc( 100% - 160px )',
            display: 'flex',
            flexDirection: 'row',
            width: 'calc( 100% - 0px )',
            gap: '24px',
            padding: isMobile ? '0' : '0 24px',
          }}
        >
          <Box
            sx={{
              height: '100%',
              width: isMobile ? '100%' : '300px',
              maxWidth: isMobile ? '100%' : '300px',
              minWidth: isMobile ? '100%' : '300px',
            }}
          >
            {
            tab === ETemplatesTypes.TEMPLATES
            && (
              <ProcessTemplatesList />
            )
            }
            {
            tab === ETemplatesTypes.STAGES
            && (
              <StagesTemplatesList />
            )
            }
            {
            tab === ETemplatesTypes.SECTIONS
            && (
              <SectionTemplatesList />
            )
            }
          </Box>
          {
            !isMobile
            && (
              <Box
                sx={{
                  height: 'calc( 100% - 34px)',
                  width: 'calc( 100% - 324px)',
                  maxWidth: 'calc( 100% - 324px)',
                  minWidth: 'calc( 100% - 324px)',
                  borderRadius: '20px 20px 0px 0px',
                  border: '2px solid #FFFFFF',
                  boxShadow: ' 0px 0px 18px -2px rgba(0, 57, 96, 0.1), 0px 0px 3px rgba(0, 57, 96, 0.1)',
                  overflow: 'hidden',
                }}
              >
                <TemplatePreviewHeader />
                <Box
                  sx={{
                    height: 'calc( 100% - 56px)',
                    background: stagesetPalette.background?.default,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: tab === ETemplatesTypes.SECTIONS ? '24px' : '',
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: '12px 12px 0px 0px',
                      overflow: 'hidden',
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      height: '100%',
                    }}
                  >
                    <TemplateDisplay />
                  </Box>
                </Box>
              </Box>
            )
          }
        </Box>
      </Box>
      {
        isMobile
        && (
        <MobileTemplateDisplay />
        )
      }
    </>
  );
};

export default Templates;
