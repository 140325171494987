import {
  ChangeEvent, useEffect, useMemo, useRef, useState,
} from 'react';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { size } from 'cypress/types/lodash';
import { redirectAfterOrgUpdate, updateOrganization, uploadTeamLogo } from '../core/store/appState/appState';
import { useOrganization } from './useOrganization';
import { useAppDispatch } from './stateHooks';
import { useFileHelpers } from './useFileHelpers';

export type OrganizationSettingsFormValues = {
  logo?: { file?: File, tempUrl: string } | null,
  title?: string,
  domain?: string,
  wideLogo?: { file?: File, tempUrl: string } | null;
  customDomain?: string;
};

const formSchema = yup.object({
  title: yup.string().min(1, 'Title is required'),
});

export const useOrganizationSettings = () => {
  const { organization } = useOrganization();
  const [saving, setSaving] = useState(false);
  const [currency, setCurrency] = useState(organization.currency);
  const [formValues, setFormValues] = useState<OrganizationSettingsFormValues>({});
  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const { validateFile } = useFileHelpers();

  const logoUrl = useMemo(() => {
    switch (formValues.logo) {
      case null:
        return null;
      case undefined:
        return organization?.organizationLogo;
      default:
        return formValues.logo.tempUrl;
    }
  }, [organization?.organizationLogo, formValues.logo]);

  const updateFormValue = <T extends keyof OrganizationSettingsFormValues>(
    key: T,
    value: OrganizationSettingsFormValues[T],
  ) => {
    setFormValues((state: OrganizationSettingsFormValues) => ({
      ...state,
      [key]: value,
    }));
  };

  const handleUploadClick = () => {
    // 👇 We redirect the click event onto the hidden input element
    inputRef.current?.click();
  };

  const handleLogoChange = (e: ChangeEvent<HTMLInputElement>) => {
    const [file] = e.target.files || [];

    if (!validateFile(file)) return;

    updateFormValue('logo', { file, tempUrl: URL.createObjectURL(file) });
  };

  const handleLogoRemove = () => {
    if (inputRef.current) inputRef.current.value = '';

    if (formValues.logo) {
      // remove uploaded logo
      updateFormValue('logo', undefined);
      return;
    }
    // remove exist logo
    updateFormValue('logo', null);
  };

  const onFormChange = async () => {
    let shouldUpdate = false;
    let showSnackbar = false;
    let shouldRedirect = false;
    setSaving(true);

    try {
      await formSchema.validate(formValues);
    } catch (e) {
      if (e instanceof yup.ValidationError) {
        for (const message of e.errors) {
          enqueueSnackbar(message, { variant: 'warning' });
        }
      }
      setSaving(false);
      return;
    }

    let org = { ...organization };

    if (formValues.logo?.file) {
      /**
       * Remove validateFile from here, because in this version
       * file crop is used which validates the input file size but produces an even bigger file
       * this effect is negated on the backed, where the input logo file is resized to 128X128
       */

      const updatedOrg = await dispatch(uploadTeamLogo({
        file: formValues.logo.file,
        userId: '',
        organizationId: organization?.id,
      })).unwrap();
      org = { ...org, ...updatedOrg };
      showSnackbar = true;
    }

    if (formValues.logo === null) {
      org.organizationLogo = '';
      shouldUpdate = true;
    }

    if (!formValues.logo?.file && !!formValues.logo?.tempUrl) {
      org.organizationLogo = formValues.logo?.tempUrl;
      shouldUpdate = true;
    }

    if (formValues.title) {
      org.name = formValues.title;
      shouldUpdate = true;
    }

    if (currency !== organization.currency) {
      org.currency = currency;
      shouldUpdate = true;
    }

    if ('domain' in formValues) {
      org.domain = formValues.domain;
      shouldUpdate = true;
    }

    if ('customDomain' in formValues) {
      shouldRedirect = org.customDomain != formValues.customDomain;
      org.customDomain = formValues.customDomain;
      shouldUpdate = true;
    }

    if (shouldUpdate) {
      try {
        const updatedOrg = await dispatch(updateOrganization(org)).unwrap();
        showSnackbar = true;
        if (shouldRedirect) {
          dispatch(redirectAfterOrgUpdate(updatedOrg));
        }
      } catch (e: any) {
        enqueueSnackbar(e?.message, { variant: 'error' });
      }
    }
    if (showSnackbar) enqueueSnackbar('Organization updated', { variant: 'success' });
    setSaving(false);
  };

  const deleteCustomDomain = async () => {
    setSaving(true);
    let showSnackbar = false;

    const org = { ...organization };
    org.customDomain = '';

    try {
      const updatedOrg = await dispatch(updateOrganization(org)).unwrap();
      showSnackbar = true;
      dispatch(redirectAfterOrgUpdate(updatedOrg));
    } catch (e: any) {
      enqueueSnackbar(e?.message, { variant: 'error' });
    }

    if (showSnackbar) enqueueSnackbar('Organization updated', { variant: 'success' });
    setSaving(false);
  };

  useEffect(() => {
    setFormValues({});
  }, [organization]);

  return {
    logoUrl,
    saving,
    inputRef,
    updateFormValue,
    handleUploadClick,
    handleLogoChange,
    handleLogoRemove,
    onFormChange,
    currency,
    setCurrency,
    deleteCustomDomain,
  };
};
