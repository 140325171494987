import {
  Box, Dialog, Typography,
} from '@mui/material';
import classNames from 'classnames';
import { ChangeEvent, useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
  CheckCircle, HideSource, Refresh, WarningAmber,
} from '@mui/icons-material';
import { AppAvatar } from '../../../../shared/AppAvatar';
import NormalizedSvgIcon from '../../../../shared/NormalizedSvgIcon/NormalizedSvgIcon';
import UploadIcon from '../../../../icons/Upload.svg';

import { useOrganizationSettings } from '../../../../hooks/useOrganizationSettings';
import { useOrganization } from '../../../../hooks/useOrganization';
import { StyledSettingsTab } from '../../OrganizationUsersStyles';
import TrashIcon from '../../../../icons/TrashIcon';
import { AppButton } from '../../../../shared/AppButton/AppButton';
import { AppIconButton } from '../../../../shared/AppButton/AppIconButton';
import { AppInput } from '../../../../shared/AppInput/AppInput';
import useUserOrganizationStatus from '../../../../hooks/useSubscriptionStatus';
import { config } from '../../../../core/config';
import CurrencySelector from '../../../../pages/Settings/Company/CurrencySelector';
import { useAppDispatch, useAppSelector } from '../../../../hooks/stateHooks';
import { activateCustomDomain, getCustomDomainStatus } from '../../../../routes-old/auth/CustomDomain/state/customDomainSlice';
import { CustomDomainStatus } from '../../../../../shared/domains/CustomDomainStatus';
import { ECustomDomainEnableStatus } from '../../../../../shared/CustomDomains';
import ImageCrop from '../../../ImageCrop/ImageCrop';
import { useFileHelpers } from '../../../../hooks/useFileHelpers';
import { selectIsSubscriptionPremium } from '../../../../routes-old/Billing/state/billingSlice';

export const SettingsTab = () => {
  const { organization } = useOrganization();
  const {
    logoUrl,
    handleLogoRemove,
    handleUploadClick,
    onFormChange,
    saving,
    inputRef,
    updateFormValue,
    currency,
    setCurrency,
    deleteCustomDomain,
  } = useOrganizationSettings();
  const {
    validateFile,
  } = useFileHelpers();
  const dispatch = useAppDispatch();

  const isPremium = useAppSelector(selectIsSubscriptionPremium);
  const { hasCustomDomains } = useUserOrganizationStatus();
  const [subdomain, setSubdomain] = useState<string>(organization.domain);
  const [customDomain, setCustomDomain] = useState<string>(organization.customDomain);
  const [customDomainStatus, setCustomDomainStatus] = useState<CustomDomainStatus>({
    DNSEnabled: false, SSLEnabled: false, Resolved: false, multipleDNS: false, multipleDNSArray: '',
  });

  const [disabled, setDisabled] = useState<boolean>(!subdomain && !!customDomain);
  // eslint-disable-next-line max-len
  const [activateEnabled, setActivateEnabled] = useState<boolean>(customDomainStatus.DNSEnabled && customDomainStatus.Resolved && customDomainStatus.SSLEnabled);
  const [activateLoading, setActivateLoading] = useState<boolean>(false);
  const [isActivated, setIsActivated] = useState<ECustomDomainEnableStatus>(organization.customDomainEnabledStatus);
  const [tempFile, setTempFile] = useState<File | undefined>();

  const getData = async (): Promise<void> => {
    try {
      const status = await dispatch(getCustomDomainStatus({
        customDomain: organization.customDomain,
        organizationId: organization.id,
      })).unwrap();
      setCustomDomainStatus(status);
      setActivateEnabled((status.DNSEnabled && status.Resolved && status.SSLEnabled) || isActivated === ECustomDomainEnableStatus.ACTIVE);
    } catch (e) {
      // Maybe do smth
    }
  };

  const handleActivateCustomDomain = async () => {
    try {
      setActivateLoading(true);
      const org = await dispatch(activateCustomDomain({
        customDomain: organization.customDomain,
        organizationId: organization.id,
        status: isActivated !== ECustomDomainEnableStatus.ACTIVE ? ECustomDomainEnableStatus.ACTIVE : ECustomDomainEnableStatus.DEACTIVATED,
      })).unwrap();
      setIsActivated(org.customDomainEnabledStatus);
      setActivateEnabled((customDomainStatus.DNSEnabled && customDomainStatus.Resolved
          && customDomainStatus.SSLEnabled) || org.customDomainEnabledStatus === ECustomDomainEnableStatus.ACTIVE);
    } catch (e) {
      // Silent catch
    }
    setActivateLoading(false);
  };

  const getUrlFromOrigin = () => {
    if (!config.app.domain) {
      return '';
    }
    const url = new URL(config.app.domain);
    return `.${url.hostname}`;
  };

  useEffect(() => {
    getData();
    if (organization) {
      setCustomDomain(organization.customDomain);
    }
  }, [organization]);

  const customHandleLogoChange = (e: ChangeEvent<HTMLInputElement>) => {
    const [file] = e.target.files || [];
    if (!validateFile(file)) return;
    setTempFile(file);
  };

  const onCustomeBackgroundUpload = async (file: File): Promise<void> => {
    if (!file) {
      setTempFile(undefined);
      return;
    }
    updateFormValue('logo', { file, tempUrl: URL.createObjectURL(file) });
    setTempFile(undefined);
  };

  return (
    <>
      <StyledSettingsTab>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box className="logo-section">
            <Box hidden={!logoUrl}>
              <AppAvatar
                name={organization?.name}
                src={logoUrl}
                className="logo"
              />
            </Box>
            <Box className="manage-logo-section">
              <Box>
                <input
                  type="file"
                  accept="image/*"
                  ref={inputRef}
                  onChange={customHandleLogoChange}
                  className="input"
                />
                <AppButton
                  startIcon={<NormalizedSvgIcon component={UploadIcon} />}
                  className="upload-button"
                  variant="primary"
                  onClick={handleUploadClick}
                >
                  Squared logo
                </AppButton>
                <AppIconButton
                  className={classNames('remove-button', { 'remove-button-hidden': !logoUrl })}
                  onClick={handleLogoRemove}
                  icon={<TrashIcon />}
                  variant="danger"
                />
              </Box>
              <Box>
                <Typography className="description">
                  JPG, PNG no more than 1 Mb
                </Typography>
              </Box>
            </Box>
          </Box>
          <CurrencySelector
            currency={currency}
            setCurrency={setCurrency}
            size={70}
          />
        </Box>
        <Box className="form-fields-section">
          <Box>
            <AppInput
              fullWidth
              label="Team name"
              defaultValue={organization?.name}
              onChange={(e) => { updateFormValue('title', e.target.value); }}
            />
          </Box>
        </Box>
        {/* Subdomain */}
        {isPremium && (
        <Box className="form-fields-section">
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <AppInput
              fullWidth
              label="Subdomain"
              defaultValue={organization?.domain}
              onChange={(e) => {
                updateFormValue('domain', e.target.value);
                setSubdomain(e.target.value);
                setDisabled(!e.target.value && !!customDomain);
              }}
              disabled={!!organization?.customDomain}
            />
            <label style={{
              paddingTop: '6px', whiteSpace: 'nowrap', fontFamily: 'Inter', color: 'rgb(112, 132, 180)',
            }}
            >
              {getUrlFromOrigin()}
            </label>
          </Box>
        </Box>
        )}
        {/* Custom domain */}
        {hasCustomDomains() && (
        <Box className="form-fields-section">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              height: '76px',
              paddingTop: '24px',
              marginTop: '10px',
              direction: 'row',
            }}
          >
            <AppInput
              fullWidth
              label="Custom Domain"
              defaultValue={customDomain}
              value={customDomain}
              disabled={!subdomain}
              onChange={(e) => {
                updateFormValue('customDomain', e.target.value);
                setCustomDomain(e.target.value);
                setDisabled(!subdomain && !!e.target.value);
              }}
            />
            {!!organization?.customDomain && (
            <AppButton
              sx={{
                marginLeft: '10px',
              }}
              className="upload-button"
              variant="primary"
              onClick={() => {
                updateFormValue('customDomain', '');
                deleteCustomDomain();
              }}
              loading={saving}
            >
              Delete
            </AppButton>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
              height: '76px',
              paddingTop: '24px',
              flexDirection: 'row',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '80px',
                alignItems: 'center',
                color: '#7084B4',
              }}
            >
              DNS:
              {' '}
              {customDomainStatus.DNSEnabled
                ? <CheckCircle sx={{ marginLeft: '5px', color: 'green' }} />
                : <HideSource sx={{ marginLeft: '5px', color: 'orange' }} />}
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '100px',
                alignItems: 'center',
                color: '#7084B4',
              }}
            >
              Resolved:
              {' '}
              {customDomainStatus.Resolved
                ? <CheckCircle sx={{ marginLeft: '5px', color: 'green' }} />
                : <HideSource sx={{ marginLeft: '5px', color: 'orange' }} />}
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                width: '80px',
                alignItems: 'center',
                color: '#7084B4',
              }}
            >
              SSL:
              {' '}
              {customDomainStatus.SSLEnabled
                ? <CheckCircle sx={{ marginLeft: '5px', color: 'green' }} />
                : <HideSource sx={{ marginLeft: '5px', color: 'orange' }} />}
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'left',
                width: '50px',
                alignItems: 'left',
              }}
            >
              <AppIconButton
                size="m"
                variant="secondary"
                onClick={getData}
                sx={{
                  marginLeft: '10px',
                }}
                icon={<Refresh />}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'right',
                width: '152px',
                alignItems: 'right',
              }}
            >
              <AppButton
                className="upload-button"
                variant="primary"
                disabled={!activateEnabled || activateLoading}
                onClick={handleActivateCustomDomain}
              >
                { isActivated !== ECustomDomainEnableStatus.ACTIVE ? 'Activate' : 'Deactivate' }
              </AppButton>
            </Box>
          </Box>
          {customDomainStatus.multipleDNS && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                height: '76px',
                paddingTop: '24px',
                flexDirection: 'row',
                color: '#7084B4',
              }}
            >
              <WarningAmber sx={{ marginRight: '5px', color: 'orange' }} />
              <Typography className="description">
                Multiple DNS records:
                {' '}
                { customDomainStatus.multipleDNSArray }
              </Typography>
            </Box>
          )}
        </Box>
        )}
        {/* Message */}
        {(isPremium || hasCustomDomains()) && (
        <Box className="form-fields-section">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              paddingLeft: '12px',
              alignItems: 'center',
              height: '56px',
              fontSize: '12px',
              color: '#7084B4',
              fontFamily: 'Inter',
            }}
          >
            <Typography className="description">
              {hasCustomDomains()
                ? 'Relogin is required once new subdomain or custom domain is set'
                : 'Relogin is required once new subdomain is set'}
            </Typography>
          </Box>
        </Box>
        )}

        <Box className="submit-section">
          <AppButton
            disabled={disabled}
            onClick={onFormChange}
            fullWidth
            loading={saving}
            variant="primary"
            size="l"
          >
            Save
          </AppButton>
        </Box>

        {(hasCustomDomains() && !!customDomain) && (
        <Box className="form-fields-section">
          <p style={{ color: '#7084B4', fontFamily: 'Inter' }}>
            Create a DNS A record pointed at your cluster IP address
            { config.app.approximatedClusterIP }
          </p>
          <ul style={{
            listStyleType: 'inherit',
            fontSize: '14px',
            color: '#7084B4',
            fontFamily: 'Inter',
          }}
          >
            <li style={{ paddingBottom: '7px' }}>
              Example A record for an apex/root domain:
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 400 }} aria-label="customized table">
                  <TableHead>
                    <TableRow sx={{ backgroundColor: 'rgba(0, 0, 0, 0.04)' }}>
                      <TableCell>Type</TableCell>
                      <TableCell align="right">Hostname/Name</TableCell>
                      <TableCell align="right">Address/Content/Value</TableCell>
                      <TableCell align="right">TTL</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        A
                      </TableCell>
                      <TableCell align="right">@</TableCell>
                      <TableCell align="right">{ config.app.approximatedClusterIP }</TableCell>
                      <TableCell align="right">3600</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </li>
            <li>
              Or with subdomain:
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 400 }} aria-label="customized table">
                  <TableHead>
                    <TableRow sx={{ backgroundColor: 'rgba(0, 0, 0, 0.04)' }}>
                      <TableCell>Type</TableCell>
                      <TableCell align="right">Hostname/Name</TableCell>
                      <TableCell align="right">Address/Content/Value</TableCell>
                      <TableCell align="right">TTL</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        A
                      </TableCell>
                      <TableCell align="right">subdomain</TableCell>
                      <TableCell align="right">{ config.app.approximatedClusterIP }</TableCell>
                      <TableCell align="right">3600</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </li>
          </ul>
        </Box>
        )}
      </StyledSettingsTab>
      <Dialog
        open={!!tempFile}
        onClose={() => setTempFile(undefined)}
        keepMounted={false}
        PaperProps={{
          sx: {
            width: 'auto',
            maxHeight: '80vh',
            height: 'auto',
            padding: '24px',
          },
        }}
      >
        <ImageCrop
          image={tempFile}
          onCropAccept={onCustomeBackgroundUpload}
          onCancel={() => setTempFile(undefined)}
          customAspect={1}
        />
      </Dialog>
    </>
  );
};
