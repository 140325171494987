import { Box, Grid, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { isMobile } from 'react-device-detect';
import classNames from 'classnames';
import OrganizationUserInvite from './OrganizationInfoUserInvite/OrganizationUserInvite';
import { AppAvatar } from '../../../shared/AppAvatar';
import NormalizedSvgIcon from '../../../shared/NormalizedSvgIcon/NormalizedSvgIcon';
import Copy from '../../../icons/Copy.svg';
import { useOrganization } from '../../../hooks/useOrganization';

interface Props {
  inviteAvailable: boolean;
}

const OrganizationInfoLowerSection = ({
  inviteAvailable,
}: Props): JSX.Element => {
  const { enqueueSnackbar } = useSnackbar();
  const { organization } = useOrganization();

  const handleCopyId = async () => {
    await navigator.clipboard.writeText(organization?.orgSupportId);
    enqueueSnackbar('Link copied', {
      variant: 'success',
    });
  };

  return (
    <Grid
      container
      className={classNames('org-panel-section', { 'org-panel-section-mobile': isMobile })}
      px={isMobile ? 2 : 0}
    >
      <Grid
        xs={12}
        md={6}
        item
        sx={{
          display: 'inline-flex',
          gap: '12px',
          alignItems: 'center',
        }}
      >
        <Box hidden={!organization?.organizationLogo}>
          <AppAvatar
            src={organization?.organizationLogo}
            sx={{
              borderRadius: '10px',
              border: (theme) => `1px solid ${theme.palette.grey[100]}`,
            }}
          />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          <Box>
            <Typography sx={{
              color: '#1A1929',
            }}
            >
              {organization?.name}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'inline-flex',
              alignContent: 'center',
              cursor: 'pointer',
            }}
            onClick={handleCopyId}
          >
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
            }}
            >
              <Typography sx={{
                fontSize: '12px',
                color: '#7084B4',
                verticalAlign: 'center',
              }}
              >
                ID
                {' '}
                {organization?.orgSupportId}
              </Typography>
            </Box>
            <Box
              sx={{
                marginLeft: '5px',
              }}
            >
              <NormalizedSvgIcon component={Copy} />
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item md={6} justifyContent="flex-end" display={isMobile ? 'none' : 'flex'}>
        {
        inviteAvailable && (
        <OrganizationUserInvite />
        )
      }
      </Grid>
    </Grid>
  );
};

export default OrganizationInfoLowerSection;
