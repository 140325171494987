import axios, {
  AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse,
} from 'axios';
import jwtDecode from 'jwt-decode';
import storage from 'redux-persist/lib/storage';
import { config } from './config';

export class ApiService {
  protected ax: AxiosInstance;

  constructor(routePrefix: string, baseUrl?: string, apiPrefix?: string) {
    this.ax = axios.create({
      withCredentials: true,
      baseURL: baseUrl ? new URL(apiPrefix + routePrefix, baseUrl).href
        : new URL(config.api.prefix + routePrefix, config.api.origin).href,
    });

    this.ax.interceptors.request.use((requestConfig) => {
      requestConfig.headers = {
        // @ts-ignore
        ...config.headers,
        Authorization: `Bearer ${localStorage.getItem('token') ?? sessionStorage.getItem('token')}`,
      };
      return requestConfig;
    });
    this.ax.interceptors.response.use(
      (response: AxiosResponse) => response,
      (error: AxiosError<{ message: string, statusCode: number }>) => {
        if (error.response) {
          const { response } = error;
          // @ts-ignore
          error.code = response.status;
          error.message = response.data?.message ? response.data.message : response?.statusText;
          // @ts-ignore
          error.data = error.response.data;
          if (response?.status === 401
            || (!!localStorage.getItem('token') && jwtDecode(localStorage.getItem('token')).exp * 1000 < new Date().getTime())) {
            localStorage.clear();
            storage.removeItem('persist:auth');
            storage.removeItem('persist:root');
            window.location.reload();
          }
        }
        return Promise.reject(error);
      },
    );
  }

  async get<T>(path: string, requestConfig?: AxiosRequestConfig): Promise<T> {
    const response: AxiosResponse = await this.ax.get<AxiosResponse<T>>(path, requestConfig);
    return response.data;
  }

  async post<T>(path: string, data?: any, requestConfig?: AxiosRequestConfig): Promise<T> {
    const response: AxiosResponse = await this.ax.post<AxiosResponse<T>>(path, data, requestConfig);
    return response.data;
  }

  async put<T>(path: string, data: any, requestConfig?: AxiosRequestConfig): Promise<T> {
    const response: AxiosResponse = await this.ax.put<AxiosResponse<T>>(path, data, requestConfig);
    return response.data;
  }

  async delete<T>(path: string, data?: any): Promise<T> {
    const response: AxiosResponse = await this.ax.delete<AxiosResponse<T>>(path, data);
    return response.data;
  }

  async patch<T>(path: string, data?: any): Promise<T> {
    const response: AxiosResponse = await this.ax.patch<AxiosResponse<T>>(path, data);
    return response.data;
  }
}
