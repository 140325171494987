import { Box } from '@mui/material';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import {
  CellHeader,
  CellInfo,
  CustomDomainDashedBorderBox,
  DomainCellTitle,
  DomainTable,
  DomianTableListElement,
  InfoTableBullet,
  InfoTableSubtitle,
  InfoTableTitle,
  InfoTableWrapper,
  InstructionsPaper,
  InstructionsTable,
  TableRowWrapper,
} from '../../Styles/CustomDomainTabStyles';
import { config } from '../../../../../core/config';

const PremiumUserGuide = () => (
  <Box>
    <InstructionsPaper
      className={classNames({ 'instructions-paper-mobile': isMobile })}
    >
      <InfoTableTitle
        className={classNames({ 'info-table-title-mobile': isMobile })}
      >
        To set custom domain for your Stageset account perform the following steps:
      </InfoTableTitle>
      <InstructionsTable>
        <DomianTableListElement>
          <InfoTableBullet>
            1
          </InfoTableBullet>
          <InfoTableSubtitle>
            Specify
            <span>
            &nbsp;subdomain&nbsp;
            </span>
            for your organization. It should include only latin symbols (a-z) and digits (0-9)
          </InfoTableSubtitle>
        </DomianTableListElement>
        <DomianTableListElement>
          <InfoTableBullet>
            2
          </InfoTableBullet>
          <InfoTableSubtitle>
            Once subdomain is set specify
            <span>
            &nbsp;custom domain&nbsp;
            </span>
            that you own and save.
            <br />
            Custom domain can look like
            <span>
              &nbsp;&ldquo;company.com&ldquo;&nbsp;
            </span>
            (first level domain) or it can be
            <span>
              &nbsp;&ldquo;subname.company.com&ldquo;&nbsp;
            </span>
            (second level domain).
          </InfoTableSubtitle>
        </DomianTableListElement>
        <DomainTable>
          <DomianTableListElement>
            <InfoTableBullet>
              3
            </InfoTableBullet>
            <InfoTableSubtitle>
              Add new
              <span>
                &nbsp;DNS record&nbsp;
              </span>
              for your
              <span>
                &nbsp;custom domain&nbsp;
              </span>
              <br />
              Particular steps may vary depending on the registrator (Name.com, GoDaddy.com and so on).
              Typically following record is sufficient to serve this purpose.
            </InfoTableSubtitle>
          </DomianTableListElement>
          <InfoTableWrapper>
            <TableRowWrapper>
              <DomainCellTitle
                className="cell-title-grey"
              >
                Type
              </DomainCellTitle>
              <CustomDomainDashedBorderBox />
              <CellInfo>
                A
              </CellInfo>
            </TableRowWrapper>
            <TableRowWrapper>
              <DomainCellTitle
                className="cell-title-grey"
              >
                Address/Content/Value
              </DomainCellTitle>
              <CustomDomainDashedBorderBox />
              <CellInfo>
                { config.app.approximatedClusterIP }
              </CellInfo>
            </TableRowWrapper>
            <TableRowWrapper>
              <DomainCellTitle
                className="cell-title-grey"
              >
                TTL
              </DomainCellTitle>
              <CustomDomainDashedBorderBox />
              <CellInfo>
                3600
              </CellInfo>
            </TableRowWrapper>
            <CellHeader>If your domain is the first level domain</CellHeader>
            <TableRowWrapper>
              <DomainCellTitle
                className="cell-title-grey"
              >
                Hostname/Name
              </DomainCellTitle>
              <CustomDomainDashedBorderBox />
              <CellInfo>
                @
              </CellInfo>
            </TableRowWrapper>
            <CellHeader>If your domain is the second level domain</CellHeader>
            <TableRowWrapper>
              <DomainCellTitle
                className="cell-title-grey"
              >
                Hostname/Name
              </DomainCellTitle>
              <CustomDomainDashedBorderBox />
              <CellInfo>
                subname
              </CellInfo>
            </TableRowWrapper>
          </InfoTableWrapper>
        </DomainTable>

        <DomianTableListElement>
          <InfoTableBullet>
            4
          </InfoTableBullet>
          <InfoTableSubtitle>
            Periodically check your DNS status by clicking on
            <span>
                &nbsp;Update&nbsp;
            </span>
            button.
            It can take some time but typically it is a matter of several minutes.
          </InfoTableSubtitle>
        </DomianTableListElement>
        <DomianTableListElement>
          <InfoTableBullet>
            5
          </InfoTableBullet>
          <InfoTableSubtitle>
            Once DNS status is confirmed press
            <span>
                &nbsp;Activate&nbsp;
            </span>
            button to use your
            <span>
                &nbsp;custom domain&nbsp;.
            </span>
          </InfoTableSubtitle>
        </DomianTableListElement>
        <DomainCellTitle>
          If you faced any problems, please contact us.
        </DomainCellTitle>
      </InstructionsTable>
    </InstructionsPaper>
  </Box>
);

export default PremiumUserGuide;
