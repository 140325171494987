import {
  createContext, useContext, useEffect, useState,
} from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/stateHooks';
import { FielItemsRecord, FileItem, UploadItemStatus } from '../../../../shared/FileItems/FileItems';
import {
  fileItemUpload, selectCurrentEntityFiles, selectCurrentStage, selectFileItem,
} from '../../ProcessFields/lib/processItemsSlice';
import { selectOrganizationId, selectUserId } from '../../../core/store/appState/appState';
import { selectProcessId } from '../../../routes-old/process/state/processSlice';
import { LibraryApi } from '../../../api/LibraryApi';

type TFileUploadContext = {
  entityId: string;
  onUpload: (f?: any, fieldId?: string) => Promise<string>;
  [key: string]: any;
};

const generateId = (entityFiles: FielItemsRecord): string => {
  const id = window.crypto.randomUUID();
  if (entityFiles && entityFiles[id]) {
    return generateId(entityFiles);
  }
  return id;
};

export const FilesUploadContext = createContext<TFileUploadContext>(
  {
    entityId: '',
    onUpload: async () => '',
  },
);

interface Props {
  entityId: string;
  children: JSX.Element | Array<JSX.Element | null | false>;
}

const libraryApi: LibraryApi = new LibraryApi();

export const FileUploadPrivider = (
  {
    entityId,
    children,
  }: Props,
) => {
  const dispatch = useAppDispatch();
  const uploaderOrganization = useAppSelector(selectOrganizationId);
  const userId = useAppSelector(selectUserId);
  const processId = useAppSelector(selectProcessId);

  // get file => generate ID => get local url => crete local file item data => upload file => get actual file item
  const onUpload = async (file: File, fieldId?: string): Promise<string> => {
    const currentFiles = await dispatch(selectCurrentEntityFiles(entityId)).unwrap();
    const fileId = generateId(currentFiles);
    dispatch(fileItemUpload(
      {
        file,
        fileType: file.type,
        entityId,
        fileId,
        name: file.name,
        size: file.size,
        url: URL.createObjectURL(file),
        status: UploadItemStatus.IN_PROGRESS,
        userId,
        processId,
        uploaderOrganization,
        fieldId,
      },
    ));
    return fileId;
  };

  const [context, setContext] = useState<TFileUploadContext>({ entityId, onUpload });

  useEffect(() => {
    setContext({ entityId, onUpload });
  }, [entityId]);

  return (
    <FilesUploadContext.Provider value={context}>
      {children}
    </FilesUploadContext.Provider>
  );
};

export const useFileUploadContext = () => {
  const c = useContext(FilesUploadContext);
  return c;
};

export const useFileItemSelect = (fileId?: string) => {
  const { entityId } = useContext(FilesUploadContext);
  const fileItem = useAppSelector((state) => selectFileItem(state, entityId, fileId));
  return fileItem;
};

export const useLibraryFile = async (fileId?: string): Promise<FileItem> => {
  const fileItem = await libraryApi.getUrlByFileUid(fileId || '');

  return {
    name: fileItem.name,
    url: fileItem.url,
    subType: fileItem.fileSubType,
    linkType: fileItem.linkType,
    size: 0,
    status: UploadItemStatus.UPLOADED,
  };
};

export const useItemFileUpload = () => {
  const dispatch = useAppDispatch();
  const currentStage = useAppSelector(selectCurrentStage);
  const userId = useAppSelector(selectUserId);
  const processId = useAppSelector(selectProcessId);
  const uploaderOrganization = useAppSelector(selectOrganizationId);

  const entityId = currentStage?.id;

  const onUpload = async (file: File, {
    fieldId,
    itemId,
    docId,
  }: {
    fieldId?: string,
    itemId?: string,
    docId?: string,
  } = {}): Promise<string> => {
    let fileId: string | undefined;
    if (!docId) {
      const currentFiles = await dispatch(selectCurrentEntityFiles(entityId)).unwrap();
      fileId = generateId(currentFiles);
    } else {
      fileId = docId;
    }
    dispatch(fileItemUpload(
      {
        file,
        fileType: file.type,
        entityId: itemId ?? entityId,
        fileId,
        name: file.name,
        size: file.size,
        url: URL.createObjectURL(file),
        status: UploadItemStatus.IN_PROGRESS,
        userId,
        processId,
        uploaderOrganization,
        fieldId,
      },
    ));
    return fileId;
  };

  return { onUpload };
};
