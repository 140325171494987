import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { GridRowsProp } from '@mui/x-data-grid';
import { useEffect } from 'react';
import { CrossDimensionDataGrid } from '../../shared/CrossDimensionDataGrid/CrossDimensionDataGrid';
import { GridItemSkeleton, GridTitleItemSkeleton, skeletonRows } from '../../routes-old/deals/DealsHelpers/DealsHelpers';
import { NameCell } from '../../features/Organization/OrganizationUsersDataGrid/NameCell';
import { LibraryGrid } from './styles/LibraryStyle';
import { EFileGridColumns } from './helpers/library.helper';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import {
  allProductsSelector, getProductItems, selectLoadingStatus, selectProductsFirstFetchStatus,
} from './lib/librarySlice';
import { ExtendedGridColumns } from '../../types/mobile-table';
import { ProductWithOwnerDTO } from '../../../shared/library/LibraryDTO';
import LibraryGridItemMenu from './LibraryGridItemMenu';
import { EStateStatus } from '../../core/commonTypes';
import { PRICING_PERIODS, PricingPeriod } from '../../../shared/pricing-table/PricingTableTypes';
import { selectLibraryProductFilterParams, selectLibraryProductFilterSearch } from './lib/libraryProductFilterSlice';
import BagIcon from '../../icons/bag20px.svg';

interface Props {
  onCreateProductItemDialogOpen: (item?: ProductWithOwnerDTO) => void;
}

const LibraryProductGrid = ({
  onCreateProductItemDialogOpen,
}: Props): JSX.Element => {
  const products: ProductWithOwnerDTO[] = useAppSelector(allProductsSelector);
  const productsTotalCount = products ? products.length : 0;

  const dispatch = useAppDispatch();
  const firstFetchStatus = useAppSelector(selectProductsFirstFetchStatus);
  const status = useAppSelector(selectLoadingStatus);
  const filterParams = useAppSelector(selectLibraryProductFilterParams);
  const search = useAppSelector(selectLibraryProductFilterSearch);

  useEffect(() => {
    if (status !== EStateStatus.LOADING) {
      dispatch(getProductItems({}));
    }
  }, []);

  useEffect(() => {
    dispatch(getProductItems({}));
  }, [filterParams, search]);

  const columns: ExtendedGridColumns = [
    {
      field: EFileGridColumns.name,
      sortable: true,
      headerName: 'Name',
      align: 'left',
      cellClassName: 'pro-grid-cell',
      flex: 5,
      renderCell: ({
        value,
      }) => (
        value !== 'skeleton' ? (
          <Box
            sx={{
              display: 'flex',
              gap: '4px',
            }}
          >
            <BagIcon />
            <Typography
              sx={{
                fontWeight: '500',
              }}
            >
              {value}
            </Typography>
          </Box>
        ) : (
          <GridTitleItemSkeleton />
        )
      ),
    },
    {
      field: EFileGridColumns.currency,
      sortable: true,
      headerName: 'Currency',
      align: 'left',
      cellClassName: 'pro-grid-cell',
      flex: 1,
      renderCell: ({
        value,
      }) => (
        value !== 'skeleton' ? (
          <Box
            sx={{
              display: 'flex',
              gap: '4px',
            }}
          >
            <Typography
              sx={{
                fontWeight: '400',
              }}
            >
              {value}
            </Typography>
          </Box>
        ) : (
          <GridTitleItemSkeleton />
        )
      ),
    },
    {
      field: EFileGridColumns.model,
      sortable: true,
      headerName: 'Model',
      align: 'left',
      cellClassName: 'pro-grid-cell',
      flex: 1,
      renderCell: ({
        value,
      }) => (
        value !== 'skeleton' ? (
          <Box
            sx={{
              display: 'flex',
              gap: '4px',
            }}
          >
            <Typography
              sx={{
                fontWeight: '400',
              }}
            >
              {value}
            </Typography>
          </Box>
        ) : (
          <GridTitleItemSkeleton />
        )
      ),
    },
    {
      field: EFileGridColumns.updated,
      sortable: true,
      headerName: 'Updated',
      align: 'left',
      cellClassName: 'pro-grid-cell',
      flex: 1,
      renderCell: ({
        value,
      }) => (
        value !== 'skeleton' ? (
          <Box
            sx={{
              display: 'flex',
              gap: '4px',
            }}
          >
            <Typography
              sx={{
                fontWeight: '400',
              }}
            >
              {moment(value).format('DD.MM.YYYY')}
            </Typography>
          </Box>
        ) : (
          <GridTitleItemSkeleton />
        )
      ),
    },
    {
      field: EFileGridColumns.menu,
      sortable: true,
      headerName: '',
      align: 'left',
      cellClassName: 'pro-grid-cell',
      flex: 0.5,
      renderCell: ({
        value,
      }) => (
        value !== 'skeleton'
          ? (
            <Box sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
            >
              <LibraryGridItemMenu
                item={value}
                onCreateFileItemDialogOpen={
                  (item: any) => { }
                }
                onCreateProductItemDialogOpen={onCreateProductItemDialogOpen}
              />
            </Box>
          ) : (
            null)
      ),
    },
  ];

  let rows: GridRowsProp = [];
  if (products.length) {
    rows = products.map((product) => ({
      ...product,
      [EFileGridColumns.name]: product.name,
      [EFileGridColumns.currency]: product.currency,
      [EFileGridColumns.model]: product.pricingPeriod ? PRICING_PERIODS[product.pricingPeriod as PricingPeriod].displayText : '',
      [EFileGridColumns.updated]: product.updated,
      [EFileGridColumns.menu]: product,
    }));
  }

  return (
    <Box
      id="my-deals-tab-container"
      sx={{
        display: 'flex',
        flex: 1,
        height: '100%',
        flexDirection: 'column',
      }}
    >
      <Box sx={{
        display: 'inline-flex',
        gap: '4px',
        marginBottom: '12px',
        marginLeft: '12px',
      }}
      >
        {
          firstFetchStatus ? (
            <>
              <Typography sx={{
                fontSize: '16px',
                color: (theme) => theme.palette.grey[600],
              }}
              >
                {productsTotalCount}
              </Typography>
              <Typography sx={{
                fontSize: '14px',
                color: (theme) => theme.palette.grey[400],
                paddingTop: '2px',
              }}
              >
                products
              </Typography>
            </>
          ) : (
            <GridItemSkeleton />
          )
        }
      </Box>
      <CrossDimensionDataGrid
        rows={rows}
        columns={columns}
        getId={(row) => row.id}
        renderMobileTitle={(row) => (
          <NameCell
            name={row[EFileGridColumns.name]}
            title={row[EFileGridColumns.model]}
            avatarSrc={row[EFileGridColumns.owner]?.avatarSrc}
          />
        )}
        renderMobileMenu={(row) => {
          const value = row[EFileGridColumns.menu];
          return (
            <Box sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
            }}
            >
              <LibraryGridItemMenu
                item={value}
                onCreateFileItemDialogOpen={
                  (item: any) => { }
                }
                onCreateProductItemDialogOpen={onCreateProductItemDialogOpen}
              />
            </Box>
          );
        }}
        renderDesktopGridComponent={(props) => (
          <LibraryGrid
            // loading={processesLoadingJobs > 0}
            disableSelectionOnClick
            disableColumnMenu
            columns={columns}
            rows={firstFetchStatus ? rows : skeletonRows} // ToDo
            headerHeight={36}
            rowHeight={65}
            getRowClassName={(params) => (params.row.status === 1 ? 'highlighted_row' : '')}
            hideFooter
          />
        )}
      />
    </Box>
  );
};

export default LibraryProductGrid;
