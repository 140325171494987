import { Box } from '@mui/material';
import 'react-image-crop/dist/ReactCrop.css';
import BackgroundColorPickerPanel from './BackgroundColorPickerPanel';
import DealCustomizationBannerSection from './DealCustomizationBannerSection';
import { greySidebarContrastText } from '../../core/utils/greyContrastText';

const DealCustomizationMenu = (): JSX.Element => (
  <Box
    sx={{
      display: 'flex',
      flexWrap: 'wrap',
      gap: '24px',
      flexDirection: 'column',
    }}
  >
    <DealCustomizationBannerSection />
    <Box
      sx={{
        padding: '16px',
        borderRadius: '16px',
        backgroundColor: (theme) => greySidebarContrastText(theme, 0.1),
        border: '1px solid ',
        borderColor: (theme) => greySidebarContrastText(theme, 0.2),
      }}
    >
      <BackgroundColorPickerPanel />
    </Box>
  </Box>
);

export default DealCustomizationMenu;
