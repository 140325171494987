/* eslint-disable max-len */

import { SvgIconProps } from '@mui/material';
import { RGBColor } from 'react-color';
import {
  SvgBackground1,
  SvgBackground2,
  SvgBackground3,
  SvgBackground4,
  SvgBackground5,
  SvgBackground6,
  SvgBackground7,
  SvgBackground8,
  SvgBackground9,
  SvgBackground10,
} from '../../../common/SvgBackgrounds/SvgBackgrounds';

export const DefaultImgBackgroundsPreview = [
  'https://d2nfbjsnctzi3k.cloudfront.net/background-templates/1-min.jpg',
  'https://d2nfbjsnctzi3k.cloudfront.net/background-templates/2-min.jpg',
  'https://d2nfbjsnctzi3k.cloudfront.net/background-templates/3-min.jpg',
  'https://d2nfbjsnctzi3k.cloudfront.net/background-templates/4-min.jpg',
  'https://d2nfbjsnctzi3k.cloudfront.net/background-templates/5-min.jpg',
];

export const DefaultImgBackgrounds = [
  'https://d2nfbjsnctzi3k.cloudfront.net/background-templates/1.jpg',
  'https://d2nfbjsnctzi3k.cloudfront.net/background-templates/2.jpg',
  'https://d2nfbjsnctzi3k.cloudfront.net/background-templates/3.jpg',
  'https://d2nfbjsnctzi3k.cloudfront.net/background-templates/4.jpg',
  'https://d2nfbjsnctzi3k.cloudfront.net/background-templates/5.jpg',
];

enum EColorSchemasVariants {
  single = 'single',
  doubleLight = 'doubleLight',
  doubleContrast = 'doubleContrast',
  doubleDark = 'doubleDark',
  triple = 'triple',
  quadruple = 'quadruple',
}

enum EColorSchemasNames {
  blue = 'blue',
  darkBlue = 'darkBlue',
  green = 'green',
  red = 'red',
  yellow = 'yellow',
  darkGrey = 'darkGrey',
  // deepRed = 'deepRed',
}

type TColorSchemasNames = Record<EColorSchemasNames, string>;
type TColorSchemasVariants = Record<EColorSchemasVariants, { [K in keyof TColorSchemasNames]: string[] }>;

// string[] => Light -> dark

export const soldiBgColors = ['#0073F5', '#313EA7', '#298D9B', '#FF5045', '#FFC839', '#383F51'];

const standardColorsArrays = {
  blue: ['#8FC3FF', '#5CA8FF', '#1F88FF', '#0073F5'],
  darkBlue: ['#CEFFFF', '#AAF3FF', '#46E1F7', '#00CBE8'],
  green: ['#C7FFC5', '#8EFF84', '#0CF34C', '#1EE11E'],
  red: ['#FFCBD0', '#FF9793', '#F4706C', '#FF5045'],
  // deepRed: ['#CF253F', '#CF253F', '#CF253F', '#CF253F'],
  yellow: ['#FEFAD2', '#FFF6A4', '#FDEE7A', '#FFE600'],
  darkGrey: ['#ACB0B8', '#747B8C', '#4D566C', '#2F3646'],
};

export const extendedColorScheme:TColorSchemasVariants = {
  single: {
    blue: [standardColorsArrays.blue[3]],
    darkBlue: [standardColorsArrays.darkBlue[3]],
    green: [standardColorsArrays.green[3]],
    red: [standardColorsArrays.red[3]],
    // deepRed: [standardColorsArrays.deepRed[3]],
    yellow: [standardColorsArrays.yellow[3]],
    darkGrey: [standardColorsArrays.darkGrey[3]],
  },
  doubleLight: {
    blue: [standardColorsArrays.blue[0], standardColorsArrays.blue[2]],
    darkBlue: [standardColorsArrays.darkBlue[0], standardColorsArrays.darkBlue[2]],
    green: [standardColorsArrays.green[0], standardColorsArrays.green[2]],
    red: [standardColorsArrays.red[0], standardColorsArrays.red[2]],
    yellow: [standardColorsArrays.yellow[0], standardColorsArrays.yellow[2]],
    darkGrey: [standardColorsArrays.darkGrey[0], standardColorsArrays.darkGrey[2]],
    // deepRed: [standardColorsArrays.deepRed[0], standardColorsArrays.deepRed[2]],
  },
  doubleContrast: {
    blue: [standardColorsArrays.blue[1], standardColorsArrays.blue[3]],
    darkBlue: [standardColorsArrays.darkBlue[1], standardColorsArrays.darkBlue[3]],
    green: [standardColorsArrays.green[1], standardColorsArrays.green[3]],
    red: [standardColorsArrays.red[1], standardColorsArrays.red[3]],
    yellow: [standardColorsArrays.yellow[1], standardColorsArrays.yellow[3]],
    darkGrey: [standardColorsArrays.darkGrey[1], standardColorsArrays.darkGrey[3]],
    // deepRed: [standardColorsArrays.deepRed[1], standardColorsArrays.deepRed[3]],
  },
  doubleDark: {
    blue: [standardColorsArrays.blue[2], standardColorsArrays.blue[3]],
    darkBlue: [standardColorsArrays.darkBlue[2], standardColorsArrays.darkBlue[3]],
    green: [standardColorsArrays.green[2], standardColorsArrays.green[3]],
    red: [standardColorsArrays.red[2], standardColorsArrays.red[3]],
    yellow: [standardColorsArrays.yellow[2], standardColorsArrays.yellow[3]],
    darkGrey: [standardColorsArrays.darkGrey[2], standardColorsArrays.darkGrey[3]],
    // deepRed: [standardColorsArrays.deepRed[2], standardColorsArrays.deepRed[3]],
  },
  triple: {
    blue: [standardColorsArrays.blue[1], standardColorsArrays.blue[2], standardColorsArrays.blue[3]],
    darkBlue: [standardColorsArrays.darkBlue[1], standardColorsArrays.darkBlue[2], standardColorsArrays.darkBlue[3]],
    green: [standardColorsArrays.green[1], standardColorsArrays.green[2], standardColorsArrays.green[3]],
    red: [standardColorsArrays.red[1], standardColorsArrays.red[2], standardColorsArrays.red[3]],
    yellow: [standardColorsArrays.yellow[1], standardColorsArrays.yellow[2], standardColorsArrays.yellow[3]],
    darkGrey: [standardColorsArrays.darkGrey[1], standardColorsArrays.darkGrey[2], standardColorsArrays.darkGrey[3]],
    // deepRed: [standardColorsArrays.deepRed[1], standardColorsArrays.deepRed[2], standardColorsArrays.deepRed[3]],
  },
  quadruple: { ...standardColorsArrays },
};

export type SvgBackgroundColorChangeEventData = { layer: number, color: string };

export type SvgBackgroundColorChangeEvent = Event & { detail: SvgBackgroundColorChangeEventData };

export type SvgBackgroundProps = SvgIconProps & { colors?: Array<string | undefined> };

export type SVGBackgroundItemsType = {
  Element: (props?: SvgBackgroundProps) => JSX.Element,
  localId: number;
  colorsCount: number;
  defaultColors: string[]
  schemeVariant: EColorSchemasVariants,
};

export const DefaultSvgBackgrounds: SVGBackgroundItemsType[] = [
  {
    Element: (props) => <SvgBackground5 {...props} />,
    localId: 0,
    colorsCount: 2,
    defaultColors: extendedColorScheme[EColorSchemasVariants.doubleContrast].blue,
    schemeVariant: EColorSchemasVariants.doubleContrast,
  },
  {
    Element: (props) => <SvgBackground2 {...props} />,
    localId: 1,
    colorsCount: 1,
    defaultColors: extendedColorScheme[EColorSchemasVariants.single].blue,
    schemeVariant: EColorSchemasVariants.single,
  },
  {
    Element: (props) => <SvgBackground3 {...props} />,
    localId: 2,
    colorsCount: 1,
    defaultColors: extendedColorScheme[EColorSchemasVariants.triple].blue,
    schemeVariant: EColorSchemasVariants.triple,
  },
  {
    Element: (props) => <SvgBackground4 {...props} />,
    localId: 3,
    colorsCount: 1,
    defaultColors: extendedColorScheme[EColorSchemasVariants.doubleContrast].blue,
    schemeVariant: EColorSchemasVariants.doubleContrast,
  },
  {
    Element: (props) => <SvgBackground6 {...props} />,
    localId: 4,
    colorsCount: 2,
    defaultColors: extendedColorScheme[EColorSchemasVariants.doubleLight].blue,
    schemeVariant: EColorSchemasVariants.doubleLight,
  },
  {
    Element: (props) => <SvgBackground7 {...props} />,
    localId: 5,
    colorsCount: 4,
    defaultColors: extendedColorScheme[EColorSchemasVariants.quadruple].blue,
    schemeVariant: EColorSchemasVariants.quadruple,
  },
  {
    Element: (props) => <SvgBackground8 {...props} />,
    localId: 6,
    colorsCount: 4,
    defaultColors: extendedColorScheme[EColorSchemasVariants.quadruple].blue,
    schemeVariant: EColorSchemasVariants.quadruple,
  },
  {
    Element: (props) => <SvgBackground9 {...props} />,
    localId: 7,
    colorsCount: 3,
    defaultColors: extendedColorScheme[EColorSchemasVariants.triple].blue,
    schemeVariant: EColorSchemasVariants.triple,
  },
  {
    Element: (props) => <SvgBackground10 {...props} />,
    localId: 8,
    colorsCount: 2,
    defaultColors: extendedColorScheme[EColorSchemasVariants.doubleDark].blue,
    schemeVariant: EColorSchemasVariants.doubleDark,
  },
  {
    Element: (props) => <SvgBackground1 {...props} />,
    localId: 9,
    colorsCount: 1,
    defaultColors: extendedColorScheme[EColorSchemasVariants.single].blue,
    schemeVariant: EColorSchemasVariants.single,
  },
];

export const SvgBackgroundElement = ({
  id,
  props,
}: {
  id: number,
  props?: SvgBackgroundProps,
}): JSX.Element | null => {
  const SvgBackground = DefaultSvgBackgrounds.find((svgItem) => svgItem.localId === id);
  if (SvgBackground) {
    return (
      <SvgBackground.Element
        {...props}
      />
    );
  }
  return null;
};

export const findSvgBackgroundItem = (id: number):SVGBackgroundItemsType | undefined => DefaultSvgBackgrounds.find((svgItem) => svgItem.localId === id);

export const rgbaObjToString = (rgbObj: RGBColor) => `rgba(${rgbObj.r}, ${rgbObj.g}, ${rgbObj.b}, ${rgbObj.a})`;
