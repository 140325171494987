import { Typography } from '@mui/material';

const TemplateListTitle = ({ title }: { title: string | React.ReactNode }) => (
  <Typography
    sx={{
      fontSize: '16px',
      lineHeight: '24px',
      color: (theme) => theme.palette.grey[600],
    }}
  >
    {title}
  </Typography>
);

export default TemplateListTitle;
