import { ESubscriptionType } from '../../../shared/Subscription';
import { ESettingsTabs } from '../../features/Layout/lib/fieldsLayoutSlice';
import CustomDomainTab from '../../routes-old/account/SettingsTabs/CustomDomainTab';
import IntegrationsTab from '../../routes-old/account/SettingsTabs/IntegrationsTab';
import OrganizationTab from '../../routes-old/account/SettingsTabs/OrganizationTab';
import ProfileTab from '../../routes-old/account/SettingsTabs/ProfileTab';
import SubscriptionTab from '../../routes-old/account/SettingsTabs/SubscriptionTab';
import TeamTab from '../../routes-old/account/SettingsTabs/TeamTab';
import SettingsPublicApiToken from '../../routes-old/account/SettingsTabs/public-api-settings/SettingsPublicApiToken';

export type SettingsMenuItem = {
  text: string,
  subscription: ESubscriptionType,
  settingsTab: ESettingsTabs,
  component: JSX.Element
  requirePublicApiEnabled?: boolean,
};

export const settingsMenuItems: SettingsMenuItem[] = [
  {
    text: 'Settings_Profile',
    subscription: ESubscriptionType.SELLER,
    settingsTab: ESettingsTabs.PROFILE,
    component: <ProfileTab />,
  },
  {
    text: 'Team_Team',
    subscription: ESubscriptionType.SELLER_BASIC,
    settingsTab: ESettingsTabs.SETTINGS_TEAM,
    component: <TeamTab />,
  },
  {
    text: 'Settings_Custom_Domain',
    subscription: ESubscriptionType.SELLER_BASIC,
    settingsTab: ESettingsTabs.CUSTOM_DOMAIN,
    component: <CustomDomainTab />,
  },
  {
    text: 'Settings_Organization',
    subscription: ESubscriptionType.SELLER,
    settingsTab: ESettingsTabs.ORGANIZATION,
    component: <OrganizationTab />,
  },
  {
    text: 'Subscriptions_Subscription',
    subscription: ESubscriptionType.SELLER,
    settingsTab: ESettingsTabs.SUBSCRIPTION,
    component: <SubscriptionTab />,
  },
  {
    text: 'Integrations_Integrations',
    subscription: ESubscriptionType.SELLER_PREMIUM,
    settingsTab: ESettingsTabs.INTEGRATIONS,
    component: <IntegrationsTab />,
  },
  {
    text: 'Settings_Public_Api_Token',
    subscription: ESubscriptionType.SELLER,
    settingsTab: ESettingsTabs.PUBLIC_API_TOKENS,
    component: <SettingsPublicApiToken />,
    requirePublicApiEnabled: true,
  },
];
