import styled from '@emotion/styled';
import { Button, Box } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { stagesetPalette } from '../../theme/stagesetPalette';

export const NavButton = styled(Button)(() => ({
  color: '#7E7E7E',
  display: 'flex',
  alignContent: 'center',
  alignItems: 'center',
  padding: '0',
  height: '100%',
  width: '100%',
  minWidth: '36px',
  marginLeft: '30px',
  paddingLeft: '20px',
  '&.mobile': {
    display: 'none',
  },
  '&:hover': {
    color: 'black',
  },
  '& .NavButtonContent': {
    '& .NavIcon': {
      display: 'flex',
      '& svg': {
        width: '20px',
        height: '20px',
        fontSize: '20px',
      },
    },
    '& .NavTitle': {
      marginLeft: '15px',
      width: 'max-content',
      display: 'flex',
    },
  },
}));

export const NavigationLink = styled(NavLink)(() => ({
  width: '100%',
  height: '70px',
  display: 'flex',
  alignContent: 'center',
  alignItems: 'center',
  textDecoration: 'none',
  flexDirection: 'row',
  justifyContent: 'space-between',
  color: stagesetPalette.grey?.[600],
  fontSize: '16px',
  '&:hover': {
    color: 'black',
  },
  '& .LinkInfoContainer': {
    width: '100%',
    height: '70px',
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
    color: 'inherit',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    '& .LinkIcon': {
      '& svg': {
        display: 'flex',
        width: '20px',
        height: '20px',
        fontSize: '20px',
      },
    },
    '& .LinkTitle': {
      display: 'flex',
      alignContent: 'center',
      alignItems: 'center',
      marginLeft: '15px',
    },
  },
}));

export const NavigationReturn = styled(Box)(() => ({
  display: 'flex',
  color: '#7E7E7E',
  cursor: 'pointer',
  alignItems: 'center',
  '& .ReturnText': {
    marginLeft: '20px',
    width: 'auto',
    whiteSpace: 'nowrap',
    fontWeight: 500,
  },
  '&:hover': {
    color: 'black',
  },
}));
