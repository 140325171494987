import { isMobile } from 'react-device-detect';
import { Box } from '@mui/material';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import AppTopBar from '../../shared/AppTopBar/AppTopBar';
import OrganizationUserInvite
  from '../../widgets/OrganizationInfoPanel/OrganizationInfoPanelLowerSection/OrganizationInfoUserInvite/OrganizationUserInvite';
import { EUserOrganizationPermissions } from '../../../shared/permissions';
import useUserOrganizationPermissions from '../../hooks/useOrganizationPermissions';
import AppTabsWithIndicator from '../../shared/AppTabs/AppTabsWithIndicator';
import { TeamMenu } from '../../routes-old/organizationUsers/OrganizationUsers';

type Props = {
  tabValue: TeamMenu,
  onTabChange: (index: TeamMenu) => void
};

export const OrganizationUsersToolbar = ({ tabValue, onTabChange }: Props) => {
  const [checkOrganizationPermissions] = useUserOrganizationPermissions();
  const inviteAvailable: boolean = checkOrganizationPermissions(EUserOrganizationPermissions.ORGANIZATION_TEAM_INVITE);
  const teamManager: boolean = checkOrganizationPermissions(EUserOrganizationPermissions.ORGANIZATION_TEAM_MANAGE);
  const { t } = useTranslation();

  const options = [
    {
      title: t('Team_Members'), value: TeamMenu.MEMBERS,
    },
    {
      title: t('Team_Settings'), value: TeamMenu.SETTINGS,
    },
  ];

  const handleChange = (event: React.SyntheticEvent, newValue: TeamMenu) => {
    onTabChange(newValue);
  };

  const renderTabSelector = () => teamManager && (
    <Box className="tab-selector-wrapper">
      <AppTabsWithIndicator
        className="organization-users-tabs"
        ariaLabel="Users menu"
        tabValue={tabValue}
        setTabValue={handleChange}
        items={options as any[]}
      />
    </Box>
  );

  const renderToolsSlot = () => (isMobile && inviteAvailable
    ? (
      <OrganizationUserInvite />
    )
    : renderTabSelector());

  const renderSecondLevelSlot = () => isMobile && (
    <Box className="app-toolbar-content-wrapper">
      {renderTabSelector()}
    </Box>
  );

  return (
    <AppTopBar
      title={t('Team_Team')}
      toolsSlot={renderToolsSlot()}
      secondLevelSlot={renderSecondLevelSlot()}
      className={classNames('app-toolbar', { 'app-toolbar-mobile': isMobile })}
    />
  );
};
