import { Box, Popover, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import { RGBColor } from 'react-color';
import { greySidebarContrastText } from '../../core/utils/greyContrastText';
import { DropletIcon } from '../../icons/Droplex';
import BackgroundColorPicker from './BackgroundColorPicker';
import { rgbaObjToString, soldiBgColors } from './helpers/DealCustomizationMenuHelpers';
import useProcessBackground from '../../hooks/useProcessBackground';
import { isMobile } from 'react-device-detect';

const ProcessBackgroudSolidColorSettings = () => {
  const [open, setOpen] = useState(false);
  const {
    backgroundColor: color,
    onBackgroundColorUpdate,
  } = useProcessBackground();

  const colorSelectionLayerRef = useRef<HTMLDivElement>(null);

  const colorRef = useRef(color);

  const onColorChange = (newColor: string | RGBColor) => {
    colorRef.current = typeof newColor === 'string' ? newColor : rgbaObjToString(newColor);
  };

  const handleClose = () => {
    setOpen(false);
    onBackgroundColorUpdate(colorRef.current);
  };

  const handlePreviewItemHover = (newColor?: string) => {
    window.dispatchEvent(
      new CustomEvent(
        'background-single-color-change',
        {
          bubbles: true,
          detail: {
            color: newColor ?? color,
            layer: 0,
          },
        },
      ),
    );
  };

  const handleItemSelect = (newColor: string) => {
    onColorChange(newColor);
    onBackgroundColorUpdate(newColor);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '16px',
            color: (theme) => greySidebarContrastText(theme, 0.8),
          }}
        >
          Color
        </Typography>
        <Box
          ref={colorSelectionLayerRef}
          className="SvgBackgroundLayerSelectinBtn"
          onClick={() => setOpen(true)}
          sx={{
            width: isMobile ? '200px' : '150px',
            height: '32px',
            borderRadius: '8px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            border: '1px solid',
            borderColor: open ? '#5CA8FF' : (theme) => greySidebarContrastText(theme, 0.2),
            cursor: 'pointer',
            padding: '6px 12px',
            backgroundColor: (theme) => greySidebarContrastText(theme, 0.2),
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <DropletIcon
              pathClassName="BackgroundLayerItemColorIndicator"
              customeColor={color ?? '#FFFFFF'}
              customeStroke={(theme) => greySidebarContrastText(theme, 0.3)}
            />
            <Typography
              className="BackgroundLayerItemColorLabel"
              sx={{
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '16px',
                color: (theme) => greySidebarContrastText(theme, 0.8),
                filter: 'invert(100%)'
              }}
            >
              {color ?? '#FFFFFF'}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        onMouseLeave={() => handlePreviewItemHover()}
        sx={{
          width: '100%',
          display: 'flex',
          gap: '4px',
          marginTop: '16px',
          justifyContent: 'flex-end',
        }}
      >
        {
          soldiBgColors.map((itemColor) => (
            <Box
              key={`solid=color-bg-item-${itemColor}`}
              onMouseEnter={() => handlePreviewItemHover(itemColor)}
              onClick={() => handleItemSelect(itemColor)}
              sx={{
                width: '32px',
                height: '32px',
                borderRadius: '8px',
                border: '1px solid',
                borderColor: (theme) => greySidebarContrastText(theme, 0.2),
                background: itemColor,
                cursor: 'pointer',

              }}
            />
          ))
}
      </Box>
      <Popover
        open={open}
        anchorEl={colorSelectionLayerRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            marginTop: '4px',
            padding: '12px',
            borderRadius: '8px',
            border: '1px solid #5CA8FF',
            background: '#F2F3F7',
            // eslint-disable-next-line max-len
            boxShadow: '0px 3px 2px rgba(0, 113, 190, 0.04), 0px 1px 1px rgba(0, 113, 190, 0.07), 0px 0px 1px rgba(0, 113, 190, 0.08), 0px 0px 0px rgba(0, 113, 190, 0.08)',
          },
        }}
      >
        <BackgroundColorPicker
          activeLayer={0}
          startingColor={color}
          onColorConfirm={onColorChange}
          eventName="background-single-color-change"
        />
      </Popover>
    </>
  );
};

export default ProcessBackgroudSolidColorSettings;
