import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import {
  redirectAfterOrgUpdate, updateOrganization,
} from '../core/store/appState/appState';
import { useAppDispatch, useAppSelector } from './stateHooks';
import { config } from '../core/config';
import { ECustomDomainEnableStatus } from '../../shared/CustomDomains';
import { activateCustomDomain, getCustomDomainStatus } from '../routes-old/auth/CustomDomain/state/customDomainSlice';
import { CustomDomainStatus } from '../../shared/domains/CustomDomainStatus';
import { selectIsSubscriptionBasic } from '../routes-old/Billing/state/billingSlice';
import useUserOrganizationPermissions from './useOrganizationPermissions';
import { EUserOrganizationPermissions } from '../../shared/permissions';

export type OrganizationSettingsFormValues = {
  logo?: { file?: File, tempUrl: string } | null,
  title?: string,
  domain?: string,
  wideLogo?: { file?: File, tempUrl: string } | null;
  customDomain?: string;
};

export interface FormState {
  domain: string,
  customDomain: string,
}

export const useCustomDomainSettingsNew = () => {
  const [saving, setSaving] = useState(false);
  const { organization } = useAppSelector((state) => state.app);
  const [currency, setCurrency] = useState(organization.currency);
  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [disabled, setDisabled] = useState<boolean>(true);
  const [showDeleteButton, setShowDeleteButton] = useState<boolean>(false);
  const [customDomainStatus, setCustomDomainStatus] = useState<CustomDomainStatus>({
    DNSEnabled: false, SSLEnabled: false, Resolved: false, multipleDNS: false, multipleDNSArray: '',
  });

  // eslint-disable-next-line max-len
  const [activateEnabled, setActivateEnabled] = useState<boolean>(customDomainStatus.DNSEnabled && customDomainStatus.Resolved && customDomainStatus.SSLEnabled);
  const [activateLoading, setActivateLoading] = useState<boolean>(false);
  const [isActivated, setIsActivated] = useState<ECustomDomainEnableStatus>(organization.customDomainEnabledStatus);
  const isBasic = useAppSelector(selectIsSubscriptionBasic);
  const [checkOrganizationPermissions] = useUserOrganizationPermissions();
  const teamManager: boolean = checkOrganizationPermissions(EUserOrganizationPermissions.ORGANIZATION_TEAM_MANAGE);

  const customDomainFormSchemaConfig = useMemo(() => {
    const validationSchemaConfigTemp: { [key: string]: any } = {};
    if (organization.customDomainEnabledStatus === ECustomDomainEnableStatus.DEACTIVATED) {
      validationSchemaConfigTemp.domain = yup.string()
        .trim()
        .ensure()
        .when({
          is: (value: string) => value.length > 0,
          then: yup.string().matches(
            /^(((?!-))(xn--|_)?[a-z0-9-]{0,61}[a-z0-9]{1,1}\.)*(xn--)?([a-z0-9][a-z0-9]{0,60}|[a-z0-9-]{1,30}\.[a-z]{2,})$/gmi,
            'Incorrect domain format',
          ),
        });
    }
    if (organization.customDomainEnabledStatus === ECustomDomainEnableStatus.ACTIVE) {
      validationSchemaConfigTemp.customDomain = yup.string()
        .trim()
        .ensure()
        .when({
          is: (value: string) => value.length > 0,
          then: yup.string().matches(
            /^(((?!-))(xn--|_)?[a-z0-9-]{0,61}[a-z0-9]{1,1}\.)*(xn--)?([a-z0-9][a-z0-9]{0,60}|[a-z0-9-]{1,30}\.[a-z]{2,})$/gmi,
            'Incorrect domain format',
          ),
        });
    }
    return validationSchemaConfigTemp;
  }, [organization]);

  const schema = yup.object(customDomainFormSchemaConfig);

  const defaultDomainObject: FormState = {
    domain: organization.domain || '',
    customDomain: organization.customDomain || '',
  };

  const customDomainFormMethods = useForm<FormState>({
    mode: 'onChange',
    defaultValues: defaultDomainObject,
    resolver: yupResolver(schema),
  });

  const {
    formState: { isValid, isDirty },
    watch,
    setValue,
  } = customDomainFormMethods;

  useEffect(() => {
    if (!teamManager) return;
    if (!isValid || !isDirty) {
      setDisabled(true);
    }
    if (isDirty && isValid) {
      setDisabled(false);
    }
  }, [isValid, isDirty, teamManager]);

  const selectedRoom = watch('customDomain', '');

  useEffect(() => {
    if (!selectedRoom) return setShowDeleteButton(false);
    return setShowDeleteButton(true);
  }, [selectedRoom]);

  const refreshPage = () => {
    window.location.reload();
  };

  const getUrlFromOrigin = () => {
    if (!config.app.domain) {
      return '';
    }
    const url = new URL(config.app.domain);
    return `.${url.hostname}`;
  };

  const getData = async (): Promise<void> => {
    try {
      const status = await dispatch(getCustomDomainStatus({
        customDomain: organization.customDomain,
        organizationId: organization.id,
      })).unwrap();
      setCustomDomainStatus(status);
      setActivateEnabled((status.DNSEnabled && status.Resolved && status.SSLEnabled) || isActivated === ECustomDomainEnableStatus.ACTIVE);
    } catch (e) {
      // Maybe do smth
    }
  };

  useEffect(() => {
    getData();
    if (organization) {
      setValue('customDomain', organization.customDomain);
    }
  }, [organization]);

  const handleCustomDomainDeactivationBySwitch = async () => {
    if (!teamManager) return;
    if (isActivated === ECustomDomainEnableStatus.ACTIVE) {
      try {
        setActivateLoading(true);
        const org = await dispatch(activateCustomDomain({
          customDomain: organization.customDomain,
          organizationId: organization.id,
          status: ECustomDomainEnableStatus.DEACTIVATED,
        })).unwrap();
        setIsActivated(org.customDomainEnabledStatus);
        setActivateEnabled((customDomainStatus.DNSEnabled && customDomainStatus.Resolved
          && customDomainStatus.SSLEnabled) || org.customDomainEnabledStatus === ECustomDomainEnableStatus.ACTIVE);
      } catch (e) {
        // Silent catch
      }
      setActivateLoading(false);
      refreshPage();
    }
  };

  const handleActivateCustomDomain = async () => {
    if (!teamManager) return;
    try {
      setActivateLoading(true);
      const org = await dispatch(activateCustomDomain({
        customDomain: organization.customDomain,
        organizationId: organization.id,
        status: isActivated !== ECustomDomainEnableStatus.ACTIVE ? ECustomDomainEnableStatus.ACTIVE : ECustomDomainEnableStatus.DEACTIVATED,
      })).unwrap();
      setIsActivated(org.customDomainEnabledStatus);
      setActivateEnabled((customDomainStatus.DNSEnabled && customDomainStatus.Resolved
          && customDomainStatus.SSLEnabled) || org.customDomainEnabledStatus === ECustomDomainEnableStatus.ACTIVE);
    } catch (e) {
      // Silent catch
    }
    setActivateLoading(false);
    refreshPage();
  };

  const submitCustomDomainForm = async (data: FormState) => {
    if (!teamManager) return;
    let shouldUpdate = false;
    let showSnackbar = false;
    let shouldRedirect = false;
    let shouldRefresh = false;
    setSaving(true);

    const org = { ...organization };
    if ('domain' in data) {
      org.domain = data.domain;
      shouldUpdate = true;
      shouldRefresh = true;
    }
    if ('customDomain' in data) {
      shouldRedirect = org.customDomain !== data.customDomain;
      org.customDomain = data.customDomain;
      shouldUpdate = true;
    }
    if (shouldUpdate) {
      try {
        const updatedOrg = await dispatch(updateOrganization(org)).unwrap();
        showSnackbar = true;
        if (shouldRedirect) {
          dispatch(redirectAfterOrgUpdate(updatedOrg));
        }
      } catch (e: any) {
        enqueueSnackbar(e?.message, { variant: 'error' });
      }
    }
    if (showSnackbar) enqueueSnackbar('Organization updated', { variant: 'success' });
    setSaving(false);
    if (shouldRefresh) refreshPage();
  };

  const deleteCustomDomain = async () => {
    if (!teamManager) return;
    setSaving(true);
    let showSnackbar = false;
    const org = { ...organization };
    org.customDomain = '';
    try {
      const updatedOrg = await dispatch(updateOrganization(org)).unwrap();
      showSnackbar = true;
      dispatch(redirectAfterOrgUpdate(updatedOrg));
    } catch (e: any) {
      enqueueSnackbar(e?.message, { variant: 'error' });
    }
    if (showSnackbar) enqueueSnackbar('Organization updated', { variant: 'success' });
    setSaving(false);
    refreshPage();
  };

  return {
    saving,
    inputRef,
    submitCustomDomainForm,
    currency,
    setCurrency,
    deleteCustomDomain,
    getUrlFromOrigin,
    customDomainStatus,
    getData,
    activateEnabled,
    activateLoading,
    customDomainFormMethods,
    handleActivateCustomDomain,
    handleCustomDomainDeactivationBySwitch,
    isActivated,
    isBasic,
    disabled,
    showDeleteButton,
    teamManager,
  };
};
