import {
  Box, Popover, Typography,
} from '@mui/material';
import { useEffect, useState, useRef } from 'react';
import { RGBColor } from 'react-color';
import BackgroundColorPicker from './BackgroundColorPicker';
import { SvgBackgroundColorChangeEvent, rgbaObjToString } from './helpers/DealCustomizationMenuHelpers';
import { DropletIcon } from '../../icons/Droplex';
import { CloseIcon } from '../../icons/Close16px';
import { greySidebarContrastText } from '../../core/utils/greyContrastText';
import { isMobile } from 'react-device-detect';

interface Props {
  isActive?: boolean;
  onSelect?: () => void;
  onDeselect?: () => void;
  defaultColor?: string;
  startingColor: string;
  layer?: number;
  onColorUpdate: (position: number, color: string) => void;
  label?: string;
  layerLabel: string;
  eventName?: string;
}

const BackgroundColorLayerSelector = ({
  label,
  isActive,
  onSelect,
  onDeselect,
  startingColor,
  layer,
  onColorUpdate,
  layerLabel,
  defaultColor,
  eventName = 'svg-background-color-change',
}: Props): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [color, setColor] = useState<string>(startingColor);

  const colorSelectionLayerRef = useRef<HTMLDivElement | null>(null);
  const colorRef = useRef(startingColor);

  function colorChangeHandler({ detail }: SvgBackgroundColorChangeEvent) {
    if (colorSelectionLayerRef.current) {
      const indicator: HTMLElement = colorSelectionLayerRef.current.querySelector('.BackgroundLayerItemColorIndicator') as HTMLElement;
      if (indicator && indicator.style) {
        indicator.style.stroke = detail.color;
        indicator.style.fill = detail.color;
      }
      const colorLabel: HTMLParagraphElement = colorSelectionLayerRef.current.querySelector(
        '.BackgroundLayerItemColorLabel',
      ) as HTMLParagraphElement;
      if (colorLabel) {
        colorLabel.innerText = detail.color;
      }
    }
  }

  const handleOpen = () => {
    setAnchorEl(colorSelectionLayerRef.current);
    setOpen(true);
    if (colorSelectionLayerRef?.current) {
      window.addEventListener(eventName, colorChangeHandler as EventListener);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
    if (onDeselect) onDeselect();
    setColor(colorRef.current);
    if (typeof layer === 'number') onColorUpdate(layer, colorRef.current);
    if (colorSelectionLayerRef?.current) {
      window.removeEventListener(eventName, colorChangeHandler as EventListener);
    }
  };

  useEffect(() => {
    if (isActive) {
      handleOpen();
    }
    return () => {
      if (isActive) {
        handleClose();
      }
    };
  }, [isActive]);

  const onColorChange = (newColor: string | RGBColor) => {
    colorRef.current = typeof newColor === 'string' ? newColor : rgbaObjToString(newColor);
  };

  const onColorReset = () => {
    if (!defaultColor) return;
    setColor(defaultColor);
    if (layer) onColorUpdate(layer, defaultColor);
    onColorChange(defaultColor);
    window.dispatchEvent(
      new CustomEvent(
        eventName,
        {
          bubbles: true,
          detail: {
            color: defaultColor,
            layer,
          },
        },
      ),
    );
  };

  //const customColor: string = "rgba(112, 132, 180, 1)" 
  const customColor = (theme: any) => {return `${greySidebarContrastText(theme, 1)}`}
  

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
      }}
    >
      <Box>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '16px',
            color: (theme) => greySidebarContrastText(theme, 0.8),
          }}
        >
          {layerLabel}
        </Typography>
      </Box>
      <Box
        ref={colorSelectionLayerRef}
        className="SvgBackgroundLayerSelectinBtn"
        onClick={onSelect}
        sx={{
          width: isMobile ? '200px' : '150px',
          height: '32px',
          borderRadius: '8px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          border: '1px solid ',
          borderColor: isActive ? '#5CA8FF' : (theme) => greySidebarContrastText(theme, 0.3),
          cursor: 'pointer',
          padding: '6px 12px',
          backgroundColor: (theme) => greySidebarContrastText(theme, 0.2),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <DropletIcon
            pathClassName="BackgroundLayerItemColorIndicator"
            customeColor={label || color}
            customeStroke={(theme) => greySidebarContrastText(theme, 0.3)}
          />
          <Typography
            className="BackgroundLayerItemColorLabel"
            sx={{
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '16px',
              color: (theme) => greySidebarContrastText(theme, 0.8),
              filter: 'invert(100%)'
            }}
          >
            {label}
          </Typography>
        </Box>
        {
          startingColor !== defaultColor
          && (
            <Box
              onClick={(e) => { e.stopPropagation(); onColorReset(); }}
              sx={{
                display: 'flex',
                '& path': {
                  stroke: (theme) => greySidebarContrastText(theme, 0.6),
                },
              }}
            >
              <CloseIcon
                sx={{
                  width: '18px',
                  height: '18px',
                }}
              />
            </Box>
          )
        }
      </Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            marginTop: '4px',
            padding: '12px',
            borderRadius: '8px',
            border: '1px solid #5CA8FF',
            background: '#F2F3F7',
            // eslint-disable-next-line max-len
            boxShadow: '0px 3px 2px rgba(0, 113, 190, 0.04), 0px 1px 1px rgba(0, 113, 190, 0.07), 0px 0px 1px rgba(0, 113, 190, 0.08), 0px 0px 0px rgba(0, 113, 190, 0.08)',
          },
        }}
      >
        <BackgroundColorPicker
          startingColor={color}
          onColorConfirm={onColorChange}
        />
      </Popover>
    </Box>
  );
};

export default BackgroundColorLayerSelector;
