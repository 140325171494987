import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Controller } from 'react-hook-form';
import { AppButton } from '../../../shared/AppButton/AppButton';
import SettingLanguageSwitcherInput from '../../../features/LanguageSwitcher/SettingLanguageSwitcherInput';
import { AppInput } from '../../../shared/AppInput/AppInput';
import { UploadIcon20 } from '../../../icons/UploadIcon';
import { AppIconButton } from '../../../shared/AppButton/AppIconButton';
import TrashIcon from '../../../icons/TrashIcon';
import { profileTabItemsWitFullWidth, profileTabItemsWitHalfWidth } from './tabItems/profileTabItems';
import {
  SettingsCardsDescription,
  SettingsCardsHeader,
  SettingsCardsHeadingWrapper,
  SettingsFormWrapper,
  SettingsTabContentWrapper,
} from './Styles/GeneralTabStyles';
import {
  AvatarButtonGroup,
  AvatarCaption,
  AvatarControlsWrapper,
  AvatarFieldWrapper,
  HiddenInput,
  SettingsAvatar,
  SettingsAvatarPlaceholder,
} from './Styles/ProfileTabStyles';
import { avatarHandler } from './Helper/AvatarHandler';
import { useProfileSettings } from '../../../hooks/useProfileSettings';
import { selectUser } from '../../../core/store/appState/appState';
import { useAppSelector } from '../../../hooks/stateHooks';

const ProfileTab = (): JSX.Element => {
  const { t } = useTranslation();
  const user = useAppSelector(selectUser);

  const {
    profileFormMethods,
    submitProfileForm,
    disabled,
  } = useProfileSettings();

  const {
    handleSubmit,
    control,
    setValue,
  } = profileFormMethods;

  const profileTabItemsHalfWidthRenderer = profileTabItemsWitHalfWidth.map(
    (item) => (
      <Controller
        key={item.name}
        name={item.name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <AppInput
            className={classNames('settings-menu-input', { 'settings-menu-input-full': isMobile })}
            fullWidth
            disabled={item.disabled}
            size="l"
            label={item.label}
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
      />
    ),
  );

  const profileTabItemsFullWidthRenderer = profileTabItemsWitFullWidth.map(
    (item) => (
      <Controller
        key={item.name}
        name={item.name}
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <AppInput
            className={classNames('settings-menu-input', 'settings-menu-input-full')}
            fullWidth
            size="l"
            label={item.label}
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
      />
    ),
  );

  return (
    <SettingsTabContentWrapper
      className={classNames({ 'settings-content-wrapper-mobile': isMobile })}
    >
      <SettingsCardsHeadingWrapper>
        <SettingsCardsHeader>
          {t('Settings_Profile')}
        </SettingsCardsHeader>
        <SettingsCardsDescription>
          Your personal account settings
        </SettingsCardsDescription>
      </SettingsCardsHeadingWrapper>
      <SettingsFormWrapper
        className={classNames('settings-profile-wrapper', { 'settings-profile-wrapper-mobile': isMobile })}
        component="form"
        onSubmit={handleSubmit(submitProfileForm)}
      >
        <Controller
          name="avatar"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <AvatarFieldWrapper>
              {value
                ? (
                  <SettingsAvatar
                    alt="user-avatar"
                    src={avatarHandler(value) || ''}
                  />
                )
                : (
                  <SettingsAvatarPlaceholder>
                    {user.name?.split('')[0]}
                  </SettingsAvatarPlaceholder>
                )}
              <AvatarControlsWrapper>
                <AvatarButtonGroup>
                  <AppButton
                    startIcon={<UploadIcon20 />}
                    variant="secondary"
                    component="label"
                    role={undefined}
                    tabIndex={-1}
                  >
                    Avatar
                    <HiddenInput
                      type="file"
                      accept="image/*"
                      onChange={(event) => {
                        if (event?.target?.files) {
                          onChange(event.target.files[0]);
                        }
                      }}
                    />
                  </AppButton>
                  <AppIconButton
                    disableRipple
                    value={undefined}
                    icon={<TrashIcon />}
                    variant="danger-transparent"
                    onClick={() => {
                      setValue('avatar', null, { shouldDirty: true });
                    }}
                  />
                </AvatarButtonGroup>
                <AvatarCaption
                  className={classNames({ 'settings-avatar-caption-error': error })}
                >
                  {error?.message || 'JPG, PNG, WEBP, SVG no more than 1 Mb'}
                </AvatarCaption>
              </AvatarControlsWrapper>
            </AvatarFieldWrapper>
          )}
        />
        {profileTabItemsHalfWidthRenderer}
        {profileTabItemsFullWidthRenderer}
        <SettingLanguageSwitcherInput />
        <AppButton
          type="submit"
          variant="primary"
          size="l-grid-span-full"
          disabled={disabled}
        >
          Save
        </AppButton>
      </SettingsFormWrapper>
    </SettingsTabContentWrapper>
  );
};

export default ProfileTab;
