import { styled } from '@mui/styles';
import { Avatar, Box, Typography } from '@mui/material';
import { stagesetColors } from '../../../../theme/stagesetPalette';

export const OrganizationIdWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirectionL: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '12px',
}));

export const OrganizationIdCodeWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '8px',
  cursor: 'pointer',
  '& svg': {
    background: 'transparent',
    '& path': {
      stroke: stagesetColors.newGrey[800],
    },
  },
}));

export const OrganizationIdTitle = styled(Typography)(() => ({
  fontSize: '14px',
  lineHeight: '16px',
  color: stagesetColors.newGrey[500],
}));

export const OrganizationIdCode = styled(Typography)(() => ({
  fontSize: '16px',
  lineHeight: '22px',
  color: stagesetColors.newGrey[800],
}));

export const DashedBorderBox = styled(Box)(() => ({
  maxWidth: '220px',
  width: '50%',
  height: '1px',
  border: '1px dashed',
  borderColor: stagesetColors.newGrey[200],
}));

export const CompanyLogoPlaceholderFrame = styled(Box)(() => ({
  width: '72px',
  height: '72px',
  padding: '16px',
  backgroundColor: stagesetColors.white[100],
  borderRadius: '16px',
  borderColor: stagesetColors.newGrey[100],
  border: '1px solid',
}));

export const CompanyLogo = styled(Avatar)(() => ({
  width: '72px',
  height: '72px',
  borderRadius: '16px',
  borderColor: stagesetColors.newGrey[100],
  border: '1px solid',
  display: 'flex',
  '& img': {
    objectFit: 'cover',
    flex: 1,
  },
}));
