import { TabContext, TabPanel } from '@mui/lab';
import {
  useState,
} from 'react';
import { isMobile } from 'react-device-detect';
import classNames from 'classnames';
import { EUserOrganizationPermissions } from '../../../shared/permissions';
import PagePresentation from '../../common/PagePresentation/PagePresentation';
import PagePresentationNoPermissions from '../../common/PagePresentation/PagePresentationNoPermissions';
import useUserOrganizationPermissions from '../../hooks/useOrganizationPermissions';
import { handleScroll } from '../process/helpers';
import { OrganizationUsersContainer } from './OrganizationUsersStyles/OrganizationUsersStyle';
import { MainTab } from '../../features/Organization/Tabs/MainTab/MainTab';
import { SettingsTab } from '../../features/Organization/Tabs/MainTab/SettingsTab';
import { OrganizationUsersToolbar } from '../../features/Organization/OrganizationUsersToolbar';
import { OrganizationUsersWrapper } from '../../features/Organization/OrganizationUsersStyles';
import { useAppSelector } from '../../hooks/stateHooks';
import { ESubscriptionLimitStatus } from '../../../shared/Subscription';

export enum TeamMenu {
  MEMBERS = 'MEMBERS',
  SETTINGS = 'SETTINGS',
}

const OrganizationUsers = (): JSX.Element => {
  const [checkOrganizationPermissions] = useUserOrganizationPermissions();
  const [tabValue, setTabValue] = useState<TeamMenu>(TeamMenu.MEMBERS);

  const { TEAM_CAN_MANAGE } = useAppSelector((state) => state.billing.subscription.limits);

  const isPageAvailable: boolean = checkOrganizationPermissions(EUserOrganizationPermissions.ORGANIZATION_NAV_TEAM)
    && TEAM_CAN_MANAGE === ESubscriptionLimitStatus.ACTIVE;

  const isBillingAvailable: boolean = checkOrganizationPermissions(EUserOrganizationPermissions.ORGANIZATION_NAV_BILLING);

  return (
    <OrganizationUsersWrapper>
      {isPageAvailable
        && (
          <>
            <OrganizationUsersToolbar tabValue={tabValue} onTabChange={setTabValue} />
            <OrganizationUsersContainer
              className={classNames({ 'org-users-container-mobile': isMobile })}
              onScroll={(e) => handleScroll(e.target as Element, 'OrganizationUsersContainer-active')}
            >
              <TabContext value={tabValue}>
                <TabPanel
                  value={TeamMenu.MEMBERS}
                  className="tab-panel"
                >
                  <MainTab />
                </TabPanel>
                <TabPanel value={TeamMenu.SETTINGS}>
                  <SettingsTab />
                </TabPanel>
              </TabContext>
            </OrganizationUsersContainer>
          </>

        )}
      {
        !isPageAvailable
        && isBillingAvailable
        && (
          <PagePresentation
            title="Team"
            // eslint-disable-next-line
            description="With Team Management, you will have a clear overview over all deals and empower Sales reps with your best templates and analytical input. Unlock the Company plan to add team members to your organisation."
          />
        )
      }
      {
        !isPageAvailable
        && !isBillingAvailable
        && (
          <PagePresentationNoPermissions
            title="No Access"
            description="You do not have access to this page"
          />
        )
      }
    </OrganizationUsersWrapper>
  );
};

export default OrganizationUsers;
