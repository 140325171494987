import { ActivityLogRequest, OnlineOfflineActivityItemDTO } from '../../shared/events/activity-log';
import { StagesetEvent } from '../../shared/events/Events';
import { AddStageToProcessData, ProcessStageDTO } from '../../shared/Field/Field';
import { FileDTO } from '../../shared/FileDTO';
import { PaginationResponseBase } from '../../shared/pagination-base';
import { TAllPermissions } from '../../shared/permissions';
import {
  CreateProcessByTemplateIdRequest,
  CreateTemplateRequest,
  IGetProcessResponse,
  IProcessChangeOwnerRequest,
  IProcessesResponse,
  ProcessDTO,
  ProcessField,
  ProcessSvgBackgroundColorUpdateReq,
} from '../../shared/process/ProcessMilestoneActionDTO';
import {
  AddUserByIdToProcessRequest,
  CreateTaskRequest,
  CreateTaskResponse,
  DeactivateUserRequest,
  IAddManyUsersToProcessRequest,
  IAddUserToProcessRequest,
  MilestoneDTO,
  TaskDTO,
} from '../../shared/TaskDTO';
import { ProcessUserDTO } from '../../shared/UserDTO';
import { ApiService } from '../core/api';
import { IUploadFilesFormData } from './FileStorageApi';
import { FilterInfo, FilterParams, Filters } from '../../shared/process/ProcessFilter';
import { DealsType } from '../../shared/process/Process';

export class ProcessApi {
  private api: ApiService;

  constructor() {
    this.api = new ApiService('process-management');
  }

  async getProcess(id: string): Promise<IGetProcessResponse> {
    return this.api.get<IGetProcessResponse>(id);
  }

  async getProcessLastActiveDate(id: string): Promise<number | undefined> {
    return this.api.get<number>(`${id}/last-active`);
  }

  async getUserProcessPermissions(id: string): Promise<TAllPermissions> {
    return this.api.get<TAllPermissions>(`${id}/user-permissions`);
  }

  async getTaskById(id: string): Promise<MilestoneDTO> {
    return this.api.get<MilestoneDTO>(`milestone/${id}`);
  }

  async updateTask(payload: Partial<TaskDTO>, processId: string): Promise<TaskDTO> {
    return this.api.put<TaskDTO>(`${processId}/task/${payload.id}`, payload);
  }

  async deleteTask(taskId: string, processId: string): Promise<string[]> {
    return this.api.delete<string[]>(`${processId}/task/${taskId}`, {});
  }

  async deleteProcess(payload: string): Promise<string> {
    return this.api.delete<string>(`${payload}`, {});
  }

  async createTask(payload: CreateTaskRequest): Promise<CreateTaskResponse> {
    return this.api.post<CreateTaskResponse>(`${payload.processId}/task`, payload);
  }

  async reorderTasks(payload: string[]): Promise<string[]> {
    return this.api.post<string[]>('task/reorder', payload);
  }

  async updateProcess(payload:Partial<ProcessDTO> & Pick<ProcessDTO, 'id'>): Promise<ProcessDTO> {
    return this.api.put<ProcessDTO>(`${payload.id}`, payload);
  }

  async deleteProcessBackground(payload:string): Promise<ProcessDTO> {
    return this.api.delete<ProcessDTO>(`${payload}/delete-background`, payload);
  }

  async updateProcessFields(processId: string, fields: ProcessField[]): Promise<ProcessField[]> {
    return this.api.put<ProcessField[]>(`${processId}/fields`, fields);
  }

  async addStageToProcess(payload: AddStageToProcessData): Promise<{
    fields: ProcessField[];
    newField: string;
    newFieldData: ProcessStageDTO;
  } > {
    return this.api.put<{
      fields: ProcessField[];
      newField: string;
      newFieldData: ProcessStageDTO;
    } >(`${payload.processId}/create-field`, payload);
  }

  async getProcessFields(processId: string): Promise<ProcessField[]> {
    return this.api.get<ProcessField[]>(`${processId}/fields`);
  }

  async getProcessesByTemplateId(id: string): Promise<ProcessDTO[]> {
    return this.api.get<ProcessDTO[]>(`template-process/${id}`);
  }

  async getDealsByOrganizationId(): Promise<ProcessDTO[]> {
    return this.api.get<ProcessDTO[]>('');
  }

  async getSellerDeals(payload?: Filters, limit: number = 50, skip: number = 0): Promise<IProcessesResponse> {
    return this.api.post<IProcessesResponse>('seller-deals', {
      filter: payload,
      getOwner: true,
      limit,
      skip,
    });
  }

  async getBuyerDeals(payload?: Filters): Promise<ProcessDTO[]> {
    return this.api.post<ProcessDTO[]>('buyer-deals', { filter: payload, getOwner: true });
  }

  async getTeamDeals(payload?: Filters): Promise<ProcessDTO[]> {
    return this.api.post<ProcessDTO[]>('team-deals', { filter: payload });
  }

  async getFilterInfo(payload?: FilterParams): Promise<Record<DealsType.BUYER_DEALS | DealsType.SELLER_DEALS, FilterInfo>> {
    return this.api.post<Record<DealsType.BUYER_DEALS | DealsType.SELLER_DEALS, FilterInfo>>('filter-info', { filterParams: payload });
  }

  async getTemplates(payload: string): Promise<ProcessDTO[]> {
    return this.api.post<ProcessDTO[]>('processes-templates', { filter: payload });
  }

  async createProcessByTemplateId(payload: CreateProcessByTemplateIdRequest): Promise<ProcessDTO> {
    return this.api.post<ProcessDTO>('', payload);
  }

  async createTemplate(payload: CreateTemplateRequest): Promise<ProcessDTO> {
    return this.api.post<ProcessDTO>('template', payload);
  }

  async inviteUserToProcess(payload: IAddUserToProcessRequest): Promise<ProcessUserDTO> {
    return this.api.post<ProcessUserDTO>(`${payload.processId}/add-user-by-email`, payload);
  }

  async addUserToProcessByIdNoCheck(payload: AddUserByIdToProcessRequest): Promise<ProcessUserDTO> {
    return this.api.post<ProcessUserDTO>(`${payload.processId}/add-user-by-id-no-check`, payload);
  }

  async addUserToProcessById(payload: AddUserByIdToProcessRequest): Promise<ProcessUserDTO> {
    return this.api.post<ProcessUserDTO>(`${payload.processId}/add-user-by-id`, payload);
  }

  async inviteManyUsersToProcess(payload: IAddManyUsersToProcessRequest): Promise<ProcessUserDTO[]> {
    return this.api.post<ProcessUserDTO[]>(`${payload.processId}/add-many-users`, payload);
  }

  async deactivateUser(payload: DeactivateUserRequest): Promise<ProcessUserDTO> {
    return this.api.post<ProcessUserDTO>(`${payload.processId}/deactivate-user`, payload);
  }

  async leaveDeal(payload: DeactivateUserRequest): Promise<ProcessUserDTO> {
    return this.api.post<ProcessUserDTO>(`${payload.processId}/leave-deal`, payload);
  }

  async getProcessUsers(processId: string): Promise<ProcessUserDTO[]> {
    return this.api.get<ProcessUserDTO[]>(`${processId}/users`);
  }

  async getProcessActivityLog(request: ActivityLogRequest): Promise<PaginationResponseBase<StagesetEvent>> {
    return this.api.post<PaginationResponseBase<StagesetEvent>>(`${request.processId}/activity-log`, request);
  }

  async getProcessTimelines(request: any): Promise<any> {
    return this.api.post<any>(`${request.processId}/timelines`, request);
  }

  async getProcessOnlineOfflineLog(request: ActivityLogRequest): Promise<OnlineOfflineActivityItemDTO[]> {
    return this.api.post<OnlineOfflineActivityItemDTO[]>(`${request.processId}/activity-log-online-offline`, request);
  }

  async getProcessFiles(processId: string, organizationId: string): Promise<FileDTO[]> {
    return this.api.get<FileDTO[]>(`${organizationId}/${processId}/files`);
  }

  async updateSvgBackgroundColors(data: ProcessSvgBackgroundColorUpdateReq): Promise<any> {
    return this.api.post<ProcessSvgBackgroundColorUpdateReq>(`${data.processId}/update-svg-backgrounds`, data);
  }

  async uploadLogo(payload: IUploadFilesFormData): Promise<ProcessDTO> {
    const formData: FormData = new FormData();
    for (const key in payload) {
      if (key !== 'file') {
        formData.append(key, payload[key]);
      }
    }
    const buffer = await payload.file.slice();
    formData.append('file', buffer);
    formData.append('fileName', `${new Date().getTime()}-${payload.file.name.replaceAll('+', '_')}`);
    formData.append('fileType', payload.file.type);

    return this.api.post<ProcessDTO>('/upload-logo', formData);
  }

  async uploadWideLogo(payload: IUploadFilesFormData): Promise<ProcessDTO> {
    const formData: FormData = new FormData();
    for (const key in payload) {
      if (key !== 'file') {
        formData.append(key, payload[key]);
      }
    }
    const buffer = await payload.file.slice();
    formData.append('file', buffer);
    formData.append('fileName', payload.file.name);

    return this.api.post<ProcessDTO>('/upload-wide-logo', formData);
  }

  async uploadBackground(payload: IUploadFilesFormData): Promise<ProcessDTO> {
    const formData: FormData = new FormData();
    for (const key in payload) {
      if (key !== 'file') {
        formData.append(key, payload[key]);
      }
    }
    const buffer = await payload.file.slice();
    formData.append('file', buffer);
    formData.append('fileName', payload.file.name);

    return this.api.post<ProcessDTO>('/upload-background', formData);
  }

  async sendMessageEvent(processId: string): Promise<void> {
    try {
      this.api.post<void>(`${processId}/send-message-event`);
    } catch (e) {
      console.log(e);
    }
  }

  async changeProcessOwner(payload: IProcessChangeOwnerRequest): Promise<void> {
    return this.api.post('change-process-owner', payload);
  }
}
