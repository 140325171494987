import {
  createAsyncThunk, createEntityAdapter, createSlice, EntityState, PayloadAction,
} from '@reduxjs/toolkit';
import {
  CreateTaskRequest, ETaskStatus, ETaskTypes, TaskDTO,
} from '../../../../shared/TaskDTO';
import { ProcessApi } from '../../../api/ProcessApi';
import { EStateStatus } from '../../../core/commonTypes';
import { appReducerName } from '../../../core/store/appState/appState';
import { AsyncThunkConfig, RootState } from '../../../core/store/store';
import { getProcess } from './dealActions';

const processApi = new ProcessApi();

export interface IMilestonesAdditionalState {
  status: EStateStatus;
  error?: string;
  expandedMilestoneIds: string[];
  selectedTaskId?: string;
  titleEditingTaskId?: string;
  isStartDialogOpen: boolean;
  openedStatusMilestoneId?: string;
  openedTargetStatus?: ETaskStatus;
  milestoneToCompleteId?: string;
  milestoneToStartId?: string;
  expandedMilestonesIdsMap: {
    [key: string]: boolean;
  }
}

const tasksAdapter = createEntityAdapter<TaskDTO>({
  sortComparer: (a, b) => a.title.localeCompare(b.title),
});

export const tasksReducerName: string = 'tasks';
export enum EMilestoneActions {
  GET_TASK = 'GET_TASK',
  UPDATE_TASK = 'UPDATE_TASK',
  CREATE_TASK = 'CREATE_TASK',
  DELETE_TASK = 'DELETE_TASK',
  REORDER_TASKS = 'REORDER_TASKS',
  UPDATE_PARENT_TASK = 'UPDATE_PARENT_TASK',
}

export const getTask = createAsyncThunk(
  `${tasksReducerName}/${EMilestoneActions.GET_TASK}`,
  async (id: string) => processApi.getTaskById(id),
);

export const updateTask = createAsyncThunk<TaskDTO, Partial<TaskDTO>, AsyncThunkConfig>(
  `${appReducerName}/${EMilestoneActions.UPDATE_TASK}`,
  async (payload: Partial<TaskDTO>, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    return processApi.updateTask(payload, state.process.process.id);
  },
);

export const updateParentTask = createAsyncThunk(
  `${appReducerName}/${EMilestoneActions.UPDATE_PARENT_TASK}`,
  async (payload: { parentId: string, userId: string }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const parentTask = state.tasks.entities[payload.parentId];
    if (!parentTask.assigneesIds.includes(payload.userId)) {
      const updatedParentTask = {
        id: parentTask.id,
        assigneesIds: [
          ...parentTask.assigneesIds,
          payload.userId,
        ],
      };
      return processApi.updateTask(updatedParentTask, state.process.process.id);
    }
    return parentTask;
  },
);

export const createTask = createAsyncThunk(
  `${tasksReducerName}/${EMilestoneActions.CREATE_TASK}`,
  async (payload: CreateTaskRequest) => processApi.createTask(payload),
);

export const deleteTask = createAsyncThunk<string[], string, AsyncThunkConfig>(
  `${tasksReducerName}/${EMilestoneActions.DELETE_TASK}`,
  async (payload: string, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    return processApi.deleteTask(payload, state.process.process.id);
  },
);

export const reorderTasks = createAsyncThunk(
  `${tasksReducerName}/${EMilestoneActions.REORDER_TASKS}`,
  async (payload: string[]) => processApi.reorderTasks(payload),
);

export type TTasksState = IMilestonesAdditionalState & EntityState<TaskDTO>;

export const initialState: TTasksState = tasksAdapter.getInitialState({
  status: EStateStatus.IDLE,
  expandedMilestoneIds: [],
  expandedMilestonesIdsMap: {},
});

const adapterReorderTasks = (state: any, reorder: string[]): void => {
  tasksAdapter.updateMany(
    state,
    reorder.map((id: string, index: number) => ({
      id,
      changes: {
        order: index,
      },
    })),
  );
};

export const tasksSlice = createSlice({
  name: tasksReducerName,
  initialState,
  reducers: {
    clearTasksState: (state) => tasksAdapter.removeAll(state),
    setSelectedTaskId: (state, { payload }: PayloadAction<string | undefined>) => {
      state.selectedTaskId = payload;
    },
    setTitleEditingTaskId: (state, { payload }: PayloadAction<string | undefined>) => {
      state.titleEditingTaskId = payload;
    },
    setMilestoneToCompleteId: (state, { payload }: PayloadAction<string | undefined>) => {
      state.milestoneToCompleteId = payload;
    },
    setMilestoneToStartId: (state, { payload }: PayloadAction<string | undefined>) => {
      state.milestoneToStartId = payload;
    },
    setOpenedStatusMilestoneId: (state, { payload }: PayloadAction<string | undefined>) => {
      state.openedStatusMilestoneId = payload;
    },
    setOpenedTargetStatus: (state, { payload }: PayloadAction<ETaskStatus | undefined>) => {
      state.openedTargetStatus = payload;
    },
    addExpandedMilestoneId: (state, { payload }: PayloadAction<string>) => {
      state.expandedMilestoneIds.push(payload);
      state.expandedMilestonesIdsMap[payload] = true;
    },
    removeExpandedMilestoneId: (state, { payload }: PayloadAction<string>) => {
      state.expandedMilestoneIds = state.expandedMilestoneIds.filter((id: string) => id !== payload);
      delete state.expandedMilestonesIdsMap[payload];
    },
    removeOtherExpandedMilestoneIds: (state, { payload }: PayloadAction<string>) => {
      state.expandedMilestoneIds = state.expandedMilestoneIds.filter((id: string) => id === payload);
      state.expandedMilestonesIdsMap = {
        [payload]: true,
      };
    },
    resetExpandingMilestonesIds: (state) => {
      state.expandedMilestoneIds = [];
      state.expandedMilestonesIdsMap = {};
    },
    revertExpandedMilestoneIdStatus: (state, { payload }: PayloadAction<string>) => {
      state.expandedMilestonesIdsMap[payload] = !state.expandedMilestonesIdsMap[payload];
    },
  },
  extraReducers: ((builder) => builder
    .addCase(updateTask.pending, (state) => {
      state.status = EStateStatus.LOADING;
    })
    .addCase(updateTask.fulfilled, (state, action) => {
      state.status = EStateStatus.IDLE;
      tasksAdapter.setOne(state, action.payload);
    })
    .addCase(updateTask.rejected, (state) => {
      state.status = EStateStatus.ERROR;
    })
    .addCase(updateParentTask.pending, (state) => {
      state.status = EStateStatus.LOADING;
    })
    .addCase(updateParentTask.fulfilled, (state, action) => {
      state.status = EStateStatus.IDLE;
      tasksAdapter.setOne(state, action.payload);
    })
    .addCase(updateParentTask.rejected, (state) => {
      state.status = EStateStatus.ERROR;
    })
    .addCase(getTask.pending, (state) => {
      state.status = EStateStatus.LOADING;
    })
    .addCase(getTask.fulfilled, (state, action) => {
      state.status = EStateStatus.IDLE;
      tasksAdapter.addOne(state, action.payload);
    })
    .addCase(getTask.rejected, (state) => {
      state.status = EStateStatus.ERROR;
    })
    .addCase(getProcess.pending, (state) => {
      state.status = EStateStatus.LOADING;
    })
    .addCase(getProcess.fulfilled, (state, action) => {
      state.status = EStateStatus.IDLE;
      tasksAdapter.addMany(state, action.payload.tasks);
    })
    .addCase(getProcess.rejected, (state) => {
      state.status = EStateStatus.ERROR;
    })
    .addCase(createTask.pending, (state) => {
      state.status = EStateStatus.LOADING;
    })
    .addCase(createTask.fulfilled, (state, action) => {
      state.status = EStateStatus.IDLE;
      tasksAdapter.addOne(state, action.payload.task);
      adapterReorderTasks(state, action.payload.reorder);
    })
    .addCase(createTask.rejected, (state) => {
      state.status = EStateStatus.ERROR;
    })
    .addCase(deleteTask.pending, (state) => {
      state.status = EStateStatus.LOADING;
    })
    .addCase(deleteTask.fulfilled, (state, action) => {
      state.status = EStateStatus.IDLE;
      action.payload.forEach((id: string) => {
        tasksAdapter.updateOne(state, {
          id,
          changes: {
            deleted: true,
          },
        });
      });
    })
    .addCase(deleteTask.rejected, (state) => {
      state.status = EStateStatus.ERROR;
    })
    .addCase(reorderTasks.pending, (state) => {
      state.status = EStateStatus.LOADING;
    })
    .addCase(reorderTasks.fulfilled, (state, action) => {
      state.status = EStateStatus.IDLE;
      adapterReorderTasks(state, action.payload);
    })
    .addCase(reorderTasks.rejected, (state) => {
      state.status = EStateStatus.ERROR;
    })
  ),
});

export const {
  clearTasksState,
  addExpandedMilestoneId,
  removeExpandedMilestoneId,
  setSelectedTaskId,
  setTitleEditingTaskId,
  removeOtherExpandedMilestoneIds,
  resetExpandingMilestonesIds,
  setOpenedStatusMilestoneId,
  setOpenedTargetStatus,
  revertExpandedMilestoneIdStatus,
  setMilestoneToCompleteId,
  setMilestoneToStartId,
} = tasksSlice.actions;
export const { selectById, selectAll } = tasksAdapter.getSelectors();
export const selectNotDeletedTasks = (state: RootState) => selectAll(state.tasks).filter(
  (task: TaskDTO) => !task.deleted,
);
export const milestoneSelector = (state: RootState, milestoneIds: string[]) => selectAll(state.tasks).filter(
  (task: TaskDTO) => task.type === ETaskTypes.MILESTONE && !task.deleted && milestoneIds.includes(task.id),
);
export const selectIsTaskStatusOpened = (state: RootState, taskId: string): boolean => state.tasks.openedStatusMilestoneId === taskId;
export const selectIsTaskTitleEditing = (state: RootState, taskId: string): boolean => state.tasks.titleEditingTaskId === taskId;
export const selectIsTaskSelected = (state: RootState, taskId: string): boolean => state.tasks.selectedTaskId === taskId;
