import { Box, Container, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { FileUploadStatusWrapper } from './UploadStatusStyles';

export enum EFileUploadSource {
  DEAL = 'DEAL',
  FILE_MANAGEMENT = 'FILE_MANAGEMENT',
}

interface Props {
  fileProgress: number | undefined;
  uploadSuccess: boolean;
  uploadStatusSource: EFileUploadSource,
}

const FileUploadStatus = ({ fileProgress, uploadSuccess, uploadStatusSource }: Props): JSX.Element => {
  const [uploadStatusStyles, setUploadStatusStyles] = useState({
    container: '',
    successBack: '',
    successText: '',
    uploadBack: '',
    uploadText: '',
    text: '',
  });

  useEffect(() => {
    if (uploadStatusSource === EFileUploadSource.FILE_MANAGEMENT) {
      setUploadStatusStyles({
        container: 'FileUploadStatusContainer',
        successBack: 'FileUploadSuccessBack',
        successText: 'FileUploadSuccessText',
        uploadBack: 'FileUploadBack',
        uploadText: 'FileUploadText',
        text: 'h2',
      });
    } else if (uploadStatusSource === EFileUploadSource.DEAL) {
      setUploadStatusStyles({
        container: 'DealFileUploadStatusContainer',
        successBack: 'DealFileUploadSuccessBack',
        successText: 'DealFileUploadSuccessText',
        uploadBack: 'DealFileUploadBack',
        uploadText: 'DealFileUploadText',
        text: 'subtitle2',
      });
    }
  }, [uploadStatusSource]);

  // @ts-ignore
  return (
    <FileUploadStatusWrapper
      sx={{
        height: uploadStatusSource === EFileUploadSource.FILE_MANAGEMENT ? 'inherit' : 'auto',
      }}
    >
      <Container className={uploadStatusStyles.container}>
        {
          uploadSuccess ? (
            <>
              <Box className={uploadStatusStyles.successBack} />
              <Typography
                className={uploadStatusStyles.successText}
                // @ts-ignore
                variant={uploadStatusStyles.text}
              >
                File was successfully uploaded
              </Typography>
            </>
          ) : (
            <>
              <Box
                className={uploadStatusStyles.uploadBack}
                sx={{
                  width: `${fileProgress}%`,
                }}
              />
              <Typography
                className={uploadStatusStyles.uploadText}
                // @ts-ignore
                variant={uploadStatusStyles.text}
              >
                Uploading file
                {' '}
                {fileProgress}
                %
              </Typography>
            </>
          )
        }
      </Container>
    </FileUploadStatusWrapper>
  );
};

export default FileUploadStatus;
