import { styled } from '@mui/system';
import { TextField } from '@mui/material';
import { stagesetColors } from '../../theme/stagesetPalette';
import { greySidebarContrastText } from '../../core/utils/greyContrastText';

export const StyledAppInput = styled<any>(TextField)(({ theme }) => ({
  '&.variant': {
    '&-transparent': {
      '& .MuiInputAdornment-root': {
        '& svg': {
          '& path': {
            stroke: stagesetColors.newGrey[500],
          },
        },
      },
      '& input': {
        color: stagesetColors.newGrey[800],
      },
      '& .MuiOutlinedInput-root': {
        backgroundColor: stagesetColors.white[100],
        '& fieldset': {
          borderColor: stagesetColors.newGrey[300],
        },
        '&:hover fieldset': {
          borderColor: stagesetColors.newGrey[400],
        },
        '&.Mui-focused fieldset': {
          color: stagesetColors.newGrey[600],
          border: '1px solid',
        },
      },
    },
    '&-themed': {
      '& .MuiInputAdornment-root': {
        '& svg': {
          '& path': {
            stroke: greySidebarContrastText(theme, 0.8),
          },
        },
      },
      '& input': {
        color: greySidebarContrastText(theme, 0.8),
      },
      '& .MuiOutlinedInput-root': {
        backgroundColor: greySidebarContrastText(theme, 0.3),
        '& fieldset': {
          borderColor: greySidebarContrastText(theme, 0.6),
        },
        '&:hover fieldset': {
          borderColor: greySidebarContrastText(theme, 0.9),
        },
        '&:active fieldset': {
          borderColor: greySidebarContrastText(theme, 1),
        },
        '&.Mui-focused fieldset': {
          color: greySidebarContrastText(theme, 0.9),
          border: '1px solid',
        },
      },
    },
    '&-lite-grey:not(.disabled)': {
      '& .MuiOutlinedInput-root:hover': {
        backgroundColor: stagesetColors.newGrey[50],
        '& textarea': {
          backgroundColor: stagesetColors.newGrey[50],
        },
      },
      '&.Mui-focused': {
        backgroundColor: stagesetColors.newGrey[50],
      },
    },
    '&-lite:not(.disabled)': {
      '& .MuiOutlinedInput-root:hover': {
        backgroundColor: stagesetColors.white[100],
        '& textarea': {
          backgroundColor: stagesetColors.white[100],
        },
      },
      '&.Mui-focused': {
        backgroundColor: stagesetColors.white[100],
      },
    },
    '&-lite:not(.disabled), &-lite-grey:not(.disabled)': {
      '& input': {
        color: stagesetColors.newGrey[800],
      },
      '& .MuiOutlinedInput-root': {
        borderColor: stagesetColors.newGrey[300],
        backgroundColor: 'transparent',
        '& fieldset': {
          border: '1px solid',
        },
        '&:hover fieldset': {
          borderColor: stagesetColors.newGrey[400],
          border: '1px solid',
          boxShadow: '0px 3px 2px 0px rgba(30, 41, 59, 0.04),'
            + '0px 1px 1px 0px rgba(30, 41, 59, 0.07),'
            + '0px 0px 1px 0px rgba(30, 41, 59, 0.08),'
            + '0px 0px 0px 0px rgba(30, 41, 59, 0.08)',
        },
        '&:not(.Mui-focused) fieldset': {
          border: 'none',
        },
        '&.Mui-focused fieldset': {
          color: stagesetColors.newGrey[400],
          border: '1px solid',
        },
      },
      '& .MuiFormHelperText-root.Mui-disabled': {
        display: 'none',
      },
    },
    '&-lite.disabled, &-lite-grey.disabled': {
      '& .MuiOutlinedInput-root': {
        backgroundColor: 'transparent',
        '& textarea': {
          '-webkit-text-fill-color': stagesetColors.newGrey[800],
        },
        '& fieldset': {
          border: 'none',
          borderColor: 'transparent',
        },
      },
    },
  },
  '&.size': {
    '&-xs': {
      '& input': {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '16px',
      },
      '& label:not(.MuiInputLabel-shrink)': {
        top: '-7px',
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: '4px',
        padding: '0px 0px 0px 2px',
        '& fieldset': {
          borderRadius: '4px',
        },
      },
    },
    '&-s': {
      '& input': {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '16px',
      },
      '& label:not(.MuiInputLabel-shrink)': {
        top: '-7px',
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: '8px',
        padding: '6px 4px 6px 4px',
        '& fieldset': {
          borderRadius: '8px',
        },
      },
    },
    '&-m': {
      '& input': {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '22px',
      },
      '& label:not(.MuiInputLabel-shrink)': {
        top: '-6px',
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: '10px',
        padding: '9px 8px 9px 12px',
        '& fieldset': {
          borderRadius: '10px',
        },
      },
    },
    '&-l': {
      '& input': {
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '22px',
      },
      '& label:not(.MuiInputLabel-shrink)': {
        top: '-2px',
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: '12px',
        padding: '13px 8px 13px 12px',
        '& fieldset': {
          borderRadius: '12px',
        },
      },
    },
    '&-l-heading-font': {
      '& input': {
        fontSize: '20px',
        fontWeight: 600,
        lineHeight: '26px',
      },
      '& label:not(.MuiInputLabel-shrink)': {
        top: '-2px',
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: '8px',
        padding: '9px 4px',
        fontSize: '20px',
        fontWeight: 600,
        lineHeight: '26px',
        '& fieldset': {
          borderRadius: '8px',
        },
      },
    },
  },
  '&.helper-text-placement': {
    '&-start': {
      '& .MuiFormHelperText-root': {
        left: 0,
      },
    },
    '&-end': {
      '& .MuiFormHelperText-root': {
        right: 0,
      },
    },
  },
  '& .MuiFormHelperText-root': {
    position: 'absolute',
    display: 'flex',
    height: '30px',
    margin: '0px',
    paddingTop: '4px',
    color: stagesetColors.red[600],
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '14px',
    bottom: '-26px',
    alignItems: 'flex-start',
  },
  '& input': {
    padding: '0px',
  },
  '& label': {
    color: stagesetColors.newGrey[500],
  },
  '& label.Mui-focused': {
    color: stagesetColors.newGrey[500],
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: stagesetColors.newGrey[300],
  },
  '& .MuiInputAdornment-root': {
    margin: '0px',
    paddingRight: '8px',
    '& svg': {
      width: '20px',
      height: '20px',
      '& path': {
        stroke: stagesetColors.newGrey[500],
      },
    },
  },
  '& .MuiSelect-select': {
    padding: '0px',
  },
  '& .MuiAutocomplete-input': {
    padding: '0px !important',
  },
  '& .MuiOutlinedInput-root': {
    '& button': {
      border: 'none',
      backgroundColor: 'transparent',
      padding: '0px',
      width: '20px',
      height: '20px',
      cursor: 'pointer',
      '& svg': {
        width: '20px',
        height: '20px',
        '& path': {
          color: stagesetColors.newGrey[500],
          stroke: stagesetColors.newGrey[500],
        },
      },
      '&:hover': {
        '& svg': {
          '& path': {
            color: stagesetColors.orange[600],
            stroke: stagesetColors.orange[600],
          },
        },
      },
    },
  },
  '&.settings-menu-input-half': {
    gridColumn: 'span 1 ',
  },
  '&.settings-menu-input-full': {
    gridColumn: 'span 2',
  },
}));
