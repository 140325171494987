import {
  useEffect,
  useMemo, useState,
} from 'react';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { updateOrganization, uploadTeamLogo } from '../core/store/appState/appState';
import { useOrganization } from './useOrganization';
import { useAppDispatch } from './stateHooks';
import { EUserOrganizationPermissions } from '../../shared/permissions';
import useUserOrganizationPermissions from './useOrganizationPermissions';

export type OrganizationSettingsFormValues = {
  logo?: { file?: File, tempUrl: string } | null,
  title?: string,
  domain?: string,
  wideLogo?: { file?: File, tempUrl: string } | null;
  customDomain?: string;
};

export interface FormState {
  logo?: File | string | null;
  teamName: string;
  teamCurrency?: string | undefined;
}

const FILE_SIZE = 1024 * 1024 * 5;
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png', 'image/webp', 'image/svg+xml'];

export const useOrganizationSettingsNew = () => {
  const { organization } = useOrganization();
  const [saving, setSaving] = useState(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [checkOrganizationPermissions] = useUserOrganizationPermissions();
  const teamManager: boolean = checkOrganizationPermissions(EUserOrganizationPermissions.ORGANIZATION_TEAM_MANAGE);

  const organizationFormSchemaConfig = useMemo(() => {
    const validationSchemaConfigTemp: { [key: string]: any } = {};
    validationSchemaConfigTemp.logo = yup.mixed()
      .test(
        'Please select a file',
        (files) => {
          if (!files || typeof files === 'string') return true;
          return files?.size > 0;
        },
      )
      .test(
        'fileSize',
        'File is too large',
        (files) => {
          if (!files || typeof files === 'string') return true;
          return files?.size <= FILE_SIZE;
        },
      )
      .test(
        'fileType',
        'jpg, jpeg, png, webp, svg only',
        (files) => {
          if (!files || typeof files === 'string') return true;
          return SUPPORTED_FORMATS.includes(files?.type);
        },
      );
    validationSchemaConfigTemp.teamName = yup.string().required('Team Name is required');
    validationSchemaConfigTemp.teamCurrency = yup.string().required();
    return validationSchemaConfigTemp;
  }, []);

  const schema = yup.object(organizationFormSchemaConfig).required();

  const organizatioDefaultData: FormState = {
    teamName: organization.name,
    logo: organization.organizationLogo ?? null,
    teamCurrency: organization.currency ?? 'USD',
  };

  const organizationFormMethods = useForm<FormState>({
    mode: 'onSubmit',
    shouldUnregister: false,
    defaultValues: organizatioDefaultData,
    resolver: yupResolver(schema),
  });

  const {
    reset,
    formState: { isValid, isDirty },
  } = organizationFormMethods;

  useEffect(() => {
    reset(organizatioDefaultData);
  }, [organization]);

  useEffect(() => {
    if (!isValid || !isDirty) {
      setDisabled(true);
    }
    if (isDirty && isValid) {
      setDisabled(false);
    }
  }, [isValid, isDirty]);

  const handleCopyId = async () => {
    await navigator.clipboard.writeText(organization?.orgSupportId);
    enqueueSnackbar('Link copied', {
      variant: 'success',
    });
  };

  const submitOrganizationForm = async (data: FormState) => {
    if (!teamManager) return;
    let shouldUpdate = false;
    let shouldUpdateOrgLogo = false;
    let showSnackbar = false;
    setSaving(true);

    const org = { ...organization };

    if (!isValid || !isDirty) {
      return;
    }
    if (organization.name !== data.teamName) {
      org.name = data.teamName;
      shouldUpdate = true;
    }
    if (organization.organizationLogo !== data.logo && !!data.logo) {
      shouldUpdate = true;
      shouldUpdateOrgLogo = true;
    }
    if (
      organization.organizationLogo
      && organization.organizationLogo !== data.logo
      && !data.logo) {
      org.organizationLogo = '';
      shouldUpdate = true;
    }
    if (organization.currency !== data.teamCurrency) {
      org.currency = data.teamCurrency;
      shouldUpdate = true;
    }

    if (shouldUpdate) {
      try {
        await dispatch(updateOrganization(org)).unwrap();
        showSnackbar = true;
      } catch (e: any) {
        enqueueSnackbar(e?.message, { variant: 'error' });
      }
    }
    if (shouldUpdateOrgLogo && !!data.logo) {
      try {
        await dispatch(
          uploadTeamLogo({
            file: data.logo as File,
            userId: '',
            organizationId: organization?.id,
          }),
        ).unwrap();
        showSnackbar = true;
      } catch (e: any) {
        enqueueSnackbar(e?.message, { variant: 'error' });
      }
    }
    if (showSnackbar) enqueueSnackbar('Organization updated', { variant: 'success' });
    setSaving(false);
    setDisabled(true);
  };

  return {
    organizationFormMethods,
    submitOrganizationForm,
    handleCopyId,
    saving,
    disabled,
    teamManager,
  };
};
