import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';
import { DateTime } from 'luxon';
import { useAppSelector } from '../../../hooks/stateHooks';
import { selectLibraryTemplateData, selectAllTemplates } from '../../../pages/templates/lib/templatesSlice';
import { AppLinkButton } from '../../../shared/AppButton/AppLinkButton';
import { formateValueForLastActive } from '../../../routes-old/process/helpers';
import { AppIconButton } from '../../../shared/AppButton/AppIconButton';
import ChevronLeft from '../../../icons/ChevronLeft.svg';
import { AppButton } from '../../../shared/AppButton/AppButton';
import { MobileTemplate } from '../MobileTemplate';

export type Props = {
  onTemplateSelect: (id: string) => void;
  onGoBack: () => void;
  onClose: () => void;
};

export const TemplatePreviewTab = ({ onTemplateSelect, onGoBack, onClose }: Props) => {
  const selectedTemplateData = useAppSelector(selectLibraryTemplateData);
  const templates = useAppSelector(selectAllTemplates);
  const chosenTemplate = useMemo(
    () => templates.find((template) => template.id === selectedTemplateData?.id),
    [templates, selectedTemplateData?.id],
  );

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    }}
    >
      <Box sx={{
        zIndex: 2,
        background: 'white',
        width: '100%',
        alignItems: 'center',
        overflow: 'hidden',
        padding: '16px 16px 8px 16px',
        boxShadow: `0px -3px 2px rgba(0, 113, 190, 0.04),
                    0px -1px 1px rgba(0, 113, 190, 0.07),
                    0px 0px 1px rgba(0, 113, 190, 0.08),
                    0px 0px 0px rgba(0, 113, 190, 0.08);`,
      }}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
        }}
        >

          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
          >

            <Box>
              <AppIconButton icon={<ChevronLeft />} onClick={onGoBack} />
            </Box>

            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
            >
              <Typography
                sx={{
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  fontWeight: 600,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                {chosenTemplate?.title}
              </Typography>
              <Typography sx={{
                fontSize: '12px',
                color: '#7084B4',
              }}
              >
                {
                `Updated ${formateValueForLastActive(DateTime.fromISO(chosenTemplate?.lastInteraction).toMillis() as number)}`
              }
              </Typography>
            </Box>
          </Box>

          <Box sx={{
            display: 'flex',
          }}
          >
            <AppLinkButton
              onClick={onClose}
              sx={{
                paddingRight: '0px !important',
                minWidth: '100% !important',
                width: '100% !important',
              }}
            >
              Cancel
            </AppLinkButton>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          height: 'calc( 100% - 117px )',
          margin: '0px',
          flexDirection: 'column',
          overflow: 'overlay',
        }}
        onDrag={(e) => e.stopPropagation()}
        onTouchMove={(e) => e.stopPropagation()}
        onPointerDown={(e) => e.stopPropagation()}
      >
        <MobileTemplate />
      </Box>
      <Box sx={{
        maxHeight: '56px',
        height: '56px',
        display: 'flex',
        flex: 1,
        padding: '8px 16px',
        boxShadow: `0px -3px 2px rgba(0, 113, 190, 0.04),
                    0px -1px 1px rgba(0, 113, 190, 0.07),
                    0px 0px 1px rgba(0, 113, 190, 0.08),
                    0px 0px 0px rgba(0, 113, 190, 0.08);`,
        backgroundColor: 'white',
        alignItems: 'flex-start',
        zIndex: 1,
      }}
      >
        <AppButton size="l" fullWidth variant="primary" onClick={() => onTemplateSelect(chosenTemplate!.id)}>Use template</AppButton>
      </Box>
    </Box>
  );
};
