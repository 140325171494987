import {
  Box, CardMedia, Popover, Typography,
} from '@mui/material';
import { useRef, useState } from 'react';
import { TabPanelProps } from '@mui/lab';
import { isMobile } from 'react-device-detect';
import { RGBColor } from 'react-color';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../hooks/stateHooks';
import { selectProcess } from '../../state/processSlice';
import { AppInput } from '../../../../shared/AppInput/AppInput';
import { AppTabs } from '../../../../shared/AppTabs/AppTabs';
import BackgroundColorPicker from '../../../../features/DealCustomizationMenu/BackgroundColorPicker';
import {
  rgbaObjToString,
} from '../../../../features/DealCustomizationMenu/helpers/DealCustomizationMenuHelpers';
import { updateProcess } from '../../state/dealActions';
import { AppButton } from '../../../../shared/AppButton/AppButton';
import {
  DealFeedTabItemContainer, DealFeedTabItemWrapper, DealFeedTabPanel, DealFeedTabSubtitle,
} from '../Styles/DealFeedStyles';
import {
  SharePreviewTabColorIcon,
  SharePreviewTabColorTitle,
  SharePreviewTabColorViewer, SharePreviewTabDashedBorderBox, SharePreviewTabItemTitle, SharePreviewTabItemWrapper,
} from './Styles/SharePreviewTabStyles';

const width = 500;
const height = 200;
const logoSize = width * 0.1;

interface Props extends TabPanelProps {

}

const SharePreviewTab = ({ value, sx, ...props }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const process = useAppSelector(selectProcess);
  const [title, setTitle] = useState(process?.linkPreview?.title ?? 'Welcome to Stageset');
  const [showLogo, setShowLogo] = useState(process?.linkPreview?.showLogo ?? true);

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [color, setColor] = useState<string>(process?.linkPreview?.backgroundColor ?? '#E5EDFF');
  const [textColor, setTextColor] = useState<string>(process?.linkPreview?.textColor ?? '#1A1929');

  const selectorRef = useRef<HTMLDivElement | null>(null);
  const indicatorRef = useRef<HTMLDivElement | null>(null);
  const previewContainerRef = useRef<HTMLDivElement>(null);
  const colorRef = useRef(color);

  const textSelectorRef = useRef<HTMLDivElement | null>(null);
  const textIndicatorRef = useRef<HTMLDivElement | null>(null);
  const textPreviewContainerRef = useRef<HTMLDivElement>(null);
  const textColorRef = useRef(textColor);

  const option = useRef<'backgroung' | 'text'>('backgroung');

  const onColorChange = (newColor: string | RGBColor) => {
    switch (option.current) {
      case 'backgroung':
        colorRef.current = typeof newColor === 'string' ? newColor : rgbaObjToString(newColor);
        break;
      case 'text':
        textColorRef.current = typeof newColor === 'string' ? newColor : rgbaObjToString(newColor);
        break;
      default:
        break;
    }
  };

  const colorChangeHandler = (color_: string | RGBColor) => {
    const newColor = typeof color_ === 'string' ? color_ : rgbaObjToString(color_);
    switch (option.current) {
      case 'backgroung':
        colorRef.current = newColor;
        if (indicatorRef?.current) {
          indicatorRef.current.style.background = newColor;
        }
        if (previewContainerRef?.current) {
          previewContainerRef.current.style.background = newColor;
        }
        break;
      case 'text':
        textColorRef.current = newColor;
        if (textIndicatorRef?.current) {
          textIndicatorRef.current.style.background = newColor;
        }
        if (textPreviewContainerRef?.current) {
          textPreviewContainerRef.current.style.color = newColor;
        }
        break;
      default:
        break;
    }
  };

  const handleOpen = () => {
    option.current = 'backgroung';
    setAnchorEl(selectorRef.current);
    setOpen(true);
  };

  const handleOpenText = () => {
    option.current = 'text';
    setAnchorEl(selectorRef.current);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
    switch (option.current) {
      case 'backgroung':
        setColor(colorRef.current);
        break;
      case 'text':
        setTextColor(textColorRef.current);
        break;
      default:
        break;
    }
  };

  const onPreviewUpdate = async () => {
    if (
      !process.linkPreview
      || process.linkPreview.backgroundColor !== color
      || process.linkPreview.textColor !== textColor
      || process.linkPreview.showLogo !== showLogo
      || process.linkPreview.title !== title
    ) {
      setLoading(true);
      await dispatch(updateProcess({
        id: process.id,
        linkPreview: {
          title,
          showLogo,
          textColor,
          backgroundColor: color,
        },
      }));
      setLoading(false);
    }
  };

  const isDisabled = process?.linkPreview?.backgroundColor === color
  && process?.linkPreview?.textColor === textColor
  && process?.linkPreview?.showLogo === showLogo
  && process?.linkPreview?.title === title;

  return (
    <DealFeedTabPanel
      sx={{
        ...sx,
      }}
      value={value}
      {...props}
    >
      <DealFeedTabItemWrapper>
        <DealFeedTabSubtitle>
          {t('Room_Customize_link_preview')}
        </DealFeedTabSubtitle>
        <DealFeedTabItemContainer
          className="share-preview-tab-container"
        >
          <AppInput
            fullWidth
            label={t('Room_Title')}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <SharePreviewTabItemWrapper>
            <SharePreviewTabItemTitle>
              {t('Room_Logo')}
            </SharePreviewTabItemTitle>
            <SharePreviewTabDashedBorderBox />
            <AppTabs
              color="white"
              variant="boxedNew"
              size="m-new"
              value={showLogo}
              onChange={(option) => {
                setShowLogo(option.value);
              }}
              options={[
                {
                  title: t('Room_Show'), value: true,
                },
                {
                  title: t('Room_Hide'), value: false,
                },
              ]}
            />
          </SharePreviewTabItemWrapper>
          <SharePreviewTabItemWrapper>
            <SharePreviewTabItemTitle>
              {t('Room_Background')}
            </SharePreviewTabItemTitle>
            <SharePreviewTabDashedBorderBox />
            <SharePreviewTabColorViewer
              ref={selectorRef}
              onClick={handleOpen}
            >
              <SharePreviewTabColorIcon
                ref={indicatorRef}
                sx={{
                  background: color,
                }}
              />
              <SharePreviewTabColorTitle>
                {color.toUpperCase()}
              </SharePreviewTabColorTitle>
            </SharePreviewTabColorViewer>
          </SharePreviewTabItemWrapper>
          <SharePreviewTabItemWrapper>
            <SharePreviewTabItemTitle>
              {t('Room_Text')}
            </SharePreviewTabItemTitle>
            <SharePreviewTabDashedBorderBox />
            <SharePreviewTabColorViewer
              ref={textSelectorRef}
              onClick={handleOpenText}
            >
              <SharePreviewTabColorIcon
                ref={textIndicatorRef}
                sx={{
                  background: textColor,
                }}
              />
              <SharePreviewTabColorTitle>
                {textColor.toUpperCase()}
              </SharePreviewTabColorTitle>
            </SharePreviewTabColorViewer>
          </SharePreviewTabItemWrapper>
          <Box
            id="link-preview-display-container"
            ref={previewContainerRef}
            sx={{
              width: `${width}px`,
              padding: '24px',
              height: `${height}px`,
              background: color,
              borderRadius: '12px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '24px',
              maxWidth: isMobile ? 'calc(100%)' : undefined,
            }}
          >
            {
              showLogo
              && (process.buyerLogoSrc || process.sellerLogoSrc)
              && (
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'absolute',
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      position: 'relative',
                      bottom: `${logoSize}px`,
                    }}
                  >
                    {
                    process.buyerLogoSrc
                    && (
                      <CardMedia
                        component="image"
                        image={process?.buyerLogoSrc}
                        sx={{
                          border: (theme) => `1px solid ${theme.palette.grey[100]}`,
                          width: `${logoSize - 2}px`,
                          height: `${logoSize - 2}px`,
                          borderRadius: '12px',
                          marginRight: process?.sellerLogoSrc ? '-8px' : undefined,
                        }}
                      />
                    )
                      }
                    {
                    process.sellerLogoSrc
                    && (
                      <CardMedia
                        component="image"
                        image={process?.sellerLogoSrc}
                        sx={{
                          border: (theme) => `1px solid ${theme.palette.grey[100]}`,
                          width: `${logoSize - 2}px`,
                          height: `${logoSize - 2}px`,
                          borderRadius: '12px',
                          marginRight: process?.buyerLogoSrc ? '-8px' : undefined,
                        }}
                      />
                    )
                      }
                  </Box>
                </Box>
              )
            }
            <Box
              sx={{
                position: 'absolute',
              }}
            >
              <Typography
                ref={textPreviewContainerRef}
                sx={{
                  fontSize: `${height * 0.1}px`,
                  width: `${height * 0.9}px`,
                  lineHeight: '26px',
                  fontWeight: 600,
                  color: textColor ?? ((theme) => theme.palette.grey[600]),
                  position: 'relative',
                  top: showLogo ? `${logoSize * 0.5}px` : undefined,
                  textAlign: 'center',
                  maxHeight: '52px',
                  overflow: 'hidden',
                  fontFamily: 'sans-serif',
                }}
              >
                {title}
              </Typography>
            </Box>
          </Box>
          <AppButton
            size="l"
            variant="primary"
            loading={loading}
            onClick={onPreviewUpdate}
            disabled={isDisabled}
            fullWidth
          >
            {t('Room_Save')}
          </AppButton>
        </DealFeedTabItemContainer>
      </DealFeedTabItemWrapper>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            marginTop: '4px',
            padding: '12px',
            borderRadius: '8px',
            border: '1px solid #5CA8FF',
            background: '#F2F3F7',
            // eslint-disable-next-line max-len
            boxShadow: '0px 3px 2px rgba(0, 113, 190, 0.04), 0px 1px 1px rgba(0, 113, 190, 0.07), 0px 0px 1px rgba(0, 113, 190, 0.08), 0px 0px 0px rgba(0, 113, 190, 0.08)',
          },
        }}
      >
        <BackgroundColorPicker
          startingColor={color}
          onColorConfirm={onColorChange}
          onColorChange={colorChangeHandler}
        />
      </Popover>
    </DealFeedTabPanel>
  );
};

export default SharePreviewTab;
