import {
  createAsyncThunk, createEntityAdapter, createSlice, EntityState, PayloadAction,
} from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { EFieldType } from '../../../../shared/Field/Field';
import { CreateTemplateRequest, EProcessType, ProcessDTO } from '../../../../shared/process/ProcessMilestoneActionDTO';
import { EStateStatus } from '../../../core/commonTypes';
import { RootState } from '../../../core/store/store';
import { ProcessApi } from '../../../api/ProcessApi';

const processApi: ProcessApi = new ProcessApi();

export enum ETemplatesActions {
  GET_TEMPLATES = 'GET_TEMPLATES',
  UPDATE_TEMPLATE = 'UPDATE_TEMPLATE',
  CREATE_TEMPLATE = 'CREATE_TEMPLATE',
  DELETE_TEMPLATE = 'DELETE_TEMPLATE',
}

interface TemplatesAditionalState {
  status: EStateStatus;
  templateId: string;
  selectedTemplateId: string;
  isCreateDialogOpen: boolean;
  isCloneTemplateDialogOpen: boolean;
  error?: string;
  filter: string,
  currentTab: ETemplatesTypes,
  newTemplateDialogStatus: boolean,
  templateEditMode: boolean,
  selectedTemplate?: {
    id?: string,
    title?: string,
    type?: EFieldType | EProcessType,
  },
}
// @ts-ignore
const formDateToCompare = (date: Date): number => DateTime.fromISO(date.toString()).ts;

const templatesAdapter = createEntityAdapter<ProcessDTO>({
  sortComparer: (a: ProcessDTO, b: ProcessDTO) => formDateToCompare(b.lastInteraction) - formDateToCompare(a.lastInteraction),
});

export enum ETemplatesTypes {
  TEMPLATES = 'Rooms',
  STAGES = 'Pages',
  SECTIONS = 'Sections',
}

export const templatesSliceName: string = 'templates';

export type TTemplatesState = TemplatesAditionalState & EntityState<ProcessDTO>;

export const initialState: TTemplatesState = templatesAdapter.getInitialState({
  status: EStateStatus.IDLE,
  selectedTemplateId: '',
  isCreateDialogOpen: false,
  isCloneTemplateDialogOpen: false,
  templateId: '',
  filter: '',
  newTemplateDialogStatus: false,
  templateEditMode: false,
  currentTab: ETemplatesTypes.TEMPLATES,
});

export const getTemplates = createAsyncThunk(
  `${templatesSliceName}/${ETemplatesActions.GET_TEMPLATES}`,
  async (payload: string = '') => processApi.getTemplates(payload),
);

export const updateTemplate = createAsyncThunk(
  `${templatesSliceName}/${ETemplatesActions.UPDATE_TEMPLATE}`,
  async (payload: Partial<ProcessDTO> & Pick<ProcessDTO, 'id'>) => processApi.updateProcess(payload),
);

export const createTemplate = createAsyncThunk(
  `${templatesSliceName}/${ETemplatesActions.CREATE_TEMPLATE}`,
  async (payload: CreateTemplateRequest) => processApi.createTemplate(payload),
);

export const deleteTemplate = createAsyncThunk(
  `${templatesSliceName}/${ETemplatesActions.DELETE_TEMPLATE}`,
  async (payload: string) => processApi.deleteProcess(payload),
);

const initialTemplateLibraryState: Partial<TTemplatesState> = {
  status: EStateStatus.IDLE,
  filter: '',
  newTemplateDialogStatus: false,
  templateEditMode: false,
  currentTab: ETemplatesTypes.TEMPLATES,
};

const initialTemplateState: Partial<TTemplatesState> = templatesAdapter.getInitialState({
  status: EStateStatus.IDLE,
  selectedTemplateId: '',
  isCreateDialogOpen: false,
  isCloneTemplateDialogOpen: false,
  templateId: '',
});

// type TemplatesSliceType = {
//   status: EStateStatus,
//   filter: string,
//   currentTab: ETemplatesTypes,
//   newTemplateDialogStatus: boolean,
//   templateEditMode: boolean,
//   selectedTemplate?: {
//     id?: string,
//     title?: string,
//     type?: EFieldType | EProcessType,
//   },
// };

// const initialState: TemplatesSliceType = {
//   status: EStateStatus.IDLE,
//   filter: '',
//   newTemplateDialogStatus: false,
//   templateEditMode: false,
//   currentTab: ETemplatesTypes.TEMPLATES,
// };

export const templatesSlice = createSlice({
  name: templatesSliceName,
  initialState,
  reducers: {
    setLibraryTab: (state, { payload }: PayloadAction<ETemplatesTypes>) => ({
      ...state,
      currentTab: payload,
    }),
    resetLibraryFilter: (state) => ({
      ...state,
      ...initialTemplateLibraryState,
    }),
    setLibraryFilter: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      filter: payload,
    }),
    setSelectedTemplateData: (state, { payload }: PayloadAction<TTemplatesState['selectedTemplate']>) => ({
      ...state,
      selectedTemplate: payload,
    }),
    updateSelectedTemplateData: (state, { payload }: PayloadAction<Partial<TTemplatesState['selectedTemplate']>>) => {
      state.selectedTemplate = state?.selectedTemplate ? { ...state.selectedTemplate, ...payload } : { ...payload };
    },
    resetSelectedTemplateData: (state) => ({
      ...state,
      selectedTemplate: undefined,
    }),
    setNewTemplateDialogStatus: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      newTemplateDialogStatus: payload,
    }),
    setTemplateEditMode: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      templateEditMode: payload,
    }),
    clearTemplatesState: (state) => ({
      ...state,
      ...initialTemplateState,
    }),
    openCloneTemplateDialog: (state, action: PayloadAction<string>) => {
      state.isCloneTemplateDialogOpen = true;
      state.selectedTemplateId = action.payload;
    },
    closeCloneTemplateDialog: (state) => {
      state.isCloneTemplateDialogOpen = false;
      state.selectedTemplateId = '';
    },
  },
  extraReducers: ((builder) => {
    builder
      .addCase(getTemplates.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(getTemplates.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        templatesAdapter.setMany(state, action.payload);
      })
      .addCase(getTemplates.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(updateTemplate.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(updateTemplate.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        templatesAdapter.updateOne(state, {
          id: action.payload.id,
          changes: {
            ...action.payload,
          },
        });
      })
      .addCase(updateTemplate.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(createTemplate.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(createTemplate.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        templatesAdapter.addOne(state, action.payload);
      })
      .addCase(createTemplate.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(deleteTemplate.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(deleteTemplate.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        templatesAdapter.removeOne(state, action.payload);
      })
      .addCase(deleteTemplate.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      });
  }
  ),
});

export const {
  setLibraryTab,
  resetLibraryFilter,
  setLibraryFilter,
  setSelectedTemplateData,
  updateSelectedTemplateData,
  resetSelectedTemplateData,
  setNewTemplateDialogStatus,
  setTemplateEditMode,
  clearTemplatesState,
  openCloneTemplateDialog,
  closeCloneTemplateDialog,
} = templatesSlice.actions;

const { selectAll } = templatesAdapter.getSelectors();

export const selectAllTemplates = (state: RootState) => selectAll(state.templates);
export const selectTemaplatesLoadingStatus = (state: RootState): EStateStatus => state?.templates?.status;
export const selectLibraryTab = (state: RootState): ETemplatesTypes => state.templates.currentTab;
export const selectLibraryFilter = (state: RootState): string => state.templates.filter;
export const selectLibraryTemplateData = (state: RootState): TTemplatesState['selectedTemplate'] => state?.templates?.selectedTemplate;
export const selectLibraryTemplateId = (state: RootState):string | undefined => state?.templates?.selectedTemplate?.id;
export const selectNewTemplateDialogStatus = (state: RootState): boolean => state.templates.newTemplateDialogStatus;
export const selectIsTemplateEditMode = (state: RootState): boolean => state.templates.templateEditMode;
