import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded';
import { Navigate, Route, Routes } from 'react-router-dom';
import { EUserOrganizationPermissions } from '../../shared/permissions';
import { ESubscriptionLimits } from '../../shared/Subscription';
import {
  BillingIcon, BoardsIcon, CloudIcon, IntegrationIcon, TeamIcon,
} from '../icons/RouterIcons/RouterIcons';
import Templates from '../pages/templates/Templates';
import TemplatePage from '../pages/templates/TemplatePage';
// import Settings from '../routes-old/account/Settings';
//
import SettingsNew from '../routes-old/account/SettingsNew';
//
import InactiveAccountSocialLogin from '../routes-old/auth/Components/InactiveAccountSocialLogin';
import ResendActivationLetter from '../routes-old/auth/Components/ResendActivationLetter';
import { ConfirmAccount } from '../routes-old/auth/ConfirmAccount/ConfirmAccount';
import SocialConfirmationsSuccessRedirect from '../routes-old/auth/ConfirmAccount/SocialConfirmationsSuccessRedirect';
import GoogleAuthRedirect from '../routes-old/auth/Google/GoogleAuthRedirect';
import SocialAuthRedirect from '../routes-old/auth/Google/SocialAuthRedirect';
import { Login } from '../routes-old/auth/Login/Login';
import ResetPassword from '../routes-old/auth/ResetPassword/ResetPassword';
import ResetPasswordConfirm from '../routes-old/auth/ResetPassword/ResetPasswordConfirm';
import ResetPasswordSuccess from '../routes-old/auth/ResetPassword/ResetPasswordSuccess';
import { ShareAuthorization } from '../routes-old/auth/ShareAuth/ShareAuthorization';
import Signup from '../routes-old/auth/Signup/Signup';
import SignupSuccess from '../routes-old/auth/Signup/SignupSuccess';
import Billing from '../routes-old/Billing/Billing';
import BuyerDeals from '../routes-old/BuyerDeals/BuyerDeals';
import Deals from '../routes-old/deals/Deals';
import FilesManagement from '../routes-old/FileManagement/FileManagement';
import Integrations from '../routes-old/Integrations/Integrations';
import OrganizationUsers from '../routes-old/organizationUsers/OrganizationUsers';
import DealFeed from '../routes-old/process/DealFeed/DealFeed';
import { DealLayout } from '../routes-old/process/DealLayout';
import { Milestone } from '../routes-old/process/Milestone/Milestone';
import DealGuestAuth from '../routes-old/auth/DealGuestAuth/DealGuestAuth';
import DealGuestRedirect from '../routes-old/auth/DealGuestAuth/DealGuestRedirect';
import GuestFallback from '../pages/Guest/GuestFallback';
import CustomDomainConfirm from '../routes-old/auth/CustomDomain/CustomDomainConfirm';
import CustomDomainSoacialAuthStart from '../routes-old/auth/CustomDomain/CustomDomainSocialAuthStart';
import AdminPage from '../routes-old/admin/Admin';
import { UserNotFound } from '../common/UserNotFound';
import LibraryPage from '../pages/library/LibraryPage';

export interface IRoute {
  path: string;
  element: JSX.Element;
  premium?: boolean;
  icon?: JSX.Element;
  checkSubscriptionLimits?: ESubscriptionLimits;
  title?: string;
  routes?: IRoute[];
  orgPermission?: EUserOrganizationPermissions;
  pathItemId?: string;
  admin?: boolean;
}

export enum EAppRoutes {
  home = '/home',
  deal = '/deal',
  deals = '/deals',
  dealsManagement = '/deals-management',
  filesManagement = '/files',
  templateDeals = '/template-deals',
  settings = '/settings',
  organizationUsers = '/team',
  templates = '/templates',
  auth = '/auth',
  socialConfirmationSuccess = '/social-confirmation-success',
  billing = '/billing',
  login = '/login',
  signup = '/signup',
  signupSuccess = '/signup-success',
  confirm = '/confirm',
  milestone = '/milestone',
  resetPassword = '/reset-password',
  resetPasswordSuccess = '/reset-password-success',
  resetPasswordConfirm = '/reset-password-confirm',
  share = '/share',
  integrations = '/integrations',
  google = '/google',
  socialAuthRedirect = '/social-auth-redirect',
  resendActivationLetter = '/resend-activation-letter',
  useSocialLogin = '/use-social-login',
  dealActivityLog = '/activity-log',
  guestFallback = '/guest-fallback',
  customDomain = '/custom-domain',
  customDomainSocialAuth = '/custom-domain-social-auth',
  admin = '/admin',
  userNotFound = '/user-no-found',
  library = '/library',
}

const authRoutes: IRoute[] = [
  {
    path: EAppRoutes.auth + EAppRoutes.confirm,
    element: <ConfirmAccount />,
  },
  {
    path: EAppRoutes.auth + EAppRoutes.login,
    element: <Login />,
  },
  {
    path: `${EAppRoutes.auth + EAppRoutes.share}/:hash`,
    element: <ShareAuthorization />,
  },
  {
    path: `${EAppRoutes.auth + EAppRoutes.share}`,
    element: <DealGuestAuth />,
  },
  {
    path: `${EAppRoutes.deal}/:id`,
    element: <DealGuestAuth />,
  },
  {
    path: EAppRoutes.auth + EAppRoutes.signup,
    element: <Signup />,
  },
  {
    path: EAppRoutes.auth + EAppRoutes.signupSuccess,
    element: <SignupSuccess />,
  },
  {
    path: EAppRoutes.auth + EAppRoutes.resetPassword,
    element: <ResetPassword />,
  },
  {
    path: EAppRoutes.auth + EAppRoutes.resetPasswordSuccess,
    element: <ResetPasswordSuccess />,
  },
  {
    path: EAppRoutes.auth + EAppRoutes.resetPasswordConfirm,
    element: <ResetPasswordConfirm />,
  },
  {
    path: EAppRoutes.auth + EAppRoutes.google,
    element: <GoogleAuthRedirect />,
  },
  {
    path: EAppRoutes.auth + EAppRoutes.socialConfirmationSuccess,
    element: <SocialConfirmationsSuccessRedirect />,
  },
  {
    path: EAppRoutes.auth + EAppRoutes.resendActivationLetter,
    element: <ResendActivationLetter />,
  },
  {
    path: EAppRoutes.auth + EAppRoutes.useSocialLogin,
    element: <InactiveAccountSocialLogin />,
  },
  {
    path: EAppRoutes.auth + EAppRoutes.socialAuthRedirect,
    element: <SocialAuthRedirect />,
  },
  {
    path: EAppRoutes.auth + EAppRoutes.customDomain,
    element: <CustomDomainConfirm />,
  },
  {
    path: EAppRoutes.auth + EAppRoutes.customDomainSocialAuth,
    element: <CustomDomainSoacialAuthStart />,
  },
  {
    path: EAppRoutes.auth + EAppRoutes.userNotFound,
    element: <UserNotFound />,
  },
];

const dealRoutes: IRoute[] = [
  // {
  //   path: '/*',
  //   element: <ProcessWrapper />,
  // },
  {
    path: `${EAppRoutes.milestone}/:milestoneId`,
    element: <Milestone />,
  },
];

const dealMobileRoutes: IRoute[] = [
  {
    path: '/*',
    element: <DealFeed />,
  },
];

const sharedRoutes: IRoute[] = [
  {
    path: `${EAppRoutes.deal}/:id`,
    element: <DealLayout />,
  },
];

const guestRouter: IRoute[] = [
  {
    path: `${EAppRoutes.deal}/:id`,
    element: <DealLayout />,
  },
  {
    path: `${EAppRoutes.auth + EAppRoutes.share}/:hash`,
    element: <DealGuestRedirect />,
  },
  {
    path: `${EAppRoutes.auth + EAppRoutes.share}`,
    element: <DealGuestAuth />,
  },
  {
    path: EAppRoutes.auth + EAppRoutes.confirm,
    element: <ConfirmAccount />,
  },
  {
    path: EAppRoutes.guestFallback,
    element: <GuestFallback />,
  },
];

export const appRoutes: IRoute[] = [
  {
    icon: <BoardsIcon />,
    title: 'Deals',
    orgPermission: EUserOrganizationPermissions.ORGANIZATION_NAV_DEALS,
    path: EAppRoutes.home,
    element: <Deals />,
    pathItemId: 'deals',
  },
  {
    path: `${EAppRoutes.auth + EAppRoutes.share}/:hash`,
    element: <ShareAuthorization />,
  },
  {
    path: `${EAppRoutes.auth + EAppRoutes.share}`,
    element: <ShareAuthorization />,
  },
  {
    icon: <BusinessCenterIcon />,
    title: 'Templates',
    orgPermission: EUserOrganizationPermissions.ORGANIZATION_NAV_DEALS,
    path: EAppRoutes.templates,
    element: <Templates />,
    pathItemId: 'templates',
  },
  {
    icon: <CorporateFareRoundedIcon />,
    title: 'Deals Management',
    path: EAppRoutes.dealsManagement,
    element: <Deals />,
    pathItemId: 'deals',
  },
  {
    icon: <BusinessCenterIcon />,
    title: 'Buyer deals',
    path: EAppRoutes.deals,
    element: <BuyerDeals />,
    pathItemId: 'buyer-deals',
  },
  {
    premium: true,
    icon: <TeamIcon />,
    checkSubscriptionLimits: ESubscriptionLimits.TEAM_CAN_MANAGE,
    orgPermission: EUserOrganizationPermissions.ORGANIZATION_NAV_TEAM,
    title: 'Team',
    path: EAppRoutes.organizationUsers,
    element: <OrganizationUsers />,
    pathItemId: 'team',
  },
  {
    premium: true,
    icon: <CloudIcon />,
    title: 'File Management',
    orgPermission: EUserOrganizationPermissions.ORGANIZATION_NAV_FILES,
    path: EAppRoutes.filesManagement,
    element: <FilesManagement isUploadActive isDeleteActive isDownloadActive isToolbarActive />,
    pathItemId: 'file-management',
  },
  {
    path: `${EAppRoutes.deal}/:id/*`,
    element: <DealLayout />,
  },
  {
    path: `${EAppRoutes.templates}/:type/:id/*`,
    element: <TemplatePage />,
  },
  {
    path: EAppRoutes.settings,
    element: <SettingsNew />,
  },
  {
    path: EAppRoutes.billing,
    icon: <BillingIcon />,
    orgPermission: EUserOrganizationPermissions.ORGANIZATION_NAV_BILLING,
    element: <Billing />,
    pathItemId: 'billing',
  },
  {
    premium: true,
    path: EAppRoutes.integrations,
    orgPermission: EUserOrganizationPermissions.ORGANIZATION_NAV_INTEGRATION,
    element: <Integrations />,
    title: 'Integrations',
    icon: <IntegrationIcon />,
    pathItemId: 'integrations',
  },
  {
    admin: true,
    path: EAppRoutes.admin,
    element: <AdminPage />,
    title: 'Admin page',
    pathItemId: 'admin_page',
  },
  {
    icon: <BusinessCenterIcon />,
    title: 'Library',
    path: EAppRoutes.library,
    element: <LibraryPage />,
    pathItemId: 'library',
  },
];

export const buyerRoutes: IRoute[] = [
  {
    icon: <BusinessCenterIcon />,
    title: 'Buyer deals',
    path: EAppRoutes.home,
    element: <Deals />,
    pathItemId: 'deals',
  },
  {
    icon: <BusinessCenterIcon />,
    title: 'Buyer deals',
    path: EAppRoutes.deals,
    element: <BuyerDeals />,
  },
  {
    path: `${EAppRoutes.deal}/:id/*`,
    element: <DealGuestAuth />,
  },
  {
    path: EAppRoutes.settings,
    element: <SettingsNew />,
  },
];

const generateRoutes = (routes: IRoute[]): JSX.Element[] => routes
  .map((route: IRoute) => {
    if (!route.routes) {
      return <Route path={route.path} element={route.element} key={route.path} />;
    }
    return (
      <Route path={route.path} element={route.element} key={route.path}>
        {generateRoutes(route.routes)}
      </Route>
    );
  });

export const AuthRouter = (): JSX.Element => (
  <Routes>
    {generateRoutes(authRoutes)}
    <Route path="/*" element={<Navigate to={EAppRoutes.auth + EAppRoutes.login} />} />
  </Routes>
);

export const BuyerRouter = (): JSX.Element => (
  <Routes>
    <Route path="/*" element={<Navigate to="/home" />} />
    {generateRoutes(buyerRoutes)}
  </Routes>
);

export default function AppRouter(): JSX.Element {
  return (
    <Routes>
      <Route path="/*" element={<Navigate to="/home" />} />
      {generateRoutes(appRoutes)}
    </Routes>
  );
}

export const DealRouter = (): JSX.Element => (
  <Routes>
    {generateRoutes(dealRoutes)}
  </Routes>
);
export const DealMobileRouter = (): JSX.Element => (
  <Routes>
    {generateRoutes(dealMobileRoutes)}
  </Routes>
);

export const SharedRouter = (): JSX.Element => (
  <Routes>
    <Route path="/*" element={<Navigate to="/deal/share" />} />
    {generateRoutes(sharedRoutes)}
  </Routes>
);

export const GuestRouter = (): JSX.Element => (
  <Routes>
    <Route path="/*" element={<Navigate to={EAppRoutes.guestFallback} />} />
    {generateRoutes(guestRouter)}
  </Routes>
);
