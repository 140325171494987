import {
  alpha,
  lighten,
  styled,
} from '@mui/system';
import { LoadingButton } from '@mui/lab';
import { greySidebarContrastText } from '../../core/utils/greyContrastText';
import { stagesetColors } from '../../theme/stagesetPalette';

export const StyledAppButton = styled<any>(LoadingButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  stroke: theme.palette.primary.main,
  whiteSpace: 'nowrap',
  '&.hidden': {
    display: 'none !important',
  },
  '&:not(.initial-stroke) path': {
    stroke: 'inherit',
  },
  '&:not(.initial-stroke) g': {
    stroke: 'inherit',
  },
  '&.size': {
    '&-xs': {
      '&:not(.auto-width)': {
        minWidth: '48px',
      },
      height: '20px',
      padding: '0',
      borderRadius: '4px',
    },
    '&-s': {
      '&:not(.auto-width)': {
        minWidth: '98px',
      },
      height: '24px',
      padding: '4px 8px',
      borderRadius: '6px',
    },
    '&-s-without-padding': {
      '&:not(.auto-width)': {
        minWidth: 'min-content',
      },
      height: '24px',
      padding: '0px',
    },
    '&-m': {
      '&:not(.auto-width)': {
        minWidth: '122px',
      },
      height: '32px',
      padding: '6px 12px',
      borderRadius: '8px',
    },
    '&-m-minWidth': {
      '&:not(.auto-width)': {
        width: 'min-content',
      },
      height: '32px',
      padding: '6px 12px',
      borderRadius: '8px',
    },
    '&-m-auto': {
      width: 'auto',
      minWidth: 'auto',
      height: '32px',
      padding: '6px 12px',
      borderRadius: '8px',
    },
    '&-l': {
      '&:not(.auto-width)': {
        minWidth: '130px',
      },
      height: '40px',
      padding: '10px 16px',
      borderRadius: '10px',
      fontSize: '16px',
      lineHeight: '22px',
    },
    '&-l-auto': {
      width: 'auto',
      minWidth: 'auto',
      height: '40px',
      padding: '10px 16px',
      borderRadius: '10px',
    },
    '&-l-ds': {
      display: 'flex',
      flexDirection: 'row',
      maxWidth: '100%',
      gap: '8px',
      height: '40px',
      padding: '10px 16px',
      borderRadius: '10px 0px 0px 10px',
      '& span': {
        margin: 0,
      },
    },
    '&-l-grid-span-full': {
      '&:not(.auto-width)': {
        minWidth: '130px',
      },
      height: '40px',
      padding: '10px 16px',
      borderRadius: '10px',
      gridColumn: 'span 2',
    },
    '&-l-minWidth': {
      '&:not(.auto-width)': {
        width: 'min-content',
      },
      height: '40px',
      padding: '16px',
      borderRadius: '10px',
    },
    '&-l-contactCard': {
      '&:not(.auto-width)': {
        width: 'min-content',
      },
      display: 'flex',
      height: '40px',
      borderRadius: '30px 10px 10px 30px',
      width: 'min-content',
      paddingRight: '16px',
      gap: '12px',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '16px',
      '&-mobile': {
        gap: '8px',
        '& span': {
          marginLeft: '-8px',
          marginRight: '0px',
        },
      },
      '& span': {
        marginLeft: '-8px',
        marginRight: '0px',
      },
    },
    '&-l-flex-start': {
      display: 'flex',
      justifyContent: 'flex-start',
      height: '40px',
      padding: '10px 16px',
      borderRadius: '10px',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '22px',
    },
    '&-l-flex-start-new-page': {
      display: 'flex',
      justifyContent: 'flex-start',
      height: '40px',
      padding: '10px 24px',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '22px',
    },
    '&-xl': {
      '&:not(.auto-width)': {
        minWidth: '138px',
      },
      height: '48px',
      padding: '12px 16px',
      borderRadius: '12px',
    },
    '&-xl-flex-start': {
      display: 'flex',
      justifyContent: 'flex-start',
      height: '48px',
      padding: '8px 16px',
      borderRadius: '12px',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '22px',
    },
    '&-xl-flex-start-with-padding': {
      display: 'flex',
      justifyContent: 'flex-start',
      height: '48px',
      padding: '8px 24px 8px 24px',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '22px',
      gap: '6px',
      '& span': {
        display: 'flex',
        margin: '0px',
        width: '26px',
        maxWidth: '26px',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    '&-xxxl-flex-start-new-page': {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      height: '56px',
      padding: '8px 24px',
      gap: '6px',
      '& span': {
        display: 'flex',
        margin: '0px',
        width: '26px',
        maxWidth: '26px',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
  },
  '&.icon-button': {
    padding: '10px',
    '&.size': {
      '&-xxs': {
        width: '16px',
        minWidth: '16px',
        height: '16px',
        minHeight: '16px',
      },
      '&-xs': {
        width: '20px',
        minWidth: '20px',
        padding: '2px',
      },
      '&-s': {
        width: '24px',
        minWidth: '24px',
        padding: '4px',
      },
      '&-m': {
        width: '32px',
        minWidth: '32px',
        padding: '6px',
      },
      '&-l': {
        width: '40px',
        minWidth: '40px',
        padding: '10px',
      },
      '&-l-ds-icon': {
        minWidth: '44px',
        width: '44px',
        height: '40px',
        padding: '10px 12px',
        borderRadius: '0 10px 10px 0',
        borderLeft: '1px solid',
        '& svg': {
          width: '20px',
          height: '20px',
        },
      },
      '&-xl': {
        width: '48px',
        minWidth: '48px',
        padding: '12px',
      },
      '&-xxl': {
        width: '52px',
        minWidth: '52px',
        padding: '10px',
      },
    },
    '&.variant': {
      '&-tertiary-newGrey-ds-right': {
        background: stagesetColors.white[100],
        stroke: stagesetColors.newGrey[800],
        borderColor: stagesetColors.newGrey[200],
        boxShadow: '1px 0px 4px -2px rgba(0, 57, 96, 0.4)',
        '&:not(.mobile):hover': {
          background: stagesetColors.newGrey[100],
          boxShadow: '1px 0px 2px -2px rgba(0, 57, 96, 0.4)',
        },
        '&:not(.mobile):active': {
          background: stagesetColors.newGrey[200],
        },
        '&.disabled': {
          color: stagesetColors.newGrey[300],
          stroke: stagesetColors.newGrey[300],
        },
        '&.loader': { boxShadow: '1px 0px 4px -2px rgba(0, 57, 96, 0.4)' },
      },
      '&-gradient': {
        background: 'linear-gradient(139deg, #E5EDFF 10.78%, #D2E6FF 50.62%, #8FC3FF 104.52%)',
        color: stagesetColors.newGrey[800],
        stroke: stagesetColors.newGrey[800],
        borderRadius: '10px',
        '&:not(.mobile):hover': {
          background: stagesetColors.blue[200],
        },
        '&:not(.mobile):active': {
          background: stagesetColors.blue[300],
        },
      },
    },
  },
  '&.link-button': {
    '&:not(.auto-width)': {
      // minWidth: '99px',
    },
    background: 'transparent !important',
    '&.size': {
      '&-xs': {
        height: '20px',
      },
      '&-s': {
        height: '24px',
      },
      '&-m': {
        height: '32px',
      },
      '&-l': {
        height: '40px',
      },
    },
  },
  '&.variant': {
    '&-accent': {
      background: 'linear-gradient(180deg, #009FFD -76.25%, #2A2A72 100%)',
      color: theme.palette.common.white,
      stroke: theme.palette.common.white,
      '&:not(.mobile):hover': {
        background: 'linear-gradient(180deg, #4CBDFF -76.25%, #4545BA 100%)',
      },
      '&:not(.mobile):active': {
        background: 'linear-gradient(180deg, #6BC8FF -76.25%, #7D7DCF 100%)',
      },
      '&.disabled': {
        color: theme.palette.grey[200],
        stroke: theme.palette.grey[200],
        background: theme.palette.grey[100],
      },
    },
    '&-primary': {
      background: theme.palette.primary[800],
      color: theme.palette.common.white,
      stroke: theme.palette.common.white,
      '&:not(.mobile):hover': {
        background: theme.palette.primary[600],
      },
      '&:not(.mobile):active': {
        background: theme.palette.primary[500],
      },
      '&.disabled': {
        color: theme.palette.primary[300],
        stroke: theme.palette.primary[300],
        background: theme.palette.primary[50],
      },
      '&.loader': {
        background: theme.palette.primary[50],
        color: theme.palette.primary[800],
        stroke: theme.palette.primary[800],
      },
    },
    '&-primary-grey': {
      background: stagesetColors.grey[600],
      color: theme.palette.common.white,
      stroke: theme.palette.common.white,
      '&:not(.mobile):hover': {
        color: theme.palette.warning[600],
        stroke: theme.palette.warning[600],
      },
      '&:not(.mobile):active': {
        color: stagesetColors.grey[400],
        stroke: stagesetColors.grey[400],
      },
      '&.disabled': {
        color: stagesetColors.grey[200],
        stroke: stagesetColors.grey[200],
      },
    },
    '&-primary-newGrey': {
      fontWeight: 600,
      background: stagesetColors.newGrey[800],
      color: stagesetColors.white[100],
      stroke: stagesetColors.white[100],
      '&:not(.mobile):hover': {
        background: stagesetColors.newGrey[600],
      },
      '&:not(.mobile):active': {
        background: stagesetColors.newGrey[500],
      },
      '&.disabled': {
        background: stagesetColors.newGrey[50],
        color: stagesetColors.newGrey[300],
        stroke: stagesetColors.newGrey[300],
      },
      '&.loader': {
        background: stagesetColors.newGrey[50],
        color: stagesetColors.newGrey[800],
        stroke: stagesetColors.newGrey[800],
      },
    },
    '&-primary-success': {
      background: stagesetColors.green[600],
      color: stagesetColors.white[100],
      stroke: stagesetColors.white[100],
      '&:not(.mobile):hover': {
        background: stagesetColors.green[500],
      },
      '&:not(.mobile):active': {
        background: stagesetColors.green[400],
      },
      '&.disabled': {
        background: stagesetColors.newGrey[50],
        color: stagesetColors.newGrey[300],
        stroke: stagesetColors.newGrey[300],
      },
      '&.loader': {
        background: stagesetColors.newGrey[50],
        color: stagesetColors.newGrey[800],
        stroke: stagesetColors.newGrey[800],
      },
    },
    '&-buyer-burger-button-themed': {
      backgroundColor: theme.dealOverrides.sidebar.backgroundColor,
      color: greySidebarContrastText(theme, 0.8),
      stroke: greySidebarContrastText(theme, 0.8),
      '&:not(.mobile):hover': {
        color: greySidebarContrastText(theme, 0.6),
        stroke: greySidebarContrastText(theme, 0.6),
      },
      '&:not(.mobile):active': {
        color: greySidebarContrastText(theme, 0.4),
        stroke: greySidebarContrastText(theme, 0.4),
      },
      '&.disabled': {
        color: greySidebarContrastText(theme, 0.2),
        stroke: greySidebarContrastText(theme, 0.2),
      },
    },
    '&-secondary': {
      color: theme.palette.primary[800],
      stroke: theme.palette.primary[800],
      background: theme.palette.primary[200],
      '&:not(.mobile):hover': {
        background: theme.palette.primary[300],
        color: theme.palette.primary[800],
        stroke: theme.palette.primary[800],
      },
      '&:not(.mobile):active': {
        background: theme.palette.primary[500],
        color: theme.palette.common.white,
        stroke: theme.palette.common.white,
      },
      '&.disabled': {
        color: theme.palette.primary[300],
        stroke: theme.palette.primary[300],
        background: theme.palette.primary[50],
      },
      '&.loader': {
        background: theme.palette.primary[50],
        color: theme.palette.primary[800],
        stroke: theme.palette.primary[800],
      },
    },
    '&-secondary-black-icon': {
      fontWeight: 600,
      color: stagesetColors.newGrey[800],
      stroke: stagesetColors.newGrey[800],
      background: stagesetColors.newGrey[200],
      '&:not(.mobile):hover': {
        color: stagesetColors.newGrey[800],
        stroke: stagesetColors.newGrey[800],
        background: stagesetColors.newGrey[300],
      },
      '&:not(.mobile):active': {
        color: stagesetColors.white[100],
        stroke: stagesetColors.white[100],
        background: stagesetColors.newGrey[500],
      },
      '&.disabled': {
        color: stagesetColors.newGrey[500],
        stroke: stagesetColors.newGrey[500],
        background: 'transparent',
      },
      '&.loader': {
        background: stagesetColors.newGrey[50],
        color: stagesetColors.white[800],
        stroke: stagesetColors.white[800],
      },
    },
    '&-secondary-newGrey': {
      fontWeight: 600,
      color: stagesetColors.newGrey[800],
      stroke: stagesetColors.newGrey[500],
      background: stagesetColors.newGrey[200],
      '&:not(.mobile):hover': {
        color: stagesetColors.newGrey[800],
        stroke: stagesetColors.newGrey[500],
        background: stagesetColors.newGrey[300],
      },
      '&:not(.mobile):active': {
        color: stagesetColors.white[100],
        stroke: stagesetColors.white[100],
        background: stagesetColors.newGrey[500],
      },
      '&.disabled': {
        color: stagesetColors.newGrey[500],
        stroke: stagesetColors.newGrey[500],
        background: 'transparent',
      },
      '&.loader': {
        background: stagesetColors.newGrey[50],
        color: stagesetColors.white[800],
        stroke: stagesetColors.white[800],
      },
    },
    '&-secondary-success': {
      border: '1px solid',
      background: stagesetColors.green[100],
      borderColor: stagesetColors.green[200],
      color: stagesetColors.green[600],
      stroke: stagesetColors.green[600],
      '&:not(.mobile):hover': {
        background: stagesetColors.green[200],
      },
      '&:not(.mobile):active': {
        color: stagesetColors.white[100],
        stroke: stagesetColors.white[100],
        background: stagesetColors.green[600],
      },
      '&.disabled': {
        background: stagesetColors.newGrey[50],
        color: stagesetColors.newGrey[300],
        stroke: stagesetColors.newGrey[300],
      },
      '&.loader': {
        background: stagesetColors.newGrey[50],
        color: stagesetColors.newGrey[800],
        stroke: stagesetColors.newGrey[800],
      },
    },
    '&-secondary-disabled': {
      '&.disabled': {
        border: '1px solid',
        borderColor: stagesetColors.newGrey[200],
        color: stagesetColors.newGrey[800],
        stroke: stagesetColors.newGrey[500],
        background: stagesetColors.newGrey[100],
      },
    },
    '&-transparent': {
      background: 'transparent',
      fontWeight: 600,
      color: theme.palette.primary.main,
      stroke: theme.palette.primary.main,
      '&-grey-icon': {
        stroke: theme.palette.primary[500],
      },
      '& rect': {
        stroke: theme.palette.primary.main,
      },
      '&:not(.mobile):hover': {
        background: theme.palette.primary[200],
      },
      '&:not(.mobile):active': {
        background: theme.palette.primary[300],
      },
      '&.disabled': {
        background: 'transparent',
        color: theme.palette.primary[300],
        stroke: theme.palette.primary[300],
      },
      '&.loader': {
        color: theme.palette.primary[800],
        stroke: theme.palette.primary[800],
        background: 'transparent',
      },
      // '&.fixed-colors': {
      //   color: '#0073F5',
      //   stroke: '#0073F5',
      //   '&:not(.mobile):hover': {
      //     background: '#E5EDFF',
      //     color: '#0073F5',
      //     stroke: '#0073F5',
      //     // color: ,
      //     // stroke: ,
      //   },
      // },
    },
    '&-transparent-without-stroke': {
      background: 'transparent',
      color: 'unset',
      stroke: 'unset',
      '&:not(.mobile):hover': {
        color: 'unset',
        stroke: 'unset',
        background: theme.palette.primary[100],
      },
      '&:not(.mobile):active': {
        color: 'unset',
        stroke: 'unset',
        background: theme.palette.primary[400],
      },
      '&.loader': {
        color: 'unset',
        stroke: 'unset',
        background: theme.palette.grey[100],
      },
    },
    '&-transparent-white': {
      background: 'transparent',
      color: stagesetColors.white[100],
      stroke: stagesetColors.white[100],
      '& rect': {
        stroke: stagesetColors.white[100],
      },
      '&:not(.mobile):hover': {
        background: stagesetColors.newGrey[700],
      },
      '&:not(.mobile):active': {
        background: stagesetColors.newGrey[600],
      },
      '&.disabled': {
        background: 'transparent',
        color: alpha(stagesetColors.white[100], 0.1),
        stroke: alpha(stagesetColors.white[100], 0.1),
      },
      '&.loader': {
        color: alpha(stagesetColors.white[100], 0.1),
        stroke: alpha(stagesetColors.white[100], 0.1),
        background: 'transparent',
      },
      // '&.fixed-colors': {
      //   color: '#0073F5',
      //   stroke: '#0073F5',
      //   '&:not(.mobile):hover': {
      //     background: '#E5EDFF',
      //     color: '#0073F5',
      //     stroke: '#0073F5',
      //     // color: ,
      //     // stroke: ,
      //   },
      // },
    },
    '&-transparent-black': {
      background: 'transparent',
      color: stagesetColors.black[800],
      stroke: stagesetColors.black[800],
      '& rect': {
        stroke: stagesetColors.black[800],
      },
      '&:not(.mobile):hover': {
        background: alpha(stagesetColors.black[800], 0.2),
      },
      '&:not(.mobile):active': {
        background: alpha(stagesetColors.black[800], 0.4),
      },
      '&.disabled': {
        background: 'transparent',
        color: alpha(stagesetColors.black[800], 0.1),
        stroke: alpha(stagesetColors.black[800], 0.1),
      },
      '&.loader': {
        color: alpha(stagesetColors.black[800], 0.1),
        stroke: alpha(stagesetColors.black[800], 0.1),
        background: 'transparent',
      },
      // '&.fixed-colors': {
      //   color: '#0073F5',
      //   stroke: '#0073F5',
      //   '&:not(.mobile):hover': {
      //     background: '#E5EDFF',
      //     color: '#0073F5',
      //     stroke: '#0073F5',
      //     // color: ,
      //     // stroke: ,
      //   },
      // },
    },
    '&-transparent-grey': {
      color: stagesetColors.grey[800],
      stroke: stagesetColors.grey[800],
      background: 'transparent',
      '& rect': {
        stroke: stagesetColors.grey[800],
      },
      '&:not(.mobile):hover': {
        color: theme.palette.primary[500],
        stroke: theme.palette.primary[500],
        background: theme.palette.primary[100],
      },
      '&:not(.mobile):active': {
        background: theme.palette.primary[400],
        color: theme.palette.common.white,
        stroke: theme.palette.common.white,
      },
      '&.disabled': {
        color: theme.palette.grey[200],
        stroke: theme.palette.grey[200],
      },
      '&.loader': {
        color: theme.palette.grey[200],
        stroke: theme.palette.grey[200],
        background: theme.palette.grey[100],
      },
    },
    '&-transparent-black-icon': {
      fontWeight: 600,
      color: stagesetColors.newGrey[800],
      stroke: stagesetColors.newGrey[800],
      background: 'transparent',
      '& rect': {
        stroke: stagesetColors.newGrey[800],
      },
      '&:not(.mobile):hover': {
        background: stagesetColors.newGrey[200],
      },
      '&:not(.mobile):active': {
        background: stagesetColors.newGrey[300],
      },
      '&.disabled': {
        background: 'transparent',
        color: stagesetColors.newGrey[200],
        stroke: stagesetColors.newGrey[200],
      },
      '&.loader': {
        background: stagesetColors.newGrey[50],
        color: stagesetColors.newGrey[800],
        stroke: stagesetColors.newGrey[800],
      },
    },
    '&-transparent-newGrey': {
      fontWeight: 600,
      color: stagesetColors.newGrey[800],
      stroke: stagesetColors.newGrey[500],
      background: 'transparent',
      '& rect': {
        stroke: stagesetColors.newGrey[500],
      },
      '&:not(.mobile):hover': {
        background: stagesetColors.newGrey[200],
      },
      '&:not(.mobile):active': {
        background: stagesetColors.newGrey[300],
      },
      '&.disabled': {
        background: 'transparent',
        color: stagesetColors.newGrey[200],
        stroke: stagesetColors.newGrey[200],
      },
      '&.loader': {
        background: stagesetColors.newGrey[50],
        color: stagesetColors.newGrey[800],
        stroke: stagesetColors.newGrey[800],
      },
    },
    '&-transparent-contactCard': {
      fontWeight: 600,
      color: stagesetColors.newGrey[800],
      stroke: stagesetColors.newGrey[800],
      background: 'transparent',
      '& rect': {
        stroke: stagesetColors.newGrey[800],
      },
      '&-light': {
        '&:not(.mobile):hover': {
          background: lighten(stagesetColors.white[100], 0.2),
        },
        '&:not(.mobile):active': {
          background: lighten(stagesetColors.white[100], 0.6),
        },
      },
      '&-dark': {
        '&:not(.mobile):hover': {
          background: lighten(stagesetColors.black[800], 0.2),
        },
        '&:not(.mobile):active': {
          background: lighten(stagesetColors.black[800], 0.4),
        },
      },
      '&.disabled': {
        background: 'transparent',
        color: stagesetColors.newGrey[200],
        stroke: stagesetColors.newGrey[200],
      },
      '&.loader': {
        background: stagesetColors.newGrey[50],
        color: stagesetColors.newGrey[800],
        stroke: stagesetColors.newGrey[800],
      },
    },
    '&-outline': {
      // border: `1px solid ${theme.palette.primary[500]}`,
      // background: '#f5f6fb',
      // '&:hover': {
      //   background: theme.palette.grey[100],
      // },
      // '&:active': {
      //   background: theme.palette.grey[200],
      // },
      // '&:not(.mobile):hover': {
      //   background: theme.palette.primary[50],
      // },
      // '&:not(.mobile):active': {
      //   background: theme.palette.primary[400],
      //   color: theme.palette.common.white,
      // },
      '&.disabled': {
        color: theme.palette.grey[200],
        stroke: theme.palette.grey[200],
        border: `1px solid ${theme.palette.grey[200]}`,
      },
      '&.loader': {
        color: theme.palette.grey[200],
        stroke: theme.palette.grey[200],
        background: theme.palette.grey[100],
        border: 'none',
      },
    },
    '&-tertiary-newGrey-ds-left': {
      background: stagesetColors.white[100],
      color: stagesetColors.newGrey[800],
      stroke: stagesetColors.newGrey[800],
      boxShadow: '0px 0px 4px -2px rgba(0, 57, 96, 0.4)',
      '&:not(.mobile):hover': {
        background: stagesetColors.newGrey[100],
        boxShadow: '0px 0px 1px -2px rgba(0, 57, 96, 0.4)',
      },
      '&:not(.mobile):active': {
        background: stagesetColors.newGrey[200],
      },
      '&.disabled': {
        color: stagesetColors.newGrey[300],
        stroke: stagesetColors.newGrey[300],
      },
      '&.loader': { boxShadow: '0px 0px 4px -2px rgba(0, 57, 96, 0.4)' },
    },
    '&-tertiary-newGrey': {
      background: stagesetColors.white[100],
      color: stagesetColors.newGrey[800],
      stroke: stagesetColors.newGrey[800],
      boxShadow: '0px 3px 2px 0px rgba(30, 41, 59, 0.04),'
        + '0px 1px 1px 0px rgba(30, 41, 59, 0.07),'
        + '0px 0px 1px 0px rgba(30, 41, 59, 0.08),'
        + '0px 0px 0px 0px rgba(30, 41, 59, 0.08);',
      '&:not(.mobile):hover': {
        background: stagesetColors.newGrey[100],
      },
      '&:not(.mobile):active': {
        background: stagesetColors.newGrey[200],
      },
      '&.disabled': {
        color: stagesetColors.newGrey[300],
        stroke: stagesetColors.newGrey[300],
      },
    },
    '&-danger': {
      background: theme.palette.error[100],
      color: theme.palette.error.main,
      stroke: theme.palette.error.main,
      '&:not(.mobile):hover': {
        background: theme.palette.error[500],
        color: theme.palette.common.white,
        stroke: theme.palette.common.white,
      },
      '&:not(.mobile):active': {
        background: theme.palette.error[400],
        color: theme.palette.common.white,
        stroke: theme.palette.common.white,
      },
      '&.disabled': {
        color: theme.palette.grey[200],
        stroke: theme.palette.grey[200],
        background: theme.palette.grey[100],
      },
      '&.loader': {
        color: theme.palette.grey[200],
        stroke: theme.palette.grey[200],
        background: theme.palette.error[200],
      },
    },
    '&-danger-primary': {
      background: stagesetColors.red[600],
      color: stagesetColors.white[100],
      stroke: stagesetColors.white[100],
      '&:not(.mobile):hover': {
        background: stagesetColors.red[500],
      },
      '&:not(.mobile):active': {
        background: stagesetColors.red[400],
      },
      '&.disabled': {
        color: stagesetColors.newGrey[300],
        stroke: stagesetColors.newGrey[300],
        background: stagesetColors.newGrey[50],
      },
      '&.loader': {
        color: stagesetColors.newGrey[300],
        stroke: stagesetColors.newGrey[300],
        background: stagesetColors.newGrey[50],
      },
    },
    '&-danger-secondary': {
      background: stagesetColors.red[100],
      color: stagesetColors.red[600],
      stroke: stagesetColors.red[600],
      '&:not(.mobile):hover': {
        background: stagesetColors.red[200],
      },
      '&:not(.mobile):active': {
        background: stagesetColors.red[300],
      },
      '&.disabled': {
        color: stagesetColors.newGrey[300],
        stroke: stagesetColors.newGrey[300],
        background: stagesetColors.newGrey[50],
      },
      '&.loader': {
        color: stagesetColors.newGrey[300],
        stroke: stagesetColors.newGrey[300],
        background: stagesetColors.newGrey[50],
      },
    },
    '&-danger-transparent': {
      background: 'tansparent',
      color: stagesetColors.red[600],
      stroke: stagesetColors.red[600],
      '&:not(.mobile):hover': {
        background: stagesetColors.red[200],
      },
      '&:not(.mobile):active': {
        background: stagesetColors.red[300],
      },
      '&.disabled': {
        color: stagesetColors.newGrey[300],
        stroke: stagesetColors.newGrey[300],
        background: stagesetColors.newGrey[50],
      },
      '&.loader': {
        color: stagesetColors.newGrey[300],
        stroke: stagesetColors.newGrey[300],
        background: stagesetColors.newGrey[50],
      },
    },
    '&-lite-primary': {
      background: 'transparent',
      color: stagesetColors.newGrey[800],
      stroke: stagesetColors.newGrey[800],
      '&:not(.mobile):hover': {
        color: stagesetColors.orange[600],
        stroke: stagesetColors.orange[600],
      },
      '&:not(.mobile):active': {
        color: stagesetColors.orange[400],
        stroke: stagesetColors.orange[400],
      },
      '&.disabled': {
        color: stagesetColors.newGrey[300],
        stroke: stagesetColors.newGrey[300],
      },
    },
    '&-lite-danger': {
      background: 'transparent',
      color: stagesetColors.red[600],
      stroke: stagesetColors.red[600],
      '&:not(.mobile):hover': {
        color: stagesetColors.orange[600],
        stroke: stagesetColors.orange[600],
      },
      '&:not(.mobile):active': {
        color: stagesetColors.orange[400],
        stroke: stagesetColors.orange[400],
      },
      '&.disabled': {
        color: stagesetColors.newGrey[300],
        stroke: stagesetColors.newGrey[300],
      },
    },
    '&-lite-secondary': {
      background: 'transparent',
      color: stagesetColors.newGrey[500],
      stroke: stagesetColors.newGrey[500],
      '&:not(.mobile):hover': {
        color: stagesetColors.orange[600],
        stroke: stagesetColors.orange[600],
      },
      '&:not(.mobile):active': {
        color: stagesetColors.orange[400],
        stroke: stagesetColors.orange[400],
      },
      '&.disabled': {
        color: stagesetColors.newGrey[300],
        stroke: stagesetColors.newGrey[300],
      },
    },
  },
}));
