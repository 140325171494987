import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { MouseEventHandler } from 'react';
import { AppButton } from '../../../shared/AppButton/AppButton';
import Check from '../../../icons/Check20.svg';
import LightToolTip from '../../../shared/LightToolTip/LightToolTip';
import { useTranslation } from "react-i18next";

interface Props {
  logo: JSX.Element;
  name: string;
  haveIntegration: boolean;
  integration: { crmDisplayName: string | undefined; crmEmail: string | undefined; };
  onCancel: MouseEventHandler;
  onSet: MouseEventHandler;
  description: string,
  somethingIntegrated: boolean,
}

export const IntegrationCard = ({
  logo,
  name,
  haveIntegration,
  integration,
  onCancel,
  onSet,
  description,
  somethingIntegrated,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  return(
    <Grid
      item
      sm={6}
      xs={12}
      sx={{
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: '100%',
          paddingX: '24px',
          paddingY: '32px',
          display: 'flex',
          flex: 1,
          justifyContent: 'space-around',
          flexDirection: 'column',
          background: 'white',
          border: '1px solid #EEF0FA',
          borderRadius: '12px',
          gap: '8px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            maxWidth: '45px',
            alignItems: 'center',
            gap: '12px',
          }}
        >
          <Box>
            {logo}
          </Box>
          <Typography
            sx={{
              fontSize: '18px',
              fontWeight: 600,
            }}
          >
            {name}
          </Typography>
        </Box>
        <Box>
          <Typography>
            {description}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            marginTop: '8px',
          }}
        >
          {
            haveIntegration && (
              <LightToolTip title={integration?.crmEmail} placement="bottom-start">
                <Box>
                  <AppButton
                    variant="transparent"
                    sx={{
                      minWidth: 'auto !important',
                      pointerEvents: 'none',
                      backgroundColor: (theme) => `${theme.palette.success[100]} !important`,
                      color: (theme) => theme.palette.success[600],
                      stroke: (theme) => theme.palette.success[600],
                    }}
                    startIcon={<Check />}
                  >
                    {t('Integrations_Connected')}
                  </AppButton>
                  <AppButton
                    onClick={onCancel}
                    variant="danger-transparent"
                    sx={{
                      minWidth: 'auto !important',
                    }}
                  >
                    {t('Integrations_Remove_Connection')}
                  </AppButton>
                </Box>
              </LightToolTip>
            )
          }
          {
            !haveIntegration && (
              <Box>
                <AppButton
                  onClick={onSet}
                  variant="secondary"
                  disabled={somethingIntegrated}
                  sx={{
                    minWidth: 'auto !important',
                  }}
                >
                  {t('Integrations_Connect')}
                </AppButton>
              </Box>
            )
          }
        </Box>
      </Box>
    </Grid>
  );
};
