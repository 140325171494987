import {
  createSlice, PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from '../../../core/store/store';
import {
  EProcessBackgroundStorageType,
  EProcessBackgroundType,
  ThemePalette,
} from '../../../../shared/process/ProcessMilestoneActionDTO';
import { findSvgBackgroundItem } from '../helpers/DealCustomizationMenuHelpers';
import { getProcess } from '../../../routes-old/process/state/dealActions';

const dealCustomizationReducerName: string = 'dealCustomization';

export interface IDealCustomizationState {
  background?: number | string;
  tempBackground?: number | string;
  backgroundType?: EProcessBackgroundType;
  tempBackgroundType?: EProcessBackgroundType;
  currentBackgroundColors?:string[];
  tempBackgroundColors?: string[];
  backgroundStorageType?: EProcessBackgroundStorageType;
  tempBackgroundStorageType?: EProcessBackgroundStorageType;
  backgroundDisplayStatus?: boolean;
  processSvgColors?: { [key: number]: string[] };
  url?: string;
  backgroundColor?: string;
  dealThemePalette?: ThemePalette;
  initialDealThemePalette?: ThemePalette;
  displayLogo?: boolean;
}

const initialState: IDealCustomizationState = {
};

export const dealCustomizationSlice = createSlice({
  name: dealCustomizationReducerName,
  initialState,
  reducers: {
    setCustomizationData: (state, { payload }: PayloadAction<IDealCustomizationState>) => ({
      ...state,
      ...payload,
    }),
    setBackground: (state, { payload }: PayloadAction<number | string | undefined>) => ({
      ...state,
      background: payload,
    }),
    setBackgroundColor: (state, { payload }: PayloadAction<string >) => ({
      ...state,
      backgroundColor: payload,
    }),
    setTempBackground: (state, { payload }: PayloadAction<number | string | undefined>) => ({
      ...state,
      tempBackground: payload,
    }),
    setBackgroundType: (state, { payload }: PayloadAction<EProcessBackgroundType | undefined>) => ({
      ...state,
      backgroundType: payload,
    }),
    setTempBackgroundType: (state, { payload }: PayloadAction<EProcessBackgroundType | undefined>) => ({
      ...state,
      tempBackgroundType: payload,
    }),
    setCurrentBackgroundColor: (state, { payload }: PayloadAction<string[]>) => ({
      ...state,
      currentBackgroundColors: payload.length ? payload : undefined,
    }),
    setTempBackgroundColor: (state, { payload }: PayloadAction<string[]>) => ({
      ...state,
      tempBackgroundColors: payload.length ? payload : undefined,
    }),
    setBackgroundStorageType: (state, { payload }: PayloadAction<EProcessBackgroundStorageType | undefined>) => ({
      ...state,
      backgroundStorageType: payload,
    }),
    setTempBackgroundStorageType: (state, { payload }: PayloadAction<EProcessBackgroundStorageType | undefined>) => ({
      ...state,
      tempBackgroundStorageType: payload ?? undefined,
    }),
    setBackgroundDisplayStatus: (state, { payload }: PayloadAction<boolean | undefined>) => ({
      ...state,
      backgroundDisplayStatus: payload,
    }),
    setLogoDisplayStatus: (state, { payload }: PayloadAction<boolean | undefined>) => ({
      ...state,
      displayLogo: payload,
    }),
    setProcessSvgColors: (state, { payload }: PayloadAction<{ [key: number]: string[] } | undefined>) => ({
      ...state,
      processSvgColors: payload ?? undefined,
    }),
    setProcessBackgroundUrl: (state, { payload }: PayloadAction<string | undefined>) => ({
      ...state,
      url: payload ?? undefined,
    }),
    setDealThemePalette: (state, { payload }: PayloadAction<ThemePalette | undefined>) => ({
      ...state,
      dealThemePalette: payload,
    }),
    saveDealThemePalette: (state, { payload }: PayloadAction<ThemePalette | undefined>) => ({
      ...state,
      dealThemePalette: payload,
      initialDealThemePalette: payload,
    }),
    resetDealThemePalette: (state) => ({
      ...state,
      dealThemePalette: state.initialDealThemePalette,
    }),
    resetBackgrounds: () => initialState,
  },
  extraReducers: (builder) => builder
    .addCase(getProcess.fulfilled, (state, action) => {
      const {
        process,
      } = action.payload;
      state.dealThemePalette = process?.styles?.theme;
      state.initialDealThemePalette = process?.styles?.theme;
    }),
});

export const {
  setCustomizationData,
  setBackground,
  setTempBackground,
  resetBackgrounds,
  setBackgroundType,
  setTempBackgroundType,
  setCurrentBackgroundColor,
  setTempBackgroundColor,
  setBackgroundStorageType,
  setBackgroundDisplayStatus,
  setProcessSvgColors,
  setProcessBackgroundUrl,
  setTempBackgroundStorageType,
  setDealThemePalette,
  resetDealThemePalette,
  setBackgroundColor,
  saveDealThemePalette,
  setLogoDisplayStatus,
} = dealCustomizationSlice.actions;

export const selectBackground = (state: RootState): number => state.dealCustomization.background;
export const selectTempBackground = (state: RootState): number => state.dealCustomization.tempBackground;
// eslint-disable-next-line max-len
export const selectBackgroundType = (state: RootState): EProcessBackgroundType | undefined => state.dealCustomization.backgroundType ?? state.process?.process?.styles?.type;
// eslint-disable-next-line max-len
export const selectTempBackgroundType = (state: RootState): EProcessBackgroundType | undefined => state.dealCustomization.tempBackgroundType;
// eslint-disable-next-line max-len
export const selectBackgroundStorageType = (state: RootState): EProcessBackgroundStorageType => state.dealCustomization?.backgroundStorageType ?? state.process?.process?.styles?.backgroundStorage;
// eslint-disable-next-line max-len
export const selectTempBackgroundStorageType = (state: RootState): EProcessBackgroundStorageType => state.dealCustomization?.tempBackgroundStorageType;
// eslint-disable-next-line max-len
export const selectProcessBackgroundDisplayStatus = (state: RootState): boolean => state.dealCustomization?.backgroundDisplayStatus ?? state?.process?.process?.styles?.displayBackground;
// eslint-disable-next-line max-len
export const selectLogoDisplayStatus = (state: RootState): boolean => state.dealCustomization?.displayLogo ?? state?.process?.process?.styles?.displayLogo ?? true;
// eslint-disable-next-line max-len
export const selectProcessSvgColors = (state: RootState): { [key: number]: string[] } => state.dealCustomization?.processSvgColors ?? state?.process?.process?.styles?.svgColors;
// eslint-disable-next-line max-len
export const selectProcessBackgroundColor = (state: RootState): string => {
  if (state.dealCustomization?.backgroundColor) {
    return state.dealCustomization?.backgroundColor;
  }
  if (state?.process?.process?.styles?.backgroundColor) {
    return state.process.process.styles.backgroundColor;
  }
  return '#FFFFFF';
};
export const selectCurrentBackgroundColors = (state: RootState): string[] | undefined => state.dealCustomization.currentBackgroundColors;
export const selectTempBackgroundColors = (state: RootState): string[] | undefined => state.dealCustomization.tempBackgroundColors;

// eslint-disable-next-line max-len
export const selectProcessBackgroundUrl = (state: RootState): string => state.dealCustomization?.url ?? state?.process?.process?.styles?.url ?? '';
export const selectDealThemePaletteCommon = (state: RootState): string | undefined => state.dealCustomization?.dealThemePalette?.common;
export const selectDealThemePaletteSidebar = (state: RootState): string | undefined => state.dealCustomization?.dealThemePalette?.sidebar;
// eslint-disable-next-line max-len
export const selectInitialDealPaletteCommon = (state: RootState): string | undefined => state.dealCustomization?.initialDealThemePalette?.common;
// eslint-disable-next-line max-len
export const selectInitialDealPaletteSidebar = (state: RootState): string | undefined => state.dealCustomization?.initialDealThemePalette?.sidebar;

export const selectSvgBackroundColorsById = (
  state: RootState,
  backgroundId: number,
): string[] | undefined => {
  if (state?.dealCustomization?.processSvgColors?.[backgroundId]) {
    return state.dealCustomization.processSvgColors[backgroundId];
  }
  if (state?.process?.process?.styles?.svgColors?.[backgroundId]) {
    return state.process.process.styles.svgColors[backgroundId];
  }
  return findSvgBackgroundItem(backgroundId)?.defaultColors;
};
