import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { Controller, FormProvider } from 'react-hook-form';
import { Dialog } from '@mui/material';
import { useState } from 'react';
import { useOrganization } from '../../../hooks/useOrganization';
import TrashIcon from '../../../icons/TrashIcon';
import { AppButton } from '../../../shared/AppButton/AppButton';
import { AppIconButton } from '../../../shared/AppButton/AppIconButton';
import { AppInput } from '../../../shared/AppInput/AppInput';
import {
  SettingsFormWrapper,
  SettingsCardsDescription,
  SettingsCardsHeader,
  SettingsCardsHeadingWrapper,
  SettingsTabContentWrapper,
} from './Styles/GeneralTabStyles';
import { UploadIcon20 } from '../../../icons/UploadIcon';
import {
  AvatarButtonGroup,
  AvatarCaption,
  AvatarControlsWrapper,
  AvatarFieldWrapper,
  HiddenInput,
} from './Styles/ProfileTabStyles';
import { useOrganizationSettingsNew } from '../../../hooks/useOrganizationSettingsNew';
import { CurrencySelectorFullName } from '../../../pages/Settings/Company/CurrencySelectorFullName';
import {
  DashedBorderBox,
  OrganizationIdCode,
  OrganizationIdTitle,
  OrganizationIdWrapper,
  OrganizationIdCodeWrapper,
  CompanyLogoPlaceholderFrame,
  CompanyLogo,
} from './Styles/OrganizationTabStyles';
import { CopyIcon16 } from '../../../icons/CopyIcon';
import { avatarHandler } from './Helper/AvatarHandler';
import { CompanyLogoPlaceholder40 } from '../../../icons/CompanyLogoPlaceholder';
import ImageCrop from '../../../features/ImageCrop/ImageCrop';

const OrganizationTab = () => {
  const { organization } = useOrganization();
  const {
    disabled,
    saving,
    organizationFormMethods,
    submitOrganizationForm,
    handleCopyId,
    teamManager,
  } = useOrganizationSettingsNew();
  const { t } = useTranslation();
  const [tempFile, setTempFile] = useState<File | undefined>(undefined);

  const {
    handleSubmit,
    control,
    setValue,
    trigger,
  } = organizationFormMethods;

  const setCroppedfile = async (file: File): Promise<void> => {
    if (!file) {
      setTempFile(undefined);
      return;
    }
    setValue('logo', file, { shouldDirty: true });
    trigger('logo');
    setTempFile(undefined);
  };

  return (
    <SettingsTabContentWrapper
      className={classNames({ 'settings-content-wrapper-mobile': isMobile })}
    >
      <SettingsCardsHeadingWrapper>
        <SettingsCardsHeader>
          {t('Settings_Organization')}
        </SettingsCardsHeader>
        <SettingsCardsDescription>
          Setup your company
        </SettingsCardsDescription>
      </SettingsCardsHeadingWrapper>
      <SettingsFormWrapper
        className={classNames('settings-organization-wrapper', { 'settings-profile-wrapper-mobile': isMobile })}
        component="form"
        onSubmit={handleSubmit(submitOrganizationForm)}
      >
        <Controller
          name="logo"
          control={control}
          render={({ field: { value }, fieldState: { error } }) => (
            <AvatarFieldWrapper>
              {value
                ? (
                  <CompanyLogo
                    variant="rounded"
                    className="organization-logo"
                    src={avatarHandler(value) as string}
                  />
                )
                : (
                  <CompanyLogoPlaceholderFrame>
                    <CompanyLogoPlaceholder40 />
                  </CompanyLogoPlaceholderFrame>
                )}
              {teamManager && (
              <AvatarControlsWrapper>
                <AvatarButtonGroup>
                  <AppButton
                    startIcon={<UploadIcon20 />}
                    variant="secondary"
                    component="label"
                    size="m-auto"
                    role={undefined}
                    tabIndex={-1}
                  >
                    Logo
                    <HiddenInput
                      type="file"
                      accept="image/*"
                      onChange={(event) => {
                        if (event && event.target?.files[0]) {
                          setTempFile(event.target.files[0]);
                        }
                      }}
                    />
                  </AppButton>
                  <AppIconButton
                    disableRipple
                    value={undefined}
                    icon={<TrashIcon />}
                    variant="danger-transparent"
                    onClick={() => {
                      setValue('logo', null, { shouldDirty: true });
                      trigger('logo');
                    }}
                  />
                </AvatarButtonGroup>
                <AvatarCaption
                  className={classNames({ 'settings-avatar-caption-error': error })}
                >
                  {error?.message || 'JPG, PNG, WEBP, SVG no more than 5 Mb'}
                </AvatarCaption>
              </AvatarControlsWrapper>
              )}
            </AvatarFieldWrapper>
          )}
        />
        <Controller
          name="teamName"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <AppInput
              className={classNames('settings-menu-input', 'settings-menu-input-full')}
              disabled={!teamManager}
              fullWidth
              size="l"
              label="Team name"
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
            />
          )}
        />
        <FormProvider {...organizationFormMethods}>
          <CurrencySelectorFullName />
        </FormProvider>
        <OrganizationIdWrapper>
          <OrganizationIdTitle>
            Organization ID
          </OrganizationIdTitle>
          <DashedBorderBox />
          <OrganizationIdCodeWrapper
            onClick={handleCopyId}
          >
            <OrganizationIdCode>
              {organization?.orgSupportId}
            </OrganizationIdCode>
            <CopyIcon16 />
          </OrganizationIdCodeWrapper>
        </OrganizationIdWrapper>
        {teamManager && (
          <AppButton
            type="submit"
            loading={saving}
            disabled={disabled}
            variant="primary"
            size="l-grid-span-full"
          >
            Save
          </AppButton>
        )}
      </SettingsFormWrapper>
      <Dialog
        open={!!tempFile}
        onClose={() => setTempFile(undefined)}
        keepMounted={false}
        PaperProps={{
          sx: {
            width: 'auto',
            maxHeight: '80vh',
            height: 'auto',
            padding: '24px',
          },
        }}
      >
        <ImageCrop
          image={tempFile}
          onCropAccept={setCroppedfile}
          onCancel={() => setTempFile(undefined)}
          customAspect={1}
        />
      </Dialog>
    </SettingsTabContentWrapper>
  );
};
export default OrganizationTab;
