import {
  Box, Typography,
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { AdminPageContentType } from '../../../../shared/admin/Types';
import AppTabsWithIndicator from '../../../shared/AppTabs/AppTabsWithIndicator';
import { useAppDispatch } from '../../../hooks/stateHooks';
import { setAdminGridToRender } from '../state/adminSlice';

interface Props {
  gridToRender: AdminPageContentType | string;
}

const AdminTabsHeader = ({
  gridToRender,
}: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleChange = (event: React.SyntheticEvent, newValue: AdminPageContentType) => {
    dispatch(setAdminGridToRender(newValue));
  };

  const options = [
    { title: 'Active', value: AdminPageContentType.Active },
    { title: 'Deleted', value: AdminPageContentType.Deleted },
    { title: 'Rooms', value: AdminPageContentType.Rooms },
  ];

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flex: 0,
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          height: '56px',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          paddingX: isMobile ? '16px' : '24px',
          background: 'white',
          borderBottom: '1px solid #EEF0FA',
        }}
      >
        <Typography
          sx={{
            fontSize: '20px',
            lineHeight: '26px',
            fontWeight: 600,
            color: (theme) => theme.palette.primary.main,
          }}
        >
          {t('Overview_Organizations')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            height: '100%',
            alignItems: 'flex-end',
            '& button': {
              minWidth: '70px',
            },
          }}
        >
          <AppTabsWithIndicator
            className="gridToRender"
            aria-label="Deal menu"
            tabValue={gridToRender}
            setTabValue={handleChange}
            items={options as any[]}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AdminTabsHeader;
