import { useEffect, useState } from 'react';
import {
  Box, List, ListItemButton, ListItemIcon, ListItemText,
} from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../hooks/stateHooks';
import { allProductsSelector, getProductItems, selectLoadingStatus } from '../../../pages/library/lib/librarySlice';
import { EStateStatus } from '../../../core/commonTypes';
import { ProductWithOwnerDTO } from '../../../../shared/library/LibraryDTO';
import { AppInput } from '../../../shared/AppInput/AppInput';
import { PricingItem } from '../../../../shared/pricing-table/PricingTableTypes';
import BagIcon from '../../../icons/bag20px.svg';
import { setProduct, EProductTabState, setTabState } from '../../PricingTable/lib/pricingTableSlice';

const ProductListTab = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const status = useAppSelector(selectLoadingStatus);
  const products: ProductWithOwnerDTO[] = useAppSelector(allProductsSelector);
  const [searchFilter, setSearchFilter] = useState('');
  const [rows, setRows] = useState<PricingItem[]>([]);

  useEffect(() => {
    if (status !== EStateStatus.LOADING) {
      dispatch(getProductItems({}));
    }
  }, []);

  useEffect(() => {
    let tempRows: PricingItem[] = [];
    if (products.length) {
      tempRows = products.map((x: ProductWithOwnerDTO) => ({
        isOptional: x.isOptional,
        name: x.name,
        shortDescription: x.shortDescription,
        discount: x.discount,
        price: x.price,
        units: x.units,
        tax: x.tax,
      } as PricingItem));
    }

    if (searchFilter) {
      tempRows = tempRows.filter((x: Partial<PricingItem>) => (!!x.name && x.name.indexOf(searchFilter) >= 0));
    }

    setRows(tempRows);
  }, [searchFilter, products]);

  const setPricingItem = (item: PricingItem) => {
    dispatch(setProduct({
      product: item,
      tabState: EProductTabState.ADD_PRODUCT_FROM_LIST,
    }));
    dispatch(setTabState(EProductTabState.ADD_PRODUCT_FROM_LIST));
  };

  return (
    <>
      <Box
        sx={{
          width: '100%',
          padding: '5px 22px',
          marginTop: '20px',
        }}
      >
        <AppInput
          placeholder="Search"
          defaultValue={searchFilter}
          type="text"
          value={searchFilter}
          onChange={(event) => {
            setSearchFilter(event.target.value);
          }}
          sx={{
            width: '100%',
          }}
        />
      </Box>
      <Box
        sx={{
          padding: '0 5px',
        }}
      >
        <List>
          {
            rows.map((x: PricingItem) => (
              <ListItemButton
                onClick={() => setPricingItem(x)}
                key={x.name}
              >
                <ListItemIcon>
                  <BagIcon />
                </ListItemIcon>
                <ListItemText primary={x.name} />
              </ListItemButton>
            ))
          }
        </List>
      </Box>
    </>
  );
};

export default ProductListTab;
