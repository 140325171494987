import { isMobile } from 'react-device-detect';
import { MouseEvent } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import { SettingsIcon } from '../../icons/SettingsIcon';
import { selectLayouEditingStatus } from '../../routes-old/process/state/processSlice';
import { AppIconButton } from '../../shared/AppButton/AppIconButton';
import {
  ESidebarTabs,
  selectDealViewMode,
  selectSidebarTab,
  setSidebarTab,
  setStagesDrawerOpen,
} from '../Layout/lib/fieldsLayoutSlice';
import { BroomIcon20 } from '../../icons/BroomIcon';
import { AppButton } from '../../shared/AppButton/AppButton';
import { useMobileBottomDrawer } from '../../hooks/useMobileBottomDrawer';

interface Props {
  roomGeneralMobileMenu?: boolean
}

const DealCustomizationButton = ({ roomGeneralMobileMenu }: Props): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const isLayoutEdit = useAppSelector(selectLayouEditingStatus);
  const tab = useAppSelector(selectSidebarTab);
  const { closeDrawer } = useMobileBottomDrawer();
  const viewMode = useAppSelector(selectDealViewMode);

  if (
    (!isLayoutEdit && !isMobile)
    || (tab === ESidebarTabs.CUSTOMIZATION && isMobile)
  ) return null;

  const onClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    dispatch(setSidebarTab(ESidebarTabs.CUSTOMIZATION));
    if (isMobile) {
      dispatch(setStagesDrawerOpen(true));
    }
    if (roomGeneralMobileMenu) {
      closeDrawer();
    }
  };

  if (roomGeneralMobileMenu) {
    return (
      <AppButton
        fullWidth
        disableRipple
        variant="transparent-grey-icon"
        size="l-flex-start"
        onClick={onClick}
        startIcon={<SettingsIcon />}
      >
        Customization
      </AppButton>
    );
  }

  if (!isMobile) {
    return (
      <AppIconButton
        disableRipple
        variant="transparent-white"
        size="l"
        onClick={onClick}
        icon={<BroomIcon20 />}
        hidden={viewMode === 'internal'}
      />
    );
  }

  return (
    <AppIconButton
      variant="secondary"
      onClick={onClick}
      size="l"
      sx={{
        zIndex: 10,
        position: 'absolute',
        right: isMobile ? '32px' : '122px',
        top: '32px',
      }}
      icon={<SettingsIcon />}
    />
  );
};

export default DealCustomizationButton;
