import { SvgIcon } from '@mui/material';
import { SvgBackgroundProps } from '../../features/DealCustomizationMenu/helpers/DealCustomizationMenuHelpers';

export const SvgBackground1 = (props: SvgBackgroundProps = {}): JSX.Element => (
  <SvgIcon
    width="1276"
    height="400"
    viewBox="0 0 1276 400"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      className="SvgBakcgroundLayer-1"
      d="M0 0H1276V320C1276 364.183
      1240.18 400 1196 400H80C35.8172
      400 0 364.183 0 320V0Z"
      fill={props.colors ? props.colors[0] : '#0073F5'}
    />
  </SvgIcon>
);

export const SvgBackground2 = (props: SvgBackgroundProps = {}): JSX.Element => (
  <SvgIcon
    width="1276"
    height="400"
    viewBox="0 0 1276 400"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      className="SvgBakcgroundLayer-1"
      d="M-0.00976671 390.245L-0.00981012 0.000179216L1275.99
      9.15527e-05L1275.99 296.795C1213.72 248.666 1067.75 180.483
      777.329 245.858C439.955 321.26 212.827 433.16 0.500609
      390.646L-0.00976671 390.245Z
      M1232.81 302.028C1232.81 302.028
      1081.22 188.517 603.997
      316.869C603.997 316.869 1064.38
      150.012 1232.81 302.028Z"
      fill={props.colors ? props.colors[0] : '#1F88FF'}
    />
    <path
      d="M776.329 192.528C1067.26 127.55 1213.23 195.737
1274.99 243.468L1274.99 210.822C1212.72 163.091 1066.75
92.5855 776.329 157.564C438.955 232.971 211.827 369.535
-0.49988 326.618L-0.499879 336.523C212.337 379.441
438.955 267.133 776.329 191.726L776.329 192.528Z"
      fill="#EFF2FA"
    />
  </SvgIcon>
);

export const SvgBackground3 = (props: SvgBackgroundProps = {}): JSX.Element => (
  <SvgIcon
    width="1276"
    height="450"
    viewBox="0 0 1276 450"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      className="SvgBakcgroundLayer-1"
      d="M688.598 279.324C518.22 337.231
      365.471 453.083 180.005 449.874C88.9334
      448.301 31.4999 402.51 -0.000114689
      365.51C-0.000114689 239.51 0.499912
      63.5098 0.499912 63.5098L1277.22 64.5098L1276
      405.01C1008.5 202.51 838.394 228.421 682.725
      281.329L688.598 279.324Z"
      fill={props.colors ? props.colors[0] : '#5CA8FF'}
    />
    <path
      d="M678.069 183.705C504.505 231.216 336.072
      345.599 151.14 331.193C71.0032 324.951 1.89039
      278.507 1.89039 278.507L0.00344946 0.551616L1276.06
      0.551605C1276.06 102.768 1276.51 360.824 1276.51
      360.824C1143.01 245.323 876 132.824 678.069
      183.71L678.069 183.705Z"
      fill="white"
    />
    <path
      className="SvgBakcgroundLayer-2"
      d="M674.998 164.5C500.421 208.142
    384.499 311.009 150.261 315.433C61.9989
    310.509 1.49195 258.901 1.49195 258.901L1.49209
    2.01036L1276.03 0.509857L1276.99 286.999C1180
    189 889.978 110.758 674.998 164.5Z"
      fill={props.colors ? props.colors[1] : '#1F88FF'}
    />
    <path
      className="SvgBakcgroundLayer-3"
      d="M667.763 143.356C491.663 180.394 324.197
    292.955 140.458 267.506C90.1455 260.539 40.1953
    240.894 0.00488922 210.203L0.00490754
    0.712626L1276.04 0.518097L1276 283.507C1120
    156.007 828.661 109.519 667.767
    143.356L667.763 143.356Z"
      fill={props.colors ? props.colors[2] : '#0073F5'}
    />
  </SvgIcon>
);

export const SvgBackground4 = (props: SvgBackgroundProps = {}): JSX.Element => (
  <SvgIcon
    width="1276"
    height="400"
    viewBox="0 0 1276 400"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      className="SvgBakcgroundLayer-1"
      d="M0.702243 150.994C771.758 429.408 1230.26
145.991 1276 115.5L1276 173.484C1124.57 261.756
1003.24 315.165 801.261 358.923C652.128 391.232
526.06 397.532 391.761 400.411C321.072 401.926
81.7607 383.916 0.702266 363.923L0.702243 150.994Z"
      fill={props.colors ? props.colors[0] : '#5CA8FF'}
    />
    <path
      className="SvgBakcgroundLayer-2"
      d="M0.241556 0.500011L1276.41 0.499924L1276.41
53.2306C722.761 352.862 102.26 71.3578
0.241558 19.3576L0.241556 0.500011Z"
      fill={props.colors ? props.colors[1] : '#0073F5'}
    />
    <path
      d="M931.761 343.362C931.761 343.362 1095.26
305.862 1276.26 189.862V215.362C1229.26 240.362
1110.26 304.362 931.761 343.362Z"
      fill="#A3B0D1"
    />
    <path
      d="M1.24424 45.8725C656.26 342.858 1068.26 215.362
1276.24 95.3934L1276.24 110.36C1009.26 263.362
612.26 342.357 1.24425 123.369L1.24424 45.8725Z"
      fill="#A3B0D1"
    />
  </SvgIcon>
);

export const SvgBackground5 = (props: SvgBackgroundProps = {}): JSX.Element => (
  <SvgIcon
    width="1276"
    height="400"
    viewBox="0 0 1276 400"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      className="SvgBakcgroundLayer-1"
      d="M1276 400.001C1276 400.001 849 266.32
 -7.81995e-07 375.459L0 165.014C258.103 165.673
 1276 165.014 1276 165.014L1276 400.001Z"
      fill={props.colors ? props.colors[0] : '#5CA8FF'}
    />
    <path
      className="SvgBakcgroundLayer-2"
      d="M1276 335.249C1276 335.249 849 226.11
-1.24575e-06 335.249L0 0C258.103 0.659244 1276
0.000188274 1276 0.000188274L1276 335.249Z"
      fill={props.colors ? props.colors[1] : '#0073F5'}
    />
  </SvgIcon>
);

export const SvgBackground6 = (props: SvgBackgroundProps = {}): JSX.Element => (
  <SvgIcon
    width="1276"
    height="400"
    viewBox="0 0 1276 400"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      className="SvgBakcgroundLayer-1"
      d="M243.595 0.374014C579.996 7.37232 1091
34.6417 1276 290.308L1276 0.359748C787.853
0.359756 237.796 0.253371 243.595 0.374014Z"
      fill={props.colors ? props.colors[0] : '#8FC3FF'}
    />
    <path
      className="SvgBakcgroundLayer-2"
      d="M870.005 0.335702C694.538 34.8777 192.797
35.2396 0.181245 402.003L-7.4024e-09
0.327699C-7.4024e-09 0.327699 875.805
-0.80608 870.005 0.335702Z"
      fill={props.colors ? props.colors[1] : '#1F88FF'}
    />
  </SvgIcon>
);

export const SvgBackground7 = (props: SvgBackgroundProps = {}): JSX.Element => (
  <SvgIcon
    width="1276"
    height="400"
    viewBox="0 0 1276 400"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      className="SvgBakcgroundLayer-1"
      d="M355.089 335.427C539.134 335.427 539.134
     400 723.152 400C907.169 400 907.141 335.427 1091.16
     335.427C1183.72 335.427 1229.72 351.748 1276
     367.986L1276 0L-0.00167649 0.469851L-0.00170897
 399.864C171.127 396.894 175.496 335.4
 355.089 335.4L355.089 335.427Z"
      fill={props.colors ? props.colors[0] : '#8FC3FF'}
    />
    <path
      className="SvgBakcgroundLayer-2"
      d="M171.484 326.922C355.529 326.922 355.529
262.349 539.574 262.349C723.619 262.349 723.619 326.922
907.636 326.922C1091.65 326.922 1091.63 262.349 1275.64
262.349C1275.75 262.349 1275.86 262.349 1276 262.349L1276
0.471283L-0.00168208 0.471161L-0.00170668 298.995C42.1827
313.571 87.8297 326.922 171.539 326.922L171.484 326.922Z"
      fill={props.colors ? props.colors[1] : '#5CA8FF'}
    />
    <path
      className="SvgBakcgroundLayer-3"
      d="M348.411 173.242C532.456 173.242 532.456 237.814
716.473 237.814C900.491 237.814 900.463 173.242 1084.48
173.242C1181.46 173.242 1227.36 191.17 1276 208.144L1276
0.471649L-0.00193357 0.471528L-0.0019531 237.569C164.613
233.128 171.182 173.269 348.438 173.269L348.411 173.242Z"
      fill={props.colors ? props.colors[2] : '#1F88FF'}
    />
    <path
      className="SvgBakcgroundLayer-4"
      d="M118.142 165.205C302.187 165.205 302.187 100.632
 486.232 100.632C670.277 100.632 670.277 165.205 854.294
 165.205C1038.31 165.205 1038.28 100.632 1222.3 100.632C1242.2
 100.632 1259.92 101.395 1276 102.758L1276 0.471603L-0.00316025
 0.471482L-0.00195213 153.135C30.5028 160.273 67.3008 165.205
 118.169 165.205L118.142 165.205Z"
      fill={props.colors ? props.colors[3] : '#0073F5'}
    />
  </SvgIcon>
);

export const SvgBackground8 = (props: SvgBackgroundProps = {}): JSX.Element => (
  <SvgIcon
    width="1276"
    height="423"
    viewBox="0 0 1276 423"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_dd_16703_178021)">
      <path
        className="SvgBakcgroundLayer-1"
        d="M1249 395.32C1241.6 385.577 1232.38 378.009
      1220.25 368.115C1169.07 326.375 1085.25 304.607
      1002.02 297.217C918.785 289.826 833.938 292.448
      750.205 297.29C419.611 316.381 315.392 189.678
      234.267 146.985C169.83 113.067 0.000459048 112.841
      0.000459048 112.841L3.39925e-05 2.5007e-05L1276
      1.41859e-05L1276 395.32C1276 395.32 1257 405.851
      1249 395.32Z"
        fill={props.colors ? props.colors[0] : '#8FC3FF'}
      />
    </g>
    <g filter="url(#filter1_dd_16703_178021)">
      <path
        className="SvgBakcgroundLayer-2"
        d="M16 -2.11563e-05C16 -2.11563e-05 38.8724
      8.40307 53.054 14.4438C108.474 38.0499 141.21
      63.0105 181.784 89.7313C222.359 116.452 268.123
      142.22 324.734 152.502C442.148 173.853 564.665
      123.233 686.389 130.546C774.047 135.824 849.597
      170.352 916.234 206.328C982.871 242.323 1047.98
      281.964 1130.39 301.409C1177.65 312.566 1276 315.357
      1276 315.357C1276 315.357 1276 358.715 1276 3.95857e-07L16
      -2.11563e-05Z"
        fill={props.colors ? props.colors[1] : '#5CA8FF'}
      />
    </g>
    <g filter="url(#filter2_dd_16703_178021)">
      <path
        className="SvgBakcgroundLayer-3"
        d="M1276 216.905C1276 216.905 1247.2 212.978
      1232.48 211.992C1153.63 206.712 1074.86 218.684 996.359
      225.632C917.858 232.58 833.316 233.845 764.287 209.48C708.6
      189.827 670.126 156.094 628.105 125.955C586.083 95.8151
      533.856 66.9221 470.193 63.2371C406.12 59.5338 334.304 81.2586
      281.081 58.6355C242.079 42.0624 212 -2.58582e-05 212
      -2.58582e-05L1276 9.74991e-07L1276 216.905Z"
        fill={props.colors ? props.colors[2] : '#1F88FF'}
      />
    </g>
    <g filter="url(#filter3_dd_16703_178021)">
      <path
        className="SvgBakcgroundLayer-4"
        d="M334 -9.46977e-05C334 -9.46977e-05 391.701 23.279
      426.011 26.9061C495.37 34.2347 564.789 12.7789 635.087
      14.314C703.538 15.8127 766.006 38.9316 819.206 65.8155C872.406
      92.6993 920.035 124.079 977.781 147.033C1035.53 169.988 1107.58
      183.932 1172.9 171.121C1218.56 162.166 1276 139.104 1276
      139.104L1276 0.00750462L334 -9.46977e-05Z"
        fill={props.colors ? props.colors[3] : '#0073F5'}
      />
    </g>
    <defs>
      <filter
        id="filter0_dd_16703_178021"
        x="-26"
        y="-29"
        width="1328"
        height="452"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="-3" />
        <feGaussianBlur stdDeviation="13" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.00204861 0 0 0 0 0.292024 0 0 0 0 0.489618 0 0 0 0.44 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_16703_178021" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_16703_178021" result="effect2_dropShadow_16703_178021" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_16703_178021" result="shape" />
      </filter>
      <filter
        id="filter1_dd_16703_178021"
        x="-10"
        y="-29"
        width="1312"
        height="369"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="-3" />
        <feGaussianBlur stdDeviation="13" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.00204861 0 0 0 0 0.292024 0 0 0 0 0.489618 0 0 0 0.44 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_16703_178021" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_16703_178021" result="effect2_dropShadow_16703_178021" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_16703_178021" result="shape" />
      </filter>
      <filter
        id="filter2_dd_16703_178021"
        x="186"
        y="-29"
        width="1116"
        height="282"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="-3" />
        <feGaussianBlur stdDeviation="13" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.00204861 0 0 0 0 0.292024 0 0 0 0 0.489618 0 0 0 0.44 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_16703_178021" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_16703_178021" result="effect2_dropShadow_16703_178021" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_16703_178021" result="shape" />
      </filter>
      <filter
        id="filter3_dd_16703_178021"
        x="308"
        y="-29"
        width="994"
        height="228"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="-3" />
        <feGaussianBlur stdDeviation="13" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.00204861 0 0 0 0 0.292024 0 0 0 0 0.489618 0 0 0 0.44 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_16703_178021" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_16703_178021" result="effect2_dropShadow_16703_178021" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_16703_178021" result="shape" />
      </filter>
    </defs>
  </SvgIcon>
);

export const SvgBackground9 = (props: SvgBackgroundProps = {}): JSX.Element => (
  <SvgIcon
    width="1276"
    height="400"
    viewBox="0 0 1276 400"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      className="SvgBakcgroundLayer-1"
      d="M1276 315.599C1276 315.599 1169.5 504.594
    2.93259e-06 316.955L0 0C258.103 0.6976 1276
    0.000214887 1276 0.000214887L1276 315.599Z"
      fill={props.colors ? props.colors[0] : '#5CA8FF'}
    />
    <path
      className="SvgBakcgroundLayer-2"
      d="M1276 258.626C1276 258.626 1191
    476.111 2.86565e-06 309.721L0 0C257.968
    0.659911 1276 1.06761e-05 1276
    1.06761e-05L1276 258.626Z"
      fill={props.colors ? props.colors[1] : '#1F88FF'}
    />
    <path
      className="SvgBakcgroundLayer-3"
      d="M1276 198.493C1276 198.493 1161
    454.86 2.77362e-06 299.774L0 0C258.103
    0.570814 1276 0.000177772 1276
    0.000177772L1276 198.493Z"
      fill={props.colors ? props.colors[2] : '#0073F5'}
    />
  </SvgIcon>
);

export const SvgBackground10 = (props: SvgBackgroundProps = {}): JSX.Element => (
  <SvgIcon
    width="1276"
    height="400"
    viewBox="0 0 1276 400"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_16404_221623)">
      <path
        className="SvgBakcgroundLayer-1"
        d="M0 0H1276V423C1276 466.63 1240.63
502 1197 502H79C35.3695 502 0 466.63 0 423V0Z"
        fill={props.colors ? props.colors[0] : '#1F88FF'}
      />
      <path
        className="SvgBakcgroundLayer-2"
        d="M0 0H1276L1233.6 56.6343C1132.33 191.896
968.304 265.148 799.991 250.274L435.788 218.089C372.348
212.483 308.509 219.825 247.417 237.819C221.265 245.521
193.495 253.401 169.838 259.451C104.835 276.073 0 297 0 297L0 0Z"
        fill={props.colors ? props.colors[1] : '#0073F5'}
      />
    </g>
    <defs>
      <clipPath id="clip0_16404_221623">
        <path
          d="M0 0H1276V321C1276 364.63 1240.63 400 1197
400H79C35.3695 400 0 364.63 0 321V0Z"
          fill="white"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);
