import { AccessLinkDTO } from '../AccessLinkDTO';
import { EMediaFieldStorageType, FieldBase } from '../Field/Field';
import { FileDTO } from '../FileDTO';
import { DealNotification, DealNotificationPayload } from '../notifications/Notifications';
import { EUserDealPermissions, TAllRoleNames, TPermissions } from '../permissions';
import { ProcessTaskBase } from '../process-task-base';
import { MilestoneDTO, TaskDTO } from '../TaskDTO';
import { ProcessUserDTO, UserDTO } from '../UserDTO';

export interface LastActiveUser {
  id: string;
  name: string;
  lastActiveAt: number;
}

export enum ELogoType {
  BuyerLogo = 'BuyerLogo',
  SellerLogo = 'SellerLogo',
}

export enum EDealStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  ON_HOLD = 'ON_HOLD',
  COMPLETED = 'COMPLETED',
  NOTHING_IN_PROGRESS = 'NOTHING_IN_PROGRESS',
  OVERDUE = 'OVERDUE',
  DUE_TODAY = 'DUE_TODAY',
}

export enum EProcessType {
  DEAL = 'DEAL',
  TEMPLATE = 'TEMPLATE',
}

export enum ECreateTemplateRequestType {
  BLANK = 'BLANK',
  CLONE = 'CLONE',
  DEAL = 'DEAL',
}

export enum EProcessBackgroundStorageType {
  // LOCAL refers to backgrounds from DealCustomizationMenuHelpers
  LOCAL = 'LOCAL',
  AWS = 'AWS',
  EXTERNAL = 'EXTERNAL',
}

export enum EProcessBackgroundType {
  SVG = 'SVG',
  IMG = 'IMG',
  COLOR = 'COLOR',
}

export type ThemePalette = {
  // common?: string,
  sidebar?: string,
}

export type ProcessSvgBackgroundColorUpdateReq = {
  processId: string,
  backgroundId: number,
  newColors: string[]
}

export type ProcessStyles = {
  backgroundStorage: EProcessBackgroundStorageType,
  type: EProcessBackgroundType,
  url?: string,
  localId?: number,
  displayBackground?: boolean,
  backgroundColor?: string,
  svgColors?: {
    [key: number]: string[] | undefined,
  }
  theme: ThemePalette
  displayLogo?: boolean,
}

export type ProcessLinkPreview = {
  title?: string,
  ogImage?: string,
  backgroundColor?: string,
  showLogo?: boolean,
  textColor?: string,
}

export enum ProcessStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  ON_HOLD = 'ON_HOLD',
  CLOSED_WON = 'CLOSED_WON',
  CLOSED_LOST = 'CLOSED_LOST',

}

export class ProcessDTO extends ProcessTaskBase {
  templateId: string;
  summaryTitle?: string;
  type: EProcessType = EProcessType.DEAL;
  value?: number;
  currency?: string;
  buyerLogoSrc?: string;
  sellerLogoSrc?: string;
  wideLogo?: string;
  client?: string;
  processStats?: ProcessStatsDTO;
  notifications?: DealNotification<DealNotificationPayload>[];
  chatUrl?: string;
  lastInteraction: Date;
  colorId?: number;
  templateTitle?: string
  fields: ProcessField[];
  updatedAt?: number;
  sellerName?: string;
  styles?: ProcessStyles;
  lastActive: number;
  status: ProcessStatus;
  ogImage: string;
  linkPreview?: ProcessLinkPreview;
  clientName?: string;
}

export interface ProcessField extends Pick<FieldBase, 'id' | 'type'> {
  order: number;
  data?: any;
}

export class ProcessStatsDTO {
  processId: string;
  lastActiveUser?: LastActiveUser;
  status?: EDealStatus;
  tasksStatus?: EDealStatus;
  lastMilestone?: {
    id: string;
    title: string;
  };
  organizationName: string;
  tasks: StatsTask[];
  tasksStats?: AggregatedStatsTasks;
  owner?: UserDTO;
}

export interface AggregatedStatsTasks {
  overdue: StatsTask[];
  dueToday: StatsTask[];
  dueTomorrow: StatsTask[];
  notStarted: StatsTask[];
}

export type StatsTask = Pick<TaskDTO, 'id'
  | 'status'
  | 'timeLineEndDate'
  | 'title'
  | 'type'
  | 'order'
>


export class ProcessMilestoneActionDTO extends ProcessDTO {
  milestones: MilestoneDTO[];
  accessLink: AccessLinkDTO;
  files: FileDTO[];
  processUsers: UserDTO[];
}

export type CreateProcessDTO = Pick<ProcessMilestoneActionDTO, 'title'
  | 'organizationId'
  | 'description'
>;

export interface CreateProcessBase {
  title: string;
  type: EProcessType;
}

export interface CreateProcessByTemplateIdRequest extends CreateProcessBase {
  templateId: string;
  value?: number;
  client?: string;
  opportunityId?: string;
  logo?: string;
  clientName?: string;
}

export interface CreateTemplateRequest extends CreateProcessByTemplateIdRequest {
  createType: ECreateTemplateRequestType;
}


export type TemplateProcessDTO = Pick<CreateProcessDTO, 'title'
  | 'description'
>;

export interface IGetProcessResponse {
  process: ProcessDTO;
  accessLink: AccessLinkDTO;
  // files: FileDTO[];
  processUsers: ProcessUserDTO[];
  tasks: TaskDTO[];
  currentUserPermissions: TPermissions<EUserDealPermissions>
  isConnectedToSalesforce?: boolean;
  owner: string;
  userProcessRoleName?: TAllRoleNames;
  processDomain?: string;
  processOrgWideLogo?: string;
  processOrgSquaredLogo?: string;
  currency?: string;
}

export interface IProcessesResponse {
  processes: ProcessDTO[];
  totalCount: number;
}

export interface IProcessWithUsersDTO extends ProcessDTO {
  users?: ProcessUserDTO[];
}

export type ProcessGetRequestQuery = {
	search?: string;
	owner?: string;
	status?: string;
	health?: string;
  company?: string;
  limit?: number;
  skip?: number;
};

export interface IProcessChangeOwnerRequest {
  processId: string;
  newOwnerId: string;
}
