import * as React from 'react';
import * as Sentry from '@sentry/react';
import TagManager from 'react-gtm-module';
import { SnackbarProvider } from 'notistack';
import { config } from '../core';
import { Analytics, EMixPanelEvents } from '../core/Analytics';
import { History, LocationContext } from '../core/history';
import Layout from './Layout';
import AppFallback from '../features/AppFallback';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { DEFAULT_LANG, LANGUAGES } from '../features/LanguageSwitcher/Languages';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";

type AppProps = {
  history: History;
};

const tagManagerArgs = {
  gtmId: config.googleTagManager.container,
};

const analytics: Analytics = Analytics.getInstance();

i18next
  .use(initReactI18next)
  .use(HttpApi)
  .use(LanguageDetector)
  .init({
    interpolation: {
      escapeValue: false,
    },
    supportedLngs: LANGUAGES.map(lng => lng.lng),
    fallbackLng: DEFAULT_LANG,
    debug: true,
    backend: {
      loadPath: `${config.app.localesBasicPath}/{{lng}}/{{ns}}.json`,
    },
    react: {
      useSuspense: true,
    },
  });

export class App extends React.Component<AppProps, any> {
  state = {
    location: this.props.history.location,
    error: undefined as Error | undefined,
  };

  dispose?: () => void;

  static getDerivedStateFromError(error: Error): { error: Error } {
    return { error };
  }

  componentDidMount(): void {
    analytics.track(EMixPanelEvents.APPLICATION_OPENED);
    TagManager.initialize(tagManagerArgs);
  }

  componentDidCatch(error: Error, errorInfo: unknown): void {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  componentWillUnmount(): void {
    if (this.dispose) this.dispose();
  }

  render(): JSX.Element {
    const { location, error } = this.state;
    const locale = navigator.language;
    const myFallback = () => <AppFallback error={error} />;

    return (
      <Sentry.ErrorBoundary fallback={myFallback}>
        <LocationContext.Provider value={location}>
          <LocalizationProvider
            dateAdapter={AdapterLuxon}
            adapterLocale={locale}
          >
            <SnackbarProvider
              maxSnack={3}
              autoHideDuration={3000}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            >
              <Layout />
            </SnackbarProvider>
          </LocalizationProvider>
        </LocationContext.Provider>
      </Sentry.ErrorBoundary>
    );
  }
}
