import {
  InputAdornment, SvgIcon,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import useDebounce from '../../hooks/useDebounce';
import { selectLibraryFilter, setLibraryFilter } from './lib/templatesSlice';
import SearchActive from '../../icons/SearchActive.svg';
import { AppInput } from '../../shared/AppInput/AppInput';

type LibraryFilterProps = {
  newPageTemplate?: boolean
};

const TemplatesFilter = ({ newPageTemplate }:LibraryFilterProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const filter = useAppSelector(selectLibraryFilter);
  const [filterValue, setFilterValue] = useState<string>('');
  const debouncedValue = useDebounce(filterValue, 250);
  const { t } = useTranslation();

  useEffect(() => {
    if (filterValue !== filter) {
      dispatch(setLibraryFilter(filterValue));
    }
  }, [debouncedValue]);

  return (
    <AppInput
      fullWidth={isMobile || newPageTemplate}
      value={filterValue}
      autoComplete="off"
      onChange={(e) => setFilterValue(e.target.value)}
      placeholder={t('Templates_Search')}
      InputProps={{
        startAdornment: (
          <InputAdornment position={newPageTemplate ? 'end' : 'start'}>
            <SvgIcon
              component={SearchActive}
              sx={{
                color: 'transparent',
              }}
            />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default TemplatesFilter;
