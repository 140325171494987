import { isMobile } from 'react-device-detect';
import { Box } from '@mui/material';
import { ReactNode } from 'react';
import classNames from 'classnames';
import AppLoadingModalWindow from '../../features/AppLoadingModalWindow/AppLoadingModalWindow';
import CommonSideMenu from '../../features/SideMenu/CommonSideMenu';
import LayoutStateControlPlugin from '../../features/Layout/LayoutStateControlPlugin';
import MobileBottomMenu from '../../features/SideMenu/MobileBottomMenu';
import { SideMenuDrawer, SideMenuDrawerWrapper } from '../../features/SideMenu/SideMenuStyles';
import useSideMenu from '../../hooks/useSideMenu';
import SideMenuLogoSection from '../../features/SideMenu/SideMenuLogoSection';
import SideMenuContent from '../../features/SideMenu/SideMenuContent';
import SideMenuFooter from '../../features/SideMenu/SideMenuFooter';
import { useAppSelector } from '../../hooks/stateHooks';
import { selectLayouEditingStatus } from '../../routes-old/process/state/processSlice';
import { selectLibraryTemplateData } from '../../pages/templates/lib/templatesSlice';
import { EProcessType } from '../../../shared/process/ProcessMilestoneActionDTO';

type Props = {
  children: ReactNode
};

const Page = ({ children }: Props) => {
  const {
    isOpened, open, close, delay,
  } = useSideMenu();
  const isLayoutEditMode = useAppSelector(selectLayouEditingStatus);
  const selectedTemplateData = useAppSelector(selectLibraryTemplateData);
  const dontShowOnEdititngTemplatesInStageAndSections = !selectedTemplateData
    || (selectedTemplateData && selectedTemplateData?.type === EProcessType.TEMPLATE)
    || !isLayoutEditMode;

  const sideMenu = () => {
    if (isMobile) {
      return (
        <>
          <Box
            sx={{
              width: '100%',
              maxHeight: '100%',
              height: '100%',
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
            }}
          >
            {children}
          </Box>
          <SideMenuDrawerWrapper>
            <SideMenuDrawer
              className={classNames({ 'side-menu-drawer-mobile': isMobile })}
              variant="persistent"
              open={isOpened}
              onMouseLeave={close}
              onMouseEnter={delay}
              onClose={close}
              onOpen={open}
              anchor="bottom"
            >
              <Box>
                <SideMenuLogoSection />
                <SideMenuContent />
              </Box>
              <SideMenuFooter />
            </SideMenuDrawer>
          </SideMenuDrawerWrapper>
          { dontShowOnEdititngTemplatesInStageAndSections && (<MobileBottomMenu />)}
          <LayoutStateControlPlugin />
        </>
      );
    } return (
      <>
        <CommonSideMenu />
        <LayoutStateControlPlugin />
        <Box
          sx={{
            width: '100%',
            maxHeight: '100%',
            height: '100%',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
          }}
        >
          {children}
        </Box>
      </>
    );
  };

  return (
    <>
      <Box sx={{
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        width: '100%',
        maxHeight: '100vh',
      }}
      >
        {sideMenu()}
      </Box>
      <AppLoadingModalWindow />
    </>
  );
};

export default Page;
