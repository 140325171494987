import {
  Box, Typography,
} from '@mui/material';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {
  EFeedTab,
  selectFeedTab,
  selectFeedTitle,
  selectIsInternalFilters,
  setFeedTab,
  setIsInternalFilters,
} from '../../../features/DealFeed/lib/feedSlice';
import { useAppDispatch, useAppSelector } from '../../../hooks/stateHooks';
import { AppIconButton } from '../../../shared/AppButton/AppIconButton';
import { CloseIcon } from '../../../icons/Close16px';
import AgendaFeedHeader from './AgendaFeedHeader';
import { stagesetColors } from '../../../theme/stagesetPalette';
import Add from '../../../icons/DealsFiltersInput/Add.svg';
import { FeedHeader } from './Styles/DealFeedStyles';

const DealFeedHeader = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const currentFeedTab = useAppSelector(selectFeedTab);
  const isFilters = useAppSelector(selectIsInternalFilters);
  const tabTitle = useAppSelector(selectFeedTitle);
  const { t } = useTranslation();

  const dealHeader = () => {
    switch (currentFeedTab) {
      case EFeedTab.NONE:
        return undefined;
      case EFeedTab.PRICING_TABLE:
        return tabTitle;
      case EFeedTab.ROOM_INFO:
        return 'Room details';
      case EFeedTab.INTERNAL:
        return isFilters && isMobile ? 'Filters' : 'Activity';
      default:
        return t('Room_Invite_people');
    }
  };

  return (
    <FeedHeader
      id="deal-feed-header"
      className={classNames(
        { 'feed-header-transparent': currentFeedTab === EFeedTab.INTERNAL },
        { 'feed-header-transparent': currentFeedTab === EFeedTab.AGENDA },
      )}
    >
      {
            currentFeedTab !== EFeedTab.AGENDA
              ? (
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    wordBreak: 'break-word',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '20px',
                      fontWeight: 600,
                      lineHeight: '26px',
                      color: stagesetColors.newGrey[800],
                    }}
                  >
                    {dealHeader()}
                  </Typography>
                </Box>
              ) : (
                <AgendaFeedHeader />
              )
          }
      {
            (!isFilters || !isMobile)
            && currentFeedTab === EFeedTab.INTERNAL
        && (
          <AppIconButton
            onClick={() => dispatch(setIsInternalFilters())}
            transparentStroke
            icon={<Add />}
            size="m"
          />
        )
          }
      <AppIconButton
        onClick={() => {
          dispatch(setFeedTab(EFeedTab.NONE));
          dispatch(setIsInternalFilters(false));
        }}
        transparentStroke
        icon={<CloseIcon />}
        size="m"
      />
    </FeedHeader>
  );
};

export default DealFeedHeader;
