import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../hooks/stateHooks';
import { EMenuTabs, setCurrentMenuTab } from '../../Layout/lib/fieldsLayoutSlice';
import { clearProcessState, setIsLayoutEditActive } from '../../../routes-old/process/state/processSlice';
import { EFieldType } from '../../../../shared/Field/Field';
import { EAppRoutes } from '../../../core/router';
import { PenAndPaperIcon20 } from '../../../icons/PenAndPaperIcon';
import { AppIconButton } from '../../../shared/AppButton/AppIconButton';
import { selectLibraryTemplateData } from '../../../pages/templates/lib/templatesSlice';
import { clearTasksState } from '../../../routes-old/process/state/tasksSlice';
import { clearNotificationsState } from '../../../routes-old/process/state/dealNotificationsSlice';
import { EProcessType } from '../../../../shared/process/ProcessMilestoneActionDTO';

const TemplateEditButton = (): JSX.Element | null => {
  const selectedTemplateData = useAppSelector(selectLibraryTemplateData);
  if (!selectedTemplateData || !selectedTemplateData.id) return null;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onEditClick = () => {
    dispatch(setCurrentMenuTab(EMenuTabs.TEMPLATES));
    switch (selectedTemplateData?.type) {
      case EFieldType.SECTION:
        dispatch(setIsLayoutEditActive(true));
        navigate(`${EAppRoutes.templates}/${EFieldType.SECTION}/${selectedTemplateData.id}`);
        break;
      case EFieldType.STAGE:
        dispatch(setIsLayoutEditActive(true));
        navigate(`${EAppRoutes.templates}/${EFieldType.STAGE}/${selectedTemplateData.id}`);
        break;
      case EProcessType.TEMPLATE:
        dispatch(clearProcessState());
        dispatch(clearTasksState());
        dispatch(clearNotificationsState());
        navigate(`${EAppRoutes.deal}/${selectedTemplateData?.id}`);
        break;
      default:
        break;
    }
  };

  return (
    <AppIconButton
      disableRipple
      size="l"
      icon={<PenAndPaperIcon20 />}
      onClick={onEditClick}
    />
  );
};

export default TemplateEditButton;
