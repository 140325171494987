import { useEffect, useState } from 'react';

function useDebouncedValue<T>(value: T, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState<T>();
  const [delayed, setDelayed] = useState<T>();
  const [timer, setTimer] = useState<NodeJS.Timeout>();

  const clear = () => {
    if (timer) clearTimeout(timer);
  };

  const handleValueChange = () => {
    if (value === delayed) return;
    clear();
    const timeout = setTimeout(() => {
      setDebouncedValue(value);
      setDelayed(undefined);
    }, delay);
    setTimer(timeout);
    setDelayed(value);
  };

  useEffect(() => {
    handleValueChange();
    return clear;
  }, [value]);

  return { delayed, debouncedValue, clear };
}

export default useDebouncedValue;
