import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/stateHooks';
import { selectProcessFields } from './state/processSlice';
import {
  selectCurrentStageId,
  selectProcessItemsFetchStatus,
  selectProcessStages,
  setCurrentStage,
} from '../../features/ProcessFields/lib/processItemsSlice';
import { EStateStatus } from '../../core/commonTypes';
import { ProcessStageDTO } from '../../../shared/Field/Field';
import useDealPermissions from '../../hooks/useDealPermissions';
import { EUserDealPermissions, EUserOrganizationPermissions } from '../../../shared/permissions';

const ProcessStageSetterPlugin = (): null => {
  const dispatch = useAppDispatch();
  const processFields = useAppSelector(selectProcessFields);
  const fetchStatus = useAppSelector(selectProcessItemsFetchStatus);
  const stageId = useAppSelector(selectCurrentStageId);
  const fullFields = useAppSelector(selectProcessStages);
  const [checkPermissions] = useDealPermissions();
  const isLayoutEditable: boolean = checkPermissions(
    EUserDealPermissions.DEAL_MEDIA_FIELD_EDIT,
    EUserOrganizationPermissions.ORGANIZATION_DEALS_EDIT_ALL,
  );

  useEffect(() => {
    if (fetchStatus === EStateStatus.IDLE && !stageId && processFields.length) {
      if (fullFields.find((item: ProcessStageDTO) => item && !item?.lock && !item?.hide)) {
        dispatch(setCurrentStage(fullFields.find(
          (item: ProcessStageDTO) => item && !item?.lock && !item?.hide,
        ).id));
      } else if (isLayoutEditable) {
        dispatch(setCurrentStage(fullFields[0].id));
      }
    }
  }, [fetchStatus, stageId, processFields, fullFields]);
  return null;
};

export default ProcessStageSetterPlugin;
