import {
  Box, TextField, Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { stagesetPalette } from '../../theme/stagesetPalette';
import EditLayoutToggleButtons from '../../features/Layout/EditLayoutToggleButtons';
import BackToHomeButton from '../Buttons/BackToHomeButton';
import { useAppSelector } from '../../hooks/stateHooks';
import { ETemplatesTypes, selectLibraryTab } from '../../pages/templates/lib/templatesSlice';

interface Props {
  title: string;
  bgColor?: string;
  onTitleUpdate?: (newTitle: string) => void;
  controls?: JSX.Element[] | boolean;
  preControls?: JSX.Element[] | boolean;
  disableTitleEdit?: boolean;
  backBtn?: boolean;
  editBtns?: boolean;
  fullTitleWidth?: boolean;
  noBackToHomeBtn?: boolean;
}

const EditableHeaderTitle = ({
  title,
  onTitleUpdate,
  bgColor = 'white',
  controls,
  preControls,
  disableTitleEdit,
  backBtn,
  editBtns,
  fullTitleWidth,
  noBackToHomeBtn,
}: Props): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);
  const isSection = useAppSelector(selectLibraryTab) === ETemplatesTypes.SECTIONS;
  const [titleForDisplay, setTitleForDisplay] = useState<string>(title);

  useEffect(() => {
    if (inputRef?.current) {
      inputRef.current.value = title;
    }
    setTitleForDisplay(title);
  }, [title]);

  const handleSave = () => {
    if (inputRef.current && inputRef.current.value !== title && onTitleUpdate) {
      setTitleForDisplay(inputRef.current.value);
      onTitleUpdate(inputRef.current.value);
    }
  };

  return (
    <Box
      className="EditableTemplateHeaderTitleContainer"
      sx={{
        width: '100%',
        minHeight: '48px',
        height: '48px',
        maxHeight: '48px',
        background: bgColor,
        padding: '4px 16px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: '10px 10px 0 0',
        borderBottom: '1px solid #EEF0FA',
        boxShadow: '0px 0px 1px 0px rgba(0, 113, 190, 0.08), 0px 1px 1px 0px rgba(0, 113, 190, 0.07), '
          + '0px 3px 2px 0px rgba(0, 113, 190, 0.04)',
        zIndex: 5,
        gap: '8px',
      }}
    >
      {
        preControls
          && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: '6px',
            }}
          >
            {preControls}
          </Box>
          )
        }
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          gap: '8px',
          width: (isMobile && '65%') || (fullTitleWidth ? '100%' : '45%'),
        }}
      >
        {!noBackToHomeBtn
          && !isMobile
          && (
            <BackToHomeButton
              key="back-to-home-button"
              templateMenu
            />
          )}
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
          }}
        >
          {
            disableTitleEdit
            && (
              <Typography
                noWrap
                sx={{
                  padding: '0px',
                  fontSize: '18px',
                  fontWeight: 600,
                  borderRadius: '4px',
                  paddingX: '8px',
                  paddingTop: '1px',
                  maxWidth: 'calc( 100% - 0px )',
                  color: stagesetPalette.grey?.[500],
                }}
              >
                {titleForDisplay}
              </Typography>
            )
        }
          {
            !disableTitleEdit
          && (
            <TextField
              multiline
              maxRows={2}
              variant="standard"
              size="small"
              fullWidth
              defaultValue={titleForDisplay || 'New Section'}
              InputProps={{
                disableUnderline: true,
                sx: {
                  borderRadius: '4px',
                  paddingX: '8px',
                  paddingY: '4px',
                  '&:hover': {
                    background: '#E5EFFF',
                  },
                  '&.Mui-focused': {
                    background: 'white !important',
                    outline: '1px solid #7084B4',
                  },
                },
              }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
              inputProps={{
                ref: inputRef,
                onKeyDown: (e) => {
                  if (e.nativeEvent.key === 'Enter') {
                    inputRef.current?.blur();
                  }
                },
                onBlur: handleSave,
                sx: {
                  color: stagesetPalette.grey?.[500],
                  padding: '0px',
                  height: '32px',
                  fontSize: '18px',
                  fontWeight: 600,
                },
              }}
            />
          )
          }
        </Box>
      </Box>
      {
        !isMobile
        && editBtns
        && !isSection
        && (
          <Box
            sx={{
              width: 'min-content',
            }}
          >
            <EditLayoutToggleButtons
              variant="reverse"
            />
          </Box>
        )
      }
      <Box
        sx={{
          width: isMobile ? '35%' : '45%',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: '6px',
        }}
      >
        {controls}
      </Box>
    </Box>
  );
};

export default EditableHeaderTitle;
