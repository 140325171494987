import {
  createAsyncThunk, createEntityAdapter, createSlice, EntityState, PayloadAction,
} from '@reduxjs/toolkit';
import { FileDTO } from '../../../../shared/FileDTO';
import { FileStorageApi, IUploadFilesFormData } from '../../../api/FileStorageApi';
import { EStateStatus } from '../../../core/commonTypes';

const fileStorageApi = new FileStorageApi();

export interface FilesAdditionalState {
  status: EStateStatus;
  error?: string;
  fileUploadProgress?: {
    progress?: number,
    name?: string,
  }
}

const filesAdapter = createEntityAdapter<FileDTO>();

export const filesReducerName: string = 'files';

export enum EFilesActions {
  GET_FILES = 'GET_FILES',
  UPLOAD_FILES = 'UPLOAD_FILES',
  DELETE_FILE = 'DELETE_FILE',
}

export type FilesState = FilesAdditionalState & EntityState<FileDTO>;

export const initialState: FilesState = filesAdapter.getInitialState({
  status: EStateStatus.IDLE,
});

export const getOrganizationFiles = createAsyncThunk(
  `${filesReducerName}/${EFilesActions.GET_FILES}`,
  async () => fileStorageApi.getOrganizationFiles(),
);

export const uploadOrganizationFiles = createAsyncThunk(
  `${filesReducerName}/${EFilesActions.UPLOAD_FILES}`,
  async (payload: IUploadFilesFormData, thunkApi) => {
    const updateProgress = function (percent: number | undefined, name: string | undefined): void {
      const data = {
        progress: percent,
        name,
      };
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      thunkApi.dispatch(updateFileUploadProgress(data));
    };
    return fileStorageApi.uploadOrganizationFiles(payload, updateProgress);
  },
);

export const deleteOrganizationFile = createAsyncThunk(
  `${filesReducerName}/${EFilesActions.DELETE_FILE}`,
  async (payload: string) => fileStorageApi.deleteFileByPath({ fullPath: payload }),
);

export const filesSlice = createSlice({
  name: filesReducerName,
  initialState,
  reducers: {
    updateFileUploadProgress: (state, { payload }: PayloadAction<{ progress: number | undefined, name: string | undefined, }>) => {
      state.fileUploadProgress = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrganizationFiles.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(getOrganizationFiles.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        filesAdapter.setAll(state, action.payload);
      })
      .addCase(getOrganizationFiles.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(uploadOrganizationFiles.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(uploadOrganizationFiles.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        filesAdapter.setAll(state, action.payload);
      })
      .addCase(uploadOrganizationFiles.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      })
      .addCase(deleteOrganizationFile.pending, (state) => {
        state.status = EStateStatus.LOADING;
      })
      .addCase(deleteOrganizationFile.fulfilled, (state, action) => {
        state.status = EStateStatus.IDLE;
        filesAdapter.removeOne(state, action.payload);
      })
      .addCase(deleteOrganizationFile.rejected, (state) => {
        state.status = EStateStatus.ERROR;
      });
  },
});

export const {
  selectAll,
  selectTotal,
} = filesAdapter.getSelectors((state: FilesState) => state);

export const {
  updateFileUploadProgress,
} = filesSlice.actions;
