import { Box, styled } from '@mui/material';
import { stagesetColors } from '../../../theme/stagesetPalette';

export const FileItemContainer = styled(Box)(() => ({
  display: 'inline-flex',
  gap: '12px',
  alignItems: 'center',
}));

export const FileItemName = styled(Box)(() => ({
  color: stagesetColors.grey[600],
}));
