import { SvgIcon, SvgIconProps } from '@mui/material';

export const YouTubeSVGIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      height: '20px',
      width: '20px',
    }}
    {...props}
  >
    <path
      d="M17.9848 5.98837C17.7928 5.27083 17.2288 4.70695
        16.5113 4.51488C15.2121 4.1664 9.99996 4.1664 9.99996
        4.1664C9.99996 4.1664 4.78788 4.1664 3.48863 4.51488C2.77109
        4.70695 2.20721 5.27083 2.01514 5.98837C1.66666 7.28762 1.66666
        10 1.66666 10C1.66666 10 1.66666 12.7124 2.01514 14.0117C2.20721
        14.7292 2.77109 15.2931 3.48863 15.4851C4.78788 15.8336 9.99996
        15.8336 9.99996 15.8336C9.99996 15.8336 15.2121 15.8336 16.5113
        15.4851C17.2288 15.2931 17.7928 14.7292 17.9848 14.0117C18.3333
        12.7124 18.3333 10 18.3333 10C18.3333 10 18.3319 7.28762 17.9848 5.98837Z"
      fill="#FF0000"
    />
    <path
      d="M7.83295 12.4997L12.1629 9.99998L7.83295 7.50024V12.4997Z"
      fill="white"
    />
  </SvgIcon>
);

export const VimeoSVGIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      height: '20px',
      width: '20px',
    }}
    {...props}
  >
    <path
      d="M18.3252 6.13855C18.2505 7.7557 17.1176 9.97054 14.9264
        12.7824C12.6606 15.7182 10.7428 17.1867 9.17442 17.1867C8.20353
        17.1867 7.38134 16.2912 6.70918 14.4995L5.36487 9.57275C4.86609
        7.78036 4.33064 6.88483 3.7585 6.88483C3.63381 6.88483 3.19837
        7.14602 2.45087 7.66842L1.66669 6.66095C2.48154 5.94532 3.2904
        5.2237 4.09458 4.49608C5.19017 3.55058 6.01169 3.05284 6.55982
        3.00354C7.85478 2.87893 8.65163 3.76247 8.95037 5.65348C9.27378
        7.69374 9.4985 8.96307 9.62252 9.46014C9.99594 11.1519 10.4067
        11.9981 10.8548 11.9981C11.2036 11.9981 11.7263 11.4504 12.4238
        10.3557C13.1213 9.26091 13.4948 8.42735 13.5441 7.85498C13.6435
        6.90948 13.27 6.4364 12.4238 6.4364C12.0257 6.4364 11.6143 6.52368
        11.1916 6.69759C12.0131 4.03499 13.5821 2.74167 15.898 2.8163C17.6157
        2.86627 18.4246 3.97369 18.3252 6.13855Z"
      fill="#1AB7EA"
    />
  </SvgIcon>
);

export const LoomSVGIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      height: '20px',
      width: '20px',
    }}
    {...props}
  >
    <g
      clipPath="url(#clip0_5027_3566)"
    >
      <path
        d="M19.1666 8.98057H13.8057L18.4485 6.30004L17.4289
        4.53355L12.7861 7.21407L15.4661 2.57162L13.6996
        1.55142L11.0196 6.19386V0.833328H8.98032V6.19438L6.29937
        1.55142L4.53342 2.5711L7.21387 7.21356L2.57104 4.53355L1.55138
        6.29953L6.19422 8.98006H0.833313V11.0194H6.1937L1.55138
        13.7L2.57104 15.4665L7.21336 12.7864L4.53291 17.4289L6.29937
        18.4486L8.97981 13.8056V19.1667H11.0191V13.8061L13.6991
        18.4486L15.4655 17.4289L12.7851 12.7859L17.4279 15.4665L18.4476
        13.7L13.8052 11.0199H19.1656V8.98057H19.1666ZM9.99998 12.7736C8.46229
        12.7736 7.21592 11.5273 7.21592 9.98949C7.21592 8.45175 8.46229 7.20535
        9.99998 7.20535C11.5377 7.20535 12.784 8.45175 12.784 9.98949C12.784
        11.5273 11.5377 12.7736 9.99998 12.7736Z"
        fill="#625DF5"
      />
    </g>
    <defs>
      <clipPath id="clip0_5027_3566">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export const VidyardSVGIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      height: '20px',
      width: '20px',
    }}
    {...props}
  >
    <path
      d="M9.57331 3.80443C10.156 3.80443 10.7386 3.87321
      11.3036 4.00218C11.4449 4.03657 11.5861 3.96779 11.6391
      3.83882C11.6921 3.68406 11.6215 3.5121 11.4714 3.44332H11.4361C10.2178
      3.14239 8.95533 3.14239 7.73703 3.44332C7.56929 3.47771 7.46335 3.64107
      7.49867 3.80443V3.83882C7.56047 3.96779 7.70172 4.03657 7.83414
      4.00218C8.41681 3.899 8.99064 3.80443 9.57331 3.80443Z"
      fill="#5B627D"
    />
    <path
      d="M13.3077 4.59543C13.2812 4.7158 13.3342 4.83617 13.4401
      4.89635C16.8125 6.96844 17.8189 11.3104 15.6913 14.5862C15.6825
      14.5948 15.6825 14.6034 15.6737 14.612C13.5372 17.8878 9.08781
      18.9109 5.69776 16.8302C2.32537 14.7237 1.3366 10.356 3.4907
      7.07162C4.06453 6.19463 4.83259 5.44662 5.7419 4.88775C5.84784
      4.82757 5.90964 4.7072 5.87432 4.58683C5.85667 4.44066 5.71542
      4.32889 5.56534 4.35468C5.55651 4.35468 5.54768 4.35469 5.53885
      4.36328C5.50354 4.36328 5.46822 4.39767 5.43291 4.39767C1.76036
      6.64172 0.639173 11.3448 2.91686 14.9387C5.19455 18.5412 10.0413
      19.5987 13.7403 17.3891C17.404 15.145 18.5252 10.4334 16.2475
      6.84807C15.6384 5.85931 14.7909 5.02532 13.7668 4.43207C13.6344
      4.34609 13.4578 4.38048 13.3695 4.50945C13.3607 4.51804 13.3607
      4.52664 13.3518 4.53524C13.3342 4.52664 13.3077 4.56103 13.3077
      4.59543Z"
      fill="#5B627D"
    />
    <path
      d="M11.1977 6.60734C10.8181 6.52996 10.4297 6.48697 10.0412
      6.47837H9.56451C9.09661 6.46977 8.62872 6.51276 8.16965
      6.60734C6.54525 6.88247 5.05328 7.61329 3.86146 8.72242C3.75552
      8.84279 3.69373 8.99755 3.69373 9.15231L4.44413 14.9731C6.76596
      17.7244 10.9329 18.1371 13.7756 15.9017C14.1199 15.6179 14.4377
      15.3084 14.729 14.9731L14.8261 14.0789C15.5501 13.6834 15.9826
      12.9354 15.9473 12.1272C15.9473 11.5253 15.709 10.9407 15.2676
      10.5022L15.4353 9.11792C15.4706 8.95456 15.4 8.817 15.3029
      8.68803C14.6319 8.06038 13.8462 7.54451 12.9899 7.1662C12.416
      6.93406 11.8157 6.7449 11.1977 6.60734Z"
      fill="#46DB83"
    />
    <path
      d="M8.85824 13.7178C8.62871 13.8382 8.37269 13.8983 8.10784
      13.8811C7.15439 13.8468 6.41281 13.1245 6.44813 12.196V12.1272C6.40399
      11.2158 7.11025 10.442 8.04604 10.3732H8.11667C8.51394 10.3646 8.90238
      10.5108 9.20254 10.7687C9.59099 11.1126 9.80286 11.6113 9.77638
      12.1272C9.79403 12.7806 9.44091 13.3997 8.85824 13.7178Z"
      fill="#46DB83"
    />
    <path
      d="M14.6319 13.7178C14.4024 13.8382 14.1464 13.8897 13.8815
      13.8811C12.9281 13.8468 12.1865 13.1245 12.2218 12.196V12.1272C12.1777
      11.2158 12.8839 10.442 13.8197 10.3732H13.8903C14.2876 10.3646
      14.6761 10.5108 14.9762 10.7687C15.347 11.1298 15.5854 11.5941
      15.5501 12.1272C15.5677 12.7806 15.2146 13.3997 14.6319 13.7178Z"
      fill="#46DB83"
    />
    <path
      d="M6.85422 6.90825C6.68649 5.74754 6.48344 4.56103 6.30687 3.60666C5.74186
      3.62386 5.27397 3.19397 5.25631 2.6523C5.23865 2.10203 5.68007 1.64635 6.24507
      1.62915C6.81891 1.62915 7.29564 2.05905 7.29564 2.58352C7.32212 2.97042 7.11024
      3.33153 6.74828 3.51209L7.96658 6.61592L6.85422 6.90825Z"
      fill="#5B627D"
    />
    <path
      d="M11.1978 6.60734L12.4161 3.5035C11.9217 3.25416 11.7186 2.6695
      11.9746 2.17943C12.2307 1.68935 12.831 1.50019 13.3342 1.74953C13.8109
      2.01607 14.0493 2.60932 13.7756 3.07361C13.6079 3.40892 13.2459 3.61527
      12.8575 3.59808C12.6897 4.55244 12.522 5.74755 12.3189 6.89966C11.957
      6.77929 11.5862 6.67612 11.1978 6.60734Z"
      fill="#5B627D"
    />
    <path
      d="M8.85824 13.7178C8.62871 13.8382 8.37269 13.8983 8.10784
      13.8811C7.15439 13.8468 6.41281 13.1245 6.44813 12.196V12.1272C6.40399
      11.2158 7.11025 10.442 8.04604 10.3732H8.11667C8.51394 10.3646 8.90238
      10.5108 9.20254 10.7687C9.59099 11.1126 9.80286 11.6113 9.77638
      12.1272C9.79403 12.7806 9.44091 13.3997 8.85824 13.7178Z"
      fill="white"
    />
    <path
      d="M14.6319 13.7178C14.4024 13.8382 14.1464 13.8897 13.8815
      13.8811C12.9281 13.8468 12.1865 13.1245 12.2218 12.196V12.1272C12.1777
      11.2158 12.8839 10.442 13.8197 10.3732H13.8903C14.2876 10.3646 14.6761
      10.5108 14.9762 10.7687C15.347 11.1298 15.5854 11.5941 15.5501
      12.1272C15.5677 12.7806 15.2146 13.3997 14.6319 13.7178Z"
      fill="white"
    />
    <path
      d="M13.9875 11.4995C13.855 11.2674 13.5107 11.1986 13.2724
      11.3362C13.2017 11.3706 13.1399 11.4307 13.1046 11.4995C12.9987
      11.6715 12.9369 11.8606 12.9369 12.0584C12.9369 12.2905 12.9722
      12.4883 13.1046 12.6516C13.2106 12.815 13.343 12.8838 13.546 12.9182C13.7226
      12.9096 13.8815 12.8236 13.9875 12.686C14.2082 12.3249 14.2082 11.8692 13.9875
      11.4995Z"
      fill="#5B627D"
    />
    <path
      d="M13.6785 12.0326C13.7906 12.0326 13.8815 11.9441 13.8815
      11.8349C13.8815 11.7256 13.7906 11.6371 13.6785 11.6371C13.5663
      11.6371 13.4754 11.7256 13.4754 11.8349C13.4754 11.9441 13.5663
      12.0326 13.6785 12.0326Z"
      fill="white"
    />
    <path
      d="M8.24911 11.4995C8.11668 11.2674 7.77238 11.1986 7.53402
      11.3362C7.4634 11.3706 7.4016 11.4307 7.36628 11.4995C7.26034
      11.6801 7.19855 11.8864 7.19855 12.0928C7.19855 12.3249 7.23386
      12.5227 7.36628 12.686C7.49871 12.9182 7.79887 13.0128 8.04606
      12.8838C8.13434 12.8322 8.19614 12.772 8.24911 12.686C8.38153
      12.5141 8.44333 12.3077 8.41685 12.0928C8.41685 11.8864 8.36388
      11.6801 8.24911 11.4995Z"
      fill="#5B627D"
    />
    <path
      d="M7.90477 12.0326C8.01691 12.0326 8.10782 11.9441 8.10782
      11.8349C8.10782 11.7256 8.01691 11.6371 7.90477 11.6371C7.79263
      11.6371 7.70172 11.7256 7.70172 11.8349C7.70172 11.9441 7.79263
      12.0326 7.90477 12.0326Z"
      fill="white"
    />
  </SvgIcon>
);

export const AirtableSVGIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      height: '20px',
      width: '20px',
    }}
    {...props}
  >
    <path
      d="M9.104 3.46312L3.1676 5.9194C2.83757 6.05611 2.8409 6.52501 3.17318
      6.65668L9.13433 9.02062C9.65809 9.2283 10.2414 9.2283 10.7651 9.02062L16.7264
      6.65668C17.0585 6.52501 17.062 6.05611 16.7318 5.91949L10.7955 3.46312C10.254
      3.23904 9.64559 3.23904 9.104 3.46312Z"
      fill="#FCB400"
    />
    <path
      d="M10.4786 10.3114V16.2169C10.4786 16.4977 10.7618 16.6902 11.0229
      16.5867L17.6654 14.0083C17.7395 13.9789 17.803 13.928 17.8478 13.8621C17.8926
      13.7961 17.9165 13.7183 17.9165 13.6386V7.73317C17.9165 7.45228 17.6333 7.25995
      17.3722 7.36345L10.7297 9.94177C10.6556 9.97116 10.5921 10.0221 10.5473
      10.088C10.5025 10.154 10.4786 10.2317 10.4786 10.3114Z"
      fill="#18BFFF"
    />
    <path
      d="M8.9275 10.6161L6.95614 11.568L6.75598 11.6647L2.59456 13.6587C2.33086
      13.7859 1.99408 13.5937 1.99408 13.3007V7.75792C1.99408 7.6519 2.04844
      7.56037 2.12134 7.49152C2.15123 7.46171 2.18516 7.43626 2.22214 7.41592C2.3215
      7.35625 2.46334 7.34032 2.58394 7.38802L8.89438 9.8884C9.21514 10.0157 9.24034
      10.465 8.9275 10.6161Z"
      fill="#F82B60"
    />
    <path
      d="M8.92751 10.6161L6.95615 11.568L2.12135 7.49143C2.15125 7.46166 2.18518
      7.43624 2.22215 7.41592C2.32151 7.35625 2.46335 7.34032 2.58395 7.38802L8.89439
      9.8884C9.21515 10.0157 9.24035 10.465 8.92751 10.6161Z"
      fill="black"
      fillOpacity="0.25"
    />
  </SvgIcon>
);

export const PreziSVGIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      height: '20px',
      width: '20px',
    }}
    {...props}
  >
    <path
      d="M9.97917 5.24445C8.73126 5.24445 7.48335 5.73334
      6.63655 6.62223C5.74518 7.51112 5.21037 8.71112 5.25493
      9.95556C5.21037 11.2 5.74518 12.4 6.63655 13.2889C7.52791
      14.1778 8.73126 14.7111 9.97917 14.6667C11.2271 14.6667
      12.4304 14.1778 13.3218 13.2889C14.2132 12.4 14.748 11.2
      14.7034 9.95556C14.7034 8.71112 14.2132 7.46667 13.3218
      6.62223C12.475 5.73334 11.2716 5.24445 9.97917 5.24445ZM13.0098
      13.0222C12.2076 13.8222 11.1379 14.2667 9.97917 14.2667C8.82039
      14.3111 7.75076 13.8222 6.94853 13.0222C6.10173 12.2222 5.65605
      11.1556 5.70062 10C5.65605 8.84445 6.1463 7.77778 6.94853
      6.97778C7.75076 6.13334 8.82039 5.68889 9.97917 5.73334C11.1379
      5.73334 12.2076 6.17778 13.0098 6.97778C13.812 7.77778 14.2577
      8.84445 14.2577 10C14.3023 11.1556 13.8566 12.2222 13.0098
      13.0222ZM9.97917 6.22223C8.9541 6.22223 7.9736 6.62223 7.2605
      7.33334C6.54741 8.04445 6.1463 9.02223 6.1463 10.0444C6.1463 11.0667
      6.54741 12.0444 7.2605 12.7111C7.9736 13.4222 8.9541 13.8222 9.97917
      13.8222C11.0042 13.8222 11.9847 13.4222 12.6533 12.7111C13.3664 12
      13.7675 11.0222 13.7675 10.0444C13.7675 9.02223 13.3664 8.04445 12.6533
      7.33334C11.9847 6.62223 11.0042 6.17778 9.97917 6.22223ZM13.3218
      10.1778C13.3218 11.0667 12.9207 11.9111 12.2521 12.4889C11.5836 13.1111
      10.7368 13.4222 9.84546 13.3333C8.01816 13.2444 6.59198 11.7333 6.68112
      9.86667V9.82223C6.72569 8.93334 7.1268 8.08889 7.79532 7.51112C8.46385
      6.88889 9.31064 6.57778 10.202 6.66667C11.0934 6.71112 11.8956 7.11112
      12.475 7.77778C13.0544 8.44445 13.3664 9.28889 13.3218 10.1778ZM15.2382
      7.82223C14.9262 7.15556 14.5251 6.53334 14.0349 6C13.5001 5.46667 12.9207
      5.06667 12.2076 4.8C11.4945 4.48889 10.7368 4.35556 9.97917 4.35556C9.22151
      4.35556 8.46385 4.48889 7.75075 4.8C7.08223 5.06667 6.45828 5.46667 5.96803
      6C5.43321 6.53334 5.03209 7.11112 4.76468 7.82223C4.4527 8.53334 4.319 9.28889
      4.319 10.0444C4.319 10.8 4.4527 11.5556 4.76468 12.2667C5.65605 14.3556 7.70619
      15.7333 9.97917 15.7333C10.7368 15.7333 11.4945 15.6 12.2076 15.2889C12.8761
      15.0222 13.5001 14.5778 14.0349 14.0889C14.5697 13.5556 14.9708 12.9778 15.2382
      12.3111C15.5502 11.6 15.6839 10.8889 15.6839 10.0889C15.6839 9.24445 15.5502
      8.48889 15.2382 7.82223ZM14.7925 12.0444C14.5251 12.6667 14.1686 13.2 13.6783
      13.6889C13.1881 14.1778 12.6533 14.5333 12.0293 14.8C10.7368 15.3333 9.26608
      15.3333 7.9736 14.8C7.34964 14.5333 6.77025 14.1778 6.28 13.6889C5.78975 13.2
      5.43321 12.6667 5.1658 12.0444C4.63098 10.7556 4.63098 9.28889 5.1658 8C5.43321
      7.37778 5.78975 6.84445 6.28 6.35556C6.77025 5.86667 7.30507 5.51112 7.92903
      5.24445C8.55298 4.97778 9.26608 4.84445 9.9346 4.84445C10.6477 4.84445 11.3162
      4.97778 11.9402 5.24445C12.5641 5.51112 13.1435 5.86667 13.6338 6.35556C14.124
      6.84445 14.4806 7.37778 14.748 8C15.3719 9.28889 15.3719 10.7556 14.7925 12.0444Z"
      fill="black"
    />
    <path
      d="M7.5725 11.2889C7.66163 11.4222 7.75077 11.6 7.8399 11.7333V8.26667C7.43879
      8.75556 7.21595 9.37778 7.21595 10C7.21595 10.4444 7.34965 10.8889 7.5725
      11.2889ZM8.46386 12.3111C8.64213 12.4444 8.86497 12.5333 9.08782 12.6222V7.37778C8.86497
      7.46667 8.64213 7.55556 8.46386 7.68889V12.3111ZM9.71177 7.24444V12.7556H10.3357V7.24444H9.71177ZM10.9597
      12.6222C11.1825 12.5333 11.3608 12.4444 11.5836 12.3111V7.68889C11.4054 7.55556 11.1825 7.46667
      10.9597 7.37778V12.6222ZM12.163 8.31111V11.7778L12.4304 11.3778C12.6533 10.9778 12.7424
      10.5333 12.7424 10.0889C12.787 9.37778 12.5641 8.75556 12.163 8.31111ZM8.73127
      2.08889C8.46386 2.13333 8.19645 2.17778 7.92904 2.26667L8.37472 3.91111L9.04325
      3.77778L8.73127 2.08889ZM4.72013 6.57778L3.29394 5.64444C3.11567 5.86667 2.98197
      6.13333 2.84826 6.35556L4.36358 7.11111C4.49729 6.93333 4.58642 6.75556 4.72013
      6.57778ZM7.12681 2.53333C6.8594 2.62222 6.63656 2.75556 6.36915 2.88889L7.12681
      4.4C7.34965 4.26667 7.52793 4.22222 7.75077 4.13333L7.12681 2.53333ZM6.54743
      4.71111L5.65606 3.28889C5.38865 3.46667 5.16581 3.6 4.94297 3.77778L6.01261
      5.11111L6.54743 4.71111ZM5.52236 5.55556L4.31901 4.35556C4.14074 4.53333 3.96247
      4.75556 3.78419 4.97778L5.12124 6.04444C5.25495 5.86667 5.38865 5.68889 5.52236
      5.55556ZM13.9458 5.11111L15.0154 3.82222L14.3469 3.28889L13.4109 4.71111C13.6338
      4.84444 13.812 4.97778 13.9458 5.11111ZM13.6338 2.88889C13.3664 2.75556 13.1435
      2.66667 12.8761 2.53333L12.2522 4.13333C12.475 4.22222 12.6978 4.31111 12.8761
      4.4L13.6338 2.88889ZM14.9263 6.04444L16.2187 4.97778C16.0405 4.75556 15.8622
      4.57778 15.6393 4.35556L14.436 5.55556L14.9263 6.04444ZM15.5948 7.15556L17.1101
      6.4C16.9764 6.13333 16.8427 5.91111 16.6644 5.68889L15.2382 6.62222C15.3719
      6.75556 15.4611 6.93333 15.5948 7.15556ZM16.0405 8.35556L17.6895 7.91111L17.4221
      7.11111L15.8176 7.73333C15.9068 7.95556 15.9959 8.17778 16.0405 8.35556ZM12.0739
      2.26667C11.8065 2.17778 11.5391 2.13333 11.2717 2.08889L11.0043 3.77778L11.6728
      3.91111L12.0739 2.26667ZM10.4249 2H9.57807L9.6672 3.73333H10.3357L10.4249 2ZM3.9179
      11.6444L2.26887 12.0889L2.53628 12.8889L4.14074 12.2667C4.0516 12.0444 3.96247
      11.8222 3.9179 11.6444ZM16.2187 10.9778L17.9123 11.2444C17.9569 10.9778 18.0015
      10.6667 18.0015 10.4L16.2633 10.3111C16.2633 10.5333 16.2633 10.7556 16.2187
      10.9778ZM15.8622 12.2667L17.4666 12.8889L17.7341 12.0889L16.085 11.6444C16.0405
      11.8222 15.9513 12.0444 15.8622 12.2667ZM17.9123 8.75556L16.2187 9.02222C16.2633
      9.24444 16.2633 9.46667 16.3079 9.68889L18.0015 9.6C17.9569 9.28889 17.9569 9.02222
      17.9123 8.75556ZM15.2828 13.4222L16.709 14.3556C16.8873 14.1333 17.021 13.8667
      17.1547 13.6444L15.6393 12.8889C15.5502 13.0667 15.4165 13.2444 15.2828 13.4222ZM14.4806
      14.4444L15.6839 15.6444C15.8622 15.4667 16.085 15.2444 16.2633 15.0222L14.9263
      13.9556C14.748 14.1333 14.6143 14.3111 14.4806 14.4444ZM13.4555 15.2889L14.3914
      16.7111C14.6143 16.5778 14.8371 16.4 15.06 16.2222L13.9903 14.8889L13.4555
      15.2889ZM4.09617 7.73333L2.49172 7.11111L2.22431 7.91111L3.87333 8.35556C3.96247
      8.13333 4.0516 7.95556 4.09617 7.73333ZM5.07667 13.9556L3.73963 15.0222C3.9179
      15.2444 4.09617 15.4667 4.31901 15.6444L5.56692 14.4444L5.07667 13.9556ZM4.36358
      12.8444L2.84826 13.6C2.98197 13.8667 3.11567 14.0889 3.29394 14.3111L4.72013
      13.3778C4.63099 13.2444 4.49729 13.0667 4.36358 12.8444ZM3.69506 10.3111L2.00146
      10.4444C2.00146 10.7111 2.04603 10.9778 2.0906 11.2444L3.78419 10.9778C3.73963
      10.7556 3.69506 10.5333 3.69506 10.3111ZM6.01261 14.8889L4.94297 16.2222C5.16581
      16.4 5.38865 16.5778 5.61149 16.7111L6.54743 15.2889L6.01261 14.8889ZM3.78419
      9.02222L2.0906 8.75556C2.04603 9.02222 2.00146 9.28889 2.00146 9.6L3.69506
      9.68889C3.69506 9.46667 3.73963 9.24444 3.78419 9.02222ZM12.2522 15.8667L12.8761
      17.4667C13.1435 17.3778 13.4109 17.2444 13.6338 17.1111L12.8761 15.6C12.6533 15.6889
      12.475 15.7778 12.2522 15.8667ZM6.36915 17.1111C6.63656 17.2444 6.8594 17.3333 7.12681
      17.4667L7.75077 15.8667C7.52793 15.7778 7.34965 15.6889 7.17138 15.6L6.36915 17.1111ZM9.57807
      18H10.4249L10.3357 16.2667H9.6672L9.57807 18ZM11.0043 16.2222L11.2717 17.9111C11.5391
      17.8667 11.8065 17.8222 12.0739 17.7333L11.6282 16.0889C11.4054 16.1333 11.2271 16.1778
      11.0043 16.2222ZM7.92904 17.7333C8.19645 17.8222 8.46386 17.8667 8.73127 17.9111L8.99868
      16.2222C8.77584 16.1778 8.553 16.1333 8.37472 16.0889L7.92904 17.7333Z"
      fill="black"
    />
  </SvgIcon>
);

export const GoogleDocsSVGIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      height: '20px',
      width: '20px',
    }}
    {...props}
  >
    <path
      d="M11.5077 1.66667H5.10003C4.49773
     1.66667 3.96973 2.19665 3.96973 2.80329V17.1967C3.96973
     17.8027 4.49707 18.3333 5.10069 18.3333H14.9C15.5029 18.3333 16.0309
     17.8034 16.0309 17.1967V6.21182L13.3929 4.3179L11.5077 1.66667Z"
      fill="#4285F4"
    />
    <path
      d="M6.98474 13.7875H13.015V13.0302H6.9854L6.98474 13.7875ZM6.98474
      15.3028H11.5073V14.5455H6.98474V15.3028ZM6.98474
      9.99966V10.757H13.015V9.99966H6.98474ZM6.98474
      12.2722H13.015V11.5149H6.9854L6.98474 12.2722Z"
      fill="#F1F1F1"
    />
    <path
      d="M11.5076 1.66667V5.07587C11.5076 5.68184 12.0349
      6.21182 12.6379 6.21182H16.0301L11.5076 1.66667Z"
      fill="#A1C2FA"
    />
  </SvgIcon>
);

export const GoogleXLSvgIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      height: '20px',
      width: '20px',
    }}
    {...props}
  >
    <g
      clipPath="url(#clip0_5027_3561)"
    >
      <path
        d="M15.2686 16.926C15.2685 17.1478 15.1802 17.3606
        15.0234 17.5174C14.8665 17.6743 14.6538 17.7625 14.432
        17.7627H5.50463C5.28279 17.7627 5.07001 17.6746 4.91302
        17.5179C4.75603 17.3612 4.66765 17.1485 4.6673 16.9267V3.86001C4.66721
        3.75002 4.68881 3.6411 4.73086 3.53947C4.77291 3.43784 4.83458 3.3455
        4.91235 3.26773C4.99012 3.18996 5.08246 3.12828 5.18409 3.08623C5.28572
        3.04419 5.39465 3.02259 5.50463 3.02267H11.234L15.2686 7.13734V16.926Z"
        fill="#23A566"
      />
      <path
        d="M11.678 7.03L15.2686 10.462V7.11333H12.088C11.9491 7.1149 11.8122
        7.08045 11.6906 7.01334L11.678 7.03Z"
        fill="#1C8F5A"
      />
      <path
        d="M15.2686 7.13067H12.0793C11.8575 7.13049 11.6448 7.04228 11.4879
        6.88542C11.331 6.72855 11.2428 6.51585 11.2426 6.294V3L15.2686 7.13067Z"
        fill="#8ED1B1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.716 15.0453H7.19931V10.262H12.716V15.0453ZM10.3006
        10.952V11.7893H12.038V10.9533L10.3006 10.952ZM10.3006
        12.3167V13.1167H12.038V12.3127L10.3006 12.3167ZM10.3006
        13.6267V14.3673H12.038V13.6227L10.3006 13.6267ZM9.61864
        14.3633V13.6227H7.88198V14.3633H9.61864ZM9.61864
        13.108V12.3127H7.88198V13.112L9.61864 13.108ZM9.61864
        11.798V10.952H7.88198V11.7893L9.61864 11.798Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_5027_3561">
        <rect width="12" height="16.6667" fill="white" transform="translate(4.00064 1.66667)" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export const MSWordSvgIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      height: '20px',
      width: '20px',
    }}
    {...props}
  >
    <path
      d="M17.639 2.70834H6.52758C6.34345 2.70834 6.16686
      2.78149 6.03666 2.91169C5.90646 3.04189 5.83331 3.21848
      5.83331 3.40261V6.35418L12.0833 8.17709L18.3333 6.35418V3.40261C18.3333
      3.21848 18.2602 3.04189 18.13 2.91169C17.9998 2.78149 17.8232 2.70834
      17.639 2.70834Z"
      fill="#41A5EE"
    />
    <path
      d="M18.3333 6.35417H5.83331V10L12.0833 11.8229L18.3333 10V6.35417Z"
      fill="#2B7CD3"
    />
    <path
      d="M18.3333 10H5.83331V13.6458L12.0833 15.4688L18.3333 13.6458V10Z"
      fill="#185ABD"
    />
    <path
      d="M18.3333 13.6458H5.83331V16.5974C5.83331 16.7815 5.90646 16.9581
      6.03666 17.0883C6.16686 17.2185 6.34345 17.2917 6.52758 17.2917H17.639C17.8232
      17.2917 17.9998 17.2185 18.13 17.0883C18.2602 16.9581 18.3333 16.7815 18.3333
      16.5974V13.6458Z"
      fill="#103F91"
    />
    <path
      opacity="0.1"
      d="M10.3474 5.3125H5.83331V15.2083H10.3474C10.5313 15.2078 10.7076 15.1345
      10.8377 15.0044C10.9678 14.8743 11.0411 14.698 11.0416 14.5141V6.00677C11.0411
      5.82281 10.9678 5.64654 10.8377 5.51645C10.7076 5.38637 10.5313
      5.31305 10.3474 5.3125Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M9.82654 5.83334H5.83331V15.7292H9.82654C10.0105 15.7286 10.1868 15.6553
      10.3169 15.5252C10.4469 15.3951 10.5203 15.2189 10.5208 15.0349V6.52761C10.5203
      6.34365 10.4469 6.16738 10.3169 6.0373C10.1868 5.90722 10.0105 5.83389 9.82654 5.83334Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M9.82654 5.83334H5.83331V14.6875H9.82654C10.0105 14.687 10.1868 14.6136 10.3169
      14.4836C10.4469 14.3535 10.5203 14.1772 10.5208 13.9932V6.52761C10.5203 6.34365
      10.4469 6.16738 10.3169 6.0373C10.1868 5.90722 10.0105 5.83389 9.82654 5.83334Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M9.30571 5.83334H5.83331V14.6875H9.30571C9.48967 14.687 9.66594
      14.6136 9.79603 14.4836C9.92611 14.3535 9.99943 14.1772 9.99998 13.9932V6.52761C9.99943
      6.34365 9.92611 6.16738 9.79603 6.0373C9.66594 5.90722 9.48967 5.83389 9.30571 5.83334Z"
      fill="black"
    />
    <path
      d="M2.36093 5.83334H9.30572C9.48985 5.83334 9.66644 5.90649 9.79664 6.03669C9.92684
      6.16689 9.99999 6.34348 9.99999 6.52761V13.4724C9.99999 13.6565 9.92684 13.8331 9.79664
      13.9633C9.66644 14.0935 9.48985 14.1667 9.30572 14.1667H2.36093C2.1768 14.1667 2.0002
      14.0935 1.87 13.9633C1.7398 13.8331 1.66666 13.6565 1.66666 13.4724L1.66666 6.52761C1.66666
      6.34348 1.7398 6.16689 1.87 6.03669C2.0002 5.90649 2.1768 5.83334 2.36093 5.83334Z"
      fill="#185ABD"
    />
    <path
      d="M7.89102 12.6042H6.95352L5.85977 9.01043L4.71394 12.6042H3.77644L2.73477 7.39584H3.67227L4.40144
      11.0417L5.49519 7.50001H6.27644L7.31811 11.0417L8.04727 7.39584H8.93269L7.89102 12.6042Z"
      fill="white"
    />
  </SvgIcon>
);

export const DropboxSVGIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      height: '20px',
      width: '20px',
    }}
    {...props}
  >
    <path
      d="M10.0128 5.47528L5.4235 8.45057L10.0128 11.4259L5.4235
      14.4011L0.834229 11.4098L5.4235 8.43448L0.834229 5.47528L5.4235
      2.5L10.0128 5.47528ZM5.39976 15.3581L9.98903 12.3828L14.5783
      15.3581L9.98903 18.3333L5.39976 15.3581ZM10.0128 11.4098L14.602
      8.43448L10.0128 5.47528L14.5783 2.5L19.1676 5.47528L14.5783
      8.45057L19.1676 11.4259L14.5783 14.4011L10.0128 11.4098Z"
      fill="#0061FF"
    />
  </SvgIcon>
);

export const GoogleFormsSVGIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      height: '20px',
      width: '20px',
    }}
    {...props}
  >
    <path
      d="M11.5917 1.66667H4.84586C4.21053 1.66667 3.65521 2.22201
      3.65521 2.85734V17.1433C3.65521 17.778 4.21053 18.334 4.84586
      18.334H15.163C15.7976 18.334 16.3536 17.7787 16.3536
      17.1433V6.42867L13.5757 4.44467L11.5917 1.66667Z"
      fill="#673AB7"
    />
    <path
      d="M8.41713 13.968H13.179V13.1747H8.41713V13.968ZM8.41713
      8.80934V9.60267H13.179V8.80934H8.41713ZM7.70247 9.20667C7.70247
      9.52401 7.46448 9.84134 7.06782 9.84134C6.67116 9.84134 6.43317
      9.60334 6.43317 9.20667C6.43317 8.81001 6.67116 8.57201 7.06782
      8.57201C7.46448 8.57201 7.70247 8.88867 7.70247 9.20667ZM7.70247
      11.4287C7.70247 11.746 7.46448 12.0633 7.06782 12.0633C6.67116
      12.0633 6.43317 11.8253 6.43317 11.4287C6.43317 11.032 6.67116
      10.794 7.06782 10.794C7.46448 10.794 7.70247 11.1113 7.70247
      11.4287ZM7.70247 13.5713C7.70247 13.8887 7.46448 14.206 7.06782
      14.206C6.67116 14.206 6.43317 13.968 6.43317 13.5713C6.43317
      13.1747 6.67116 12.9367 7.06782 12.9367C7.46448 12.9367 7.70247
      13.254 7.70247 13.5713ZM8.41713 11.984H13.179V11.19H8.41713V11.984Z"
      fill="#F1F1F1"
    />
    <path
      d="M11.9091 6.11134L16.3536 10.4767V6.42868L11.9091 6.11134Z"
      fill="url(#paint0_linear_5027_3575)"
    />
    <path
      d="M11.5917 1.66667V5.23801C11.5917 5.87267 12.1471 6.42867
      12.7824 6.42867H16.3536L11.5917 1.66667Z"
      fill="#B39DDB"
    />
    <path
      d="M4.84586 1.66667C4.21053 1.66667 3.65521 2.22201 3.65521
      2.85734V2.93667C3.65521 2.30134 4.21053 1.74601 4.84586
      1.74601H11.5917V1.66667H4.84586Z"
      fill="white"
      fillOpacity="0.2"
    />
    <path
      d="M15.163 18.254H4.84586C4.21053 18.254 3.65521 17.6987
      3.65521 17.0633V17.1427C3.65521 17.7773 4.21053 18.3333
      4.84586 18.3333H15.163C15.7976 18.3333 16.3536 17.778 16.3536
      17.1427V17.0633C16.3536 17.6987 15.7976 18.254 15.163 18.254Z"
      fill="#311B92"
      fillOpacity="0.2"
    />
    <path
      d="M12.7817 6.42867C12.1471 6.42867 11.5911 5.87334 11.5911
      5.23801V5.31734C11.5911 5.95201 12.1464 6.50801 12.7817
      6.50801H16.353V6.42867H12.7817Z"
      fill="#311B92"
      fillOpacity="0.1"
    />
    <path
      d="M11.5917 1.66667H4.84586C4.21053 1.66667 3.65521 2.22201
      3.65521 2.85734V17.1433C3.65521 17.778 4.21053 18.334 4.84586
      18.334H15.163C15.7976 18.334 16.3536 17.7787 16.3536 17.1433V6.42867L11.5917
      1.66667Z"
      fill="url(#paint1_radial_5027_3575)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5027_3575"
        x1="14.1311"
        y1="6.45877"
        x2="14.1311"
        y2="10.4934"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stopColor="#311B92"
          stopOpacity="0.2"
        />
        <stop
          offset="1"
          stopColor="#311B92"
          stopOpacity="0.02"
        />
      </linearGradient>
      <radialGradient
        id="paint1_radial_5027_3575"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(4.05694 2.11147) scale(163.794 163.796)"
      >
        <stop
          stopColor="white"
          stopOpacity="0.1"
        />
        <stop
          offset="1"
          stopColor="white"
          stopOpacity="0"
        />
      </radialGradient>
    </defs>
  </SvgIcon>
);

export const TypeformSvgIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      height: '20px',
      width: '20px',
    }}
    {...props}
  >
    <g clipPath="url(#clip0_5027_3576)">
      <path
        d="M9.56701 1.69743C11.7083 1.52113 13.2463
      2.08947 14.901 3.74326C16.6876 5.52876 17.8754 7.8426
      18.1955 10.3074C18.5516 13.0483 18.0618 15.0938 16.553
      16.5913C15.1103 18.0232 13.0031 18.4955 10.1882 18.2602L10.0716
      18.25C7.67457 18.0296 5.97912 17.2437 4.33637 15.5799C2.58167
      13.8028 1.65703 11.7851 1.70641 9.66735C1.73056 8.63057 2.02425
      7.74695 2.59065 6.87996C2.96197 6.31158 3.33907 5.86819 4.12788
      5.05504L4.39114 4.78539L4.68133 4.49126C6.55077 2.61216 7.78152
      1.84444 9.56701 1.69743ZM14.7738 3.87058C13.1563 2.25404 11.6703
      1.70488 9.58178 1.87683C7.82172 2.02174 6.61299 2.79067 4.72132
      4.70663L4.58447 4.84547C3.66281 5.78242 3.23089 6.26147 2.85042
      6.81549L2.79506 6.89716L2.74134 6.97841C2.19266 7.81828 1.9097 8.66962
      1.88636 9.67154C1.83821 11.7368 2.74201 13.709 4.46446 15.4535C6.10304
      17.113 7.78642 17.8788 10.2032 18.0808L10.323 18.0904C13.0302 18.2986
      15.0486 17.8309 16.4262 16.4635C17.8899 15.0107 18.3665 13.0207 18.017
      10.3305C17.7021 7.90631 16.5329 5.62869 14.7738 3.87058ZM12.5336
      7.72311V8.60243H10.6661V13.6422H9.7358V8.60243H7.86827V7.72311H12.5336Z"
        fill="#262627"
      />
    </g>
    <defs>
      <clipPath id="clip0_5027_3576">
        <rect
          width="16.6018"
          height="16.6667"
          fill="white"
          transform="translate(1.70453 1.66667)"
        />
      </clipPath>
    </defs>

  </SvgIcon>
);

export const BehanceSvgIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      height: '20px',
      width: '20px',
    }}
    {...props}
  >
    <path
      d="M10.001 19.1667C15.0636 19.1667 19.1676 15.0626
      19.1676 10C19.1676 4.93738 15.0636 0.833328 10.001
      0.833328C4.93835 0.833328 0.83429 4.93738 0.83429
      10C0.83429 15.0626 4.93835 19.1667 10.001 19.1667Z"
      fill="#1769FF"
    />
    <path
      d="M7.3104 5.84641C7.70567 5.84641 8.06647 5.88161
      8.3928 5.95127C8.71913 6.02094 8.9978 6.13534 9.23027
      6.29447C9.46273 6.45361 9.64313 6.66481 9.77147 6.92881C9.8998
      7.19281 9.9636 7.51841 9.9636 7.90634C9.9636 8.32507 9.86827
      8.67414 9.67833 8.95354C9.4884 9.23294 9.2068 9.46174 8.835
      9.63994C9.34687 9.78734 9.72893 10.0455 9.9812 10.4136C10.2335
      10.7817 10.3596 11.2261 10.3596 11.7461C10.3596 12.1648 10.2782
      12.5278 10.1154 12.8343C9.9526 13.1409 9.73333 13.3909 9.4576
      13.5845C9.18187 13.7789 8.868 13.9219 8.51527 14.015C8.16033
      14.1081 7.7944 14.1551 7.42773 14.1543H3.401V5.84641H7.3104ZM7.07793
      9.20874C7.40353 9.20874 7.6712 9.13101 7.88093 8.97627C8.09067
      8.82081 8.19553 8.56927 8.1948 8.22021C8.1948 8.02661 8.1596
      7.86747 8.08993 7.74354C8.02027 7.61961 7.92713 7.52207 7.81053
      7.4524C7.69393 7.38274 7.56047 7.33434 7.4094 7.30721C7.25393
      7.28007 7.09627 7.26614 6.93787 7.26687H5.22847V9.21021L7.07793
      9.20874ZM7.1828 12.7339C7.35513 12.7346 7.52673 12.717 7.69467
      12.6818C7.85747 12.6466 8.0012 12.5887 8.12513 12.5073C8.24907
      12.4259 8.34807 12.3151 8.42213 12.1758C8.49547 12.0365 8.53287
      11.8575 8.53287 11.6405C8.53287 11.2144 8.4126 10.9093 8.17207
      10.7275C7.93153 10.5449 7.61327 10.4539 7.218 10.4539H5.22847V12.7346L7.1828
      12.7339ZM12.6579 12.5593C12.9057 12.7999 13.2629 12.9201 13.7285
      12.9201C14.0622 12.9201 14.3489 12.8365 14.5895 12.6701C14.83
      12.5036 14.9774 12.3269 15.0317 12.1406H16.4859C16.2534 12.8622
      15.8963 13.3777 15.4152 13.6879C14.9341 13.9981 14.3526 14.1536
      13.6699 14.1536C13.1969 14.1536 12.7701 14.0781 12.3902 13.927C12.0103
      13.7759 11.6884 13.5603 11.4244 13.2809C11.1604 13.0015 10.9565
      12.6686 10.8135 12.2807C10.6705 11.8927 10.5987 11.4659 10.5987
      11.001C10.5987 10.5515 10.672 10.1327 10.8194 9.7448C10.9668 9.35687
      11.1765 9.02174 11.4479 8.73867C11.7192 8.45561 12.0433 8.23267
      12.4195 8.06987C12.7957 7.90707 13.213 7.82567 13.6706 7.82567C14.1825
      7.82567 14.6283 7.92467 15.0089 8.12267C15.3888 8.32067 15.7012 8.58614
      15.9454 8.9198C16.1896 9.25347 16.3663 9.63334 16.4749 10.0601C16.5834
      10.4869 16.6223 10.9328 16.5915 11.3985H12.2516C12.2743 11.9316 12.41
      12.3195 12.6579 12.5593ZM14.5257 9.39501C14.3277 9.17794 14.027 9.0694
      13.6237 9.0694C13.3597 9.0694 13.1404 9.11414 12.9666 9.20287C12.7921
      9.29234 12.6527 9.40234 12.5479 9.53434C12.443 9.66634 12.3697 9.80567
      12.3271 9.95307C12.2846 10.1005 12.2589 10.2325 12.2516 10.3483H14.9393C14.8608
      9.93034 14.7229 9.61207 14.5257 9.39501ZM11.9685 6.26807H15.3265V7.1994H11.9685V6.26807Z"
      fill="white"
    />
  </SvgIcon>
);

export const PitchSvgIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      height: '20px',
      width: '20px',
    }}
    {...props}
  >
    <rect
      x="1.00635"
      y="1"
      width="18"
      height="18"
      rx="4"
      fill="#5318EB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.3098 9.08553C15.1908 10.6909 13.9146
      12.1427 12.2747 12.2089V10.9941C12.2747 9.88193
      11.4821 8.92051 10.3901 8.70836L6.32352
      7.91781V5.17441C6.32352 4.43644 6.9971 3.88088
      7.72157 4.02201L11.9043 4.83477C14.2188 5.33852
      15.4566 7.1127 15.3108 9.08553H15.3098ZM7.76571
      12.9208L5.35918 12.4535C5.01278 12.3864 4.69034
      12.6512 4.69034 13.0044V14.6701C4.6904 14.8716
      4.76051 15.0668 4.88865 15.2222C5.01679 15.3777
      5.195 15.4837 5.39274 15.5222L7.79928 15.9896C8.14567
      16.0567 8.46812 15.7909 8.46812 15.4388V13.773C8.46804
      13.5715 8.39793 13.3764 8.26979 13.2209C8.14165 13.0655
      7.96345 12.9594 7.76571 12.9209V12.9208ZM10.1972
      9.61039L6.52697 8.89747C5.99822 8.79477 5.5079 9.19972
      5.5079 9.73801V11.5468C5.51654 11.5487 5.52608 11.5497
      5.53471 11.5516L7.94131 12.0189C8.779 12.182 9.3874
      12.9189 9.3874 13.773V14.1511L10.3192 14.3324C10.8478
      14.4351 11.3382 14.0301 11.3382 13.4918V10.9951C11.3382
      10.3205 10.8594 9.73996 10.1972 9.61136V9.61039Z"
      fill="white"
    />
  </SvgIcon>
);

export const FigmaSvgIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      height: '20px',
      width: '20px',
    }}
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0073 10C10.0073 8.34317 11.3505 7 13.0073
      7C14.6641 7 16.0073 8.34317 16.0073 10C16.0073
      11.6568 14.6641 13 13.0073 13C11.3505 13 10.0073
      11.6568 10.0073 10Z"
      fill="#1ABCFE"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.00732 16C4.00732 14.3432 5.35047 13 7.00732
      13H10.0073V16C10.0073 17.6568 8.66415 19 7.00732
      19C5.35047 19 4.00732 17.6568 4.00732 16Z"
      fill="#0ACF83"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0073 1V7H13.0073C14.6641 7 16.0073 5.65683
      16.0073 4C16.0073 2.34314 14.6641 1 13.0073 1H10.0073Z"
      fill="#FF7262"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.00732 4C4.00732 5.65683 5.35047 7 7.00732
      7H10.0073V1H7.00732C5.35047 1 4.00732 2.34314
      4.00732 4Z"
      fill="#F24E1E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.00732 10C4.00732 11.6568 5.35047 13 7.00732
      13H10.0073V7H7.00732C5.35047 7 4.00732 8.34317
      4.00732 10Z"
      fill="#A259FF"
    />
  </SvgIcon>
);

export const GoogleDriveSVGIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      height: '20px',
      width: '20px',
    }}
    {...props}
  >
    <mask
      id="mask0_7741_26591"
      // style="mask-type:luminance"
      maskUnits="userSpaceOnUse"
      x="0"
      y="1"
      width="20"
      height="17"
    >
      <path
        d="M19.5269 1H0.5V18H19.5269V1Z"
        fill="white"
      />
    </mask>

    <g mask="url(#mask0_7741_26591)">
      <path
        d="M1.93846 15.5699L2.77756 17.0192C2.95193
        17.3244 3.20256 17.5641 3.49679 17.7385L6.49359
        12.5513H0.5C0.5 12.8891 0.58718 13.2269 0.761538
        13.532L1.93846 15.5699Z"
        fill="#0066DA"
      />
      <path
        d="M10.0135 6.44872L7.01666 1.26154C6.72244 1.43589
        6.47179 1.67564 6.29744 1.98077L0.761538 11.5705C0.590386
        11.8691 0.500228 12.2071 0.5 12.5513H6.49359L10.0135 6.44872Z"
        fill="#00AC47"
      />
      <path
        d="M16.5301 17.7385C16.8244 17.5641 17.075 17.3244 17.2494
        17.0192L17.5981 16.4199L19.2655 13.532C19.4397 13.2269 19.5269
        12.8891 19.5269 12.5513H13.5329L14.8083 15.0577L16.5301 17.7385Z"
        fill="#EA4335"
      />
      <path
        d="M10.0135 6.44872L13.0103 1.26154C12.716 1.08718 12.3782 1
        12.0295 1H7.99744C7.64872 1 7.3109 1.09808 7.01666 1.26154L10.0135
        6.44872Z"
        fill="#00832D"
      />
      <path
        d="M13.5333 12.5513H6.49359L3.4968 17.7385C3.79103 17.9128 4.12886
        18 4.47756 18H15.5494C15.8981 18 16.2359 17.9019 16.5301 17.7385L13.5333
        12.5513Z"
        fill="#2684FC"
      />
      <path
        d="M16.4974 6.77565L13.7295 1.98077C13.5551 1.67564 13.3045 1.43589
        13.0103 1.26154L10.0135 6.44872L13.5333 12.5513H19.516C19.516 12.2135
        19.4288 11.8756 19.2546 11.5705L16.4974 6.77565Z"
        fill="#FFBA00"
      />
    </g>
  </SvgIcon>
);

export const CalendlySvgIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      height: '20px',
      width: '20px',
    }}
    {...props}
  >
    <g clipPath="url(#clip0_5027_3568)">
      <path
        d="M13.3994 12.7213C12.8058 13.2375 12.0651
        13.8749 10.722 13.8749H9.91969C8.94787 13.8749
        8.05834 13.5288 7.43136 12.9011C6.80706 12.2697
        6.46234 11.4218 6.47227 10.5421V9.46071C6.4603
        8.57868 6.8052 7.72797 7.43136 7.09503C8.06324
        6.46732 8.94787 6.1203 9.91969 6.1203H10.722C12.0671
        6.1203 12.8058 6.76147 13.3994 7.27383C14.0147 7.80733
        14.5456 8.27258 15.9622 8.27258C16.1785 8.27246 16.3943
        8.25542 16.6078 8.22164L16.6029 8.20914C16.5196 8.00228
        16.4214 7.80151 16.309 7.60835L15.3617 5.99918C14.9349
        5.27357 14.3208 4.67105 13.5813 4.25222C12.8418 3.8334
        12.003 3.61304 11.1492 3.61331H9.25156C8.39777 3.61323
        7.559 3.83367 6.81954 4.25248C6.08009 4.67129 5.46601
        5.2737 5.03903 5.99918L4.0917 7.61315C3.66519 8.339
        3.44067 9.16213 3.44067 9.99999C3.44067 10.8378 3.66519
        11.661 4.0917 12.3868L5.03903 13.996C5.46601 14.7215 6.08009
        15.3239 6.81954 15.7427C7.559 16.1615 8.39777 16.3819 9.25156
        16.3819H11.1462C12.0001 16.3821 12.8389 16.1618 13.5784
        15.7429C14.3179 15.3241 14.9319 14.7216 15.3588 13.996L16.3061
        12.3887C16.4185 12.1959 16.5167 11.9955 16.6 11.7889L16.6049
        11.7774C16.3915 11.7422 16.1756 11.7242 15.9593 11.7235C14.5427
        11.7235 14.0117 12.1869 13.3965 12.7213"
        fill="#006BFF"
      />
      <path
        d="M10.7279 7.09792H9.92461C8.44533 7.09792 7.47546 8.13416
        7.47546 9.46072V10.5421C7.47546 11.8687 8.44729 12.9049 9.92461
        12.9049H10.7279C12.8832 12.9049 12.7137 10.7498 15.9681 10.7498C16.2764
        10.7489 16.5841 10.7766 16.887 10.8324C16.986 10.284 16.986 9.72272
        16.887 9.17426C16.5839 9.2291 16.2764 9.25677 15.9681 9.25693C12.7117
        9.25693 12.8822 7.1008 10.7279 7.1008"
        fill="#006BFF"
      />
      <path
        d="M18.7516 11.6171C18.1975 11.2193 17.5588 10.9498 16.8834
        10.8288V10.8452C16.8247 11.1629 16.7335 11.474 16.6111 11.7738C17.1695
        11.8589 17.6994 12.0731 18.157 12.3986C18.1561 12.4036 18.1544 12.4085
        18.1521 12.413C17.5635 14.283 16.3109 15.8838 14.6217 16.9246C12.9326
        17.9655 10.919 18.3773 8.94677 18.0852C6.97452 17.7932 5.17444 16.8167
        3.87353 15.3331C2.57262 13.8496 1.85718 11.9574 1.85718 10.0002C1.85718
        8.04307 2.57262 6.15085 3.87353 4.66728C5.17444 3.18372 6.97452 2.20722
        8.94677 1.91519C10.919 1.62316 12.9326 2.03496 14.6217 3.0758C16.3109
        4.11663 17.5635 5.71744 18.1521 7.58743C18.1527 7.59252 18.1543 7.59743
        18.157 7.60185C17.6995 7.92743 17.1696 8.1413 16.6111 8.22571C16.7334
        8.52615 16.8246 8.83788 16.8834 9.15622C16.8824 9.1613 16.8824 9.16652
        16.8834 9.1716C17.5588 9.05066 18.1975 8.78117 18.7516 8.38336C19.2846
        7.99885 19.1817 7.55859 19.1014 7.30001C18.4426 5.20361 17.0392 3.40869
        15.1462 2.24138C13.2532 1.07407 10.9963 0.611856 8.78545 0.938716C6.57465
        1.26558 4.5567 2.35982 3.0983 4.02262C1.6399 5.68542 0.83783 7.80642
        0.83783 10.0002C0.83783 12.194 1.6399 14.315 3.0983 15.9778C4.5567
        17.6406 6.57465 18.7348 8.78545 19.0617C10.9963 19.3886 13.2532
        18.9264 15.1462 17.759C17.0392 16.5917 18.4426 14.7968 19.1014
        12.7004C19.1817 12.4418 19.2846 12.0045 18.7516 11.6171Z"
        fill="#006BFF"
      />
      <path
        d="M16.6059 8.22549C16.3925 8.26074 16.1766 8.27875 15.9603
        8.27932C14.5447 8.27932 14.0127 7.81599 13.3975 7.28153C12.8058
        6.76436 12.0671 6.12031 10.7279 6.12031H9.92461C9.46459 6.11199
        9.00751 6.19392 8.5802 6.36131C8.1529 6.52869 7.76398 6.77815
        7.43627 7.09504C6.81056 7.7282 6.46604 8.57885 6.47817
        9.46072V10.5422C6.46578 11.4244 6.81032 12.2754 7.43627
        12.9088C8.06815 13.5365 8.95181 13.8826 9.92461
        13.8826H10.7279C12.072 13.8826 12.8107 13.2404 13.4044
        12.7242C14.0196 12.1917 14.5515 11.7264 15.9671 11.7264C16.1834
        11.7265 16.3993 11.7436 16.6127 11.7774C16.7353 11.4774 16.8262
        11.166 16.8841 10.8478V10.8305C16.581 10.776 16.2734 10.7486
        15.9652 10.7488C12.7088 10.7488 12.8793 12.905 10.725
        12.905H9.92167C8.44238 12.905 7.47252 11.8687 7.47252
        10.5412V9.46072C7.47252 8.13417 8.44434 7.09792 9.92167
        7.09792H10.725C12.8802 7.09792 12.7108 9.25309 15.9652
        9.25309C16.2735 9.25393 16.5811 9.22625 16.8841
        9.17042V9.156C16.8239 8.83735 16.7307 8.52556
        16.6059 8.22549Z"
        fill="#0AE8F0"
      />
      <path
        d="M16.6059 8.22549C16.3925 8.26074 16.1766 8.27875
        15.9603 8.27932C14.5447 8.27932 14.0127 7.81599 13.3975
        7.28153C12.8058 6.76436 12.0671 6.12031 10.7279
        6.12031H9.92461C9.46459 6.11199 9.00751 6.19392
        8.5802 6.36131C8.1529 6.52869 7.76398 6.77815 7.43627
        7.09504C6.81056 7.7282 6.46604 8.57885 6.47817
        9.46072V10.5422C6.46578 11.4244 6.81032 12.2754
        7.43627 12.9088C8.06815 13.5365 8.95181 13.8826
        9.92461 13.8826H10.7279C12.072 13.8826 12.8107
        13.2404 13.4044 12.7242C14.0196 12.1917 14.5515
        11.7264 15.9671 11.7264C16.1834 11.7265 16.3993
        11.7436 16.6127 11.7774C16.7353 11.4774 16.8262
        11.166 16.8841 10.8478V10.8305C16.581 10.776 16.2734
        10.7486 15.9652 10.7488C12.7088 10.7488 12.8793 12.905
        10.725 12.905H9.92167C8.44238 12.905 7.47252 11.8687
        7.47252 10.5412V9.46072C7.47252 8.13417 8.44434
        7.09792 9.92167 7.09792H10.725C12.8802 7.09792
        12.7108 9.25309 15.9652 9.25309C16.2735 9.25393
        16.5811 9.22625 16.8841 9.17042V9.156C16.8239
        8.83735 16.7307 8.52556 16.6059 8.22549Z"
        fill="#0AE8F0"
      />
    </g>
    <defs>
      <clipPath id="clip0_5027_3568">
        <rect
          width="20"
          height="20"
          fill="white"
        />
      </clipPath>
    </defs>
  </SvgIcon>
);

export const MSXLSvgIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      height: '20px',
      width: '20px',
    }}
    {...props}
  >
    <path
      d="M12.0833 2.70834H6.52758C6.34345 2.70834 6.16686 2.78149
      6.03666 2.91169C5.90646 3.04189 5.83331 3.21848 5.83331 3.40261V6.35418L12.0833
      10L15.2083 11.3188L18.3333 10V6.35418L12.0833 2.70834Z"
      fill="#21A366"
    />
    <path
      d="M5.83331 6.35417H12.0833V10H5.83331V6.35417Z"
      fill="#107C41"
    />
    <path
      d="M17.639 2.70834H12.0833V6.35418H18.3333V3.40261C18.3333
      3.21848 18.2602 3.04189 18.13 2.91169C17.9998 2.78149 17.8232 2.70834 17.639 2.70834Z"
      fill="#33C481"
    />
    <path
      d="M12.0833 10H5.83331V16.5974C5.83331 16.7815 5.90646 16.9581 6.03666 17.0883C6.16686
      17.2185 6.34345 17.2917 6.52758 17.2917H17.639C17.8232 17.2917 17.9998 17.2185 18.13
      17.0883C18.2602 16.9581 18.3333 16.7815 18.3333 16.5974V13.6458L12.0833 10Z"
      fill="#185C37"
    />
    <path
      d="M12.0833 10H18.3333V13.6458H12.0833V10Z"
      fill="#107C41"
    />
    <path
      opacity="0.1"
      d="M10.3474 5.3125H5.83331V15.2083H10.3474C10.5313 15.2078 10.7076
      15.1345 10.8377 15.0044C10.9678 14.8743 11.0411 14.698 11.0416
      14.5141V6.00677C11.0411 5.82281 10.9678 5.64654 10.8377 5.51645C10.7076
      5.38637 10.5313 5.31305 10.3474 5.3125Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M9.82654 5.83334H5.83331V15.7292H9.82654C10.0105 15.7286
      10.1868 15.6553 10.3169 15.5252C10.4469 15.3951 10.5203 15.2189
      10.5208 15.0349V6.52761C10.5203 6.34365 10.4469 6.16738 10.3169
      6.0373C10.1868 5.90722 10.0105 5.83389 9.82654 5.83334Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M9.82654 5.83334H5.83331V14.6875H9.82654C10.0105 14.687 10.1868
      14.6136 10.3169 14.4836C10.4469 14.3535 10.5203 14.1772 10.5208
      13.9932V6.52761C10.5203 6.34365 10.4469 6.16738 10.3169 6.0373C10.1868
      5.90722 10.0105 5.83389 9.82654 5.83334Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M9.30571 5.83334H5.83331V14.6875H9.30571C9.48967 14.687 9.66594
      14.6136 9.79603 14.4836C9.92611 14.3535 9.99943 14.1772 9.99998
      13.9932V6.52761C9.99943 6.34365 9.92611 6.16738 9.79603 6.0373C9.66594
      5.90722 9.48967 5.83389 9.30571 5.83334Z"
      fill="black"
    />
    <path
      d="M2.36093 5.83334H9.30572C9.48985 5.83334 9.66644 5.90649 9.79664
      6.03669C9.92684 6.16689 9.99999 6.34348 9.99999 6.52761V13.4724C9.99999
      13.6565 9.92684 13.8331 9.79664 13.9633C9.66644 14.0935 9.48985 14.1667
      9.30572 14.1667H2.36093C2.1768 14.1667 2.0002 14.0935 1.87 13.9633C1.7398 13.8331
      1.66666 13.6565 1.66666 13.4724L1.66666 6.52761C1.66666 6.34348 1.7398 6.16689 1.87
      6.03669C2.0002 5.90649 2.1768 5.83334 2.36093 5.83334Z"
      fill="#107C41"
    />
    <path
      d="M3.50586 12.6042L5.19128 9.99272L3.64753 7.39584H4.88971L5.73242 9.05574C5.80985
      9.21303 5.86315 9.33039 5.89232 9.40782H5.90326C5.95881 9.28213 6.01697 9.15991 6.07773
      9.04116L6.97826 7.39584H8.11836L6.53503 9.97814L8.15846 12.6042H6.94492L5.97148 10.7813C5.92562
      10.7036 5.88676 10.622 5.85534 10.5375H5.84076C5.81235 10.6202 5.7746 10.6993 5.72826
      10.7734L4.72669 12.6042H3.50586Z"
      fill="white"
    />
  </SvgIcon>
);

export const MSPPSvgIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      height: '20px',
      width: '20px',
    }}
    {...props}
  >
    <path
      d="M11.0417 2.70834C9.10953 2.71398 7.25814 3.48401
      5.8919 4.85025C4.52567 6.21648 3.75563 8.06787 3.75
      10L12.988 11.9464L11.0417 2.70834Z"
      fill="#ED6C47"
    />
    <path
      d="M11.0417 2.70834C12.9738 2.71398 14.8252 3.48401 16.1915
      4.85025C17.5577 6.21648 18.3277 8.06787 18.3334 10L14.6875
      12.4781L11.0417 10V2.70834Z"
      fill="#FF8F6B"
    />
    <path
      d="M11.0417 17.2917C12.9738 17.286 14.8252 16.516 16.1914
      15.1498C17.5577 13.7835 18.3277 11.9321 18.3333 10H3.75C3.75563
      11.9321 4.52567 13.7835 5.8919 15.1498C7.25814 16.516 9.10953
      17.286 11.0417 17.2917Z"
      fill="#D35230"
    />
    <path
      opacity="0.1"
      d="M10.3467 5.3125H5.47172C4.28115 6.71528 3.66872 8.51841
      3.75867 10.3561C3.84862 12.1938 4.63422 13.9285 5.9561
      15.2083H10.3467C10.5308 15.2079 10.7072 15.1347 10.8374
      15.0046C10.9676 14.8745 11.041 14.6981 11.0415 14.5141V6.00677C11.041
      5.82272 10.9676 5.64637 10.8374 5.51627C10.7072 5.38617 10.5308 5.31291
      10.3467 5.3125Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M9.82573 5.83334H5.0726C3.999 7.35666 3.5539 9.2352 3.82988
      11.0783C4.10586 12.9214 5.08167 14.5871 6.55437 15.7292H9.82625C10.0102
      15.7286 10.1865 15.6553 10.3166 15.5252C10.4466 15.3951 10.52 15.2189 10.5205
      15.0349V6.52761C10.52 6.34356 10.4466 6.16721 10.3164 6.03711C10.1862 5.90702
      10.0098 5.83376 9.82573 5.83334Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M9.82566 5.83334H5.07254C4.14777 7.14448 3.68511 8.72585 3.75734
      10.3287C3.82956 11.9315 4.43256 13.4649 5.4715 14.6875H9.82618C10.0101
      14.687 10.1864 14.6136 10.3165 14.4836C10.4466 14.3535 10.5199 14.1772
      10.5205 13.9932V6.52761C10.5199 6.34356 10.4465 6.16721 10.3163
      6.03711C10.1861 5.90702 10.0097 5.83376 9.82566 5.83334Z"
      fill="black"
    />
    <path
      opacity="0.2"
      d="M9.30483 5.83334H5.07254C4.14777 7.14448 3.68511 8.72585 3.75734
      10.3287C3.82956 11.9315 4.43256 13.4649 5.4715 14.6875H9.30535C9.48931
      14.687 9.66558 14.6136 9.79567 14.4836C9.92575 14.3535 9.99907 14.1772
      9.99962 13.9932V6.52761C9.99907 6.34356 9.92568 6.16721 9.79548 6.03711C9.66529
      5.90702 9.48888 5.83376 9.30483 5.83334Z"
      fill="black"
    />
    <path
      d="M2.36096 5.83334H9.30575C9.48988 5.83334 9.66647 5.90649 9.79667
      6.03669C9.92687 6.16689 10 6.34348 10 6.52761V13.4724C10 13.6565
      9.92687 13.8331 9.79667 13.9633C9.66647 14.0935 9.48988 14.1667
      9.30575 14.1667H2.36096C2.17683 14.1667 2.00024 14.0935 1.87003
      13.9633C1.73983 13.8331 1.66669 13.6565 1.66669 13.4724L1.66669
      6.52761C1.66669 6.34348 1.73983 6.16689 1.87003 6.03669C2.00024
      5.90649 2.17683 5.83334 2.36096 5.83334Z"
      fill="#C43E1C"
    />
    <path
      d="M5.83177 7.39585C6.34462 7.36132 6.85309 7.50989 7.26667
      7.81512C7.43629 7.96648 7.56927 8.15444 7.65553 8.36477C7.7418 8.57511
      7.77909 8.80231 7.76458 9.02918C7.77027 9.32376 7.68418 9.61281 7.51823
      9.85627C7.34932 10.1253 7.10623 10.3399 6.81823 10.474C6.48896 10.6269
      6.12909 10.7024 5.76615 10.6948H4.77135V12.6042H3.75V7.39585H5.83177ZM4.77083
      9.83491H5.64948C5.92807 9.8554 6.20451 9.77314 6.42656 9.60366C6.51479
      9.5469 6.58587 9.46714 6.63212 9.37298C6.67838 9.27882 6.69807 9.17381
      6.68906 9.06929C6.68906 8.49325 6.35347 8.20523 5.68229 8.20523H4.77083V9.83491Z"
      fill="#F9F7F7"
    />
  </SvgIcon>
);

export const GoogleSlidesSvgIcon = (props: SvgIconProps): JSX.Element => (
  <SvgIcon
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    sx={{
      height: '20px',
      width: '20px',
    }}
    {...props}
  >
    <path
      d="M15.1586 18.3333H4.84117C4.18363 18.3333 3.6507
17.8004 3.6507 17.1429V2.85715C3.6507 2.19961 4.18363
1.66667 4.84117 1.66667H12.3809L16.3491 5.63493V17.1429C16.3491
17.8004 15.8162 18.3333 15.1586 18.3333Z"
      fill="url(#paint0_linear_5027_3570)"
    />
    <path
      opacity="0.05"
      d="M13.9689 13.9682H6.03235V8.01587H13.9689V13.9682ZM7.61965
12.3809H12.3816V9.60317H7.61965V12.3809Z"
      fill="black"
    />
    <path
      opacity="0.07"
      d="M13.7706 13.7698H6.23096V8.21428H13.7706V13.7698ZM7.42143
12.5794H12.5802V9.40476H7.42143V12.5794Z"
      fill="black"
    />
    <path
      opacity="0.05"
      d="M16.3491 6.03175H11.9841V1.66667H12.3809L16.3491 5.63493V6.03175Z"
      fill="black"
    />
    <path
      opacity="0.07"
      d="M16.3493 5.83334H12.1827V1.66667H12.3811L16.3493 5.63493V5.83334Z"
      fill="black"
    />
    <path
      d="M16.3495 5.63493H12.3813V1.66667L16.3495 5.63493Z"
      fill="#FFE8AB"
    />
    <path
      d="M13.5724 13.5714H6.42957V8.41269H13.5724V13.5714ZM7.22322
12.7778H12.7788V9.20634H7.22322V12.7778Z"
      fill="#F1F8E9"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5027_3570"
        x1="19.2098"
        y1="21.2544"
        x2="0.55903"
        y2="1.76032"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E5A505" />
        <stop offset="0.118" stopColor="#EAA904" />
        <stop offset="0.595" stopColor="#F8B301" />
        <stop offset="1" stopColor="#FDB700" />
      </linearGradient>
    </defs>
  </SvgIcon>
);

export const GoogleDocsSVGIconBnW = (): JSX.Element => (
  <SvgIcon
    viewBox="-200 28 2100 2800"
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '24px',
      width: '24px',
    }}
  >
    <path
      d="M1136.4,0H170.4C79.6,0,0,79.5,0,170.5v2159.1c0,90.9,79.5,170.5,170.5,170.5h1477.3
       c90.9,0,170.5-79.5,170.5-170.5V681.8l-397.7-284.1L1136.4,0z"
      fill="#000000"
    />
    <path
      d="M454.5,1818.2h909.1v-113.6H454.6L454.5,1818.2L454.5,1818.2z
       M454.5,2045.5h681.8v-113.6H454.5V2045.5z
      M454.5,1250v113.6h909.1V1250H454.5z
       M454.5,1590.9h909.1v-113.6H454.6L454.5,1590.9L454.5,1590.9z"
      fill="#F1F1F1"
    />
    <path
      d="M1136.4,0v511.4c0,90.9,79.5,170.4,170.4,170.4h511.4L1136.4,0z"
      fill="#afafaf"
    />
  </SvgIcon>
);

export const MSWordSvgIconBnW = (): JSX.Element => (
  <SvgIcon
    viewBox="-4 -4 38 38"
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <path d="M30.667 2H9.333A1.333 1.333 0 0 0 8 3.333V9l12 3.5L32 9V3.333A1.333 1.333 0 0 0 30.667 2z" fill="#cccccc" />
    <path d="M32 9H8v7l12 3.5L32 16z" fill="#999999" />
    <path d="M32 16H8v7l12 3.5L32 23z" fill="#666666" />
    <path d="M32 23H8v5.667A1.333 1.333 0 0 0 9.333 30h21.334A1.333 1.333 0 0 0 32 28.667z" fill="#333333" />
    <path d="M16.667 7H8v19h8.667A1.337 1.337 0 0 0 18 24.667V8.333A1.337 1.337 0 0 0 16.667 7z" opacity=".1" />
    <path d="M15.667 8H8v19h7.667A1.337 1.337 0 0 0 17 25.667V9.333A1.337 1.337 0 0 0 15.667 8z" opacity=".2" />
    <path d="M15.667 8H8v17h7.667A1.337 1.337 0 0 0 17 23.667V9.333A1.337 1.337 0 0 0 15.667 8z" opacity=".2" />
    <path d="M14.667 8H8v17h6.667A1.337 1.337 0 0 0 16 23.667V9.333A1.337 1.337 0 0 0 14.667 8z" opacity=".2" />
    <path
      d="M1.333 8h13.334A1.333 1.333 0 0 1 16 9.333v13.334A1.333 1.333
     0 0 1 14.667 24H1.333A1.333 1.333 0 0 1 0 22.667V9.333A1.333 1.333 0 0 1 1.333 8z"
      fill="#000000"
    />
    <path d="M11.95 21h-1.8l-2.1-6.9-2.2 6.9h-1.8l-2-10h1.8l1.4 7 2.1-6.8h1.5l2 6.8 1.4-7h1.7z" fill="#fff" />
    <path d="M0 0h32v32H0z" fill="none" />
  </SvgIcon>
);

export const GoogleXLSvgIconBnW = (): JSX.Element => (
  <SvgIcon
    viewBox="4 28 18 25"
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '23px',
      width: '17px',
    }}
  >
    <path
      d="m20.902 50.889a1.256 1.256 0 0 1 -1.255 1.255h-13.391a1.256 1.256
     0 0 1 -1.256-1.254v-19.6a1.255 1.255 0 0 1 1.256-1.256h8.594l6.052 6.172z"
      fill="#000000"
    />
    <path d="m20.902 36.196h-4.784a1.256 1.256 0 0 1 -1.255-1.255v-4.941z" fill="#afafaf" />
    <path
      clipRule="evenodd"
      d="m17.073 48.068h-8.275v-7.175h8.275zm-3.623-6.14v1.256h2.606v-1.254zm0
     2.047v1.2h2.606v-1.206zm0 1.965v1.111h2.606v-1.117zm-1.023
      1.105v-1.111h-2.605v1.111zm0-1.883v-1.193h-2.605v1.199zm0-1.965v-1.269h-2.605v1.256z"
      fill="#fff"
      fillRule="evenodd"
    />
  </SvgIcon>
);

export const GoogleSlidesSvgIconBnW = (): JSX.Element => (
  <SvgIcon
    viewBox="-2 0 50 50"
    width="48px"
    height="48px"
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <linearGradient id="854G1v8zw45fnERwTyv0Za" x1="47.209" x2=".209" y1="52.361" y2="3.236" gradientUnits="userSpaceOnUse">
      <stop offset="0" stopColor="#afafaf" />
      <stop offset=".118" stopColor="#eaa904" />
      <stop offset=".595" stopColor="#f8b301" />
      <stop offset="1" stopColor="#fdb700" />
    </linearGradient>
    <path
      fill="#000000"
      d="M37,45H11c-1.657,0-3-1.343-3-3V6c0-1.657,1.343-3,3-3h19l10,10v29C40,43.657,38.657,45,37,45 z"
    />
    <path d="M34,34H14V19h20V34z M18,30h12v-7H18V30z" opacity=".05" />
    <path d="M33.5,33.5h-19v-14h19V33.5z M17.5,30.5h13v-8h-13V30.5z" opacity=".07" />
    <path d="M40,14H29V3h1l10,10V14z" opacity=".05" />
    <path d="M40,13.5H29.5V3H30l10,10V13.5z" opacity=".07" />
    <linearGradient id="854G1v8zw45fnERwTyv0Zb" x1="20.049" x2="40.674" y1="-4.488" y2="19.637" gradientUnits="userSpaceOnUse">
      <stop offset="0" stopColor="#fed100" />
      <stop offset="1" stopColor="#e36001" />
    </linearGradient>
    <path fill="#afafaf" d="M40,13H30V3L40,13z" />
    <path fill="#f1f8e9" d="M33,33H15V20h18V33z M17,31h14v-9H17V31z" />
  </SvgIcon>
);

export const MSPPSvgIconBnW = (): JSX.Element => (
  <SvgIcon
    viewBox="-4 -4 38 38"
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <path d="M18 2A14.041 14.041 0 0 0 4 16l17.737 3.737z" fill="#666666" />
    <path d="M18 2a14.041 14.041 0 0 1 14 14l-7 4.758L18 16z" fill="#cccccc" />
    <path d="M18 30a14.041 14.041 0 0 0 14-14H4a14.041 14.041 0 0 0 14 14z" fill="#333333" />
    <path d="M16.666 7h-9.36a13.914 13.914 0 0 0 .93 19h8.43A1.337 1.337 0 0 0 18 24.667V8.333A1.337 1.337 0 0 0 16.666 7z" opacity=".1" />
    <path
      d="M15.666 8H6.54a13.906 13.906 0 0 0 2.845 19h6.282A1.337
     1.337 0 0 0 17 25.667V9.333A1.337 1.337 0 0 0 15.666 8z"
      opacity=".2"
    />
    <path d="M15.666 8H6.54a13.89 13.89 0 0 0 .766 17h8.361A1.337 1.337 0 0 0 17 23.667V9.333A1.337 1.337 0 0 0 15.666 8z" opacity=".2" />
    <path d="M14.666 8H6.54a13.89 13.89 0 0 0 .766 17h7.361A1.337 1.337 0 0 0 16 23.667V9.333A1.337 1.337 0 0 0 14.666 8z" opacity=".2" />
    <path
      d="M1.333 8h13.334A1.333 1.333 0 0 1 16 9.333v13.334A1.333 1.333
0 0 1 14.667 24H1.333A1.333 1.333 0 0 1 0 22.667V9.333A1.333 1.333 0 0 1 1.333 8z"
      fill="#000000"
    />
    <path
      d="M7.997 11a4.168 4.168 0 0 1 2.755.805 2.878 2.878 0 0 1 .956 2.331 2.726 2.726 0 0 1-.473
 1.588 3.164 3.164 0 0 1-1.344 1.186 4.57 4.57 0 0 1-2.02.424h-1.91V21H4V11zM5.96 15.683h1.687a2.194
  2.194 0 0 0 1.492-.444 1.107 1.107 0 0 0 .504-1.026q0-1.659-1.933-1.659H5.96z"
      fill="#f9f7f7"
    />
    <path d="M0 0h32v32H0z" fill="none" />
  </SvgIcon>
);

export const MSXLSvgIconBnW = (): JSX.Element => (
  <SvgIcon
    viewBox="-4 -4 38 38"
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <path d="M30.667 2H9.333A1.333 1.333 0 0 0 8 3.333V9l12 3.5L32 9V3.333A1.333 1.333 0 0 0 30.667 2z" fill="#cccccc" />
    <path d="M32 9H8v7l12 3.5L32 16z" fill="#999999" />
    <path d="M32 16H8v7l12 3.5L32 23z" fill="#666666" />
    <path d="M32 23H8v5.667A1.333 1.333 0 0 0 9.333 30h21.334A1.333 1.333 0 0 0 32 28.667z" fill="#333333" />
    <path d="M16.667 7H8v19h8.667A1.337 1.337 0 0 0 18 24.667V8.333A1.337 1.337 0 0 0 16.667 7z" opacity=".1" />
    <path d="M15.667 8H8v19h7.667A1.337 1.337 0 0 0 17 25.667V9.333A1.337 1.337 0 0 0 15.667 8z" opacity=".2" />
    <path d="M15.667 8H8v17h7.667A1.337 1.337 0 0 0 17 23.667V9.333A1.337 1.337 0 0 0 15.667 8z" opacity=".2" />
    <path d="M14.667 8H8v17h6.667A1.337 1.337 0 0 0 16 23.667V9.333A1.337 1.337 0 0 0 14.667 8z" opacity=".2" />
    <path
      d="M1.333 8h13.334A1.333 1.333 0 0 1 16 9.333v13.334A1.333 1.333
     0 0 1 14.667 24H1.333A1.333 1.333 0 0 1 0 22.667V9.333A1.333 1.333 0 0 1 1.333 8z"
      fill="#000000"
    />
    <path d="M0 0h32v32H0z" fill="none" />
    <path
      d="M3.533 21l3.236-5.014L3.805 11H6.19l1.618 3.187q.223.453.307.676h.021q.16-.362.335-.704L10.2
 11h2.189l-3.04 4.958L12.466 21h-2.33l-1.869-3.5a2.922 2.922
  0 0 1-.223-.468h-.028a2.207 2.207 0 0 1-.216.453L5.877 21z"
      fill="#fff"
    />

    <path fill="none" d="M0 0h32v32H0z" />
  </SvgIcon>
);
